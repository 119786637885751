/* TAKEN FROM https://www.wur.nl/ */

.bcpNotificationBar {
    background-color: #ffffff;
}

.bcpNotificationBar:before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    content: " ";
    z-index: 1;
}

.bcpNotificationBarContent {
    box-sizing: border-box;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 30px 30px 0px;
    color: #000000;
    font-size: 13px;
    line-height: 21px;
    padding-bottom: 10px;
    position: relative;
    z-index: 2;
    background-color: #fff;
}

.bcpNotificationBarTemplate {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    max-width: 940px;
}

.bcpNotificationBarTemplate > h1 {
    text-align: left;
    line-height: 1.1em;
    font-size: 18px;
    margin-bottom: 15px;
}

.bcpNotificationBarGDPR {
    min-width: 768px;
    background-color: #f3fcff;
    line-height: 1;
    overflow-wrap: break-word;
    /* only supported by a few browsers */
    overflow-wrap: hyphenate-word;
}

.bcpNotificationBarGDPR .bcpGDPRContent {
    margin: 0;
    color: #666;
    padding: 0px;
}

.bcpNotificationBarButtons {
    padding: 10px 0;
    padding-right: 10px;
}

.bcpNotificationBarButtons > div {
    display: inline-block;
    float: left;
}

.bcpNotificationBarButtons .bcpObjectiveButtonsTemplate {
    padding: 0px 0px;
}

.bcpObjectiveTemplate button,
.bcpObjectiveTemplate button:hover,
.bcpObjectiveTemplate button:focus,
.bcpObjectiveTemplate button:active {
    outline: none !important;
}

.bcObjectiveUitklap {
    max-height: 0px;
    width: 100%;
    transition: max-height 0.3s;
    overflow: hidden;
}

.bcpNotificationBarContent.show-objectives .bcObjectiveUitklap {
    max-height: 500px;
}

.bcGDPR .bcpObjectiveTemplate .bcpObjective:hover {
    background-color: transparent;
}

.bccomplexobject {
    margin: 0px auto;
    max-width: 940px;
}

/* styles from screens lower than 768px; */
@media screen and (max-width: 768px) {
    .bcpNotificationBarGDPR {
        width: 100%;
        min-width: 0;
    }

    .bcpNotificationBarContent {
        border-top: 1px solid #f2f2f2;
    }

    .bcpNotificationBarTemplate {
        flex-direction: column;
    }

    .bcpNotificationBarButtons {
        margin-top: 5px;
        min-width: 100%;
        width: 100%;
    }

    .bcpObjectives {
        margin-top: 15px;
    }
}

/* styles from screens lower than 513px */
@media screen and (max-width: 513px) {
    .bcObjective {
        /*transform: translateX(10px);*/
    }

    .bcpNotificationBarGDPR {
        max-width: 480px;
    }

    .bcpNotificationBarGDPR .bcpGDPRContent {
        padding: 0px 20px;
    }

    .bcpNotificationBarTemplate {
        padding: 20px 20px;
    }

    .bcpNotificationBarCloseVerticalCenter {
        margin-top: 10px;
    }

    .bcpNotificationBarContent {
        padding: 0px;
    }

    .bcpNotificationBarButtons > div {
        display: unset;
        float: inherit;
    }
}

input.buttonInput.primaryAction:hover,
input.buttonInput.primaryAction:focus {
    background-color: #dedccf;
}

/* Extra door Marjolijn: Styling neccesary for the dialogue after changing close interaction to refresh page */
.bcGDPR {
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.bcGDPR:after {
    content: "";
    display: block;
    clear: both;
}

.bcGDPR .bcAfterSubmitTextWrapper {
    padding: 20px;
}

.bcpObjectiveTemplate {
    padding: 10px 18px 20px 0;
    width: auto;
    height: 100%;
    font-size: 14px;
    box-sizing: border-box;
}

.bcpObjectiveButtonsTemplate {
    padding: 0 15px 20px;
}

.bcpObjectiveTemplate .bcpObjectives {
    padding: 0px;
    margin: 0px 0px 8px;
    list-style-type: none;
    font-family: Verdana;
}
.bcpObjectiveTemplate ul li {
    margin: 0;
}

.bcpObjectiveTemplate .bcpObjective {
    min-height: 30px;
    line-height: 20px;
    padding: 5px 0px;
}
.bcpObjectiveTemplate .bcpObjective:hover,
.bcpObjectiveTemplate .bcpObjective:focus {
    background-color: #f7f7f7;
}

.bcpObjectiveTemplate .bcpObjective input[type="checkbox"] {
    margin: 4px 3px;
}
.bcpObjectiveTemplate .bcpObjective input[type="checkbox"],
.bcpObjectiveTemplate .bcpObjective label {
    line-height: 20px;
    width: 20px;
    font-weight: normal;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}
.bcpObjectiveTemplate .bcpObjective label {
    padding-bottom: 5px;
    font-size: 13px;
    color: #000000;
    width: calc(100% - 60px);
    margin-top: -3px;
    font-weight: 700;
}
.bcpObjectiveTemplate .bcpObjective label.bcpDescription {
    color: #000000;
    margin-left: 30px;
    font-weight: 100;
    font-size: 13px;
}
.bcpObjectiveTemplate .bcpObjective .bcpObjectiveCheckbox {
    width: 16px;
    height: 16px;
    margin: 6px 0px;
}

.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton:hover,
.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton:focus,
.bcpObjectiveTemplate .bcpConsentButton:hover,
.bcpObjectiveTemplate .bcpConsentButton:focus {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    background-color: #ddd;
}
.bcpObjectiveTemplate .bcpOkButton:hover,
.bcpObjectiveTemplate .bcpOkButton:focus,
.bcpObjectiveTemplate .bcpConsentOKButton:hover,
.bcpObjectiveTemplate .bcpConsentOKButton:focus {
    box-shadow: inset 0 0px 0px 0 rgba(0, 0, 0, 0);
    background-color: #007c00;
    opacity: 0.8;
}

.bcGDPR .bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpConsentOKButton {
    background-color: #007c00;
    color: #fff;
    padding: 13px 25px;
    border-radius: 25px/25px;
    font-size: 18px;
    font-weight: normal;
    box-shadow: none;
    border: none;
}

.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentCancelButton {
    background-color: #eeeeee;
    color: #555555;
    padding: 13px 25px;
    border-radius: 25px/25px;
    font-size: 16px;
    font-weight: normal;
    box-shadow: none;
    border: none;
}

.bcGDPR .bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpConsentOKButton,
.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentCancelButton {
    word-break: break-all;
    max-width: 400px;
    margin: 10px 0px;
}

/* styles from screens lower 768px; */
@media screen and (max-width: 768px) {
    .bcpObjectiveTemplate .bcpObjective label {
        padding-left: 5px;
    }
}

/* styles from screens lower than 513px */
@media screen and (max-width: 513px) {
    .bcpObjectiveTemplate {
        font-family: Arial;
        font-size: 16px;
        line-height: 20px;
        padding: 0 10px 10px;
    }
    .bcpObjectiveTemplate .bcpObjective {
        color: #666666;
        min-height: 30px;
    }

    .bcpObjectiveTemplate .bcpObjective .bcpObjectiveCheckbox {
        width: 32px;
        height: 32px;
    }

    .bcpObjectiveTemplate .bcpObjective label {
        width: calc(100% - 50px);
        padding-left: 15px;
    }

    .bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
    .bcpObjectiveTemplate .bcpConsentButton {
        margin: 5px;
    }

    .bcpConsentOKButton,
    .bcpConsentCancelButton {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
    .bcpObjectiveTemplate .bcpConsentButton,
    .bcpObjectiveTemplate .bcpOkButton {
        border-radius: 25px;

        font-size: 16px;
        min-height: 45px;

        text-align: center;
        padding: 5px 10px;
        width: 100%;
    }
}

.bcInitialConsent {
    display: grid;
    grid-template-columns: 1fr !important;
    justify-content: start !important;
    gap: 8px !important;
}
.bcInitialConsent p:last-of-type {
    margin-bottom: 12px !important;
}
.bcInitialConsent a:not(.button) {
    cursor: pointer;
    text-decoration: underline;
    -webkit-text-decoration-color: var(--apt-grey-5);
    text-decoration-color: var(--apt-grey-5);
    text-underline-offset: .2em;
    box-sizing: border-box;
}
.bcInitialConsent a:not(.button):hover,
.bcInitialConsent a:not(.button):focus-visible {
    -webkit-text-decoration-color: var(--apt-primary);
    text-decoration-color: var(--apt-primary);
}
.bcInitialConsent .button.button.button,
.bcpNotificationBar .button.button.button {
    display: flex !important;
    align-items: center !important;
    justify-self: center !important;
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 3px !important;
    min-height: 40px !important;
}
@media (min-width:376px) {
    .bcInitialConsent .button.button.button {
        justify-self: start !important;
    }
}
@media (min-width: 768px) {
    .bcInitialConsent {
        grid-template-columns: auto auto auto 1fr !important;
        grid-template-rows: auto auto auto !important;
        gap: 0px 16px !important;
    }
    .bcInitialConsent h2,
    .bcInitialConsent p {
        grid-column: 1 / -1 !important;
    }
    .bcInitialConsent p:last-of-type {
        margin-bottom: 20px !important;
    }
    .bcInitialConsent .button.button.button {
        grid-column: span 1 / span 1 !important;
        grid-row: 3 / 4 !important;
    }
}
.bcGiveConsentWrapper {
    display: flex;
    gap: 16px !important;
    margin-top: 20px !important;
    flex-wrap: wrap !important;
}