.offer-cards-list-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 34px;

  @media (min-width: 992px) {
    padding-top: 71px;
    padding-bottom: 71px;
  }
}

.offer-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--25px);

  @media (min-width: 512px) {
    margin-bottom: var(--68px);
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
}

.offer-cards-list-container h1,
.offer-cards-list-container h2 {
  padding-right: 1.5em;
  margin-bottom: 0;
}

.offer-cards-results-sort {
  width: 100%;
  max-width: 400px;
  flex: 0 0 auto;
  padding-top: 1em;
}

.offer-cards-results-header {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  padding-top: 1em;

  @media (min-width: 512px) {
    flex-direction: row;
  }
}

.offer-cards-results-header__quantity {
  flex: 0 0 auto;
  font-size: var(--18px);
  line-height: 30px;
  color: var(--apt-black);
  font-weight: var(--fw-regular);
  margin-right: 2em;
  margin-bottom: 1em;

  @media (min-width: 512px) {
    margin-bottom: 0;
  }
}

.offer-cards-results-header .tag-list {
  flex: 1 1 auto;
}

.offer-cards-results-header__quantity > span {
  display: inline-block;
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
  padding-right: 0.25em;
}

.offer-cards-list {
  flex-wrap: wrap;
  width: 100%;

  @include min-media(920px) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

.offer-cards-list-container .offer-cards-list {
  @include max-media(919px) {
    width: calc(100% + 20px);
  }
}

.offer-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;

  @include min-media(660px) {
    width: 50%;
  }
  @include min-media(1200px) {
    width: 33.333%;
  }
}

.offer-cards-list-item .card__content {
  padding-top: 20px;
}

.offer-cards-list-item .news-card {

  figure {
    width: 100%;
    flex: 1 1 auto;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .card__content {
    flex: 0 0 auto;
  }
}

.offer-cards-list-item .offer-card-featured {

  @include max-media(659px) {
    max-height: 700px;
  }

  @include min-media(660px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }

  figure {
    width: 100%;
    flex: 1 1 auto;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  footer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .card__content {
    flex: 0 0 auto;
  }
}

.featured-card-link-wrapper {
  position: relative;
}

.offer-cards-list-item.swiper-slide {
  @include min-media(1200px) {
    width: 100%;
  }
}

.offer-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.offer-card .card__content > span {
  color: var(--apt-grey-4);
}

.offer-card .card__content > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.offer-card .card__content > ul li {
  padding-right: 0.5em;
  margin-right: 0.5em;
  position: relative;
}

.offer-card .card__content > ul li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.offer-card .card__content .heading {
  margin-bottom: 15px;
}

.offer-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.offer-card {
  position: relative;
  display: flex;
  flex-direction: column;
}

.offer-cards-list-item--featured {
  @media (max-width: 659px) {
    position: relative;
    height: 0;
    padding-top: calc(135% + 30px);
  }
}

.offer-cards-list-item--featured .offer-card {
  @media (max-width: 659px) {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 30px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
}

.offer-cards-list-item--featured .offer-card figure {
  height: 100%;
  border-bottom-right-radius: var(--10px);
  border-bottom-left-radius: var(--10px);

  img {
    height: 100%;
    aspect-ratio: none;
  }
}

.offer-cards-list-item--featured .offer-card .card__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.offer-card-listing-wrapper {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -18px;
}

.offer-card-listing {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.offer-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;
}

.offer-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.offer-card-listing > span:last-child s {
  font-size: var(--16px);
  line-height: 28px;
  letter-spacing: 0.89px;
  display: inline-block;

  @include min-media(992px) {
    padding-right: 5px;
  }
}

.offer-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
  font-size: var(--18px);
  letter-spacing: 1px;

  @include min-media(992px) {
    padding-left: 2px;
  }
}

/* Exception for Zakelijk leasen where the grow-segment is removed, so the grow has to be repositioned */

// op .card.offer-card.scale-on-hover, .offer-card wordt waarschijnlijk dan vervangen?
// Aanpassing aan de HTML: verplaats .apply-cta vanuit .offer-card-listing-header naar .offer-card-listing-wrapper boven de .offer-card-listing
.zakelijk-leasen-compact-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.zakelijk-leasen-compact-card .apply-cta {
  margin-bottom: 0;
}

.zakelijk-leasen-compact-card .offer-card-listing-wrapper {
  margin-top: 0;
}