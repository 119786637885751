.overview-cards-list-container {
  position: relative;
  padding-top: 56px;
  padding-bottom: 34px;

  @media (min-width: 992px) {
    padding-top: 101px;
    padding-bottom: 71px;
  }
}

.overview-cards-list-container .content-card {
  display: flex;
  flex-direction: column;
}

.overview-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--25px);

  @media (min-width: 512px) {
    margin-bottom: var(--68px);
  }
}

.overview-cards-list-container h1,
.overview-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.overview-cards-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.overview-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;

  @include min-media(612px) {
    width: 50%;
  }

  @include min-media(1200px) {
    width: 25%;
  }
}

.overview-cards-list-item--featured {
  width: 100%;

  @include min-media(612px) {
    width: 50%;
  }
  @include min-media(1200px) {
    width: 33.333%;
  }
}

.overview-cards-list-item--cta {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include min-media(1200px) {
        width: 50%;
    }

    div {
        max-width: 333px;
    }

    a {
        margin-top: 1em;
    }
}

.overview-cards-list-item:not(.overview-cards-list-item--featured) .card__content .heading {
  flex: 1 1 auto;

  @media (min-width: 1200px) {
    font-size: var(--18px);
    line-height: 28px;
  }
}

.overview-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.overview-card .card__content .heading {
  margin-bottom: 32px;
}

.overview-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}
