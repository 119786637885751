* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #48E2FF;
}

h1, h2, h3, h4, h5, h6, p, q {
  padding: 0;
  margin: 0;
  word-break: break-word;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:active {

  }
}

html, body {
  background-color: var(--apt-white);
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 18px; // important for the rems
  transition: opacity 0.2s ease 0s;
}

html {
  scroll-behavior: smooth;
  height: 100%;

  @include reduced-motion {
    scroll-behavior: auto;
  }
}

body {
  margin: 0 auto;
  transform-origin: top center;
  word-break: break-word;
  line-height: 1.5;
  overflow-x: hidden;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  min-height: 100%;
  margin-top: 52px;
//   overflow-x: hidden;

  @media (min-width: 992px) {
    margin-top: 130px;
  }
  //@supports not (position: sticky) {
  //  margin-top: 130px;
  //}
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.opaque {
  opacity: 0;
}

main {
  flex-grow: 1;
}

@include focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  border-radius: 0.1px;
  text-decoration: none;
}

input[type]:focus,
textarea:focus,
select[id]:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--apt-primary);
  border-radius: inherit;
}

.error-text {
  color: var(--apt-error-1);
}

// grid

.grid {
  width: 100%;
  //overflow-x: hidden;
}

.grid--padding {
  padding-top: 25px;
  padding-bottom: 15px;

  @media (min-width: 512px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media (min-width: 992px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.grid--extra-padding {
  padding-top: 35px;
  padding-bottom: 15px;

  @media (min-width: 512px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  @media (min-width: 992px) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.grid-item {
  overflow-x: hidden;
}

.grid-item--no-overflow {
  overflow-x: visible;
}

.grid-item--inline-offset .zebra-grid {
  @include min-media(1200px) {
    gap: 70px;
  }
}

.grid-item--inline-offset > * {
  @include min-media(1440px) {
    padding-right: 0 !important;
  }
}

.grid-item--inline-offset .checkbox-wrapper ul li label {
  font-size: var(--14px);
}

.grid-item > * {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--15px);
  padding-right: var(--15px);

  @include min-media(768px) {
    padding-left: var(--50px);
    padding-right: var(--50px);
  }

  @include min-media(1200px) {
    padding-left: var(--85px);
    padding-right: var(--85px);
  }
}

.grid > .grid-item > h1 {
  @include max-media(767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.grid-item > [data-stretch="2"] {
  max-width: 1510px;
}

.grid-item > [data-stretch="3"] {
  max-width: 1440px;
}

.grid-item > [data-stretch="4"] {
  max-width: 1218px;
}

.grid-item > [data-stretch="4.5"] {
  max-width: 1000px;
}

.grid-item > [data-stretch="5"] {
  max-width: 780px;
}

// replace by bull
rbb > i {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
}

rbb::before {
  content: "\2022";
}

rbb[spaces]::before {
  content: "\00a0\2022\00a0";
}


.cards-list {
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media (min-width: 612px) {
    max-width: none;
    flex-direction: row;
  }

  @include min-media(992px) {
    flex-wrap: nowrap;
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @include min-media(1200px) {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.cards-list-item {
  padding: 10px;
  margin-bottom: 20px;

  @include min-media(612px) {
    width: 50%;
  }

  @include min-media(992px) {
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }

  @include min-media(1200px) {
    padding: 25px;
  }
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--10px);
  box-shadow: var(--shadow);
  background-color: var(--apt-white);
  transition: box-shadow 0.2s ease;
}

.card .heading {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-link-wrapper {
  display: block;
  height: 100%;
}

.offer-cards-list-item .offer-card .offer-card-slider-container {
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

a.card-link-wrapper,
a.card-link-wrapper:hover,
a.card-link-wrapper:focus,
a.card-link-wrapper:active {
  text-decoration: none;
}

a.card-link-wrapper:hover > .card {
  @media (any-hover: hover) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 992px) and (any-hover: hover) {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 1200px) and (any-hover: hover) {
    box-shadow: var(--shadow-hover);
  }
}

a.card-link-wrapper:hover .read-more-button,
a.card-link-wrapper:hover .read-more-button i.fal {
  color: var(--apt-primary);
}

.card figure {
  width: 100%;
  border-top-right-radius: var(--10px);
  border-top-left-radius: var(--10px);
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;

  @supports not (aspect-ratio: 3 / 2) {
    height: 0;
    padding-top: 66.67%;
  }

  &.contact-cta-card__figure{
      @supports not (aspect-ratio: 3 / 2) {
          height: 30px;
      }
  }
}

.card figure img {
  display: block;
  width: 100.01%;
  aspect-ratio: 4/3;
  object-fit: cover;

  @supports not (aspect-ratio: 3 / 1.9) {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.card .scale-on-hover > figure img {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.card a[href] {
  text-decoration: none;
}

@media (any-hover: hover) {
  .card a:hover .scale-on-hover > figure img {
    transform: scale(1.06);
  }
}


.scale-on-hover {
  @media (any-hover: hover) {
    transition: transform 0.3s ease, box-shadow 0.2s ease;

    &:hover {
      transform: scale(1.01);
    }
  }
}

.card .tag-list {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 1;

  @media (min-width: 1200px) {
    top: 30px;
    left: 30px;
    right: 30px;
  }
}

.offer-card .tag-list {
  right: 100px;
}

.card__content {
  position: relative;
  width: 100%;
  height: auto;
  padding: 34px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;

  @media (min-width: 512px) {
    padding: 22px;
  }

  @media (min-width: 1200px) {
    padding: 31px 27px 36px 29px;
  }
}

.card__content header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 auto;
}

.card__content h1 {
  width: 100%;
}

.card__content time {
  color: var(--apt-grey-4);
  letter-spacing: -0.02em;

  rbb[spaces]::before {
    letter-spacing: 0;
  }
}

.card-link-wrapper .blue-border {
  padding: 2px;
  background: none;
}

.card-link-wrapper .blue-border::before {
  content: '';
  position: absolute;
  top: 0; right: 0;
  bottom:0; left: 0;
  background: linear-gradient(135deg, #4157ff, #4157ff, #4157ff, #4157ff, #4157ff, #4157ff, #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);
  border-radius: var(--10px);
  transition: opacity 0.2s linear;
}

.card-link-wrapper .blue-border::before {
  opacity: 1;
}
a.card-link-wrapper .blue-border::before {
  opacity: 0;
}
a.card-link-wrapper:hover .blue-border::before {
  opacity: 1;
}

.blue-border > .card__content {
  background-color: var(--apt-white);
  padding: 30px 34px;
  border-radius: var(--8px);
  font-size: var(--16px);
  line-height: 1.5;

  @media (min-width: 512px) {
    padding: 40px 43px;
  }

  @media (min-width: 992px) {
    padding: 34px;
  }

  @media (min-width: 1200px) {
    padding: 40px 36px 54px 45px;
  }

  @media (min-width: 1440px) {
    padding: 49px 50px 54px 50px;
  }
}

.blue-border.content-card .card__content .heading {
  margin-bottom: var(--25px);
}

.read-more-button {
  text-decoration: none;
  color: var(--apt-grey-2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.read-more-button--inline {
  display: inline-flex;
  justify-content: flex-start;
}

a.read-more-button--inline:hover > span {
  text-decoration: underline;
  text-decoration-color: var(--apt-primary);
}

.read-more-button > span {
  flex: 1 1 auto;
}

.read-more-button--inline > span {
  flex: 0 0 auto;
}

.read-more-button:hover,
.read-more-button:active,
.read-more-button:focus {
  text-decoration: none;
}

.read-more-button .fal {
  font-size: var(--19px);
  color: var(--apt-grey-3);
  position: relative;
  top: 0.1em;
  padding-left: 0.35em;
  flex: 0 0 auto;
}

.card__content time {
  padding-right: 10px;
  margin-right: auto;
  letter-spacing: -0.1px;
}

.button-wrapper {
  margin-top: 36px;
}

.button,
.button-wrapper input[type=submit],
.button-wrapper input[type=button] {
  display: inline-block;
  position: relative;
  min-width: 110px;
  text-decoration: none;
  text-align: center;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  z-index: 1;

  &:focus { // .button:focus
    outline: none;
  }

  @include is-disabled-or-no-href {
    cursor: not-allowed;
  }
}

.button.button--special,
.button-wrapper--special input[type=submit],
.button-wrapper--special input[type=button] {
  min-height: 50px;
  border: none;
  color: var(--apt-white);
  padding: 11px 2.1em 14px 1.9em;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-decoration: none;
  border-radius: 25px;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @include is-not-disabled-hover {
    @include move-special-button;
  }

  @include is-not-disabled-active {
    @include move-special-button;
  }

  @include focus-visible {
    border-radius: 25px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--apt-primary);
    background-image: linear-gradient(90deg, #01cef4 0%, #01cef4 20%, #00c7f9, #00bfff, #00b6ff, #00aeff, #00a4ff, #009aff, #0090ff, #0084ff, #0077ff, #0068ff, #4157ff 100%);
    background-size: 300% 100%;
    background-position: 100% 0;
    transition: background-position 0.3s ease;
    border-radius: 25px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: var(--apt-primary);
    transition: width 0.2s ease;
    border-radius: 25px;
    z-index: -1;
  }

  i {
    position: relative;
    display: inline-flex;
    top: 0.15em;
    margin-left: 0.5em;
    left: 0;
    transform: translateX(0) scale(1.2);
    transition: transform 0.3s ease;
  }

  @include is-disabled-or-no-href {
    color: var(--apt-grey-3);
    background-color: var(--apt-grey-7);
    background-image: none;
    border: 1px solid var(--apt-grey-7);
    transform: scale(1.000001);
    text-decoration: none;

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}

.button.button--primary,
.button-wrapper--primary input[type=submit],
.button-wrapper--primary input[type=button] {
  min-height: 50px;
  background-color: var(--apt-primary);
  border: 1px solid var(--apt-primary);
  color: var(--apt-white);
  border-radius: 25px;
  padding: 11px 14px 13px;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-decoration: none;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (min-width: 375px) {
    padding: 11px 24px 13px;
  }

  @include is-not-disabled-hover {
    transform: scale(1.025);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  @include is-not-disabled-active {
    transform: scale(1.000001);
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  @include is-disabled-or-no-href {
    color: var(--apt-grey-3);
    background-color: var(--apt-grey-7);
    border: 1px solid var(--apt-grey-7);
    transform: scale(1.000001);
    text-decoration: none;
  }

  @include focus-visible {
    border-radius: 25px;
  }
}

.button.button--primary.button--tight,
.button-wrapper--primary.button--tight input[type=submit],
.button-wrapper--primary.button--tight input[type=button] {
  border-radius: 24px;
  min-height: 40px;
  padding: 6px 16px 8px;

  @media (min-width: 375px) {
    padding: 6px 26px 8px;
  }
}

.button.button--secondary,
.button-wrapper--secondary input[type=submit],
.button-wrapper--secondary input[type=button],
.file-upload label,
.upload-section .wm-field[id] label {
  min-height: 40px;
  background-color: var(--apt-white);
  color: var(--apt-primary);
  border-radius: 24px;
  padding: 6px 16px 8px;
  font-weight: var(--fw-medium);
  text-decoration: none;
  box-shadow: none;
  transform: scale(1.000001);

  @media (min-width: 375px) {
    padding: 6px 26px 8px;
  }

  @include is-not-disabled-hover {
    text-decoration: none;
  }

  @include is-not-disabled-active {
    text-decoration: none;
  }

  @include is-disabled-or-no-href {
    color: var(--apt-grey-3);
    text-decoration: none;

    &::before {
      background-image: none;
      background-color: var(--apt-grey-7);
    }
  }

  @include focus-visible {
    border-radius: 25px;
  }

  &::before { // .button-2::before
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--apt-primary);
    background-image: linear-gradient(90deg, #79fdfd 0%, #00e9ff 5.71428571%, #00d4ff 11.4285714%, #00bbff 17.1428571%, #00a0ff 22.8571429%, #0080ff 28.5714286%, #4157ff 40%, #4157ff 100%);
    background-size: 300% 100%;
    background-position: 100% 0;
    border-radius: 24px;
    z-index: -2;
    transition: background-position 0.4s ease;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    background-color: var(--apt-white);
    border-radius: 24px;
    z-index: -1;
  }

  &:hover { // .button-2:hover

    &::before { // .button-2:hover::before
      transition: background-position 0.4s ease;
      background-position: 0 0;
    }
  }
}

.header-list-item--primary .button.button--secondary::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--apt-white);
  border-radius: 24px;
  z-index: -1;
}

.button.button--tertiary,
.button-wrapper--tertiary input[type=submit],
.button-wrapper--tertiary input[type=button] {
  color: inherit;
  text-decoration: none;
  font-weight: var(--fw-regular);
  transform: scale(1.000001);

  @include is-not-disabled-hover {
    color: var(--apt-primary);
    text-decoration: none;
  }

  @include is-not-disabled-active {
    color: inherit;
    text-decoration: none;
  }

  @include is-disabled-or-no-href {
    color: inherit;
    text-decoration: none;
  }
}


.button.button--icon,
.button-wrapper--icon input[type=submit],
.button-wrapper--icon input[type=button] {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;

  svg { // .button.button--icon svg
    width: 1em;
    margin-right: 0.75em;
    position: relative;
    top: 0.15em;
  }

  span { // .button.button--icon span
    margin-left: auto;
    margin-right: auto;
    flex: 1 1 auto;
    padding-right: 35px;
  }

  i { // .button.button--icon i
    width: 35px;
    text-align: left;
  }
}

.button.button--icon span:last-of-type {
  padding-right: 0;
}

.button.button--icon-center,
.button-wrapper--icon-center input[type=submit],
.button-wrapper--icon-center input[type=button] {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;

  svg { // .button.button--icon-center svg
    width: 1em;
    margin-right: 0.75em;
    position: relative;
    top: 0.15em;
  }

  span { // .button.button--icon-center span
    margin-left: auto;
    margin-right: auto;
    flex: 0 1 auto;
    padding-right: 35px;
  }

  span:last-of-type { // .button.button--icon-center span:last-of-type
    padding-right: 35px;

    @include min-media(992px) {
      padding-right: 0;
    }
  }

  i { // .button.button--icon-center i
    width: 35px;
    text-align: left;
  }
}

.button.button--small,
.button-wrapper--smally input[type=submit],
.button-wrapper--small input[type=button] {
  min-width: 0;
  min-height: 28px;
  background-color: var(--apt-primary);
  border: 1px solid var(--apt-primary);
  color: var(--apt-white);
  border-radius: 28px;
  padding: 5px 5px 6px;
  font-size: var(--11px);
  line-height: 0.7;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-align: center;
  text-decoration: none;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @include is-not-disabled-hover {
    transform: scale(1.025);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  @include is-not-disabled-active {
    transform: scale(1.000001);
    box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }

  @include is-disabled-or-no-href {
    color: var(--apt-grey-3);
    background-color: var(--apt-grey-7);
    border: 1px solid var(--apt-grey-7);
    transform: scale(1.000001);
    text-decoration: none;
  }

  @include focus-visible {
    border-radius: 28px;
  }
}

.arrow-btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: var(--15px);
  padding-right: var(--15px);
  flex: 1 1 auto;
  font-size: var(--16px);
  line-height: 28px;
  border-top: 1px solid var(--apt-grey-7);

  @include min-media(768px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.arrow-btns--no-top-border {
  border-top: none;
}

.arrow-btns a,
.arrow-btns button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid var(--apt-grey-7);
  padding: var(--14px) 0 var(--16px);
  text-decoration: none;
  text-align: left;
}

.arrow-btns a span,
.arrow-btns button span {
  flex: 1 1 auto;
}

.arrow-btns a i.fal,
.arrow-btns button i.fal {
  flex: 0 0 auto;
  margin-left: 1em;
  transform: translateX(-3px);
  transition: transform 0.2s ease, opacity 0.2s ease;
  opacity: 1;
}

.arrow-btns a:last-of-type,
.arrow-btns button:last-of-type {
  border-bottom: 1px solid var(--apt-grey-7);
}

.arrow-btns a:hover i.fal,
.arrow-btns button:hover i.fal {
  transform: translateX(0px);
  opacity: 1;
}

.arrow-btns a.active,
.arrow-btns button.active {
  font-weight: var(--fw-medium);
}

.arrow-btns a.active i.fal,
.arrow-btns button.active i.fal {
  opacity: 1;
}

.heart-icon {
  display: block;
  width: 1rem;
  transform-origin: center;
}

.ov-icon__square {
  stroke: var(--apt-primary);
}
.ov-icon__text {
  fill: var(--apt-primary);
}

.heart-icon__fill {
  transform: translate(4%, 4%) scale(0.92);
  transform-box: fill-box;
  fill: var(--transparent);
}

.heart-icon__stroke {
  fill: currentColor;
}

.heart-icon--triggered {
  animation-name: bounceHeart;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.heart-icon--triggered .heart-icon__fill {
  fill: var(--apt-error-1);
}

.heart-icon--triggered .heart-icon__stroke {
  fill: var(--apt-error-1);
}

.floating-icon {
  position: absolute;
  z-index: 6;
  transform-origin: left top;
  opacity: 1;
  pointer-events: none;
}

.floating-heart {
  transform: translateX(var(--heartX)) translateY(var(--heartY)) scale(0.9);
}

.heart-button {
  cursor: pointer;
}

.floating-heart .heart-icon__fill,
.floating-heart .heart-icon__stroke {
  fill: var(--transparent);
}

.floating-heart--triggered {
  transition: transform 1.5s ease-in-out, opacity 0.5s linear 1.5s;
  opacity: 0;
}

.floating-heart--triggered .heart-icon__fill,
.floating-heart--triggered .heart-icon__stroke {
  fill: var(--apt-error-1);
}

.floating-heart--triggered svg {
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: left top;

  @media (min-width: 992px) {
    animation-name: floatingIconScaleDesktop;
  }

  @media (max-width: 991px) {
    animation-name: floatingIconScaleMobile;
  }
}

.header .header-cta .mini-appointment-button {
  display: none;
  position: relative;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;

  @include min-media(992px) {
    display: block;
    height: 28px;
    width: 0;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    width: 1.1rem;
    padding: 0;
  }

  i {
    color: currentColor;
  }
}

.header--minified .header-cta .mini-appointment-button {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.2s linear 0.1s;
}

.header--minified .header-sticky-section nav ul li {
  font-size: var(--16px);
}

.circle-plus-icon {
  display: block;
  width: 1rem;
  transform-origin: center;
  transform-box: fill-box;
}

.circle-plus-icon__bg {
  fill: transparent;
  stroke: currentColor;
}

.circle-plus-icon__solid-plus {
  opacity: 0;
  fill: var(--apt-white);
}

.circle-plus-icon__light-plus {
  fill: currentColor;
  opacity: 1;
}

.circle-plus-icon--triggered {
  animation-name: bounceCirclePlus;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.circle-plus-icon--triggered .circle-plus-icon__bg {
  fill: var(--apt-primary);
  stroke: var(--apt-primary);
}

.circle-plus-icon--triggered .circle-plus-icon__solid-plus {
  opacity: 1;
  fill: var(--apt-white);
}

.circle-plus-icon--triggered .circle-plus-icon__light-plus {
  opacity: 0;
}

.floating-circle-plus {
  transform: translateX(var(--circleplusX)) translateY(var(--circleplusY)) scale(0.9);
}

.heart-button {
  cursor: pointer;
}

.floating-circle-plus .circle-plus-icon__bg,
.floating-circle-plus .circle-plus-icon__outer-border,
.floating-circle-plus .circle-plus-icon__solid-plus,
.floating-circle-plus .circle-plus-icon__light-plus {
  fill: var(--transparent);
  stroke: var(--transparent);
}

.floating-circle-plus--triggered {
  transition: transform 1.5s ease-in-out, opacity 0.5s linear 1.5s;
  opacity: 0;
}

.floating-circle-plus--triggered .circle-plus-icon__bg {
  fill: var(--apt-primary);
  stroke: var(--apt-primary);
}

.floating-circle-plus--triggered .circle-plus-icon__solid-plus {
  opacity: 1;
  fill: var(--apt-white);
}

.floating-circle-plus--triggered .circle-plus-icon__light-plus {
  opacity: 0;
}

.floating-circle-plus--triggered svg {
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: left top;

  @media (min-width: 992px) {
    animation-name: floatingIconScaleDesktop;
  }

  @media (max-width: 991px) {
    animation-name: floatingIconScaleMobile;
  }
}

.tooltip-button {
  position: relative;
  top: 0.1em;
  font-size: var(--20px);
  line-height: 28px;
  color: var(--apt-grey-4);
  margin-left: 0.25em;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  background-color: var(--apt-black);
  width: max-content;
  padding: 0.65em 0.65em calc(0.65em + 10px) 0.65em;
  clip-path: polygon(
          0% 0%,
          100% 0%,
          100% calc(100% - 10px),
          calc(50% + 10px) calc(100% - 10px),
          50% 100%,
          calc(50% - 10px) calc(100% - 10px),
          0% calc(100% - 10px)
  );
  font-size: var(--14px);
  color: var(--apt-white);
  transform: translateY(-2px) translateX(-50%);
  display: none;
}

.tooltip--mobile-left {
  @media (max-width: 767px) {
    top: 50%;
    right: 100%;
    bottom: unset;
    left: unset;
    padding: 0.65em calc(0.65em + 10px) 0.65em 0.65em;
    clip-path: polygon(calc(100% - 10px) 0, calc(100% - 10px) calc(50% - 10px), 100% 50%, 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 0);
    transform: translateX(-2px) translateY(-50%);
  }
}

.tooltip-button:hover > .tooltip {
  display: block;
}

.packages {
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-top: 50px;
    margin-bottom: 119px;
  }
}

.package {
  position: relative;
  width: 100%;
  max-width: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  margin-bottom: 30px;
  padding: 39px 30px;
  border-radius: 10px;

  @media (min-width: 612px) {
    flex-direction: row;
    padding: 38px 32px 56px;
  }

  @media (min-width: 992px) {
    flex-direction: column;
    padding: 39px 30px;
  }

  @media (min-width: 1140px) {
    flex-direction: row;
    padding: 38px 32px 56px;
  }

  header { // .package header
    text-align: center;
    z-index: 1;
    margin-bottom: 38px;

    @media (min-width: 612px) {
      width: 50%;
      flex: 0 0 auto;
      padding-right: 36px;
      margin-bottom: 0;
    }

    @media (min-width: 992px) {
      width: 100%;
      margin-bottom: 38px;
    }

    @media (min-width: 1140px) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  svg { // .package svg
    display: inline-block;
    width: 63px;
    margin-right: 0.34em;

    @media (min-width: 768px) {
      width: 79px;
    }
  }

  .heading { // .package .heading
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-size: var(--25px);
    line-height: 38px;
    font-weight: var(--fw-medium);
    text-align: center;
    margin-bottom: 13px;
    white-space: nowrap;

    @media (min-width: 612px) {
      margin-bottom: 14px;
    }

    @media (min-width: 768px) {
      font-size: var(--32px);
      line-height: 48px;
    }
  }

  .heading > span { // .package .heading > span
    position: relative;
    top: -0.1em;
    letter-spacing: -0.4px;
  }

  .price { // .package .price
    font-size: var(--48px);
    line-height: 50px;
    letter-spacing: -0.75px;
    font-weight: var(--fw-medium);
    text-align: center;

    @media (min-width: 768px) {
      font-size: var(--60px);
      line-height: 63px;
    }
  }

  div { // .package div
    z-index: 1;
    width: 100%;
    max-width: 320px;

    @media (min-width: 612px) {
      flex: 1 1 auto;
      width: auto;
      max-width: none;
      padding-left: 25px;
      padding-top: 13px;
    }

    @media (min-width: 992px) {
      width: 100%;
      max-width: 320px;
      padding-left: 0;
      padding-top: 0;
    }

    @media (min-width: 1140px) {
      width: auto;
      max-width: none;
      padding-top: 13px;
    }

    @media (min-width: 1360px) {
      padding-left: 25px;
    }

    li:last-child { // .package div li:last-child
      margin-bottom: 0;
    }
  }

  &--promoted { // .package--promoted
    width: 100%;
    max-width: 830px;
    border: 1px solid transparent;
    border-radius: 8px;

    &::before { // .package--promoted::before
      content: "";
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background-image: linear-gradient(135deg, #79fdfd 0%, #00e9ff 5.71428571%, #00d4ff 11.4285714%, #00bbff 17.1428571%, #00a0ff 22.8571429%, #0080ff 28.5714286%, #4157ff 40%, #4157ff 100%);
      border-radius: 10px;
      z-index: 0;
    }

    &::after { // .package--promoted::after
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--apt-white);
      border-radius: 8.5px;
      z-index: 0;
    }

    .heading span { // .package--promoted .heading span
      color: var(--apt-primary);
    }
  }
}

.regular-content {
  margin-top: 36px;
  margin-bottom: 42px;

  @media (min-width: 768px) {
    margin-top: 25px;
    margin-bottom: 70px;
  }

  & > * { // .regular-content > *
    margin-bottom: var(--17px);

    @media (min-width: 768px) {
      margin-bottom: var(--24px);
    }
  }
}

.grid-item > [data-stretch] {
  position: relative;
}

a.anchor,
.content-hero a[name],
.zebra-block a[name],
.zebra-cards a[name] {
  display: block;
  position: absolute;
  top: -70px;
  visibility: hidden;
}

.apply-cta {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -18px;
}

.apply-cta__price {
  flex: 1 1 auto;
  color: var(--apt-primary);
  padding-right: 0.5em;
}

.apply-cta__btn {
  white-space: nowrap;
  flex: 0 0 auto;
  color: var(--apt-grey-2);
}

.tiny-print {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.tiny-print-normal-container {
  color: var(--apt-grey-2);
  width: 100%;
  max-width: 870px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;

  @media (min-width: 612px) {
    text-align: center;
  }
}

.tiny-print-icon-container {
  color: var(--apt-grey-2);
  width: 100%;
  max-width: 870px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 612px) {
    flex-direction: row;
  }

  figure {
    width: 100%;
    flex: 0 0 auto;

    @media (min-width: 612px) {
      width: 85px;
    }

    img {
      display: block;
      width: 63px;
      margin: 0 auto 20px;

      @media (min-width: 612px) {
        margin: 0;
      }
    }
  }
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 56px 15px 34px 15px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 100px var(--35px) 74px var(--50px);
  }

  @media (min-width: 1200px) {
    padding: 100px var(--85px) 74px var(--85px);
  }
}

.contact-left {
  width: 100%;
  flex: 1 1 auto;
  padding-bottom: 54px;

  @media (min-width: 768px) {
    width: 45%;
    padding-bottom: 74px;
  }

  @media (min-width: 992px) {
    width: 44%;
  }
}

.contact-left-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media(min-width: 768px) {
    max-width: 425px;
    padding-right: 35px;
  }
}

.contact-left-content header {
  margin-bottom: 34px;

  @media (min-width: 768px) {
    margin-bottom: 54px;
  }
}

.contact-left-content .button {
  width: 100%;
  margin-bottom: 20px;
}

.contact-right {
  width: 100%;
  flex: 0 0 auto;

  @media (min-width: 768px) {
    width: 55%;
    max-width: 640px;
  }

  @media (min-width: 992px) {
    width: 56%;
  }

  @media (min-width: 1200px) {
    margin-right: -15px;
  }
}

.contact-cta-cards-list {
  margin-left: -15px;
  width: calc(100% + 30px);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
}

.contact-cta-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;

  @media (min-width: 640px) {
    width: 50%;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: 50%;
  }
}

.contact-cta-cards-list-item a,
.contact-cta-cards-list-item a:hover,
.contact-cta-cards-list-item a:active,
.contact-cta-cards-list-item a:focus {
  text-decoration: none;
  color: inherit;
}

.contact-cta-cards-list-item a:hover .card {
  box-shadow: var(--shadow-hover);
}

.contact-cta-cards-list-item .card {
  padding: 36px 32px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.contact-left .arrow-btns {
  margin-top: 20px;
  padding-left: 0;
  padding-right: 0;
}

.contact-cta-card__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--apt-primary);
  flex: 0 0 auto;

  figure {
    width: 50px;
    border-radius: 0;

    img {
      width: auto;
      max-width: 28px;
      height: 28px;
      min-height: 0;
      display: block;
      aspect-ratio: unset;
      object-fit: contain;
    }
  }

  .heading {
    color: var(--apt-primary);
  }
}

.contact-cta-card__content {
  padding-top: 25px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  p {
    font-size: var(--14px);
    line-height: 20px;
    margin-bottom: 10px;
    flex: 1 1 auto;
  }

  time {
    font-size: var(--14px);
    line-height: 20px;
    color: var(--apt-grey-4);
    display: block;
    flex: 0 0 auto;
  }
}

.center-floating-card {
  width: 100%;
  max-width: 420px;
  margin-right: auto;
  margin-bottom: 78px;
  margin-left: auto;

  @include min-media(712px) {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
  }

  @include min-media(1024px) {
    margin-bottom: 158px;
  }

  &__figure { // .center-floating-card__figure
    width: 100%;
    max-width: 400px;
    padding-bottom: 25px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    @include min-media(712px) {
      width: 50%;
      padding: 0 25px 25px;
    }

    img {
      width: 100%;
    }
  }

  .heading {
    margin-bottom: 25px;
  }

  .button {
    margin-top: 25px;
  }

  &__content { // .center-floating-card__content
    width: 100%;
    padding: 0 15px;

    @include min-media(712px) {
      width: 50%;
      padding: 0 25px 25px;
    }
  }
}

.floating-ctas-container {
  width: 100%;
  margin-bottom: 78px;
  padding-left: 15px;
  padding-right: 15px;

  @include min-media(712px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  @include min-media(1024px) {
    margin-bottom: 158px;
    flex-wrap: nowrap;
  }
}

.floating-cta {
  position: relative;
  border-radius: 10px;
  background-color: var(--apt-white);
  box-shadow: 0 4px 6px 0 var(--apt-grey-6);
  padding-bottom: 24px;
  transition: box-shadow 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;

  @include min-media(712px) {
    padding-bottom: 32px;
  }

  @include min-media(992px) {
    padding-bottom: 32px;
    margin-left: 0;
    margin-right: 0;
  }

  @include min-media(1200px) {
    padding-bottom: 47px;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  @media (any-hover: hover) {
    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 12px 18px 0 var(--apt-grey-6);
    }
  }

  img { // .floating-cta img
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100.1%;
    height: 100%;
    z-index: 0;
  }

  .button {
    position: relative;
    z-index: 1;
  }
}

.floating-cta-1 {
  width: 100%;
  max-width: 420px;
  flex: 0 0 auto;
  margin-bottom: 40px;
  aspect-ratio: 3 / 2;

  @supports not (aspect-ratio: 3 / 2) {
    height: 280px;
  }

  @include min-media(712px) {
    width: calc(50% - 20px);
    max-width: none;
  }

  @include min-media(1024px) {
    width: calc(50% - 50px);
    margin-right: 50px;

    @supports not (aspect-ratio: 3 / 2) {
      height: 320px;
    }
  }

  @include min-media(1440px) {
    width: 500px;
    height: 380px;
    aspect-ratio: revert;
  }
}

.floating-cta-2 {
  width: 100%;
  max-width: 420px;
  flex: 0 0 auto;
  margin-bottom: 40px;
  aspect-ratio: 3/2;

  @supports not (aspect-ratio: 3 / 2) {
    height: 280px;
  }

  @include min-media(712px) {
    width: calc(50% - 20px);
    max-width: none;
  }

  @include min-media(1024px) {
    width: calc(40% - 50px);
    margin-right: 50px;
    margin-bottom: 0;
  }

  @include min-media(1440px) {
    width: 390px;
    height: 300px;
    aspect-ratio: revert;
  }
}

.floating-button {
  width: 100%;
  min-width: 86px;
  text-decoration: none;
  align-self: center;
  font-size: var(--20px);
  line-height: 22px;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: center;

  @include min-media(1024px) {
    width: 86px;
    display: inline;
  }

  &:hover, &:active, &:focus {
    text-decoration: none;
  }

  .button {
    width: 32px;
    height: 32px;
    line-height: 32px;
    min-width: 32px;
    min-height: 32px;
    padding: 0;
    margin-top: 17px;
    margin-right: 15px;
    display: block;

    @include min-media(1024px) {
      margin-right: 0;
    }
  }
}

#inruilen {
    margin-top: 2em;
}

.navigatable .nav-tablist + .hide-desktop .heading-1,
.editableContainer .hide-desktop .heading-1 {
  @include max-media(767px) {
    margin-top: 15px;
  }
}

.navigatable .nav-tablist + .hide-desktop .heading-1,
.navigatable .nav-tablist + .hide-desktop .heading-2,
.navigatable .nav-tablist + .hide-desktop .heading-3,
.navigatable .nav-tablist + .hide-desktop .heading-4 {
  padding-left: 0;
  padding-right: 0;
}

.grid-item.bg-white.navigatable .nav-tablist .tabs {
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;

  @media (min-width: 512px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media (min-width: 992px) {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.split-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 48px;

  @include min-media(512px) {
    padding-top: 106px;
    padding-bottom: 97px;
  }

  @include min-media(992px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.split-content__header {
  width: 100%;
  border-bottom: 1px solid #EAEAE7;
  margin-bottom: 58px;
  padding-bottom: 48px;
}

.split-content-column {
  width: 100%;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;

  @include min-media(992px) {
    width: 50%;
    padding-bottom: 0;
  }


  &__text { // .split-content-column__text
    flex: 1 1 auto;
  }

  &__action { // .split-content-column__action
    flex: 0 0 auto;
    padding-top: 32px;
  }

  &__figure { // .split-content-column__figure
    margin-bottom: 40px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}
@include min-media(992px) {
  .split-content-column:first-of-type {
    padding-right: 25px;
  }
  .split-content-column:last-of-type {
    padding-left: 25px;
  }
}

.nav-steps {
  width: 100%;
  border-bottom: 1px solid var(--apt-grey-5);
}

.step-list {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  overflow-x: auto;

  @include max-media(911px) {
    justify-content: flex-end;
    padding-right: 16px;
  }

  .step { // .step-list .step
    padding: 16px 39px 16px 0;
    flex: 0 0 auto;

    @include max-media(911px) {
      padding: 4px 0 4px 16px;
    }

    @include max-media(359px) {
      padding: 4px 0 4px 14px;
    }
  }

  .step--done .step-button {
    color: var(--apt-grey-4);
  }

  .step--active .step-button {
    color: var(--apt-primary);
  }

  .step-button {
    color: var(--apt-grey-5);
  }
}

.nav-steps {
  @include max-media(911px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;

  .step-list {

    .step {
      @include max-media(911px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .step-button {
      overflow: hidden;
      width: 12px;
      height: 12px;
      position: relative;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: var(--apt-grey-9);
        position: absolute;
        top: 0;
        left: 0;
      }

      &::after {
        content: '';
        width: 4px;
        height: 4px;
        background: var(--apt-grey-5);
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 4px;
      }
    }

    .step--done .step-button,
    .step--active .step-button {
      &::after {
        background: var(--apt-primary);
      }
    }
  }
  }
}

.mobile-active-step {
  flex: 0 0 auto;
  color: var(--apt-primary);

  @include min-media(912px) {
    display: none;
  }
}

.step-buttons {

  & > button {
    @include min-media(912px) {
      display: none;
    }
  }

  @include max-media(911px) {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      flex: 1 0 auto;
      color: var(--apt-grey-5);
    }

    .step-list {
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.three-columns {
  position: relative;
  width: calc(100% + 30px);
  margin-left: -15px;

  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.three-columns > div {
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.three-columns__thin-column {
  width: 100%;

  @media (min-width: 992px) {
    width: 33.33%;
  }

  .heading {
    margin-bottom: 38px;
  }
}

.three-columns__broad-column {
  width: 100%;

  @media (min-width: 992px) {
    width: 66.67%;
  }
}

.three-column-content-card {
  position: relative;
  width: 100%;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  margin-bottom: 30px;
  padding: 32px 30px;
  border-radius: 10px;

  @media (min-width: 992px) {
    padding: 32px 52px 50px;
  }
}

.three-column-content-card__header {
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 31px;
  border-bottom: 1px solid var(--apt-grey-7);
}

.finished-dot {
  position: relative;
  color: var(--apt-primary);

  img {
    position: absolute;
    left: calc(100% + 0.05em);
    bottom: 0.185em;
    display: block;
    width: 188px;

    @include min-media(512px) {
      width: 242px;
    }

    @include min-media(768px) {
      width: 296px;
    }

    @include min-media(992px) {
      width: 378px;
    }
  }
}

@media (min-width: 992px) {
  .info-toggle-select--mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .info-toggle-select--desktop {
    display: none;
  }
  .info-toggle-select--mobile {
    margin-bottom: 28px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 51px;
  margin-left: -0.6em;
}

.pagination button {
  width: 3ch;
  height: 3ch;
  padding: 3px 5px;
  background: white;
  border-radius: 3px;
  text-align: center;
  line-height: 1.5;
  color: var(--apt-grey-3);
  transition: background-color 0.2s ease;

  &:hover {
    background: var(--apt-grey-8);
  }

  &.active {
    color: var(--apt-primary);
    font-weight: var(--fw-semibold);
  }
}

.card__content--top-crop {
  @media (min-width: 660px) {
    padding-top: 13px;
  }
}

.card__content--top-crop > a:first-child {
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-grow-column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.call-card .card__content .wmpform:only-child {
  margin-top: -20px;
}

.reset-mt .card__content .wmpform {
  margin-top: 0 !important;
}
