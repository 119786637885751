.block-overlay {
  position: absolute;
}

.overlay {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  right: 0;
}

.overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0,0,0,.2);
  transition: opacity .3s linear;
}

.overlay-content {
  height: 100vh;
  background-color: var(--apt-grey-9);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  box-shadow: 8px 0 18px 0 rgba(0,0,0,0.2), 18px 0 48px 2px rgba(0,0,0,0.2);
  transform: translateX(calc(100% + 48px));
  transition: transform .3s ease-in-out;
}

.overlay--narrow .overlay-content {
  @include min-media(636px) {
    width: 636px;
  }

  @media (max-width: 635px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 636px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: translateX(calc(100% + 48px));
  }
}

.overlay--narrow.overlay--open .overlay-content {

  @media (min-width: 636px) {
    transform: translateX(0);
  }
}

.overlay:not(.overlay--narrow) .overlay-content {
  @media (min-width: 1024px) {
    width: 1024px;
  }

  @media (max-width: 1023px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  @media (min-width: 1024px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: translateX(calc(100% + 48px));
  }
}

.overlay:not(.overlay--narrow).overlay--open .overlay-content {

  @media (min-width: 1024px) {
    transform: translateX(0);
  }
}

.overlay-content__header {
  position: relative;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: var(--apt-white);
  border-bottom: 1px solid var(--apt-grey-7);
}

.overlay-content-progress {
  @media (max-width: 767px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 38px 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--apt-grey-5);
  }
}

.overlay-content-progress__current-step {

  @media (min-width: 768px) {
    display: none;
  }
}

.overlay-content-progress__breadcrumbs-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overlay-content-progress__breadcrumbs-container input[type="button"],
.overlay-content-progress__breadcrumbs-container input[type="submit"],
.overlay-content-progress__breadcrumbs-container button {
  font-size: var(--16px);
  color: var(--apt-grey-5);

  @media (min-width: 768px) {
    display: none;
  }

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }
}

.overlay-content-progress__breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-size: var(--16px);
  line-height: 24px;

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    right:0;
    justify-content: flex-end;
    margin: 0 50px 0 20px;
    height: 60px;
  }

  li { // .overlay-content-progress__breadcrumbs li
    padding: 0 7px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  li > a { // .overlay-content-progress__breadcrumbs li > a
    color: var(--apt-grey-3);
    text-decoration: none;

    @media (max-width: 767px) {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border: 8px solid var(--apt-grey-9);
      background-color: var(--apt-grey-7);
      border-radius: 50%;
    }

    &:hover { // .overlay-content-progress__breadcrumbs li > a:hover
      color: var(--apt-black);

      & > span { // .overlay-content-progress__breadcrumbs li > a:hover > span
        @media (max-width: 767px) {
          display: block;
        }
      }
    }

    &.done { // .overlay-content-progress__breadcrumbs li > a.done
      @media (max-width: 767px) {
        background-color: var(--apt-grey-5);
      }
    }

    &.active { // .overlay-content-progress__breadcrumbs li > a.active
      color: var(--apt-black);

      @media (max-width: 767px) {
        background-color: var(--apt-primary);
      }
    }

    & > span { // .overlay-content-progress__breadcrumbs li > a > span
      @media (max-width: 767px) {
        position: absolute;
        bottom: 100%;
        left: 50%;
        background-color: var(--apt-black);
        width: max-content;
        padding: 0.65em 0.65em calc(0.65em + 10px) 0.65em;
        clip-path: polygon(
                0% 0%,
                100% 0%,
                100% calc(100% - 10px),
                calc(50% + 10px) calc(100% - 10px),
                50% 100%,
                calc(50% - 10px) calc(100% - 10px),
                0% calc(100% - 10px)
        );
        font-size: var(--14px);
        color: var(--apt-white);
        transform: translateY(-2px) translateX(-50%);
        display: none;
      }
    }
  }
}

.overlay-content__close-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 60px;
  background: none;
  border: 0;
  padding: 0;
  margin: 0 16px;
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  color: black;
  font-size: var(--14px);
  line-height: 20px;

  @media (min-width: 768px) {
    margin: 0 35px;
  }

  i {
    margin-right: 17px;
    font-size: var(--20px);
    line-height: 21px;
  }
}

.overlay-content-header {
  position: relative;
  width: 100%;
  z-index: 3;

  @media (min-width: 768px) {
    flex: 0 0 auto;
  }
}

.overlay-content-article {
  width: 100%;
  overflow-y: auto;
}

.overlay-content-article__wrapper {
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
  }
}

.overlay-content-article__aside {
  width: 100%;
  background-color: var(--apt-grey-8);
  padding-bottom: 24px;

  @media (min-width: 768px) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 45.7%;
    min-width: 300px;
    min-height: calc(100vh - 60px);
    border-top: 0;
    border-right: 1px solid var(--apt-grey-7);
  }

  @media (min-width: 1024px) {
    width: 389px;
    flex: 0 0 auto;
  }

  figure img {
    width: 100%;
  }
}

.overlay-content-article__figure {
  width: 100%;
  height: 265px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--apt-white);

  img {
    display: block;
    max-width: 250px;
    max-height: 250px;
  }
}

.overlay-content-article__section {
  position: relative;
  padding: 15px;
  font-size: var(--14px);
  line-height: 22px;
  font-weight: var(--fw-regular);
  width: 100%;
  max-width: 296px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1024px) {
    padding: 32px 58px 15px 61px;
    width: auto;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

.overlay-content-article__tooltip-container + .overlay-content-article__section {

  @media (min-width: 1024px) {
    padding-top: 15px;
  }
}

.overlay-content-article__heading {
  display: block;
  margin-bottom: 10px;
}

.overlay-content-article__price {
  display: block;
  font-size: var(--40px);
  line-height: 63px;
  font-weight: var(--fw-semibold);
  color: var(--apt-black);
  margin-bottom: 10px;

  span {
    font-size: var(--16px);
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
  }
}

.overlay-content-article__description {
  display: block;
  color: var(--apt-grey-2);
  margin-bottom: 10px;
}

.overlay-content-article__tooltip-container {
  position: relative;
  z-index: 1;
}

.overlay-content-article__tooltip-container + .overlay-content-article__section .overlay-content-article__heading {
  padding-right: 24px;
}

.overlay-content-article__tooltip {
  position: absolute;
  top: 15px;
  right: 23px;
}

.overlay-content-article ul:not(.wm-field-list),
.overlay-content-article ol {
  margin-top: 18px;
  margin-bottom: 10px;
}

.overlay-content-article main {
  padding: 38px 16px;
  flex: 1 1 auto;
  align-self: flex-start;

  @media (min-width: 768px) {
    padding: 50px;
  }

  .subheading { // .overlay-content-article main .subheading
    font-size: var(--18px);
    line-height: 30px;
    color: var(--apt-grey-3);
    margin-top: -4px;
  }

  p {
    margin-bottom: var(--38px);
  }

  .button-container {
    margin-top: var(--44px);
  }
}

.overlay-content-article {

  .form-grid { // .overlay-content-article .form-grid
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
    width: calc(100% + 28px);

    @media (min-width: 768px) {
      width: calc(100% - 6px);
    }
  }

  .form-grid .text-input-wrapper {
    margin-top: -25px;
  }

  .form-grid-item {
    width: 100%;
    padding: 14px;

    &--50 {
      width: 50%;
    }

    &--25 {
      width: 25%;
    }

    @media (max-width: 511px) {
      width: 100%;
    }
  }
}

.overlay-content-article__warning-banner {
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 430px;
  display: block;
  margin: 0 auto;
}

.overlay-table {
  width: 100%;
  table-layout: fixed;
  margin: 26px 0 0;
  text-align: left;
  border: 0;
  border-collapse: collapse;

  caption {
    padding: 0 61px 31px;
    font-size: var(--14px);
    line-height: 22px;
    text-align: left;
  }

  tr:nth-child(odd) {
    background-color: var(--apt-white);
  }

  th {
    width: 50%;
    font-size: var(--14px);
    line-height: var(--22px);
    letter-spacing: 0.26px;
    color: var(--apt-grey-2);
    font-weight: var(--fw-regular);
    padding: 9.5px 5px 9.5px 15px;

    @media (min-width: 1024px) {
      padding: 9.5px 5px 9.5px 60px;
    }
  }

  td {
    width: 50%;
    font-size: var(--16px);
    line-height: var(--24px);
    letter-spacing: 0.1px;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
    padding: 7px 15px 7px 5px;

    @media (min-width: 1024px) {
      padding: 7px 60px 7px 22px;
    }
  }
}