.filter {
  @include max-media(767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.filter-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 16px;

  @media (min-width: 512px) {
    flex-direction: row; // TODO: Filter-labels weg in initiële wijzigingen
    padding: 55px 0 16px;
  }

  @media (min-width: 768px) {
    // flex-direction: row;  // TODO: Filter-labels ingevoegd in initiële wijzigingen
    justify-content: center;
    align-items: center; // TODO: Filter-labels baseline in initiële wijzigingen
    padding: 85px 0 26px;
  }
}

.filter-header > span {
  display: block;
  // font-size:var(--20px);  // TODO: Filter-labels ingevoegd in initiële wijzigingen
  line-height: 1.25;
  // font-weight: var(--fw-medium);  // TODO: Filter-labels ingevoegd in initiële wijzigingen
  padding: 0.29em 36px 0.39em 0;
  margin-bottom: var(--22px); // TODO: Filter-labels 14px in initiële wijzigingen
  // flex: 0 0 auto; // TODO: Filter-labels ingevoegd in initiële wijzigingen

  @media (min-width: 768px) {
    padding: 0.29em 36px 0.39em 15px;
    // margin-bottom: var(--22px); // TODO: Filter-labels ingevoegd in initiële wijzigingen
  }
}

.filter-grid {
  width: calc(100% + 24px);
  max-width: 1294px;
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.filter-grid-item {
  width: 100%;
  padding: 20px 12px;

  @include min-media(768px) {
    width: 50%;
  }
  @include min-media(1200px) {
    width: 33.333%;
  }
}

#input-select,
#input-number {
  padding: 7px;
  margin: 15px 5px 5px;
  width: 70px;
}

.filter-container {
  width: calc(100% + 24px);
  margin-left: -12px;
}

.filter-container .expandable__visible {
  padding-left: 12px;
  padding-right: 12px;
}

.filter-container .expandable__button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 43px 0 73px;
}

.filter-container .expandable__button {
  margin: 0;
}

.double-range-selector-container {
  width: 100%;
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  @include min-media(768px) {
    height: 40px;
  }
}

.double-range-selector-label {
  letter-spacing: normal;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  color: var(--apt-grey-3);
  text-indent: 0;
  z-index: 1;
}

.double-range-selector {
  position: relative;
  display: none;

  @include min-media(768px) {
    display: block;
  }
}

.double-range-selector #slider1-skip-min {
  position: absolute;
  left: 0;
}
.double-range-selector #slider1-skip-max {
  position: absolute;
  right: 0;
}

.double-range-selector .slider-value {
  top: 10px;
  color: var(--apt-grey-2);

  @include max-media(767px) {
    top: 12px;
  }

  &--min{
      position: absolute;
      left: 0;
  }

    &--max{
        position: absolute;
        right: 0;
    }
}

.double-range-selector .noUi-target {
  background: var(--apt-grey-7);
  border-radius: 0;
  border: 0;
  box-shadow: none;
  height: 3px;
}

.double-range-selector .noUi-handle {
  border: 0;
  border-radius: 50%;
  background: #01CEF4;
  cursor: default;
  box-shadow: none;
  outline: none;

  &::before, &::after {
    content: "";
    display: none;
  }
}

.double-range-selector .noUi-base .noUi-origin:last-child .noUi-handle {
  background: var(--apt-primary);
}

.double-range-selector .noUi-horizontal .noUi-handle {
  width: 13px;
  height: 13px;
  top: -5px;

  @include min-media(768px) {
    width: 9px;
    height: 9px;
    top: -3px;
  }
}

.double-range-selector .noUi-horizontal .noUi-handle-lower {
  right: 0;
  transform: translateX(50%);
}

.double-range-selector .noUi-horizontal .noUi-handle-upper {
  right: 0;
  transform: translateX(50%);
}

.double-range-selector .noUi-horizontal .noUi-touch-area:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: transparent;
  opacity: 0;
  z-index: -1;
}

.double-range-selector .slider-value {
  font-size: 14px;
  line-height: 1;
}

.double-range-selector .noUi-tooltip {
  top: calc(100% + 3px);
  bottom: unset !important;
  border: 0;
  border-radius: 0;
  background: var(--apt-grey-9);
  color: var(--apt-grey-2);
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1;
  padding: 0 1.4em;
}

.double-range-selector .noUi-connect {
  background-image: linear-gradient(270deg, var(--apt-primary), #2F78FC, #2193F9, #11B0F7, #01CEF4);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  //margin-right: 1px;
}

.mobile-range-selector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 14px;

  @include min-media(768px) {
    display: none;
  }
}

.mobile-range-selector__from, .mobile-range-selector__to {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0.5em 0;
  font-size: var(--14px);
  line-height: 1.4;
  border-bottom: 1px solid var(--apt-grey-4);
}

.mobile-range-selector__from {
  width: calc(50% - 35px);
  margin-right: 15px;
}

.mobile-range-selector__to {
  width: calc(50% - 35px);
  margin-left: 15px;
}

.mobile-range-selector__button {
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.mobile-range-selector__button button {
  width: 28px;
}

.mobile-range-selector__input {
  width: 100%;
  flex: 1 1 auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  color: var(--apt-grey-2);

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
    font-size: var(--14px);
    line-height: 1.4;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
    font-size: var(--14px);
    line-height: 1.4;
  }

  &:autofill::first-line {
    font-family: var(--fellix);
    font-size: var(--14px);
    line-height: 1.4;
    font-weight: normal;
    color: inherit;
  }
  &:-webkit-autofill::first-line {
    font-family: var(--fellix);
    font-size: var(--14px);
    line-height: 1.4;
    font-weight: normal;
    color: inherit;
  }
}

.mobile-range-selector__label {
  flex: 0 0 auto;
  padding-right: 0.5em;
  color: var(--apt-grey-3);
}