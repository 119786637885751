.map-widget {
  padding: 0;
  z-index: 1;
}

#gmap {
  width: 100%;
  height: 460px;
  background-color: var(--apt-grey-7);

  @media (max-height: 501px) {
    height: calc(100vh - 62px);
  }
}

.map-overlay {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include min-media(992px) {
    justify-content: flex-end;
    align-items: center;
    margin-top: -460px;
    height: 460px;
  }
}

.map-overlay-card {
  position: relative;
  width: 100%;
  max-width: 608px;
  border-radius: var(--10px);
  box-shadow: 0 4px 6px 0 var(--apt-grey-6);
  margin-top: -200px;
  margin-bottom: 10px;
  z-index: 1;

  @media (min-width: 992px) {
    top: -7px;
    right: 0;
    margin-top: 0;
  }
}

.map-overlay-card--hidden {
  display: none;
  position: absolute;
  left: -100vw;
}

.map-overlay-card .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  background: var(--apt-white);
  border-radius: 100%;
  text-align: center;
  font-size: var(--20px);
  line-height: 25px;
  color: black;
}

.map-overlay-card .close-button:focus {
  border-radius: 100%;
}

.map-overlay-card__footer {
  background-color: var(--apt-primary);
  width: 100%;
  text-align: center;
  border-bottom-left-radius: var(--10px);
  border-bottom-right-radius: var(--10px);
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  color: var(--apt-white);
  font-weight: var(--fw-medium);
  padding-top: var(--1px);
  padding-right: 20px;
  padding-bottom: var(--4px);
  padding-left: 20px;
}

.map-overlay-card .opening-hours-card__address {
  @media (min-width: 992px) {
    width: 304px;
  }
}
.map-overlay-card .opening-hours-card__address figure {

  @media (min-width: 992px) {
    height: 160px;
  }
}

.map-overlay-card .opening-hours-card__address address {
  @media (min-width: 992px) {
    padding: 15px 30px 20px;
  }
}

.map-overlay-card .opening-hours-card__listing .heading-4 {
  @media (min-width: 992px) {
    margin-bottom: var(--8px);
  }
}

.map-overlay-card .opening-hours-card__listing .dropdown__toggle {
  @media (min-width: 992px) {
    padding-bottom: var(--4px);
    height: var(--38px);
  }
}

.map-overlay-card .opening-hours-card__listing table {
  table-layout: auto;
  margin-top: 14px;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  text-align: left;
}

.map-overlay-card .opening-hours-card__listing td {
  width: 1%;
  white-space: nowrap;
  min-width: 0;
  padding-right: 10px;
}

.vestigingen {
  width: 100%;
  margin-top: var(--48px);

  @include min-media(992px) {
    display: flex;
    margin-top: var(--94px);
  }
}

.vestigingen__filters {
  flex: 0 0 auto;
  width: 100%;
  max-width: 608px;
  margin: 0 auto;
  padding: 44px 30px 35px;
  background: var(--apt-white);
  border-radius: 10px;

  @include min-media(992px) {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.vestigingen__filters .heading-2 {
  text-align: center;
  width: 200px;
  margin-right: auto;
  margin-bottom: 38px;
  margin-left: auto;

  @include min-media(512px) {
    width: auto;
  }

  @include min-media(992px) {
    text-align: left;
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

.vestigingen__sticky-container {

  @media (max-width: 991px) {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 511px) {
    max-width: none;
  }

  @include min-media(992px) {
    margin-bottom: 40px;
  }
}

.vestigingen__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;

  @include min-media(768px) {
    width: 100%;
    left: 0;
    max-width: 670px;
  }

  @include min-media(992px) {
    width: 100%;
    max-width: none;
    margin-top: calc(96px + 28px);
    margin-bottom: 96px;
  }
}

.vestigingen .dialog__title,
.vestigingen .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);

  @include min-media(768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.vestigingen__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;

  @include min-media(768px) {
    max-width: none;
  }
}

.vestigingen__result .dialog {

  @include min-media(768px) {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }

  @include min-media(992px) {
    padding-right: 0;
  }
}

.vestigingen__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.vestigingen__result .dialog .dialog__title .fa-times {
  display: none;
}

.vestigingen__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.vestigingen__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}
