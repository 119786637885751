.tag-list {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--7px);
}

.tag-list li {
  margin: 0 var(--6px) var(--6px) 0;
  line-height: 0;
}

.tag-list li a,
.tag-list li button[type="button"],
.tag-list li input[type="button"],
.tag-list li > span {
  display: inline-flex;
  font-size: var(--12px);
  line-height: 1.1em;
  letter-spacing: 0.46px;
  text-decoration: none;
  padding: 0.23em 0.75em 0.35em;
  background-color: var(--apt-grey-9);
  color: var(--apt-grey-3);
  border-radius: 2em;
  font-weight: var(--fw-semibold);
  transition: filter 0.2s ease, background-color 0.2s ease;
}

.tag-list--large li {
  // TODO: Filter-labels Onderstaand vervangen
  margin: 0 var(--10px) var(--10px) 0;

  @include min-media(512px) {
    margin: 0 var(--15px) var(--15px) 0;
  }
  // margin: 0 var(--4px) var(--4px) 0; // Filter-labels TODO: vervangen door dit
}

.tag-list--large li a,
.tag-list--large li button[type="button"],
.tag-list--large li input[type="button"],
.tag-list--large li span {
  font-size: var(--16px); // TODO: Filter-labels 13px
  line-height: 1.25;
  padding: 0.29em 0.75em 0.39em; // TODO: Filter-labels 0.07em 0.75em 0.24em
  font-weight: var(--fw-medium);

  // TODO: Filter-labels onderstaande media query weg
  @include min-media(512px) {
    font-size: var(--18px);
    line-height: 1.22;
    padding: 0.29em 0.75em 0.39em;
  }
}

.tag-list--filters .tag {
  align-items: baseline;
  border: 1px solid var(--apt-grey-4);
  color: var(--apt-grey-3);
}

.tag-list--filters .tag-close {
  width: 1em;
  text-align: center;
  line-height: 1;
  padding: 0 0 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.8em;
  position: relative;
  bottom: -0.05em;
}

.tag-list--filters .tag-close i {
  line-height: 0.7em;
  color: var(--apt-grey-3) !important;
}

.tag-list li a.tag,
.tag-list li button[type="button"].tag,
.tag-list li input[type="button"].tag,
.tag-list li > span.tag {
  background-color: var(--apt-grey-7);
  color: var(--apt-grey-3);
}

.tag-list li a.tag--used,
.tag-list li button[type="button"].tag--used,
.tag-list li input[type="button"].tag--used,
.tag-list li > span.tag--used {
  background-color: var(--apt-grey-2);
  color: var(--apt-white);
}

.tag-list li a.tag--filter,
.tag-list li a.tag--secondary,
.tag-list li button[type="button"].tag--filter,
.tag-list li button[type="button"].tag--secondary,
.tag-list li input[type="button"].tag--filter,
.tag-list li input[type="button"].tag--secondary,
.tag-list li > span.tag--filter,
.tag-list li > span.tag--secondary {
  background-color: var(--apt-grey-9);
  color: var(--apt-grey-3);
}

.tag-list li a.tag--active,
.tag-list li a.tag--primary,
.tag-list li button[type="button"].tag--active,
.tag-list li button[type="button"].tag--primary,
.tag-list li input[type="button"].tag--active,
.tag-list li input[type="button"].tag--primary,
.tag-list li > span.tag--active,
.tag-list li > span.tag--primary {
  background-color: var(--apt-primary);
  color: var(--apt-white);
}

.tag-list li a.tag--action,
.tag-list li button[type="button"].tag--action,
.tag-list li input[type="button"].tag--action,
.tag-list li > span.tag--action {
  background-color: var(--apt-action);
  //background-image: var(--blue-gradient-x);
  //background-position: center;
  //background-size: 110% 100%;
  background-repeat: no-repeat;
  color: var(--apt-white);
}

.tag-list li a:not([class]):hover,
.tag-list li a.tag:hover,
.tag-list li button:not([class])[type="button"]:hover,
.tag-list li button[type="button"].tag:hover,
.tag-list li input:not([class])[type="button"]:hover,
.tag-list li input[type="button"].tag:hover {
  text-decoration: none;
  background-color: var(--apt-grey-8);
}

.tag-list li a.tag--used:hover,
.tag-list li button[type="button"].tag--used:hover,
.tag-list li input[type="button"].tag--used:hover {
  background-color: var(--apt-grey-3);
}

.tag-list li a.tag--active:hover,
.tag-list li button[type="button"].tag--active:hover,
.tag-list li input[type="button"].tag--active:hover {
  filter: brightness(1.3);
  background-color: var(--apt-primary);
}
.tag-list li a.tag--primary:hover,
.tag-list li button[type="button"].tag--primary:hover,
.tag-list li input[type="button"].tag--primary:hover {
  filter: brightness(1.3);
  background-color: var(--apt-primary);
}

.tag-list li a.tag--action:hover,
.tag-list li button[type="button"].tag--action:hover,
.tag-list li input[type="button"].tag--action:hover {
  filter: brightness(1.2);
  background-color: var(--apt-action);
}

.tag-list--secondary {
  max-width: 280px;
}

.tag-list--secondary li {
  margin: 0 var(--7px) var(--7px) 0;
}

.tag-list--secondary li span.tag {
  border-width: 1px;
  border-style: solid;
  border-color: var(--apt-primary);
  border-radius: 12px;
  color: var(--apt-primary);
  background: none;
}

.tag-list--secondary li span.tag--disabled,
.tag-list--secondary li a.tag:not([href]),
.tag-list--secondary li button[type=button].tag:disabled {
  border-color: var(--apt-grey-5);
  color: #DBDBD8;
  cursor: not-allowed;

  & > svg {
    .ov-icon__square {
      stroke: var(--apt-grey-5);
    }
    .ov-icon__text {
      fill: var(--apt-grey-5);
    }
  }

  &:hover,
  &:focus {
    background: none;
  }
}