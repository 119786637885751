.news-detail {
  //padding-bottom: 75px;
}

.news-detail section {
  margin-top: var(--20px);
  margin-bottom: var(--28px);
  display: flow-root;
}

.news-detail section .editableContainer img,
.news-detail section .editableContainer iframe {
  max-width: 100%;
  margin-bottom: var(--20px);
}

.news-detail p {
  margin-bottom: var(--20px);
}

.news-detail hr {
  margin: var(--50px) 0;
}

.news-detail hr:last-child {
  margin: var(--50px) 0 var(--20px);
}

.news-detail .news-detail__header hr {
  margin-bottom: 0;
}

.news-detail__header--block {
  margin-top: 0;
  margin-bottom: 116px;
  padding-left: 0;
  padding-right: 0;
}

.news-detail__header--inline {
  position: relative;
  width: 100%;
  margin-top: 104px;
  margin-bottom: 30px;

  @supports not (display: grid) {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}

.news-detail__content {
  margin-bottom: var(--50px);
  font-size: var(--22px);
  font-weight: var(--fw-medium);
  line-height: 30px;
}

.news-detail__intro-text {
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
}

.news-detail__timestamp {
  color: var(--apt-grey-4);
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
}

.news-detail__header--block .news-detail__timestamp {
  position: relative;
  width: 100%;
  max-width: 710px;
  margin: 0 auto;

  @supports not (display: grid) {
    max-width: 610px;
  }

  @include min-media(768px) {
    text-align: right;
    max-width: 610px;
  }
}

.news-detail__header--block .news-detail__timestamp time {
  position: absolute;
  top: var(--48px);
  left: var(--15px);

  @include min-media(768px) {
    right: 0;
    left: 0;
  }
}

.news-detail__header--inline .news-detail__timestamp {
  position: absolute;
  top: -2.75em;

  @include min-media(768px) {
    right: 50px;
  }
}

.news-detail .embed-figure {
  margin-top: var(--30px);
}