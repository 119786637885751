.tablist-container {
  width: 100%;
  margin-top: -5px;

  &--wrapper { // .tablist-container--wrapper
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      .tabs--hide-on-mobile:first-of-type { // .tablist-container--wrapper .tabs:first-of-type {
        display: none;
      }

      .tabs:last-of-type {
        padding-right: var(--15px);
      }

      .tabs:last-of-type .tab-button:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.tab-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-align: left;
  margin-top: 4px;
}

.tab-panel .button:not(:last-child) {
  margin-bottom: 30px;
}

.tab-panel .heading {
  margin-top: -3px;
}

.tab-button {
  color: var(--apt-grey-2);
  text-decoration: none;
  font-size: var(--16px);
  letter-spacing: 0.3px;
  line-height: 24px;
  padding-bottom: 0.2em;
}

.tab-button:not(:last-child) {
    margin-right: 20px;
}

.tab-button--active {
  color: var(--apt-primary);
  text-decoration: underline;
  text-decoration-color: var(--apt-primary);
  text-underline-offset: 0.5em;
}

.tab-panel .arrow-btns:last-child {
  margin-bottom: -20px;
  padding-right: 0;
  padding-left: 0;

  @media (min-width: 991px) {
    margin-bottom: -22px;
  }
}
.tab-panel .arrow-btns:last-child a:last-of-type {
  border-bottom: 0;
}

.nav-tablist {
  position: sticky;
  top: 53px;
  width: 100%;
  z-index: 2;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: var(--apt-white);

  &--vdp { // .nav-tablist--vdp

    .tab-button { // .nav-tablist--vdp .tab-button
      @media (max-width: 767px) {
        color: var(--apt-grey-2) !important;
        font-size: var(--14px) !important;
      }
    }
  }

  @media (min-width: 992px) {
    top: 60px;
  }
}

.navigatable:has(> .nav-tablist):not(.main-header__nav-bar) {
  display: contents;
}

.nav-tablist .tablist-container {
  margin-top: 0;
  overflow-x: auto;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.nav-tablist--vdp .tablist-container {
  padding-left: 10px;

  @media (min-width: 768px) {
    padding-left: 0;
  }
}

.nav-tablist .tabs {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (min-width: 992px) {
    padding-top: 27px;
    padding-bottom: 27px;
    margin-bottom: 20px;
  }
}

.nav-tablist .tabs {

  &:first-child {
    padding-left: var(--15px);

    @media (min-width: 768px) {
      padding-left: var(--50px);
    }

    @include min-media(1200px) {
      padding-left: var(--85px);
    }
  }

  &:last-child {
    padding-right: calc(15px - 0.6rem);

    @media (min-width: 768px) {
      padding-right: calc(50px - 0.6rem);
    }

    @include min-media(1200px) {
      padding-right: calc(85px - 0.6rem);
    }
  }
}

.navigatable.main-header__nav-bar .nav-tablist .tabs {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.nav-tablist .tab-button {
  font-size: var(--12px);
  letter-spacing: 0.25px;
  color: var(--apt-grey-3);
  flex: 0 0 auto;
  margin-right: 12px;

  @media (min-width: 512px) {
    font-size: var(--18px);
    letter-spacing: 0.5px;
    margin-right: 20px;
  }

  &--tag { // .nav-tablist .tab-button--tag
      border: 1px solid var(--apt-grey-3);
      border-radius: 1rem;
      padding: 0.25em 1em 0.45em !important;
      margin: 0 0.6rem;

      &:hover, &:active {
        border-color: var(--apt-black);
      }

      &:focus-visible {
        border-radius: 1rem;
      }
  }

  &:last-child {

    @media (max-width: 991px) {
      padding-right: var(--15px);
    }
  }
}

.nav-tablist .tab-button:hover,
.nav-tablist .tab-button:active,
.nav-tablist .tab-button:focus {
  color: var(--apt-black);
  text-decoration: none;
}

.tablist-card-container .card {
  border-top-left-radius: 0;
}

.tablist-card-container {
  position: relative;
  z-index: 1;

  .tabs {
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-bottom: -2px;
    z-index: 0;
    overflow-x: auto;
  }

  .tab-button {
    position: relative;
    display: block;
    padding: 6px 10px 7px;
    margin-right: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    color: var(--apt-grey-2);
    background-color: var(--apt-grey-9);
    background-image: linear-gradient(0deg, rgba(0,0,0,0.05) 0%, transparent 20%, transparent 100%);
    box-shadow: inset 0px 0px 0px 1px var(--apt-grey-6);
    //overflow: hidden;
    white-space: nowrap;
    text-decoration: none;

    @media (min-width: 375px) {
      font-size: 16px;
      padding: 8px 20px 9px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &:focus-visible {
      background-color: var(--apt-grey-6);
      text-transform: uppercase;
      text-underline-color: var(--apt-primary);
    }
  }

  .tab-button:first-child {
    z-index: 1;
  }
  .tab-button:nth-child(2) {
    z-index: 2;
  }
  .tab-button:nth-child(3) {
    z-index: 3;
  }
  .tab-button:nth-child(4) {
    z-index: 4;
  }
  .tab-button:nth-child(5) {
    z-index: 5;
  }

  .tab-button:focus-visible {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 10;
  }

  .tab-button:focus:not(:focus-visible) {
    box-shadow: inset 0px 0px 0px 1px var(--apt-grey-6);
  }

  .tab-button--active {
    border: 0;
    box-shadow: inset 0px 4px 6px 0px #2739B8;
    color: white;
    background-color: var(--apt-primary);
    z-index: 9;

    &:focus-visible {
      background-color: #2739B8;
      text-underline-color: var(--apt-primary);
    }
  }

  .tab-button--active:focus:not(:focus-visible) {
    box-shadow: inset 0px 4px 6px 0px #2739B8;
  }

  .tab-button::before {
    content: '';
    display: block;
    position: absolute;
    top:100%;
    left: 0;
    right: 0;
    box-shadow: 0px -1px 11px 0px rgba(0,0,0,0.15);
    width: 100%;
    height:1px;
  }

  .card {
    z-index: 11;
  }

  .tab-panel .button:not(:last-child) {
    margin-bottom: 18px;
  }

  .button--primary-orange {
    background-color: var(--apt-action);
    border-color: var(--apt-action);
  }

  .button--secondary {
    padding: 11px 24px 13px;
  }
}
