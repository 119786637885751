.user-interaction-icons {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--apt-grey-4);
  font-size: var(--14px);
  line-height: 18px;
  z-index: 2;

  @media (min-width: 768px) {
    font-size: var(--18px);
    line-height: 18px;
  }
}

.user-interaction-icons li {
  margin: 10px 0 0;

  @media (min-width: 768px) {
    margin: 0 0 0 12px;
  }
}

.user-interaction-icons > li > button,
.user-interaction-icons > li > input[type="button"],
.user-interaction-icons > li > input[type="submit"] {
  height: 20px;
  width: 18px;

  @media (max-width: 767px) {
    height: 24px;
    width: 24px;
    border-radius: 10px;
  }
}

.user-interaction-dialog {
  position: relative;
}

.user-interaction-dialog__button {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.user-interaction-dialog__content {
  position: absolute;
  top: 100%;
  right: 0;
  width: 7.8rem;
  padding: 11px 16px 13px 18px;
  margin: 10px 0 0;
  background-color: var(--apt-white);
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.2s ease;
  list-style: none;
  border: 1px solid var(--apt-grey-7);
  border-top: 1px solid var(--apt-primary);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.user-interaction-dialog__content li {
  margin: 0 0 0.3rem;
}
.user-interaction-dialog__content li:last-child {
  margin: 0;
}

.user-interaction-dialog__content a,
.user-interaction-dialog__content input[type="submit"],
.user-interaction-dialog__content input[type="button"],
.user-interaction-dialog__content button, {
  font-size: var(--14px);
  line-height: 18px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-4);
  text-align: left;
  text-decoration: none;
}

.user-interaction-dialog__content a:hover,
.user-interaction-dialog__content button:hover,
.user-interaction-dialog__content input[type="submit"]:hover,
.user-interaction-dialog__content input[type="button"]:hover {
  color: var(--apt-primary);
}

.user-interaction-dialog--toggled .user-interaction-dialog__button {
  transform: rotate(90deg);
  color: var(--apt-primary);
}

.user-interaction-dialog--toggled .user-interaction-dialog__content {
  transform: scale(1);
}