.zebra-header-container {
  position: relative;
  width: 100%;
  height: 60vw;
  min-height: 360px;
  max-height: 450px;
  z-index: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    max-height: 450px;
  }
}

.zebra-header-container a {
  text-decoration: none;
}

.zebra-header-container figure {
  width: 100%;
  height: 100%;
}

.zebra-header-container picture,
.zebra-header-container video {
  position: relative;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.zebra-header-gradient {
  position: absolute;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--black-gradient);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  z-index: 1;
}

.zebra-header-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zebra-header-separator--left {
  width: 100%;
  max-width: 570px;
  flex: 1 1 auto;

  @include min-media(768px) {
    padding-right: 2em;
    max-width: 715px;
  }
}

.zebra-header-separator--right {
  flex: 0 0 auto;
}

.zebra-header {
  position: absolute;
  bottom: 0;
  right: 0;
  left:0;
  width: 100%;
  max-width: 1440px;
  padding: 3.4em var(--15px) 0;
  margin: auto auto var(--10px);
  z-index: 2;

  @include min-media(512px) {
    margin: auto auto var(--32px);
  }

  @include min-media(768px) {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4em var(--45px) 0;
  }
}

.zebra-header h1 {
  max-width: 680px;

  @include min-media(1200px) {
    margin-bottom: 31px;
  }
}
.zebra-header .zebra-header__content {
  color: var(--apt-white);

  @include min-media(1200px) {
    line-height: 1.7;
  }
}

.zebra-header__content-cta {
  margin-top: 30px;
}

.zebra-header__price-list {
  color: var(--apt-white);
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
  margin-top: 2em;

  @include min-media(768px) {
    align-self: end;
    justify-self: end;
    padding-right: 2em;
  }

  @include min-media(1200px) {
    padding-right: calc((1510px - 100vw) / 2);
  }
}

.zebra-header__price-item span {
  display: inline-block;
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.05px;
  margin-top: var(--6px);
}

.zebra-header__price-item:not(:last-child) span {
  margin-bottom: var(--19px);
}

.home-hero-main__cta {
  .zebra-header-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    z-index: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 920px) {
      padding-bottom: 56.25%;
    }
  }

  .zebra-header-container a {
    text-decoration: none;
  }

  .zebra-header-container figure {
    width: 100%;
    height: 100%;
  }

  .zebra-header-container picture,
  .zebra-header-container video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .zebra-header {
    @include min-media(768px) {
      position: absolute;
    }
  }
}