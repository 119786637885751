.werken-bij {
  width: 100%;
  margin-top: var(--48px);

  @include min-media(992px) {
    display: flex;
    margin-top: 120px;
  }

  .arrow-btns a:hover, .arrow-btns button:hover,
  .arrow-btns a:focus, .arrow-btns button:focus,
  .arrow-btns a:active, .arrow-btns button:active {
    font-weight: var(--fw-medium);
  }

  .arrow-btns a:hover .fal, .arrow-btns button:hover .fal,
  .arrow-btns a:focus .fal, .arrow-btns button:focus .fal,
  .arrow-btns a:active .fal, .arrow-btns button:active .fal {
    font-weight: var(--fw-medium);
  }
}

.werken-bij__filters {
  flex: 0 0 auto;
  width: 100%;
  max-width: 670px;
  margin: 0 auto;

  @include min-media(992px) {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.werken-bij__filters .heading-2 {
  text-align: center;
  width: 200px;
  margin-right: auto;
  margin-bottom: 38px;
  margin-left: auto;

  @include min-media(512px) {
    width: auto;
  }

  @include min-media(992px) {
    text-align: left;
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

.werken-bij__sticky-container {

  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }

  @include min-media(992px) {
    margin-bottom: 40px;
  }
}

.werken-bij__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;

  @include min-media(768px) {
    width: 100%;
    left: 0;
    max-width: 670px;
  }

  @include min-media(992px) {
    width: 100%;
    max-width: none;
    margin-top: calc(104px + 28px);
    margin-bottom: 96px;
  }
}

.werken-bij .dialog__title,
.werken-bij .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);

  @include min-media(768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.werken-bij__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;

  @include min-media(768px) {
    max-width: none;
  }
}

.werken-bij__result .dialog {

  @include min-media(768px) {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }

  @include min-media(992px) {
    padding-right: 0;
  }
}

.werken-bij__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.werken-bij__result .dialog .dialog__title .fa-times {
  display: none;
}

.werken-bij__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.werken-bij__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}

.werken-bij__detail-content {
  flex: 1 1 auto;
  padding-right: 30px;
  padding-bottom: 50px;

  @include min-media(992px) {
    padding-bottom: 160px;
  }
}

.werken-bij__detail-content-wrapper {
  width: 100%;
  max-width: 523px;

  img {
    max-width: 100%;
  }
  h1 {
    display: block;
    font-size: var(--22px);
    line-height: 24px;
    letter-spacing: 0;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
    margin-bottom: var(--12px);

    @include min-media(512px) {
      font-size: var(--28px);
      line-height: 30px;
      letter-spacing: -0.12px;
    }

    @include min-media(768px) {
      font-size: var(--40px);
      line-height: 48px;
      letter-spacing: -0.5px;
    }
  }

  h2 {
    display: block;
    font-size: var(--22px);
    line-height: 30px;
    letter-spacing: -0.09px;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
    margin-bottom: var(--17px);
  }

  h3 {
    display: block;
    font-size: var(--18px);
    line-height: 26px;
    letter-spacing: 0;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
  }
}

.werken-bij__detail-aside {
  flex: 0 0 auto;
  width: 100%;
  max-width: 390px;
  padding-bottom: 1.2em;

  .block-default, .pagesection-default, .wrapper, .editableContainer {
    height:100%;
  }

  .card {
    position: sticky;
    top: 120px;
    height: auto;
  }

  .card__content {
    @media (min-width: 512px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    & > div {
      width: 100%;
    }
  }

  h2 {
    display: block;
    font-size: var(--22px);
    line-height: 30px;
    letter-spacing: -0.09px;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
    margin-bottom: var(--17px);
  }
}

.werken-bij .card__figure figure {
  width: 100%;

  @supports not (aspect-ratio: 3/1.9) {
    margin: 0;
    height: auto;
  }

  img { // .werken-bij .card__figure figure img
    width: 100%;
    aspect-ratio: 0;
    max-height: 200px;
    object-fit: cover;
  }

  @supports not (aspect-ratio: 3/1.9) {
    img {
      height: auto;
      position: static;
      margin: 0;
    }
  }
}

.werken-bij .card .button {
  width: 100%;
  margin-bottom: 20px;
}

.werken-bij .card__content p {
  font-size: var(--14px);
  line-height: 22px;
  color: var(--apt-grey-2);
}
.werken-bij .card__content hr {
  width: 100%;
  margin-top: 46px;
  margin-bottom: 38px;
}