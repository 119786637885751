.hide-desktop{
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
}

.show-tablet{
    display:none;
    @media (max-width: 991px){
        display:block
    }
}

.hide-mobile{
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
}

.mb-1 {
    margin-bottom: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mb-3 {
    margin-bottom: 0.75rem;
}

.mb-4 {
    margin-bottom: 1rem;
}

.mb-5 {
    margin-bottom: 1.25rem;
}

.mt-1 {
    margin-top: 0.25rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mt-3 {
    margin-top: 0.75rem;
}

.mt-4 {
    margin-top: 1rem;
}

.mt-5 {
    margin-top: 1.25rem;
}

.pl-0{
    padding-left: 0 !important;
}

.bg-grey-8 {
    background-color: var(--apt-grey-8);
}

.bg-grey-8-inset {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 150px;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--apt-grey-8);
        border-top: 1px solid var(--apt-grey-7);
    }
}

.bg-grey-9 {
    background-color: var(--apt-grey-9);
}

.bg-white {
    background-color: var(--apt-white);
}

/* default classes for GX WebManager - DO NOT REMOVE */
.overflowHidden {
    overflow: hidden;
}
.overflowHidden[style^="overflow-y: initial"] {
    overflow: hidden !important;
}

@media (max-width:991px) {.mobileOverflowHidden {overflow: hidden;} }

.displayNone {
    display:none;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.textcentered {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.center {
    margin: auto;
    text-align: center;
}

.aligncenter {
    text-align: center;
}

.clearAll {
    clear: both;
}

.noWrap {
    white-space: nowrap;
}

.vertical-space {
    margin-top: 5em;
    margin-bottom: 5em;
}

.desktop-flex {
    margin-bottom: 40px;

    // TODO: Eng
    @media (min-width: 920px) {
        display: flex;
        margin-bottom: 120px;
    }
}

.grayscale {
    filter: saturate(0);
}