.dropdown {
  position: relative;
  width: 100%;
  margin-bottom: var(--39px);
}

.dropdown--large {
  margin-top: var(--17px);
  margin-bottom: var(--10px);
}

.dropdown--shown {
  position: relative;
  z-index: 3;
}

.dropdown__toggle {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 12px;
  border-bottom: 1px solid var(--apt-grey-4);
  box-shadow: 0 0 0 0;
  height: var(--36px);
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0.3px;
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;

  &::after {
    // .dropdown__toggle::after
    content: '\2303';
    font-family: var(--fa);
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform 0.1s ease;
    position: absolute;
    bottom: 16px;
    right: 20px;
    pointer-events: none;
    color: var(--apt-grey-3);
    line-height: 0;
    text-align: center;
    font-weight: var(--fw-light);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.dropdown--large .dropdown__toggle {
  height: var(--56px);
  padding: 0 0 var(--25px);
}

.dropdown__menu {
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  z-index: 0;
  overflow: auto;
  max-height: 264px;

  > * {
    // .dropdown__menu > *
    position: relative;
    width: 100%;
    height: var(--40px);
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 7px 9px 4px;
    margin: 0;
    color: var(--apt-black);
    border: 0;
    font-size: var(--16px);
    line-height: 1.15;
    text-align: left;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
    transition: background-color 0.2s linear;

    &:hover {
      // .dropdown__menu > *:hover
      background-color: var(--apt-grey-9);
    }

    &:focus {
      background-color: var(--apt-grey-9);
    }
  }

  img {
    // .dropdown__menu img
    margin-right: 12px;
  }
}

.tab-optimized .dropdown__menu>*:focus {
  background-color: lightgray;
}


.dropdown--shown {

  .dropdown__toggle::after {
    transform: rotate(0deg);
  }

  >.dropdown__menu {
    height: auto;
    opacity: 1;
    pointer-events: auto;

    &:focus-within {
      z-index: 1;
    }
  }

  .dropdown__option:focus-visible {
    box-shadow: 0 0 0 2px var(--apt-primary) !important;
  }
}

.mobile-dropdown {
  position: relative;

  &::after {
    // .mobile-dropdown::after
    content: '\2303';
    font-family: var(--fa);
    width: 12px;
    height: 12px;
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform 0.1s ease;
    position: absolute;
    bottom: 16px;
    right: 20px;
    pointer-events: none;
    color: var(--apt-grey-3);
    line-height: 0;
    text-align: center;
    font-weight: var(--fw-light);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  select {
    // .mobile-dropdown select
    display: flex;
    justify-content: stretch;
    align-items: center;
    position: relative;
    width: 100%;
    user-select: none;
    touch-action: manipulation;
    padding: 7px 0px 4px;
    letter-spacing: normal;
    border: 0;
    border-bottom: 1px solid var(--apt-grey-4);
    border-radius: 0;
    height: var(--40px);
    margin: 0 0 0;
    font-family: inherit;
    font-size: var(--16px);
    line-height: 1.15;
    color: var(--apt-black);
    background-color: inherit;
    appearance: none;
    text-indent: 0;
    z-index: 1;

    option:not([hidden]) {
      // .mobile-dropdown select option
      padding: 2px;
      display: block;
    }
  }
}

@media (hover: none),
(pointer: none),
(max-width:767px) {

  [x-cloak="desktop"],
  .desktop-dropdown {
    display: none;
  }
}

@media (hover: hover) and (min-width:767px),
(pointer: fine) and (min-width:767px) {

  [x-cloak="mobile"],
  .mobile-dropdown {
    display: none;
  }
}

.wm-field-dropdown--inline {
  margin-bottom: 0 !important;
}

.dropdown-wrapper--crop {
  height: var(--40px);
}

.wmpform .from-grid-item--self-end {
  align-self: flex-end;
}

.wmpform .dropdown-wrapper--crop {

  select:not(:placeholder-shown) ~ label {
    transform: translateY(-39px) scale(0.78);
  }
}

.dropdown-wrapper .wm-field-input.wm-field-input {
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: inherit;
  border: 0;
  border-bottom: 1px solid var(--apt-grey-4);
  border-radius: 0;
  color: var(--apt-black);
  font-family: inherit;
  font-size: var(--18px);
  height: var(--40px);
  justify-content: stretch;
  letter-spacing: normal;
  line-height: 28px;
  margin: 0;
  padding: 0 34px 12px 0;
  text-indent: 0;
  touch-action: manipulation;
  user-select: none;
  z-index: 1;

  & > span {
    position: absolute;
    pointer-events: none;
  }

  select { // .dropdown-wrapper .wm-field-input.wm-field-input select
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    appearance: auto;
    background: none;
    border: 0;
    box-shadow: none;
    color: transparent;
    z-index: 1;
    margin: 0;
    padding: 0;
    border-radius: 0;
    font-size: var(--16px); /*18 */
    line-height: 1.15;
    outline: revert;
  }

  option { // .dropdown-wrapper .wm-field-input.wm-field-input option
    color: black;
  }

  &::after { // .dropdown-wrapper .wm-field-input.wm-field-input::after
    content: "⌃";
    position: absolute;
    right: 20px;
    bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    font-family: var(--fa);
    font-weight: var(--fw-light);
    line-height: 0;
    color: var(--apt-grey-3);
    pointer-events: none;
    text-align: center;
    transform: rotate(180deg);
    transform-origin: center;
    transition: transform .1s ease;
    z-index: 1;
  }
}

.dropdown-wrapper {
  position: relative;

  &--has-placeholder { // .dropdown-wrapper--has-placeholder

    .wm-field-input.wm-field-input > span {
      color: var(--apt-grey-3) !important;
    }
  }

  &--has-value { // .dropdown-wrapper--has-value

    .wm-field-input.wm-field-input > span { // .dropdown-wrapper--has-value select
      color: var(--apt-black) !important;
    }
  }
}

.info-toggle-select--mobile.dropdown-wrapper select {
  appearance: none !important;
}

_::-webkit-full-page-media, _:future, :root select {
  appearance: none !important;
}
