.detail-grid {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 100%;

  @media (min-width: 768px) {
    padding-top: 50px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 390px;
    grid-template-rows: auto 1fr;
    padding-top: 60px;
  }
}

.detail-gallery {
  position: relative;
  grid-column: 1 / -1;
  grid-row: 1 / span 1;
  min-width: 0;
  width: calc(100% + 30px);
  margin-left: -15px;

  @media (min-width: 768px) {
    width: 100%;
    margin-left: 0;
  }

  @media (min-width: 992px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    width: calc(100% - 50px);
    margin-right: 50px;
    margin-left: 0;
  }
}

.detail-gallery .tag-list {
  position: absolute;
  top: 15px;
  left: 18px;
  z-index: 2;
  max-width: calc(100% - 100px);

  @media (min-width: 768px) {
    top: 31px;
    left: 30px;
  }
}

.detail-gallery .user-interaction-icons {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 24px;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }

  button svg {
    margin: 0 auto;
  }
}

.detail-gallery .gallery-container--spinner .gallery-button-list {
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.detail-content {
  grid-column: 1 / -1;
  grid-row: 3 / span 1;
  min-width: 0;

  @media (min-width: 992px) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-right: 50px;
  }
}

.detail-content__top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 20px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
}

.detail-content__top p a {
  white-space: nowrap;
}

.detail-content > div:first-child {
  margin-bottom: 30px;
}

.detail-content > .data-list {
  margin-bottom: 73px;
}

.detail-content > .two-columns {
  row-gap: 2rem;
}

.detail-sidebar {
  position: relative;
  grid-column: 1 / -1;
  grid-row: 2 / span 1;
  padding-top: 2px; // TODO check me
  min-width: 0;

  @media (min-width: 992px) {
    grid-column: 2 / 3;
    grid-row: 1 / -1;
    padding-top: 0;
  }

  .card {
    height: auto;
    box-shadow: var(--shadow-b);
  }
}

.detail-sidebar .tag-list {

  @media (max-width: 991px) {
    display: none !important;
  }
}
.detail-sidebar .user-interaction-icons {
  display: inline-flex;

  @media (max-width: 991px) {
    display: none !important;
  }

  @media (min-width: 768px) {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.detail-sidebar .card {
  margin-bottom: 40px;
}

.detail-sidebar > .heading-2 {
  margin-top: 27px;
  margin-bottom: 6px;

  @media (min-width: 512px) {
    margin-bottom: 12px;
  }
  @media (min-width: 768px) {
    margin-top: 0;
  }
  @media (min-width: 992px) {
    margin-top: 23px;
    margin-bottom: 20px;
  }
}

.detail-sidebar .button--primary {
  width: 100%;
  margin-top: 34px;
}

.detail-sidebar__stats-bar {
  display: inline-flex;
  gap: 11px;
  align-items: center;

  @media (max-width: 991px) {
    display: none !important;
  }
}

.detail-sidebar__supply {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  display: block;

  @media (min-width: 768px) {
    margin-top: 20px;
  }

  @include min-media(992px) {
    display: none;
  }
}

.detail-sidebar__supply a {
  display: block;

  @media (min-width: 768px) {
    display: inline;
  }
}

.detail-sidebar__tag {
  background-color: transparent;
  border-radius: 2em;
  border: 1px solid var(--apt-grey-3);
  color: var(--apt-grey-3);
  display: inline-flex;
  font-size: var(--11px);
  font-weight: var(--fw-semibold);
  letter-spacing: .46px;
  line-height: 1.1;
  padding: 0.23em 0.75em 0.35em;
  text-decoration: none;
  text-transform: uppercase;
  transition: filter .2s ease,background-color .2s ease;
}

.detail-sidebar__views {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: var(--14px);
  line-height: 1.2;
  letter-spacing: 0.26px;
  color: var(--apt-grey-4);
  z-index: 2;

  @media (max-width: 991px) {
    display: none !important;
  }

  @media (min-width: 768px) {
    top: 40px;
    right: initial;
  }

  @media (min-width: 992px) {
    position: relative;
    top: -1px;
  }
}

.detail-sidebar__class {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
  color: var(--apt-grey-4);
  margin-bottom: 9px;
}

.detail-sidebar__characteristics {
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.detail-sidebar__characteristics li:not(:last-child)::after {
  content: "|";
  margin-left: 1ch;
  margin-right: 1ch;
}

.two-columns {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 992px) {
    width: calc(100% + 50px);
    margin-left: -25px;
  }

  @supports not (padding: max(1vw, 100px)) {

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }
}

.two-columns > div {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.two-columns .heading {
  margin-bottom: var(--25px);
}

.vdp-gallery-grid-align-hack.gallery-grid__toggle-container {
  display: flex;
  gap: 10px;
  bottom: 0;
  z-index: 2;

  @media (max-width: 767px) {
    position: absolute;
    top: 16px;
    right:var(--15px);
    bottom:auto;
    left:auto;
    transform: none !important;
  }

  @include min-media(768px) {
    left: 0;
    bottom: -12px;
  }

  @include min-media(992px) {
    left: 0;
    bottom: -25px;
  }
}
.vdp-gallery-grid-align-hack.gallery-grid__toggle-container .gallery-grid__fullscreen-container {
  position: static;
}

.vdp-gallery-grid-align-hack ~ .gallery-grid__primary .gallery-grid__sirv-container {
  @media (max-width: 767px) {
    margin-top: 62px;
  }
}

.vdp-gallery-grid-align-hack ~ .gallery-grid__primary .swiper-wrapper {
  @media (max-width: 767px) {
    margin-top: 62px;
    margin-bottom: 38px;
  }
}
.vdp-gallery-grid-align-hack ~ .gallery-grid__primary .gallery-slider__pagination.swiper-pagination-fraction {
  @media (max-width: 767px) {
    top: 21px !important;
    bottom: auto !important;
    left: 0;
    right: auto;
  }
}

.gallery-slider__pagination.swiper-pagination-fraction {
  position: absolute;
}

.floating-toggle {
  position:fixed;
  bottom:-75px;
  right:20px;
  z-index: 3;

  @media (max-width: 767px) {
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .floating-toggle-button {
    position: relative;
    transform: translate3d(0px, 0px, 0px) translateY(0);
    transition: transform 0.4s ease;
  }

  .button.button--primary {
    @media (max-width: 767px) {
      padding: 7px 32px 9px;
      min-height: 42px;
    }
  }
}

.floating-toggle--visible {

  .floating-toggle-button {
    transform: translate3d(0px, 0px, 0px) translateY(-95px);

    @media (max-width: 767px) {
      transform: translate3d(0px, 0px, 0px) translateY(-107px);
    }
  }
}

[data-cy="message-input-group"] [class^="App_counter__"] {
  flex-grow: 0;
  flex-shrink: 0;
}

.direct-phone-link {
  font-family: var(--fellix);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.3px;
  color: var(--apt-primary);
}

.direct-phone-location {
  position: relative;
  width: 100%;
  max-width: 324px;
  margin: 29px 0 0;

  & > span:first-of-type {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    line-height: 28px;
    height: 50px;
    color: var(--apt-grey-3);
    font-size: var(--18px);
    display: block;
    margin-bottom: 0;
    white-space: nowrap;
    pointer-events: none;
    transform-origin: left top;
    transition: transform 0.2s linear;
    transform: translateY(-1.44em) scale(0.78);
    overflow: visible;
    text-overflow: initial;
    padding: 8px 12px 0 0;
  }

  & > span:last-of-type {
    display: block;
    padding: 10px 0 12px 0;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-style: solid;
    border-color: var(--apt-grey-4);
    border-radius: 0;
    box-shadow: 0 0 0 0;
    width: 100%;
    min-height: 50px;
    line-height: initial;
    font-size: var(--18px);
    letter-spacing: normal;
    color: var(--apt-black);
    background: none;
  }
}