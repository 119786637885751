.data-list {
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  border-collapse: collapse;
  padding: 0;
  margin: 0 0 36px;
  border: 0;
}

.data-list tbody {
  display: grid;
  width: calc(100% + 24px);
  margin-left: -12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 992px) {
    display: block;
  }

  @media (min-width: 1254px) {
    display: grid;
  }

  @supports not (padding: max(1vw, 100px)) {

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }
}

.detail-content .data-list tbody {
  display: grid;
  width: calc(100% + 30px);
  margin-left: -15px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }

  @supports not (padding: max(1vw, 100px)) {

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }

  @media (min-width: 992px) {
    display: grid;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.data-list tr {
  width: calc(100% - 24px);
  height: 100%;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
}

.detail-content .data-list tr {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: 992px) {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

.data-list tr th,
.data-list tr td {
  height: 100%;
  margin: 0;
  padding-top: 1.35em;
  padding-bottom: 1.45em;
  border-bottom: 1px solid var(--apt-grey-7);
  padding-right: 1em;
  font-weight: var(--fw-regular);
  text-align: left;
}

.data-list tr:nth-child(1) {
    border-top: 1px solid var(--apt-grey-7);
}

.data-list tr:nth-child(2) {
  @media (min-width:768px) and (max-width: 991px), (min-width: 1138px) {
    border-top: 1px solid var(--apt-grey-7);
  }
}

.detail-content .data-list tr:nth-child(1),
.detail-content .data-list tr:nth-child(2) {
  padding-top: 1.15em;
}

.data-list tr th {
  width: 110px;
}
.data-list tr td {
  width: calc(100% - 110px);
}