.content-header {
  width: 100%;
  padding: 0;

  &--narrow { // .content-header--narrow

    .zebra-header { // .content-header--narrow .zebra-header
      max-width: 1218px;
      padding-left: var(--15px);
      padding-right: var(--15px);

      @media (min-width: 768px) {
        padding-left: var(--50px);
        padding-right: var(--50px);
      }

      @media (min-width: 1200px) {
        padding-left: var(--85px);
        padding-right: var(--85px);
      }

      .zebra-header-separator--left { // .content-header--narrow .zebra-header .zebra-header-separator--left
        @media (min-width: 992px) {
          max-width: 50%;
        }
      }
    }
  }
}

.content-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    width: calc(100% + 25px);
    margin-left: -25px;
  }
}

.content-hero.has-bottom-padding {
  padding-bottom: 35px;
  @media (min-width: 992px) {
    padding-bottom: 80px;
  }
}
.content-hero.has-top-padding {
  padding-top: 35px;
  padding-bottom: 35px;
  @media (min-width: 992px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.content-hero__figure {
  position: relative;
  margin-bottom: 25px;

  @media (min-width: 992px) {
    width: calc(50% + 25px);
    padding-right: 25px;
    margin-bottom: 0;
    align-self: flex-start;
    position: sticky;
    top: 100px;
  }

  img { // .content-hero__figure img
    width: 100%;
    max-width: 420px;
    height: auto;
  }
}

.video-figure-wrapper {
  width: 100%;
  max-width: 500px;
}
.video-figure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.video-figure iframe,
.video-figure video {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.content-hero__text {
  position: relative;

  @media (min-width: 992px) {
    width: 50%;
    padding-left: 25px;
    padding-right: 50px;
    margin-left: -0.45em;
  }

  &--no-right-padding {
    @media (min-width: 992px) {
      padding-right: 0;
    }
  }

  .heading-1 {
    @media (min-width: 992px) {
      margin-bottom: var(--36px);
    }
  }
}

.content-hero__text .button {
  margin-top: 25px;

  @media (min-width: 992px) {
    margin-top: 43px;
    margin-left: 0.05em;
  }
}

.zebra-grid {
  position: relative;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    padding-bottom: 50px;
  }

  @media (min-width: 992px) {
    grid-template-columns: 19fr 23fr;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 100px;
  }

}

.zebra-grid__content {
}

.zebra-grid__content .heading {
  max-width: 21ch;
  padding-top: 20px;
  letter-spacing: -0.75px;

  @media (min-width: 768px) {
    width: calc(100% + 0.5ch);
    padding-top: 50px;
    font-size: var(--40px);
    line-height: 48px;
  }
}

.zebra-grid__box {
  background-color: var(--apt-white);
  box-shadow: 0 4px 6px #DBDBD8;
  border-radius: 10px;
  padding: 34px 22px;
  max-width: 632px;

  @media (min-width: 612px) {
    padding: 44px 56px;
  }

  @media (min-width: 1440px) {
    height: calc(100% + 110px);
    padding: 50px 56px;
    margin-top: -110px;
  }
}

.zebra-grid__box .wmpform .heading {
  padding-bottom: 4px;

  @media (min-width: 1200px) {
    padding-bottom: 24px;
  }
}

.zebra-grid__box .wmpform .form-table-wrapper {
  width: calc(100% + 28px);
  margin-bottom: -16px;
}

.zebra-grid__box .form-table-wrapper .gridcontentareastyle {
  @media (max-width: 611px) {
    width: 100% !important;
  }

  @media (min-width: 992px) {
    width: 100% !important;
  }

  @media (min-width: 1440px) {
    width: 50% !important;
  }
}
.zebra-grid__box .wmpform .form-table-wrapper .gridtable tr {
  @media (max-width: 611px) {
    flex-direction: column !important;
  }

  @media (min-width: 992px) {
    flex-direction: column !important;
  }

  @media (min-width: 1440px) {
    flex-direction: row !important;
  }
}

.zebra-block {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 35px;
  padding-bottom: 35px;

  @media (min-width: 992px) {
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.zebra-block img {
  max-width: 100%;
}

.zebra-block--inversed {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;

  @media (min-width: 992px) {
    flex-direction: row-reverse;
  }

  .zebra-block__text { // .zebra-block--inversed .zebra-block__text
    @media (min-width: 992px) {
      padding-right: 0;
      padding-left: 85px;
    }
  }

  .zebra-block__figure { // .zebra-block--inversed .zebra-block__figure
    @media (min-width: 992px) {
      padding-right: 25px;
      padding-left: 0;
    }

    img {
      float: none;
    }
  }
}

.grid-item--inline-offset .zebra-block--inversed .zebra-block__text {
  @media (min-width: 1440px) {
    padding-left: 66.5px;
  }
}

.zebra-block--wide-figure {
  @media (min-width: 992px) {
    margin-right: -85px;
  }

  @media (min-width: 1200px) {
    margin-right: -105px;
  }
}

.zebra-block--extra-top-padding {
  @media (min-width: 992px) {
    padding-top: 185px;
  }
}

.zebra-block__text {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  @media (min-width: 992px) {
    width: 50%;
    padding-right: 85px;
    padding-left: 0;
  }

  .heading { // .zebra-block__text .heading
    margin-bottom: var(--26px);

    @include min-media(992px) {
      margin-bottom: var(--38px);
    }
  }

  .button { // .zebra-block__text .button
    margin-top: var(--32px);
  }
}

.grid-item--inline-offset .zebra-block:not(.zebra-block--inversed) .zebra-block__text {
  @media (min-width: 1440px) {
    padding-right: 66.5px;
  }
}

.zebra-block--wide-figure .zebra-block__text {
  @media (min-width: 992px) {
    width: 35%;
    margin-top: 62px;
    padding-right: 40px;
  }
}

.zebra-block__figure {
  margin-bottom: 25px;

  @media (min-width: 992px) {
    width: 50%;
    padding-right: 0;
    padding-left: 25px;
    margin-bottom: 0;
  }

  img { // .zebra-block__figure img
    width: 100%;
    max-width: 500px;

    @media (min-width: 992px) {
      float: right;
    }
  }
}

.zebra-block--wide-figure .zebra-block__figure {
  width: 100%;
  max-width: 718px;

  @media (min-width: 992px) {
    width: 65%;
    max-width: none;
  }
}

.zebra-block ul.checks li {
  margin-bottom: 0.7em;
  line-height: 1;
}
.zebra-block ul.checks li::before {
  top: 0.2em;
}

.zebra-cards {
  position: relative;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 5px;

  @include min-media(512px) {
    padding-top: 48px;
  }

  @include min-media(992px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 106px;
    padding-bottom: 97px;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.zebra-cards > p {
  display: none;
}

.zebra-cards > div {
  width: 100%;
  padding-bottom: 20px;

  @include min-media(512px) {
    padding-bottom: 35px;
  }

  @include min-media(992px) {
    width: calc(50% - 50px);
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
    padding-bottom: 0;
  }

  .card:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);

    @media (min-width: 992px) {
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
    }

    @media (min-width: 1200px) {
      box-shadow: var(--shadow-hover);
    }
  }
}

.zebra-card--clickable a,
.zebra-card--clickable a:hover,
.zebra-card--clickable a:active,
.zebra-card--clickable a:focus {
  text-decoration: none;
}

/* intentionally duplicated */
.zebra-card__header.zebra-card__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
}

.zebra-card__header.zebra-card__header img {
  width: auto;
  height: 56px;
  padding-right: var(--33px);
}

.zebra-card__header.zebra-card__header span {
  color: var(--apt-primary);
  margin-bottom: 0.45em;
}

.zebra-card .card__content {

  @media (min-width: 512px) {
    padding: 32px 26px 30px;
  }

  @media (min-width: 992px) {
    padding: 52px 46px 50px;
  }
}

.zebra-card .zebra-card__text {
  flex: 1 1 auto;
  max-width: 360px;
  margin-top: 40px;
}

.nav-tablist-horizontal-ruler {
  width: 100%;
  height: 10px;
  background: var(--apt-grey-8);
  margin-top: -20px;
}

.full-width-zebra-block {
  width: 100%;

  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.full-width-zebra-block__title {
  width: 100%;
  padding-right: 20px;

  @media (min-width: 992px) {
    width: 44%;
    padding-left: calc((100vw - 1218px) / 2);
  }

  @media (min-width: 1440px) {
    width: 50%;
    padding-left: calc((1440px - 1218px) / 2);
  }

  .heading {
    width: 100%;
    max-width: 400px;
    margin-bottom: var(--30px);
  }
}

.full-width-zebra-block__content {
  width: 100%;

  @media (min-width: 992px) {
    width: 60%;
    padding-top: 10px;
  }

  @media (min-width: 1440px) {
    width: 50%;
  }
}

.table-container {
  max-width: 830px;
  width: calc(100% + 15px);
  overflow: auto;

  @include min-media(992px) {
    display: block;
    width: 100%;
    margin: 0 auto;
    overflow: visible;
  }
}

.data-list-container .data-list {
  max-width: 636px;
  margin-left: auto;
  margin-right: auto;

  caption {
    text-align: left;
    margin-bottom: 33px;
  }

  tbody {
    @include min-media(768px) {
      display: grid;
    }

    tr:nth-child(2) {
      @include min-media(768px) {
        border-top: 1px solid var(--apt-grey-7);
      }
    }
  }
}

.table-mobile-spacing {
  position: relative;
  width: 15px;
  min-width: 15px;
  background: none;
  z-index: 1;

  @include min-media(992px) {
    display: none;
  }
}

.table {
  width: auto;
  min-width: calc(100% - 15px);
  border-collapse: collapse;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0 15px 0 0;
  border: 0;
  text-align: left;

  @include min-media(992px) {
    width: 100%;
    padding: 0;
  }

  .caption {
    text-align: left;
    font-size: var(--22px);
    line-height: 30px;
    font-weight: var(--fw-medium);
  }

  [role="cell"] {
    font-size: var(--20px);
    line-height: 22px;
  }

  [role="rowgroup"] {
    display: table-row-group;
  }

  tr,
  [role="row"] {
    display: table-row;
  }

  [role="cell"], [role="rowheader"], [role="columnheader"] {
    display: table-cell;
  }

  [role="rowheader"] {
    font-size: var(--14px);
    line-height: 22px;
    font-weight: var(--fw-regular);
    text-align: left;
    color: var(--apt-grey-2);
  }

  [role="columnheader"] {
    font-weight: var(--fw-medium);
    text-align: left;
    font-size: var(--16px);
    line-height: 26px;
  }

  tr:first-child,
  [role="row"]:first-child {

    td:first-child,
    [role="cell"]:first-child {
      font-size: var(--22px);
      line-height: 30px;
      font-weight: var(--fw-medium);
    }
  }

  & > tr:first-child td,
  & > tr:first-child th,
  thead tr td,
  thead tr th,
  [role="rowgroup"]:first-child div {
    padding-top: 0;
    padding-bottom: 33px;
    position: sticky;
    left: 0;
    background: var(--apt-grey-9);
  }

  & > tr:first-child,
  & > tr:first-child,
  thead tr,
  thead tr,
  [role="rowgroup"]:first-child [role="row"] {
    box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
  }

  & > tr:first-child td:first-child,
  & > tr:first-child th:first-child,
  thead tr td:first-child,
  thead tr th:first-child,
  [role="rowgroup"]:first-child [role="row"] div:first-child {
    background: var(--apt-grey-9);
    min-width: 100px;
    z-index: 1;

    @include max-media(991px) {
      box-shadow: inset -1px 0 0 0 var(--apt-grey-7);
    }
  }

  & > tr:not(:first-child) td,
  & > tr:not(:first-child) th,
  tbody tr td,
  tbody tr th,
  [role="rowgroup"]:not(:first-child) div {
    padding-top: 15px;
    padding-bottom: 18px;
    position: sticky;
    left: 0;
    background: var(--apt-grey-9);
    box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
  }

  & > tr:not(:first-child) td:first-child,
  & > tr:not(:first-child) th:first-child,
  tbody tr td:first-child,
  tbody tr th:first-child,
  [role="rowgroup"]:not(:first-child) [role="row"] div:first-child {
    background: var(--apt-grey-9);
    min-width: 100px;
    z-index: 1;
    box-shadow: inset 0 -1px 0 0 var(--apt-grey-7), inset -1px 0 0 0 var(--apt-grey-7);

    @include min-media(991px) {
      box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
    }
  }

  tr td:not(:first-child),
  tr th:not(:first-child),
  [role="row"] [role="cell"]:not(:first-child),
  [role="row"] [role="rowheader"]:not(:first-child),
  [role="row"] [role="columnheader"]:not(:first-child) {
    text-align: center;
    min-width: 100px;
    box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
  }

  .fa-check {
    color: var(--apt-success-1);
  }

  .fa-times {
    color: var(--apt-error-1);
  }

  .fa-dash {
    color: var(--apt-grey-5);
  }
}

.narrow-content-spacing {
  width: 100%;

  h2 {
    margin-bottom: -15px;
  }
}

.narrow-content-container {
  display: grid;
  grid-template-columns: auto minmax(65.353%, 830px) auto;
  row-gap: var(--43px);
  width: 100%;

  & > * {
    grid-column: 2 / span 1;
  }

  & > hr {
    grid-column: 2/span 2;
  }
}

.narrow-content-buttons {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: var(--20px);
  padding-bottom: 40px;

  @media (min-width: 512px) {
    margin-top: var(--30px);
    padding-bottom: 10px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 0;
  }
}