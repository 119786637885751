.offer-card-list {
    margin-top: -15px; // TODO: margin-top is questionable. Applied because of a visual overflow on hover

    @include max-media(919px) {
        display: flex;
        justify-content: space-between;
    }

    &.swiper-wrapper { //.offer-card-list.swiper-wrapper
        width: 100%;
        height: auto;

        @include min-media(920px) {
            width: calc(100% + 30px);
            margin-left: -15px;
        }

        @include min-media(1200px) {
            width: calc(100% + 50px);
            margin-left: -25px;
        }
    }

    & > * { // .offer-card-list > *
        padding: 15px 10px 30px; // TODO: padding-top is questionable. Applied because of a visual overflow on hover
        height: 100%;

        @include min-media(920px) {
            padding: 15px 15px 30px;
        }

        @include min-media(1200px) {
            padding: 15px 25px 30px;
        }
    }

    .swiper-pagination { // .offer-card-list .swiper-pagination
        @include max-media(919px) {
            display: none;
        }
    }

    & > .swiper-slide { // .offer-card-list > .swiper-slide
        height: auto;
    }

    &--two > .swiper-slide { // .offer-card-list > .swiper-slide
        @media (min-width: 768px) and (max-width: 921px) {
            width: 50%;
        }
    }

    & > a {
        height: 100%;
    }

    .content-card { // .offer-card-list .content-card
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .card__content { // .offer-card-list .card__content
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        flex-grow: 1;

        .heading {
            flex-grow: 1;
        }
    }

    .tag-list li { // .offer-card-list .tag-list li
        text-transform: uppercase;

        span {
            padding: 0.3em 0.75em 0.3em;
        }
    }
}

.offer-card__aside {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 45%;
    flex: 0 0 auto;

    & > * {
        max-width: 322px;
    }

    @include max-media(919px) {
        width: 100%;
        padding: 40px 0;
        margin-left: auto;
        margin-right: auto;
    }

    .heading, h2 { // .offer-card__aside .heading
        margin-bottom: 33px;
    }
}

.offer-card__cards {
    width: 55%;

    @include max-media(919px) {
        width: 100%;
    }
}

.desktop-flex-1 .offer-card__aside {
    @include min-media(920px) {
        padding: 0 0 15px 30px;
    }
}

.desktop-flex-1 {
    @media (max-width: 919px) {
        display: flex;
        flex-direction: column;
    }
}

.desktop-flex-2 .offer-card__aside {
    @include min-media(920px) {
        padding: 0 30px 15px 0;
    }
}

.desktop-flex-2 {
    @media (max-width: 919px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

// GRID SYSTEM
.offer-card-list[data-cols="1"] > * {
    @include min-media(920px) {
        max-width: 100%;
    }
}

.offer-card-list[data-cols="2"] > * {
    @include min-media(920px) {
        max-width: 50%;
    }
}

.offer-card-list[data-cols="3"] > * {
    @include min-media(920px) {
        max-width: 33.333%;
    }
}

.offer-card-list[data-cols="4"] > * {
    @include min-media(920px) {
        max-width: 25%;
    }
}

.offer-card-listing-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    & > span:first-of-type {
        color: var(--apt-grey-4);
        flex: 1 1 auto;
    }

    ul { // .offer-card-listing-header ul
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;

        li {
            white-space: nowrap;
            margin-right: 5px;

            &::after {
                margin-left: 4px;
                content: '|';
            }

            &:last-child {
                &::after {

                    content: '';
                }
            }
        }
    }

    .tooltip-button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        //@media (min-width: 660px) {
        //    top: 0.5em;
        //}
    }
}