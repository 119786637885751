.datepicker {
  max-width: 517px;

  .qs-datepicker-container {
    width: 100%;
    font-family: var(--fellix);
    border-color: var(--apt-grey-7);
    border-radius: 0;
    box-shadow: none;
    padding: 17px 0;
  }

  .qs-controls {
    position: relative;
    width: calc(((100% - ((100% / 7) - 38px)) - (0.821em) * 2) - 4.5%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: none;
    filter: blur(0);
    transition: filter 0.3s;
    padding: 12px 0 12px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid var(--apt-grey-7);

    @include min-media(375px) {
      width: calc(((100% - ((100% / 7) - 38px)) - (1.85em) * 2) - 1%);
    }
  }

  .qs-arrow {
    width: 1.5em;

    &.qs-left {
      left: -0.5em;

      &::after {
        content: '\f177';
        font-family: var(--fa);
        font-weight: var(--fw-light);
        font-size: var(--20px);
        border: 0;
        transform: translate(50%, -50%);
        width: 1.5em;
        text-align: center;
      }
    }

    &.qs-right {
      right: -0.25em;

      &::after {
        content: '\f178';
        font-family: var(--fa);
        font-weight: var(--fw-light);
        font-size: var(--20px);
        border: 0;
        transform: translate(-50%, -50%);
        width: 1.5em;
        text-align: center;
      }
    }
  }

  .qs-squares { // .datepicker .qs-squares
    padding-top: 14px;
    padding-bottom: 10px;
    padding-left: 0.821em;
    padding-right: 0.821em;

    @include min-media(375px) {
      padding-top: 28px;
      padding-left: 1.65em;
      padding-right: 1.65em;
    }
  }

  .qs-month-year {
    position: absolute;
    left: 0;
  }

  .qs-month-year:hover {
    border: 0;
  }

  .qs-month, .qs-year {
    font-size: var(--20px);
    line-height: 27px;
    font-weight: var(--fw-medium);

    @include min-media(375px) {
      font-size: var(--24px);
      line-height: 30px;
    }
  }

  .qs-year {
    display: none;
  }

  .qs-day {
    color: var(--apt-grey-2);
    font-weight: normal;
    margin-bottom: -2px;

    @include min-media(375px) {
      margin-bottom: 7px;
    }
  }

  .qs-square {
    position: relative;
    z-index: 0;
    height: 38px;
    font-size: var(--11px);
    line-height: 38px;

    @include min-media(375px) {
      font-size: var(--14px);
    }
  }

  .qs-square.qs-day {
    font-size: var(--11px);
  }

  // Markup for today
  .qs-current {
    font-weight: normal;
    text-decoration: none;
  }

  // Markup for active items
  .qs-active {
    background: none;
    color: var(--apt-white);
    font-weight: var(--fw-medium);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 38px;
      height: 100%;
      z-index: -1;
      background: var(--apt-primary);
      border-radius: 38px;
    }
  }

  .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    background: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 38px;
      height: 100%;
      z-index: -1;
      background: var(--apt-grey-8);
      border-radius: 38px;
    }
  }
}

.datepicker-scroll-target {
  position: relative;
  top:-130px;
  width: 0px;
  height: 0px;
}