.person-scroll-widget {
  position: relative;
  width: 100%;
  max-width: 320px;
  z-index: 0;

  &:before {
    content: "";
    display: block;
    padding-bottom: 133.33333%;
  }
}

.person-scroll-widget .person-scroll-widget__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  z-index: 0;
  padding: 0;
  margin: 0;
}