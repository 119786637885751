.model-cards-list-container {
  position: relative;
  padding-top: 32px;
  padding-bottom: 34px;

  @media (min-width: 992px) {
    padding-top: 46px;
    padding-bottom: 66px;
  }
}

.model-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 22px;

  @media (min-width: 768px) {
    padding-top: 0;
  }

  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
}

.model-cards-list-container > header .tag-list {
  align-content: flex-start;
  margin-top: var(--34px);
  margin-bottom: 0;
  min-height: 56px;

  @media (min-width: 992px) {
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: -15px;
    margin-bottom: -18px;
    height: 196px;
  }
}

.model-cards-list-container h1,
.model-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
  max-width: 550px;
  flex: 1 1 auto;
}

.model-cards-list-container .model-card header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
}

.model-cards-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.model-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;

  @include min-media(660px) {
    width: 50%;
  }
  @include min-media(1200px) {
    width: 33.333%;
  }
}

.model-cards-list-item .card figure {
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  flex: 0 0 auto;
}

.model-cards-list-item .card figure img {
  position: static;
  width: 100%;
  height: 164px;
  object-fit: contain;
  min-height: 0;
  aspect-ratio: unset;
}

.model-cards-list-item .card .card__content {
  padding: 16px 22px;

  @media (min-width: 992px) {
    padding: 16px 36px 36px 34px;
  }
}

.model-cards-list-item .read-more-button {
  position: absolute;
  top: 26px;
  right: 30px;
  font-size: var(--16px);
  line-height: 1.5px;
}

.model-cards-list-item .read-more-button > span {
  text-align: right;
}

.model-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.model-card .card__content > span {
  color: var(--apt-grey-4);
}

.model-card .card__content > ul:not(.checks) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.model-card .card__content > ul:not(.checks) li {
  padding-right: 0.5em;
  position: relative;
}

.model-card .card__content > ul:not(.checks) li:not(:first-child) {
  padding-left: 0.5em;
}

.model-card .card__content > ul:not(.checks) li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.model-card .card__content .heading {
  margin-bottom: 15px;
}

.model-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.model-card {
  position: relative;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
}

.model-card-listing-wrapper {
  width: 100%;
  margin-top: 28px;
  flex: 0 0 auto;

  &:last-child {
    margin-bottom: -18px;

    @media (min-width: 768px) {
      margin-bottom: -28px;
    }
  }
}

.model-card-listing-wrapper:not(:last-child) .model-card-listing:last-child {
  border-bottom: 1px solid var(--apt-grey-7);
  margin-bottom: 33px;
}

.model-card-listing {
  position: relative;
  width: 100%;
  padding: 14px 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.model-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;
}

.model-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
}

.model-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
}

.model-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 0;
}

.model-title-merk {
  font-size: var(--14px);
  line-height: 19px;
  letter-spacing: -0.1px;
  font-weight: var(--fw-regular);

  @include min-media(768px) {
    font-size: var(--18px);
    line-height: 30px;
    letter-spacing: -0.3px;
  }
}

.model-title-model {
  font-size: var(--31px);
  line-height: 38px;
  letter-spacing: -0.4px;
  font-weight: var(--fw-medium);

  @include min-media(768px) {
    font-size: var(--40px);
    line-height: 44px;
    letter-spacing: -0.5px;
  }
}

.extended-model-card {
  padding-top: 74px;
  overflow: hidden;
}

.extended-model-card .card__content > b,
.extended-model-card-overlay div > b {
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
  padding-bottom: 16px;
  display: block;
}

.extended-model-card .card__content > span {
  color: var(--apt-grey-4);
}

.extended-model-card-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: var(--apt-white);
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease-in;
}

.extended-model-card-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.2s ease-in 0s;
}

.extended-model-card-overlay-content > div {
  width: 100%;
  flex: 1 1 auto;
  padding: 44px 33px 33px;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 74px 33px 33px;
  }
}

.extended-model-card-overlay-content button {
  flex: 0 0 auto;
}

.extended-model-card-overlay--toggled {
  transform: none;
}

.extended-model-card-overlay--toggled .extended-model-card-overlay-content {
  opacity: 1;
  transition: opacity 0.2s ease-in 0.2s;
}

.show-more-button {
  color: var(--apt-grey-4);
  padding-left: 2.1em;
  margin-top: 1em;
  font-size: var(--14px);
  line-height: 1;
}

.show-less-button {
  width: 100%;
  padding: 33px;
  text-align: left;
  color: var(--apt-grey-4);
  font-size: var(--14px);
  line-height: 1;
}