@charset "UTF-8";
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shine {
  0% {
    background-position-x: 462%;
  }
  50% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: -462%;
  }
}
@keyframes floatingIconScaleDesktop {
  0% {
    transform: scale(1) translateX(0);
  }
  60% {
    transform: scale(2) translateX(-60%);
  }
  100% {
    transform: scale(1.25) translateX(0);
  }
}
@keyframes floatingIconScaleMobile {
  0% {
    transform: scale(1) translateX(0);
  }
  60% {
    transform: scale(2) translateX(-60%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}
@keyframes bounceHeart {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
  20%, 40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.4);
  }
  80%, 100% {
    transform: scale(1);
  }
}
@keyframes bounceCirclePlus {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #48E2FF;
}

h1, h2, h3, h4, h5, h6, p, q {
  padding: 0;
  margin: 0;
  word-break: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
html, body {
  background-color: var(--apt-white);
  margin: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  transition: opacity 0.2s ease 0s;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  margin: 0 auto;
  transform-origin: top center;
  word-break: break-word;
  line-height: 1.5;
  overflow-x: hidden;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  min-height: 100%;
  margin-top: 52px;
}
@media (min-width: 992px) {
  .body-wrapper {
    margin-top: 130px;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}

.opaque {
  opacity: 0;
}

main {
  flex-grow: 1;
}

a[href]:-moz-focusring,
button:-moz-focusring,
[tabindex="0"]:-moz-focusring {
  outline: none;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  border-radius: 0.1px;
  text-decoration: none;
}

a[href]:focus-visible,
button:focus-visible,
[tabindex="0"]:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  border-radius: 0.1px;
  text-decoration: none;
}

input[type]:focus,
textarea:focus,
select[id]:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--apt-primary);
  border-radius: inherit;
}

.error-text {
  color: var(--apt-error-1);
}

.grid {
  width: 100%;
}

.grid--padding {
  padding-top: 25px;
  padding-bottom: 15px;
}
@media (min-width: 512px) {
  .grid--padding {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .grid--padding {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.grid--extra-padding {
  padding-top: 35px;
  padding-bottom: 15px;
}
@media (min-width: 512px) {
  .grid--extra-padding {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
@media (min-width: 992px) {
  .grid--extra-padding {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.grid-item {
  overflow-x: hidden;
}

.grid-item--no-overflow {
  overflow-x: visible;
}

@media (min-width: 1200px) {
  .grid-item--inline-offset .zebra-grid {
    gap: 70px;
  }
}

@media (min-width: 1440px) {
  .grid-item--inline-offset > * {
    padding-right: 0 !important;
  }
}

.grid-item--inline-offset .checkbox-wrapper ul li label {
  font-size: var(--14px);
}

.grid-item > * {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--15px);
  padding-right: var(--15px);
}
@media (min-width: 768px) {
  .grid-item > * {
    padding-left: var(--50px);
    padding-right: var(--50px);
  }
}
@media (min-width: 1200px) {
  .grid-item > * {
    padding-left: var(--85px);
    padding-right: var(--85px);
  }
}

@media (max-width: 767px) {
  .grid > .grid-item > h1 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.grid-item > [data-stretch="2"] {
  max-width: 1510px;
}

.grid-item > [data-stretch="3"] {
  max-width: 1440px;
}

.grid-item > [data-stretch="4"] {
  max-width: 1218px;
}

.grid-item > [data-stretch="4.5"] {
  max-width: 1000px;
}

.grid-item > [data-stretch="5"] {
  max-width: 780px;
}

rbb > i {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
}

rbb::before {
  content: "•";
}

rbb[spaces]::before {
  content: " • ";
}

.cards-list {
  width: calc(100% + 20px);
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
@media (min-width: 612px) {
  .cards-list {
    max-width: none;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .cards-list {
    flex-wrap: nowrap;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@media (min-width: 1200px) {
  .cards-list {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.cards-list-item {
  padding: 10px;
  margin-bottom: 20px;
}
@media (min-width: 612px) {
  .cards-list-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .cards-list-item {
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .cards-list-item {
    padding: 25px;
  }
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--10px);
  box-shadow: var(--shadow);
  background-color: var(--apt-white);
  transition: box-shadow 0.2s ease;
}

.card .heading {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-link-wrapper {
  display: block;
  height: 100%;
}

.offer-cards-list-item .offer-card .offer-card-slider-container {
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

a.card-link-wrapper,
a.card-link-wrapper:hover,
a.card-link-wrapper:focus,
a.card-link-wrapper:active {
  text-decoration: none;
}

@media (any-hover: hover) {
  a.card-link-wrapper:hover > .card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 992px) and (any-hover: hover) {
  a.card-link-wrapper:hover > .card {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 1200px) and (any-hover: hover) {
  a.card-link-wrapper:hover > .card {
    box-shadow: var(--shadow-hover);
  }
}

a.card-link-wrapper:hover .read-more-button,
a.card-link-wrapper:hover .read-more-button i.fal {
  color: var(--apt-primary);
}

.card figure {
  width: 100%;
  border-top-right-radius: var(--10px);
  border-top-left-radius: var(--10px);
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
}
@supports not (aspect-ratio: 3/2) {
  .card figure {
    height: 0;
    padding-top: 66.67%;
  }
}
@supports not (aspect-ratio: 3/2) {
  .card figure.contact-cta-card__figure {
    height: 30px;
  }
}

.card figure img {
  display: block;
  width: 100.01%;
  aspect-ratio: 4/3;
  object-fit: cover;
}
@supports not (aspect-ratio: 3/1.9) {
  .card figure img {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.card .scale-on-hover > figure img {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.card a[href] {
  text-decoration: none;
}

@media (any-hover: hover) {
  .card a:hover .scale-on-hover > figure img {
    transform: scale(1.06);
  }
}
@media (any-hover: hover) {
  .scale-on-hover {
    transition: transform 0.3s ease, box-shadow 0.2s ease;
  }
  .scale-on-hover:hover {
    transform: scale(1.01);
  }
}

.card .tag-list {
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 1;
}
@media (min-width: 1200px) {
  .card .tag-list {
    top: 30px;
    left: 30px;
    right: 30px;
  }
}

.offer-card .tag-list {
  right: 100px;
}

.card__content {
  position: relative;
  width: 100%;
  height: auto;
  padding: 34px 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
@media (min-width: 512px) {
  .card__content {
    padding: 22px;
  }
}
@media (min-width: 1200px) {
  .card__content {
    padding: 31px 27px 36px 29px;
  }
}

.card__content header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 auto;
}

.card__content h1 {
  width: 100%;
}

.card__content time {
  color: var(--apt-grey-4);
  letter-spacing: -0.02em;
}
.card__content time rbb[spaces]::before {
  letter-spacing: 0;
}

.card-link-wrapper .blue-border {
  padding: 2px;
  background: none;
}

.card-link-wrapper .blue-border::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(135deg, #4157ff, #4157ff, #4157ff, #4157ff, #4157ff, #4157ff, #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);
  border-radius: var(--10px);
  transition: opacity 0.2s linear;
}

.card-link-wrapper .blue-border::before {
  opacity: 1;
}

a.card-link-wrapper .blue-border::before {
  opacity: 0;
}

a.card-link-wrapper:hover .blue-border::before {
  opacity: 1;
}

.blue-border > .card__content {
  background-color: var(--apt-white);
  padding: 30px 34px;
  border-radius: var(--8px);
  font-size: var(--16px);
  line-height: 1.5;
}
@media (min-width: 512px) {
  .blue-border > .card__content {
    padding: 40px 43px;
  }
}
@media (min-width: 992px) {
  .blue-border > .card__content {
    padding: 34px;
  }
}
@media (min-width: 1200px) {
  .blue-border > .card__content {
    padding: 40px 36px 54px 45px;
  }
}
@media (min-width: 1440px) {
  .blue-border > .card__content {
    padding: 49px 50px 54px 50px;
  }
}

.blue-border.content-card .card__content .heading {
  margin-bottom: var(--25px);
}

.read-more-button {
  text-decoration: none;
  color: var(--apt-grey-2);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.read-more-button--inline {
  display: inline-flex;
  justify-content: flex-start;
}

a.read-more-button--inline:hover > span {
  text-decoration: underline;
  text-decoration-color: var(--apt-primary);
}

.read-more-button > span {
  flex: 1 1 auto;
}

.read-more-button--inline > span {
  flex: 0 0 auto;
}

.read-more-button:hover,
.read-more-button:active,
.read-more-button:focus {
  text-decoration: none;
}

.read-more-button .fal {
  font-size: var(--19px);
  color: var(--apt-grey-3);
  position: relative;
  top: 0.1em;
  padding-left: 0.35em;
  flex: 0 0 auto;
}

.card__content time {
  padding-right: 10px;
  margin-right: auto;
  letter-spacing: -0.1px;
}

.button-wrapper {
  margin-top: 36px;
}

.button,
.button-wrapper input[type=submit],
.button-wrapper input[type=button] {
  display: inline-block;
  position: relative;
  min-width: 110px;
  text-decoration: none;
  text-align: center;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  z-index: 1;
}
.button:focus,
.button-wrapper input[type=submit]:focus,
.button-wrapper input[type=button]:focus {
  outline: none;
}
.button:disabled, .button:is(a):not([href]),
.button-wrapper input[type=submit]:disabled,
.button-wrapper input[type=submit]:is(a):not([href]),
.button-wrapper input[type=button]:disabled,
.button-wrapper input[type=button]:is(a):not([href]) {
  cursor: not-allowed;
}
.button:-webkit-any(a):not([href]),
.button-wrapper input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper input[type=button]:-webkit-any(a):not([href]) {
  cursor: not-allowed;
}

.button.button--special,
.button-wrapper--special input[type=submit],
.button-wrapper--special input[type=button] {
  min-height: 50px;
  border: none;
  color: var(--apt-white);
  padding: 11px 2.1em 14px 1.9em;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-decoration: none;
  border-radius: 25px;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.button.button--special:is(:not(a)):not(:disabled):hover, .button.button--special:is(a)[href]:hover,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):hover,
.button-wrapper--special input[type=submit]:is(a)[href]:hover,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):hover,
.button-wrapper--special input[type=button]:is(a)[href]:hover {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.button.button--special:is(:not(a)):not(:disabled):hover::before, .button.button--special:is(a)[href]:hover::before,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):hover::before,
.button-wrapper--special input[type=submit]:is(a)[href]:hover::before,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):hover::before,
.button-wrapper--special input[type=button]:is(a)[href]:hover::before {
  background-position: 0 0;
}
.button.button--special:is(:not(a)):not(:disabled):hover::after, .button.button--special:is(a)[href]:hover::after,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):hover::after,
.button-wrapper--special input[type=submit]:is(a)[href]:hover::after,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):hover::after,
.button-wrapper--special input[type=button]:is(a)[href]:hover::after {
  width: 50px;
}
.button.button--special:is(:not(a)):not(:disabled):hover i, .button.button--special:is(a)[href]:hover i,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):hover i,
.button-wrapper--special input[type=submit]:is(a)[href]:hover i,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):hover i,
.button-wrapper--special input[type=button]:is(a)[href]:hover i {
  transform: translateX(1em) scale(1.2);
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):hover, .button.button--special:-webkit-any(a)[href]:hover,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:hover,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:hover {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):hover::before, .button.button--special:-webkit-any(a)[href]:hover::before,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover::before,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:hover::before,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):hover::before,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:hover::before {
  background-position: 0 0;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):hover::after, .button.button--special:-webkit-any(a)[href]:hover::after,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover::after,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:hover::after,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):hover::after,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:hover::after {
  width: 50px;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):hover i, .button.button--special:-webkit-any(a)[href]:hover i,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover i,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:hover i,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):hover i,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:hover i {
  transform: translateX(1em) scale(1.2);
}
.button.button--special:is(:not(a)):not(:disabled):active, .button.button--special:is(a)[href]:active,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):active,
.button-wrapper--special input[type=submit]:is(a)[href]:active,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):active,
.button-wrapper--special input[type=button]:is(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.button.button--special:is(:not(a)):not(:disabled):active::before, .button.button--special:is(a)[href]:active::before,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):active::before,
.button-wrapper--special input[type=submit]:is(a)[href]:active::before,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):active::before,
.button-wrapper--special input[type=button]:is(a)[href]:active::before {
  background-position: 0 0;
}
.button.button--special:is(:not(a)):not(:disabled):active::after, .button.button--special:is(a)[href]:active::after,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):active::after,
.button-wrapper--special input[type=submit]:is(a)[href]:active::after,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):active::after,
.button-wrapper--special input[type=button]:is(a)[href]:active::after {
  width: 50px;
}
.button.button--special:is(:not(a)):not(:disabled):active i, .button.button--special:is(a)[href]:active i,
.button-wrapper--special input[type=submit]:is(:not(a)):not(:disabled):active i,
.button-wrapper--special input[type=submit]:is(a)[href]:active i,
.button-wrapper--special input[type=button]:is(:not(a)):not(:disabled):active i,
.button-wrapper--special input[type=button]:is(a)[href]:active i {
  transform: translateX(1em) scale(1.2);
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):active, .button.button--special:-webkit-any(a)[href]:active,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:active,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):active::before, .button.button--special:-webkit-any(a)[href]:active::before,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):active::before,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:active::before,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):active::before,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:active::before {
  background-position: 0 0;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):active::after, .button.button--special:-webkit-any(a)[href]:active::after,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):active::after,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:active::after,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):active::after,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:active::after {
  width: 50px;
}
.button.button--special:-webkit-any(:not(a)):not(:disabled):active i, .button.button--special:-webkit-any(a)[href]:active i,
.button-wrapper--special input[type=submit]:-webkit-any(:not(a)):not(:disabled):active i,
.button-wrapper--special input[type=submit]:-webkit-any(a)[href]:active i,
.button-wrapper--special input[type=button]:-webkit-any(:not(a)):not(:disabled):active i,
.button-wrapper--special input[type=button]:-webkit-any(a)[href]:active i {
  transform: translateX(1em) scale(1.2);
}
.button.button--special:-moz-focusring, .button.button--special:-moz-focusring, .button.button--special:-moz-focusring,
.button-wrapper--special input[type=submit]:-moz-focusring,
.button-wrapper--special input[type=submit]:-moz-focusring,
.button-wrapper--special input[type=submit]:-moz-focusring,
.button-wrapper--special input[type=button]:-moz-focusring,
.button-wrapper--special input[type=button]:-moz-focusring,
.button-wrapper--special input[type=button]:-moz-focusring {
  border-radius: 25px;
}
.button.button--special:focus-visible, .button.button--special:focus-visible, .button.button--special:focus-visible,
.button-wrapper--special input[type=submit]:focus-visible,
.button-wrapper--special input[type=submit]:focus-visible,
.button-wrapper--special input[type=submit]:focus-visible,
.button-wrapper--special input[type=button]:focus-visible,
.button-wrapper--special input[type=button]:focus-visible,
.button-wrapper--special input[type=button]:focus-visible {
  border-radius: 25px;
}
.button.button--special::before,
.button-wrapper--special input[type=submit]::before,
.button-wrapper--special input[type=button]::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--apt-primary);
  background-image: linear-gradient(90deg, #01cef4 0%, #01cef4 20%, #00c7f9, #00bfff, #00b6ff, #00aeff, #00a4ff, #009aff, #0090ff, #0084ff, #0077ff, #0068ff, #4157ff 100%);
  background-size: 300% 100%;
  background-position: 100% 0;
  transition: background-position 0.3s ease;
  border-radius: 25px;
  z-index: -2;
}
.button.button--special::after,
.button-wrapper--special input[type=submit]::after,
.button-wrapper--special input[type=button]::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--apt-primary);
  transition: width 0.2s ease;
  border-radius: 25px;
  z-index: -1;
}
.button.button--special i,
.button-wrapper--special input[type=submit] i,
.button-wrapper--special input[type=button] i {
  position: relative;
  display: inline-flex;
  top: 0.15em;
  margin-left: 0.5em;
  left: 0;
  transform: translateX(0) scale(1.2);
  transition: transform 0.3s ease;
}
.button.button--special:disabled, .button.button--special:is(a):not([href]),
.button-wrapper--special input[type=submit]:disabled,
.button-wrapper--special input[type=submit]:is(a):not([href]),
.button-wrapper--special input[type=button]:disabled,
.button-wrapper--special input[type=button]:is(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  background-image: none;
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--special:disabled::before, .button.button--special:is(a):not([href])::before,
.button-wrapper--special input[type=submit]:disabled::before,
.button-wrapper--special input[type=submit]:is(a):not([href])::before,
.button-wrapper--special input[type=button]:disabled::before,
.button-wrapper--special input[type=button]:is(a):not([href])::before {
  display: none;
}
.button.button--special:disabled::after, .button.button--special:is(a):not([href])::after,
.button-wrapper--special input[type=submit]:disabled::after,
.button-wrapper--special input[type=submit]:is(a):not([href])::after,
.button-wrapper--special input[type=button]:disabled::after,
.button-wrapper--special input[type=button]:is(a):not([href])::after {
  display: none;
}
.button.button--special:-webkit-any(a):not([href]),
.button-wrapper--special input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper--special input[type=button]:-webkit-any(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  background-image: none;
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--special:-webkit-any(a):not([href])::before,
.button-wrapper--special input[type=submit]:-webkit-any(a):not([href])::before,
.button-wrapper--special input[type=button]:-webkit-any(a):not([href])::before {
  display: none;
}
.button.button--special:-webkit-any(a):not([href])::after,
.button-wrapper--special input[type=submit]:-webkit-any(a):not([href])::after,
.button-wrapper--special input[type=button]:-webkit-any(a):not([href])::after {
  display: none;
}

.button.button--primary,
.button-wrapper--primary input[type=submit],
.button-wrapper--primary input[type=button] {
  min-height: 50px;
  background-color: var(--apt-primary);
  border: 1px solid var(--apt-primary);
  color: var(--apt-white);
  border-radius: 25px;
  padding: 11px 14px 13px;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-decoration: none;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
@media (min-width: 375px) {
  .button.button--primary,
.button-wrapper--primary input[type=submit],
.button-wrapper--primary input[type=button] {
    padding: 11px 24px 13px;
  }
}
.button.button--primary:is(:not(a)):not(:disabled):hover, .button.button--primary:is(a)[href]:hover,
.button-wrapper--primary input[type=submit]:is(:not(a)):not(:disabled):hover,
.button-wrapper--primary input[type=submit]:is(a)[href]:hover,
.button-wrapper--primary input[type=button]:is(:not(a)):not(:disabled):hover,
.button-wrapper--primary input[type=button]:is(a)[href]:hover {
  transform: scale(1.025);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--primary:-webkit-any(:not(a)):not(:disabled):hover, .button.button--primary:-webkit-any(a)[href]:hover,
.button-wrapper--primary input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--primary input[type=submit]:-webkit-any(a)[href]:hover,
.button-wrapper--primary input[type=button]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--primary input[type=button]:-webkit-any(a)[href]:hover {
  transform: scale(1.025);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--primary:is(:not(a)):not(:disabled):active, .button.button--primary:is(a)[href]:active,
.button-wrapper--primary input[type=submit]:is(:not(a)):not(:disabled):active,
.button-wrapper--primary input[type=submit]:is(a)[href]:active,
.button-wrapper--primary input[type=button]:is(:not(a)):not(:disabled):active,
.button-wrapper--primary input[type=button]:is(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--primary:-webkit-any(:not(a)):not(:disabled):active, .button.button--primary:-webkit-any(a)[href]:active,
.button-wrapper--primary input[type=submit]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--primary input[type=submit]:-webkit-any(a)[href]:active,
.button-wrapper--primary input[type=button]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--primary input[type=button]:-webkit-any(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--primary:disabled, .button.button--primary:is(a):not([href]),
.button-wrapper--primary input[type=submit]:disabled,
.button-wrapper--primary input[type=submit]:is(a):not([href]),
.button-wrapper--primary input[type=button]:disabled,
.button-wrapper--primary input[type=button]:is(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--primary:-webkit-any(a):not([href]),
.button-wrapper--primary input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper--primary input[type=button]:-webkit-any(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--primary:-moz-focusring, .button.button--primary:-moz-focusring, .button.button--primary:-moz-focusring,
.button-wrapper--primary input[type=submit]:-moz-focusring,
.button-wrapper--primary input[type=submit]:-moz-focusring,
.button-wrapper--primary input[type=submit]:-moz-focusring,
.button-wrapper--primary input[type=button]:-moz-focusring,
.button-wrapper--primary input[type=button]:-moz-focusring,
.button-wrapper--primary input[type=button]:-moz-focusring {
  border-radius: 25px;
}
.button.button--primary:focus-visible, .button.button--primary:focus-visible, .button.button--primary:focus-visible,
.button-wrapper--primary input[type=submit]:focus-visible,
.button-wrapper--primary input[type=submit]:focus-visible,
.button-wrapper--primary input[type=submit]:focus-visible,
.button-wrapper--primary input[type=button]:focus-visible,
.button-wrapper--primary input[type=button]:focus-visible,
.button-wrapper--primary input[type=button]:focus-visible {
  border-radius: 25px;
}

.button.button--primary.button--tight,
.button-wrapper--primary.button--tight input[type=submit],
.button-wrapper--primary.button--tight input[type=button] {
  border-radius: 24px;
  min-height: 40px;
  padding: 6px 16px 8px;
}
@media (min-width: 375px) {
  .button.button--primary.button--tight,
.button-wrapper--primary.button--tight input[type=submit],
.button-wrapper--primary.button--tight input[type=button] {
    padding: 6px 26px 8px;
  }
}

.button.button--secondary,
.button-wrapper--secondary input[type=submit],
.button-wrapper--secondary input[type=button],
.file-upload label,
.upload-section .wm-field[id] label {
  min-height: 40px;
  background-color: var(--apt-white);
  color: var(--apt-primary);
  border-radius: 24px;
  padding: 6px 16px 8px;
  font-weight: var(--fw-medium);
  text-decoration: none;
  box-shadow: none;
  transform: scale(1.000001);
}
@media (min-width: 375px) {
  .button.button--secondary,
.button-wrapper--secondary input[type=submit],
.button-wrapper--secondary input[type=button],
.file-upload label,
.upload-section .wm-field[id] label {
    padding: 6px 26px 8px;
  }
}
.button.button--secondary:is(:not(a)):not(:disabled):hover, .button.button--secondary:is(a)[href]:hover,
.button-wrapper--secondary input[type=submit]:is(:not(a)):not(:disabled):hover,
.button-wrapper--secondary input[type=submit]:is(a)[href]:hover,
.button-wrapper--secondary input[type=button]:is(:not(a)):not(:disabled):hover,
.button-wrapper--secondary input[type=button]:is(a)[href]:hover,
.file-upload label:is(:not(a)):not(:disabled):hover,
.file-upload label:is(a)[href]:hover,
.upload-section .wm-field[id] label:is(:not(a)):not(:disabled):hover,
.upload-section .wm-field[id] label:is(a)[href]:hover {
  text-decoration: none;
}
.button.button--secondary:-webkit-any(:not(a)):not(:disabled):hover, .button.button--secondary:-webkit-any(a)[href]:hover,
.button-wrapper--secondary input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--secondary input[type=submit]:-webkit-any(a)[href]:hover,
.button-wrapper--secondary input[type=button]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--secondary input[type=button]:-webkit-any(a)[href]:hover,
.file-upload label:-webkit-any(:not(a)):not(:disabled):hover,
.file-upload label:-webkit-any(a)[href]:hover,
.upload-section .wm-field[id] label:-webkit-any(:not(a)):not(:disabled):hover,
.upload-section .wm-field[id] label:-webkit-any(a)[href]:hover {
  text-decoration: none;
}
.button.button--secondary:is(:not(a)):not(:disabled):active, .button.button--secondary:is(a)[href]:active,
.button-wrapper--secondary input[type=submit]:is(:not(a)):not(:disabled):active,
.button-wrapper--secondary input[type=submit]:is(a)[href]:active,
.button-wrapper--secondary input[type=button]:is(:not(a)):not(:disabled):active,
.button-wrapper--secondary input[type=button]:is(a)[href]:active,
.file-upload label:is(:not(a)):not(:disabled):active,
.file-upload label:is(a)[href]:active,
.upload-section .wm-field[id] label:is(:not(a)):not(:disabled):active,
.upload-section .wm-field[id] label:is(a)[href]:active {
  text-decoration: none;
}
.button.button--secondary:-webkit-any(:not(a)):not(:disabled):active, .button.button--secondary:-webkit-any(a)[href]:active,
.button-wrapper--secondary input[type=submit]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--secondary input[type=submit]:-webkit-any(a)[href]:active,
.button-wrapper--secondary input[type=button]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--secondary input[type=button]:-webkit-any(a)[href]:active,
.file-upload label:-webkit-any(:not(a)):not(:disabled):active,
.file-upload label:-webkit-any(a)[href]:active,
.upload-section .wm-field[id] label:-webkit-any(:not(a)):not(:disabled):active,
.upload-section .wm-field[id] label:-webkit-any(a)[href]:active {
  text-decoration: none;
}
.button.button--secondary:disabled, .button.button--secondary:is(a):not([href]),
.button-wrapper--secondary input[type=submit]:disabled,
.button-wrapper--secondary input[type=submit]:is(a):not([href]),
.button-wrapper--secondary input[type=button]:disabled,
.button-wrapper--secondary input[type=button]:is(a):not([href]),
.file-upload label:disabled,
.file-upload label:is(a):not([href]),
.upload-section .wm-field[id] label:disabled,
.upload-section .wm-field[id] label:is(a):not([href]) {
  color: var(--apt-grey-3);
  text-decoration: none;
}
.button.button--secondary:disabled::before, .button.button--secondary:is(a):not([href])::before,
.button-wrapper--secondary input[type=submit]:disabled::before,
.button-wrapper--secondary input[type=submit]:is(a):not([href])::before,
.button-wrapper--secondary input[type=button]:disabled::before,
.button-wrapper--secondary input[type=button]:is(a):not([href])::before,
.file-upload label:disabled::before,
.file-upload label:is(a):not([href])::before,
.upload-section .wm-field[id] label:disabled::before,
.upload-section .wm-field[id] label:is(a):not([href])::before {
  background-image: none;
  background-color: var(--apt-grey-7);
}
.button.button--secondary:-webkit-any(a):not([href]),
.button-wrapper--secondary input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper--secondary input[type=button]:-webkit-any(a):not([href]),
.file-upload label:-webkit-any(a):not([href]),
.upload-section .wm-field[id] label:-webkit-any(a):not([href]) {
  color: var(--apt-grey-3);
  text-decoration: none;
}
.button.button--secondary:-webkit-any(a):not([href])::before,
.button-wrapper--secondary input[type=submit]:-webkit-any(a):not([href])::before,
.button-wrapper--secondary input[type=button]:-webkit-any(a):not([href])::before,
.file-upload label:-webkit-any(a):not([href])::before,
.upload-section .wm-field[id] label:-webkit-any(a):not([href])::before {
  background-image: none;
  background-color: var(--apt-grey-7);
}
.button.button--secondary:-moz-focusring, .button.button--secondary:-moz-focusring, .button.button--secondary:-moz-focusring,
.button-wrapper--secondary input[type=submit]:-moz-focusring,
.button-wrapper--secondary input[type=submit]:-moz-focusring,
.button-wrapper--secondary input[type=submit]:-moz-focusring,
.button-wrapper--secondary input[type=button]:-moz-focusring,
.button-wrapper--secondary input[type=button]:-moz-focusring,
.button-wrapper--secondary input[type=button]:-moz-focusring,
.file-upload label:-moz-focusring,
.file-upload label:-moz-focusring,
.file-upload label:-moz-focusring,
.upload-section .wm-field[id] label:-moz-focusring,
.upload-section .wm-field[id] label:-moz-focusring,
.upload-section .wm-field[id] label:-moz-focusring {
  border-radius: 25px;
}
.button.button--secondary:focus-visible, .button.button--secondary:focus-visible, .button.button--secondary:focus-visible,
.button-wrapper--secondary input[type=submit]:focus-visible,
.button-wrapper--secondary input[type=submit]:focus-visible,
.button-wrapper--secondary input[type=submit]:focus-visible,
.button-wrapper--secondary input[type=button]:focus-visible,
.button-wrapper--secondary input[type=button]:focus-visible,
.button-wrapper--secondary input[type=button]:focus-visible,
.file-upload label:focus-visible,
.file-upload label:focus-visible,
.file-upload label:focus-visible,
.upload-section .wm-field[id] label:focus-visible,
.upload-section .wm-field[id] label:focus-visible,
.upload-section .wm-field[id] label:focus-visible {
  border-radius: 25px;
}
.button.button--secondary::before,
.button-wrapper--secondary input[type=submit]::before,
.button-wrapper--secondary input[type=button]::before,
.file-upload label::before,
.upload-section .wm-field[id] label::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--apt-primary);
  background-image: linear-gradient(90deg, #79fdfd 0%, #00e9ff 5.71428571%, #00d4ff 11.4285714%, #00bbff 17.1428571%, #00a0ff 22.8571429%, #0080ff 28.5714286%, #4157ff 40%, #4157ff 100%);
  background-size: 300% 100%;
  background-position: 100% 0;
  border-radius: 24px;
  z-index: -2;
  transition: background-position 0.4s ease;
}
.button.button--secondary::after,
.button-wrapper--secondary input[type=submit]::after,
.button-wrapper--secondary input[type=button]::after,
.file-upload label::after,
.upload-section .wm-field[id] label::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--apt-white);
  border-radius: 24px;
  z-index: -1;
}
.button.button--secondary:hover::before,
.button-wrapper--secondary input[type=submit]:hover::before,
.button-wrapper--secondary input[type=button]:hover::before,
.file-upload label:hover::before,
.upload-section .wm-field[id] label:hover::before {
  transition: background-position 0.4s ease;
  background-position: 0 0;
}

.header-list-item--primary .button.button--secondary::after {
  content: "";
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  background-color: var(--apt-white);
  border-radius: 24px;
  z-index: -1;
}

.button.button--tertiary,
.button-wrapper--tertiary input[type=submit],
.button-wrapper--tertiary input[type=button] {
  color: inherit;
  text-decoration: none;
  font-weight: var(--fw-regular);
  transform: scale(1.000001);
}
.button.button--tertiary:is(:not(a)):not(:disabled):hover, .button.button--tertiary:is(a)[href]:hover,
.button-wrapper--tertiary input[type=submit]:is(:not(a)):not(:disabled):hover,
.button-wrapper--tertiary input[type=submit]:is(a)[href]:hover,
.button-wrapper--tertiary input[type=button]:is(:not(a)):not(:disabled):hover,
.button-wrapper--tertiary input[type=button]:is(a)[href]:hover {
  color: var(--apt-primary);
  text-decoration: none;
}
.button.button--tertiary:-webkit-any(:not(a)):not(:disabled):hover, .button.button--tertiary:-webkit-any(a)[href]:hover,
.button-wrapper--tertiary input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--tertiary input[type=submit]:-webkit-any(a)[href]:hover,
.button-wrapper--tertiary input[type=button]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--tertiary input[type=button]:-webkit-any(a)[href]:hover {
  color: var(--apt-primary);
  text-decoration: none;
}
.button.button--tertiary:is(:not(a)):not(:disabled):active, .button.button--tertiary:is(a)[href]:active,
.button-wrapper--tertiary input[type=submit]:is(:not(a)):not(:disabled):active,
.button-wrapper--tertiary input[type=submit]:is(a)[href]:active,
.button-wrapper--tertiary input[type=button]:is(:not(a)):not(:disabled):active,
.button-wrapper--tertiary input[type=button]:is(a)[href]:active {
  color: inherit;
  text-decoration: none;
}
.button.button--tertiary:-webkit-any(:not(a)):not(:disabled):active, .button.button--tertiary:-webkit-any(a)[href]:active,
.button-wrapper--tertiary input[type=submit]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--tertiary input[type=submit]:-webkit-any(a)[href]:active,
.button-wrapper--tertiary input[type=button]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--tertiary input[type=button]:-webkit-any(a)[href]:active {
  color: inherit;
  text-decoration: none;
}
.button.button--tertiary:disabled, .button.button--tertiary:is(a):not([href]),
.button-wrapper--tertiary input[type=submit]:disabled,
.button-wrapper--tertiary input[type=submit]:is(a):not([href]),
.button-wrapper--tertiary input[type=button]:disabled,
.button-wrapper--tertiary input[type=button]:is(a):not([href]) {
  color: inherit;
  text-decoration: none;
}
.button.button--tertiary:-webkit-any(a):not([href]),
.button-wrapper--tertiary input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper--tertiary input[type=button]:-webkit-any(a):not([href]) {
  color: inherit;
  text-decoration: none;
}

.button.button--icon,
.button-wrapper--icon input[type=submit],
.button-wrapper--icon input[type=button] {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
}
.button.button--icon svg,
.button-wrapper--icon input[type=submit] svg,
.button-wrapper--icon input[type=button] svg {
  width: 1em;
  margin-right: 0.75em;
  position: relative;
  top: 0.15em;
}
.button.button--icon span,
.button-wrapper--icon input[type=submit] span,
.button-wrapper--icon input[type=button] span {
  margin-left: auto;
  margin-right: auto;
  flex: 1 1 auto;
  padding-right: 35px;
}
.button.button--icon i,
.button-wrapper--icon input[type=submit] i,
.button-wrapper--icon input[type=button] i {
  width: 35px;
  text-align: left;
}

.button.button--icon span:last-of-type {
  padding-right: 0;
}

.button.button--icon-center,
.button-wrapper--icon-center input[type=submit],
.button-wrapper--icon-center input[type=button] {
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
}
.button.button--icon-center svg,
.button-wrapper--icon-center input[type=submit] svg,
.button-wrapper--icon-center input[type=button] svg {
  width: 1em;
  margin-right: 0.75em;
  position: relative;
  top: 0.15em;
}
.button.button--icon-center span,
.button-wrapper--icon-center input[type=submit] span,
.button-wrapper--icon-center input[type=button] span {
  margin-left: auto;
  margin-right: auto;
  flex: 0 1 auto;
  padding-right: 35px;
}
.button.button--icon-center span:last-of-type,
.button-wrapper--icon-center input[type=submit] span:last-of-type,
.button-wrapper--icon-center input[type=button] span:last-of-type {
  padding-right: 35px;
}
@media (min-width: 992px) {
  .button.button--icon-center span:last-of-type,
.button-wrapper--icon-center input[type=submit] span:last-of-type,
.button-wrapper--icon-center input[type=button] span:last-of-type {
    padding-right: 0;
  }
}
.button.button--icon-center i,
.button-wrapper--icon-center input[type=submit] i,
.button-wrapper--icon-center input[type=button] i {
  width: 35px;
  text-align: left;
}

.button.button--small,
.button-wrapper--smally input[type=submit],
.button-wrapper--small input[type=button] {
  min-width: 0;
  min-height: 28px;
  background-color: var(--apt-primary);
  border: 1px solid var(--apt-primary);
  color: var(--apt-white);
  border-radius: 28px;
  padding: 5px 5px 6px;
  font-size: var(--11px);
  line-height: 0.7;
  font-weight: var(--fw-medium);
  box-shadow: none;
  text-align: center;
  text-decoration: none;
  transform: scale(1.000001);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.button.button--small:is(:not(a)):not(:disabled):hover, .button.button--small:is(a)[href]:hover,
.button-wrapper--smally input[type=submit]:is(:not(a)):not(:disabled):hover,
.button-wrapper--smally input[type=submit]:is(a)[href]:hover,
.button-wrapper--small input[type=button]:is(:not(a)):not(:disabled):hover,
.button-wrapper--small input[type=button]:is(a)[href]:hover {
  transform: scale(1.025);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--small:-webkit-any(:not(a)):not(:disabled):hover, .button.button--small:-webkit-any(a)[href]:hover,
.button-wrapper--smally input[type=submit]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--smally input[type=submit]:-webkit-any(a)[href]:hover,
.button-wrapper--small input[type=button]:-webkit-any(:not(a)):not(:disabled):hover,
.button-wrapper--small input[type=button]:-webkit-any(a)[href]:hover {
  transform: scale(1.025);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--small:is(:not(a)):not(:disabled):active, .button.button--small:is(a)[href]:active,
.button-wrapper--smally input[type=submit]:is(:not(a)):not(:disabled):active,
.button-wrapper--smally input[type=submit]:is(a)[href]:active,
.button-wrapper--small input[type=button]:is(:not(a)):not(:disabled):active,
.button-wrapper--small input[type=button]:is(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--small:-webkit-any(:not(a)):not(:disabled):active, .button.button--small:-webkit-any(a)[href]:active,
.button-wrapper--smally input[type=submit]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--smally input[type=submit]:-webkit-any(a)[href]:active,
.button-wrapper--small input[type=button]:-webkit-any(:not(a)):not(:disabled):active,
.button-wrapper--small input[type=button]:-webkit-any(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.button.button--small:disabled, .button.button--small:is(a):not([href]),
.button-wrapper--smally input[type=submit]:disabled,
.button-wrapper--smally input[type=submit]:is(a):not([href]),
.button-wrapper--small input[type=button]:disabled,
.button-wrapper--small input[type=button]:is(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--small:-webkit-any(a):not([href]),
.button-wrapper--smally input[type=submit]:-webkit-any(a):not([href]),
.button-wrapper--small input[type=button]:-webkit-any(a):not([href]) {
  color: var(--apt-grey-3);
  background-color: var(--apt-grey-7);
  border: 1px solid var(--apt-grey-7);
  transform: scale(1.000001);
  text-decoration: none;
}
.button.button--small:-moz-focusring, .button.button--small:-moz-focusring, .button.button--small:-moz-focusring,
.button-wrapper--smally input[type=submit]:-moz-focusring,
.button-wrapper--smally input[type=submit]:-moz-focusring,
.button-wrapper--smally input[type=submit]:-moz-focusring,
.button-wrapper--small input[type=button]:-moz-focusring,
.button-wrapper--small input[type=button]:-moz-focusring,
.button-wrapper--small input[type=button]:-moz-focusring {
  border-radius: 28px;
}
.button.button--small:focus-visible, .button.button--small:focus-visible, .button.button--small:focus-visible,
.button-wrapper--smally input[type=submit]:focus-visible,
.button-wrapper--smally input[type=submit]:focus-visible,
.button-wrapper--smally input[type=submit]:focus-visible,
.button-wrapper--small input[type=button]:focus-visible,
.button-wrapper--small input[type=button]:focus-visible,
.button-wrapper--small input[type=button]:focus-visible {
  border-radius: 28px;
}

.arrow-btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: var(--15px);
  padding-right: var(--15px);
  flex: 1 1 auto;
  font-size: var(--16px);
  line-height: 28px;
  border-top: 1px solid var(--apt-grey-7);
}
@media (min-width: 768px) {
  .arrow-btns {
    padding-left: 0;
    padding-right: 0;
  }
}

.arrow-btns--no-top-border {
  border-top: none;
}

.arrow-btns a,
.arrow-btns button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid var(--apt-grey-7);
  padding: var(--14px) 0 var(--16px);
  text-decoration: none;
  text-align: left;
}

.arrow-btns a span,
.arrow-btns button span {
  flex: 1 1 auto;
}

.arrow-btns a i.fal,
.arrow-btns button i.fal {
  flex: 0 0 auto;
  margin-left: 1em;
  transform: translateX(-3px);
  transition: transform 0.2s ease, opacity 0.2s ease;
  opacity: 1;
}

.arrow-btns a:last-of-type,
.arrow-btns button:last-of-type {
  border-bottom: 1px solid var(--apt-grey-7);
}

.arrow-btns a:hover i.fal,
.arrow-btns button:hover i.fal {
  transform: translateX(0px);
  opacity: 1;
}

.arrow-btns a.active,
.arrow-btns button.active {
  font-weight: var(--fw-medium);
}

.arrow-btns a.active i.fal,
.arrow-btns button.active i.fal {
  opacity: 1;
}

.heart-icon {
  display: block;
  width: 1rem;
  transform-origin: center;
}

.ov-icon__square {
  stroke: var(--apt-primary);
}

.ov-icon__text {
  fill: var(--apt-primary);
}

.heart-icon__fill {
  transform: translate(4%, 4%) scale(0.92);
  transform-box: fill-box;
  fill: var(--transparent);
}

.heart-icon__stroke {
  fill: currentColor;
}

.heart-icon--triggered {
  animation-name: bounceHeart;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.heart-icon--triggered .heart-icon__fill {
  fill: var(--apt-error-1);
}

.heart-icon--triggered .heart-icon__stroke {
  fill: var(--apt-error-1);
}

.floating-icon {
  position: absolute;
  z-index: 6;
  transform-origin: left top;
  opacity: 1;
  pointer-events: none;
}

.floating-heart {
  transform: translateX(var(--heartX)) translateY(var(--heartY)) scale(0.9);
}

.heart-button {
  cursor: pointer;
}

.floating-heart .heart-icon__fill,
.floating-heart .heart-icon__stroke {
  fill: var(--transparent);
}

.floating-heart--triggered {
  transition: transform 1.5s ease-in-out, opacity 0.5s linear 1.5s;
  opacity: 0;
}

.floating-heart--triggered .heart-icon__fill,
.floating-heart--triggered .heart-icon__stroke {
  fill: var(--apt-error-1);
}

.floating-heart--triggered svg {
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: left top;
}
@media (min-width: 992px) {
  .floating-heart--triggered svg {
    animation-name: floatingIconScaleDesktop;
  }
}
@media (max-width: 991px) {
  .floating-heart--triggered svg {
    animation-name: floatingIconScaleMobile;
  }
}

.header .header-cta .mini-appointment-button {
  display: none;
  position: relative;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s linear;
}
@media (min-width: 992px) {
  .header .header-cta .mini-appointment-button {
    display: block;
    height: 28px;
    width: 0;
  }
}
.header .header-cta .mini-appointment-button a {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.1rem;
  padding: 0;
}
.header .header-cta .mini-appointment-button i {
  color: currentColor;
}

.header--minified .header-cta .mini-appointment-button {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.2s linear 0.1s;
}

.header--minified .header-sticky-section nav ul li {
  font-size: var(--16px);
}

.circle-plus-icon {
  display: block;
  width: 1rem;
  transform-origin: center;
  transform-box: fill-box;
}

.circle-plus-icon__bg {
  fill: transparent;
  stroke: currentColor;
}

.circle-plus-icon__solid-plus {
  opacity: 0;
  fill: var(--apt-white);
}

.circle-plus-icon__light-plus {
  fill: currentColor;
  opacity: 1;
}

.circle-plus-icon--triggered {
  animation-name: bounceCirclePlus;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.circle-plus-icon--triggered .circle-plus-icon__bg {
  fill: var(--apt-primary);
  stroke: var(--apt-primary);
}

.circle-plus-icon--triggered .circle-plus-icon__solid-plus {
  opacity: 1;
  fill: var(--apt-white);
}

.circle-plus-icon--triggered .circle-plus-icon__light-plus {
  opacity: 0;
}

.floating-circle-plus {
  transform: translateX(var(--circleplusX)) translateY(var(--circleplusY)) scale(0.9);
}

.heart-button {
  cursor: pointer;
}

.floating-circle-plus .circle-plus-icon__bg,
.floating-circle-plus .circle-plus-icon__outer-border,
.floating-circle-plus .circle-plus-icon__solid-plus,
.floating-circle-plus .circle-plus-icon__light-plus {
  fill: var(--transparent);
  stroke: var(--transparent);
}

.floating-circle-plus--triggered {
  transition: transform 1.5s ease-in-out, opacity 0.5s linear 1.5s;
  opacity: 0;
}

.floating-circle-plus--triggered .circle-plus-icon__bg {
  fill: var(--apt-primary);
  stroke: var(--apt-primary);
}

.floating-circle-plus--triggered .circle-plus-icon__solid-plus {
  opacity: 1;
  fill: var(--apt-white);
}

.floating-circle-plus--triggered .circle-plus-icon__light-plus {
  opacity: 0;
}

.floating-circle-plus--triggered svg {
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: left top;
}
@media (min-width: 992px) {
  .floating-circle-plus--triggered svg {
    animation-name: floatingIconScaleDesktop;
  }
}
@media (max-width: 991px) {
  .floating-circle-plus--triggered svg {
    animation-name: floatingIconScaleMobile;
  }
}

.tooltip-button {
  position: relative;
  top: 0.1em;
  font-size: var(--20px);
  line-height: 28px;
  color: var(--apt-grey-4);
  margin-left: 0.25em;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  background-color: var(--apt-black);
  width: max-content;
  padding: 0.65em 0.65em calc(0.65em + 10px) 0.65em;
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(50% + 10px) calc(100% - 10px), 50% 100%, calc(50% - 10px) calc(100% - 10px), 0% calc(100% - 10px));
  font-size: var(--14px);
  color: var(--apt-white);
  transform: translateY(-2px) translateX(-50%);
  display: none;
}

@media (max-width: 767px) {
  .tooltip--mobile-left {
    top: 50%;
    right: 100%;
    bottom: unset;
    left: unset;
    padding: 0.65em calc(0.65em + 10px) 0.65em 0.65em;
    clip-path: polygon(calc(100% - 10px) 0, calc(100% - 10px) calc(50% - 10px), 100% 50%, 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 0);
    transform: translateX(-2px) translateY(-50%);
  }
}

.tooltip-button:hover > .tooltip {
  display: block;
}

.packages {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 768px) {
  .packages {
    margin-top: 50px;
    margin-bottom: 119px;
  }
}

.package {
  position: relative;
  width: 100%;
  max-width: 830px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  margin-bottom: 30px;
  padding: 39px 30px;
  border-radius: 10px;
}
@media (min-width: 612px) {
  .package {
    flex-direction: row;
    padding: 38px 32px 56px;
  }
}
@media (min-width: 992px) {
  .package {
    flex-direction: column;
    padding: 39px 30px;
  }
}
@media (min-width: 1140px) {
  .package {
    flex-direction: row;
    padding: 38px 32px 56px;
  }
}
.package header {
  text-align: center;
  z-index: 1;
  margin-bottom: 38px;
}
@media (min-width: 612px) {
  .package header {
    width: 50%;
    flex: 0 0 auto;
    padding-right: 36px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .package header {
    width: 100%;
    margin-bottom: 38px;
  }
}
@media (min-width: 1140px) {
  .package header {
    width: 50%;
    margin-bottom: 0;
  }
}
.package svg {
  display: inline-block;
  width: 63px;
  margin-right: 0.34em;
}
@media (min-width: 768px) {
  .package svg {
    width: 79px;
  }
}
.package .heading {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: var(--25px);
  line-height: 38px;
  font-weight: var(--fw-medium);
  text-align: center;
  margin-bottom: 13px;
  white-space: nowrap;
}
@media (min-width: 612px) {
  .package .heading {
    margin-bottom: 14px;
  }
}
@media (min-width: 768px) {
  .package .heading {
    font-size: var(--32px);
    line-height: 48px;
  }
}
.package .heading > span {
  position: relative;
  top: -0.1em;
  letter-spacing: -0.4px;
}
.package .price {
  font-size: var(--48px);
  line-height: 50px;
  letter-spacing: -0.75px;
  font-weight: var(--fw-medium);
  text-align: center;
}
@media (min-width: 768px) {
  .package .price {
    font-size: var(--60px);
    line-height: 63px;
  }
}
.package div {
  z-index: 1;
  width: 100%;
  max-width: 320px;
}
@media (min-width: 612px) {
  .package div {
    flex: 1 1 auto;
    width: auto;
    max-width: none;
    padding-left: 25px;
    padding-top: 13px;
  }
}
@media (min-width: 992px) {
  .package div {
    width: 100%;
    max-width: 320px;
    padding-left: 0;
    padding-top: 0;
  }
}
@media (min-width: 1140px) {
  .package div {
    width: auto;
    max-width: none;
    padding-top: 13px;
  }
}
@media (min-width: 1360px) {
  .package div {
    padding-left: 25px;
  }
}
.package div li:last-child {
  margin-bottom: 0;
}
.package--promoted {
  width: 100%;
  max-width: 830px;
  border: 1px solid transparent;
  border-radius: 8px;
}
.package--promoted::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-image: linear-gradient(135deg, #79fdfd 0%, #00e9ff 5.71428571%, #00d4ff 11.4285714%, #00bbff 17.1428571%, #00a0ff 22.8571429%, #0080ff 28.5714286%, #4157ff 40%, #4157ff 100%);
  border-radius: 10px;
  z-index: 0;
}
.package--promoted::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--apt-white);
  border-radius: 8.5px;
  z-index: 0;
}
.package--promoted .heading span {
  color: var(--apt-primary);
}

.regular-content {
  margin-top: 36px;
  margin-bottom: 42px;
}
@media (min-width: 768px) {
  .regular-content {
    margin-top: 25px;
    margin-bottom: 70px;
  }
}
.regular-content > * {
  margin-bottom: var(--17px);
}
@media (min-width: 768px) {
  .regular-content > * {
    margin-bottom: var(--24px);
  }
}

.grid-item > [data-stretch] {
  position: relative;
}

a.anchor,
.content-hero a[name],
.zebra-block a[name],
.zebra-cards a[name] {
  display: block;
  position: absolute;
  top: -70px;
  visibility: hidden;
}

.apply-cta {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -18px;
}

.apply-cta__price {
  flex: 1 1 auto;
  color: var(--apt-primary);
  padding-right: 0.5em;
}

.apply-cta__btn {
  white-space: nowrap;
  flex: 0 0 auto;
  color: var(--apt-grey-2);
}

.tiny-print {
  margin-bottom: 20px;
}
.tiny-print:last-of-type {
  margin-bottom: 0;
}

.tiny-print-normal-container {
  color: var(--apt-grey-2);
  width: 100%;
  max-width: 870px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
}
@media (min-width: 612px) {
  .tiny-print-normal-container {
    text-align: center;
  }
}

.tiny-print-icon-container {
  color: var(--apt-grey-2);
  width: 100%;
  max-width: 870px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 612px) {
  .tiny-print-icon-container {
    flex-direction: row;
  }
}
.tiny-print-icon-container figure {
  width: 100%;
  flex: 0 0 auto;
}
@media (min-width: 612px) {
  .tiny-print-icon-container figure {
    width: 85px;
  }
}
.tiny-print-icon-container figure img {
  display: block;
  width: 63px;
  margin: 0 auto 20px;
}
@media (min-width: 612px) {
  .tiny-print-icon-container figure img {
    margin: 0;
  }
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 56px 15px 34px 15px;
}
@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    padding: 100px var(--35px) 74px var(--50px);
  }
}
@media (min-width: 1200px) {
  .contact-container {
    padding: 100px var(--85px) 74px var(--85px);
  }
}

.contact-left {
  width: 100%;
  flex: 1 1 auto;
  padding-bottom: 54px;
}
@media (min-width: 768px) {
  .contact-left {
    width: 45%;
    padding-bottom: 74px;
  }
}
@media (min-width: 992px) {
  .contact-left {
    width: 44%;
  }
}

.contact-left-content {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .contact-left-content {
    max-width: 425px;
    padding-right: 35px;
  }
}

.contact-left-content header {
  margin-bottom: 34px;
}
@media (min-width: 768px) {
  .contact-left-content header {
    margin-bottom: 54px;
  }
}

.contact-left-content .button {
  width: 100%;
  margin-bottom: 20px;
}

.contact-right {
  width: 100%;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .contact-right {
    width: 55%;
    max-width: 640px;
  }
}
@media (min-width: 992px) {
  .contact-right {
    width: 56%;
  }
}
@media (min-width: 1200px) {
  .contact-right {
    margin-right: -15px;
  }
}

.contact-cta-cards-list {
  margin-left: -15px;
  width: calc(100% + 30px);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .contact-cta-cards-list {
    margin-left: 0;
    width: 100%;
  }
}

.contact-cta-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
}
@media (min-width: 640px) {
  .contact-cta-cards-list-item {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .contact-cta-cards-list-item {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .contact-cta-cards-list-item {
    width: 50%;
  }
}

.contact-cta-cards-list-item a,
.contact-cta-cards-list-item a:hover,
.contact-cta-cards-list-item a:active,
.contact-cta-cards-list-item a:focus {
  text-decoration: none;
  color: inherit;
}

.contact-cta-cards-list-item a:hover .card {
  box-shadow: var(--shadow-hover);
}

.contact-cta-cards-list-item .card {
  padding: 36px 32px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.contact-left .arrow-btns {
  margin-top: 20px;
  padding-left: 0;
  padding-right: 0;
}

.contact-cta-card__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--apt-primary);
  flex: 0 0 auto;
}
.contact-cta-card__header figure {
  width: 50px;
  border-radius: 0;
}
.contact-cta-card__header figure img {
  width: auto;
  max-width: 28px;
  height: 28px;
  min-height: 0;
  display: block;
  aspect-ratio: unset;
  object-fit: contain;
}
.contact-cta-card__header .heading {
  color: var(--apt-primary);
}

.contact-cta-card__content {
  padding-top: 25px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.contact-cta-card__content p {
  font-size: var(--14px);
  line-height: 20px;
  margin-bottom: 10px;
  flex: 1 1 auto;
}
.contact-cta-card__content time {
  font-size: var(--14px);
  line-height: 20px;
  color: var(--apt-grey-4);
  display: block;
  flex: 0 0 auto;
}

.center-floating-card {
  width: 100%;
  max-width: 420px;
  margin-right: auto;
  margin-bottom: 78px;
  margin-left: auto;
}
@media (min-width: 712px) {
  .center-floating-card {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
  }
}
@media (min-width: 1024px) {
  .center-floating-card {
    margin-bottom: 158px;
  }
}
.center-floating-card__figure {
  width: 100%;
  max-width: 400px;
  padding-bottom: 25px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 712px) {
  .center-floating-card__figure {
    width: 50%;
    padding: 0 25px 25px;
  }
}
.center-floating-card__figure img {
  width: 100%;
}
.center-floating-card .heading {
  margin-bottom: 25px;
}
.center-floating-card .button {
  margin-top: 25px;
}
.center-floating-card__content {
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 712px) {
  .center-floating-card__content {
    width: 50%;
    padding: 0 25px 25px;
  }
}

.floating-ctas-container {
  width: 100%;
  margin-bottom: 78px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 712px) {
  .floating-ctas-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  .floating-ctas-container {
    margin-bottom: 158px;
    flex-wrap: nowrap;
  }
}

.floating-cta {
  position: relative;
  border-radius: 10px;
  background-color: var(--apt-white);
  box-shadow: 0 4px 6px 0 var(--apt-grey-6);
  padding-bottom: 24px;
  transition: box-shadow 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 712px) {
  .floating-cta {
    padding-bottom: 32px;
  }
}
@media (min-width: 992px) {
  .floating-cta {
    padding-bottom: 32px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .floating-cta {
    padding-bottom: 47px;
  }
}
.floating-cta:hover, .floating-cta:active, .floating-cta:focus {
  text-decoration: none;
}
@media (any-hover: hover) {
  .floating-cta:hover, .floating-cta:active, .floating-cta:focus {
    box-shadow: 0 12px 18px 0 var(--apt-grey-6);
  }
}
.floating-cta img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  width: 100.1%;
  height: 100%;
  z-index: 0;
}
.floating-cta .button {
  position: relative;
  z-index: 1;
}

.floating-cta-1 {
  width: 100%;
  max-width: 420px;
  flex: 0 0 auto;
  margin-bottom: 40px;
  aspect-ratio: 3/2;
}
@supports not (aspect-ratio: 3/2) {
  .floating-cta-1 {
    height: 280px;
  }
}
@media (min-width: 712px) {
  .floating-cta-1 {
    width: calc(50% - 20px);
    max-width: none;
  }
}
@media (min-width: 1024px) {
  .floating-cta-1 {
    width: calc(50% - 50px);
    margin-right: 50px;
  }
  @supports not (aspect-ratio: 3/2) {
    .floating-cta-1 {
      height: 320px;
    }
  }
}
@media (min-width: 1440px) {
  .floating-cta-1 {
    width: 500px;
    height: 380px;
    aspect-ratio: revert;
  }
}

.floating-cta-2 {
  width: 100%;
  max-width: 420px;
  flex: 0 0 auto;
  margin-bottom: 40px;
  aspect-ratio: 3/2;
}
@supports not (aspect-ratio: 3/2) {
  .floating-cta-2 {
    height: 280px;
  }
}
@media (min-width: 712px) {
  .floating-cta-2 {
    width: calc(50% - 20px);
    max-width: none;
  }
}
@media (min-width: 1024px) {
  .floating-cta-2 {
    width: calc(40% - 50px);
    margin-right: 50px;
    margin-bottom: 0;
  }
}
@media (min-width: 1440px) {
  .floating-cta-2 {
    width: 390px;
    height: 300px;
    aspect-ratio: revert;
  }
}

.floating-button {
  width: 100%;
  min-width: 86px;
  text-decoration: none;
  align-self: center;
  font-size: var(--20px);
  line-height: 22px;
  display: inline-flex;
  flex-direction: row-reverse;
  align-items: baseline;
  justify-content: center;
}
@media (min-width: 1024px) {
  .floating-button {
    width: 86px;
    display: inline;
  }
}
.floating-button:hover, .floating-button:active, .floating-button:focus {
  text-decoration: none;
}
.floating-button .button {
  width: 32px;
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  min-height: 32px;
  padding: 0;
  margin-top: 17px;
  margin-right: 15px;
  display: block;
}
@media (min-width: 1024px) {
  .floating-button .button {
    margin-right: 0;
  }
}

#inruilen {
  margin-top: 2em;
}

@media (max-width: 767px) {
  .navigatable .nav-tablist + .hide-desktop .heading-1,
.editableContainer .hide-desktop .heading-1 {
    margin-top: 15px;
  }
}

.navigatable .nav-tablist + .hide-desktop .heading-1,
.navigatable .nav-tablist + .hide-desktop .heading-2,
.navigatable .nav-tablist + .hide-desktop .heading-3,
.navigatable .nav-tablist + .hide-desktop .heading-4 {
  padding-left: 0;
  padding-right: 0;
}

.grid-item.bg-white.navigatable .nav-tablist .tabs {
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}
@media (min-width: 512px) {
  .grid-item.bg-white.navigatable .nav-tablist .tabs {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media (min-width: 992px) {
  .grid-item.bg-white.navigatable .nav-tablist .tabs {
    padding-top: 21px;
    padding-bottom: 21px;
  }
}

.split-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
}
@media (min-width: 512px) {
  .split-content {
    padding-top: 106px;
    padding-bottom: 97px;
  }
}
@media (min-width: 992px) {
  .split-content {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.split-content__header {
  width: 100%;
  border-bottom: 1px solid #EAEAE7;
  margin-bottom: 58px;
  padding-bottom: 48px;
}

.split-content-column {
  width: 100%;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .split-content-column {
    width: 50%;
    padding-bottom: 0;
  }
}
.split-content-column__text {
  flex: 1 1 auto;
}
.split-content-column__action {
  flex: 0 0 auto;
  padding-top: 32px;
}
.split-content-column__figure {
  margin-bottom: 40px;
  width: 100%;
}
.split-content-column__figure img {
  width: 100%;
}

@media (min-width: 992px) {
  .split-content-column:first-of-type {
    padding-right: 25px;
  }

  .split-content-column:last-of-type {
    padding-left: 25px;
  }
}
.nav-steps {
  width: 100%;
  border-bottom: 1px solid var(--apt-grey-5);
}

.step-list {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  overflow-x: auto;
}
@media (max-width: 911px) {
  .step-list {
    justify-content: flex-end;
    padding-right: 16px;
  }
}
.step-list .step {
  padding: 16px 39px 16px 0;
  flex: 0 0 auto;
}
@media (max-width: 911px) {
  .step-list .step {
    padding: 4px 0 4px 16px;
  }
}
@media (max-width: 359px) {
  .step-list .step {
    padding: 4px 0 4px 14px;
  }
}
.step-list .step--done .step-button {
  color: var(--apt-grey-4);
}
.step-list .step--active .step-button {
  color: var(--apt-primary);
}
.step-list .step-button {
  color: var(--apt-grey-5);
}

@media (max-width: 911px) {
  .nav-steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media (max-width: 911px) and (max-width: 911px) {
  .nav-steps .step-list .step {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 911px) {
  .nav-steps .step-list .step-button {
    overflow: hidden;
    width: 12px;
    height: 12px;
    position: relative;
  }
  .nav-steps .step-list .step-button::before {
    content: "";
    width: 12px;
    height: 12px;
    background-color: var(--apt-grey-9);
    position: absolute;
    top: 0;
    left: 0;
  }
  .nav-steps .step-list .step-button::after {
    content: "";
    width: 4px;
    height: 4px;
    background: var(--apt-grey-5);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 4px;
  }
}
@media (max-width: 911px) {
  .nav-steps .step-list .step--done .step-button::after,
.nav-steps .step-list .step--active .step-button::after {
    background: var(--apt-primary);
  }
}

.mobile-active-step {
  flex: 0 0 auto;
  color: var(--apt-primary);
}
@media (min-width: 912px) {
  .mobile-active-step {
    display: none;
  }
}

@media (min-width: 912px) {
  .step-buttons > button {
    display: none;
  }
}
@media (max-width: 911px) {
  .step-buttons {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .step-buttons button {
    flex: 1 0 auto;
    color: var(--apt-grey-5);
  }
  .step-buttons .step-list {
    flex: 0 0 auto;
    width: auto;
  }
}

.three-columns {
  position: relative;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (min-width: 992px) {
  .three-columns {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.three-columns > div {
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .three-columns > div {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.three-columns__thin-column {
  width: 100%;
}
@media (min-width: 992px) {
  .three-columns__thin-column {
    width: 33.33%;
  }
}
.three-columns__thin-column .heading {
  margin-bottom: 38px;
}

.three-columns__broad-column {
  width: 100%;
}
@media (min-width: 992px) {
  .three-columns__broad-column {
    width: 66.67%;
  }
}

.three-column-content-card {
  position: relative;
  width: 100%;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  margin-bottom: 30px;
  padding: 32px 30px;
  border-radius: 10px;
}
@media (min-width: 992px) {
  .three-column-content-card {
    padding: 32px 52px 50px;
  }
}

.three-column-content-card__header {
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 31px;
  border-bottom: 1px solid var(--apt-grey-7);
}

.finished-dot {
  position: relative;
  color: var(--apt-primary);
}
.finished-dot img {
  position: absolute;
  left: calc(100% + 0.05em);
  bottom: 0.185em;
  display: block;
  width: 188px;
}
@media (min-width: 512px) {
  .finished-dot img {
    width: 242px;
  }
}
@media (min-width: 768px) {
  .finished-dot img {
    width: 296px;
  }
}
@media (min-width: 992px) {
  .finished-dot img {
    width: 378px;
  }
}

@media (min-width: 992px) {
  .info-toggle-select--mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .info-toggle-select--desktop {
    display: none;
  }

  .info-toggle-select--mobile {
    margin-bottom: 28px;
  }
}
.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 51px;
  margin-left: -0.6em;
}

.pagination button {
  width: 3ch;
  height: 3ch;
  padding: 3px 5px;
  background: white;
  border-radius: 3px;
  text-align: center;
  line-height: 1.5;
  color: var(--apt-grey-3);
  transition: background-color 0.2s ease;
}
.pagination button:hover {
  background: var(--apt-grey-8);
}
.pagination button.active {
  color: var(--apt-primary);
  font-weight: var(--fw-semibold);
}

@media (min-width: 660px) {
  .card__content--top-crop {
    padding-top: 13px;
  }
}

.card__content--top-crop > a:first-child {
  flex-grow: 1;
  flex-shrink: 1;
}

.flex-grow-column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.call-card .card__content .wmpform:only-child {
  margin-top: -20px;
}

.reset-mt .card__content .wmpform {
  margin-top: 0 !important;
}

.display {
  font-size: var(--43px);
  line-height: 43px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-semibold);
}
@media (min-width: 768px) {
  .display {
    font-size: var(--70px);
    line-height: 70px;
    letter-spacing: -1.25px;
  }
}

h1, .heading-1 {
  display: block;
  font-size: var(--28px);
  line-height: 30px;
  letter-spacing: -0.35px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--10px);
}
@media (min-width: 512px) {
  h1, .heading-1 {
    font-size: var(--40px);
    line-height: 43px;
    letter-spacing: -0.5px;
  }
}
@media (min-width: 768px) {
  h1, .heading-1 {
    font-size: var(--50px);
    line-height: 56px;
    letter-spacing: -0.63px;
    margin-bottom: var(--35px);
  }
}
@media (min-width: 992px) {
  h1, .heading-1 {
    font-size: var(--60px);
    line-height: 63px;
    letter-spacing: -0.75px;
  }
}

h2, .heading-2 {
  display: block;
  font-size: var(--22px);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--12px);
}
@media (min-width: 512px) {
  h2, .heading-2 {
    font-size: var(--28px);
    line-height: 30px;
    letter-spacing: -0.12px;
  }
}
@media (min-width: 768px) {
  h2, .heading-2 {
    font-size: var(--40px);
    line-height: 48px;
    letter-spacing: -0.5px;
  }
}

h3, .heading-3 {
  display: block;
  font-size: var(--22px);
  line-height: 30px;
  letter-spacing: -0.09px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--17px);
}

h4, .heading-4 {
  display: block;
  font-size: var(--18px);
  line-height: 26px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

h5, .heading-5 {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

h6, .heading-6 {
  display: block;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

.display--black, .heading--black {
  color: var(--apt-black);
}

.display--white, .heading--white {
  color: var(--apt-white);
}

.display--primary, .heading--primary {
  color: var(--apt-primary);
}

a {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--apt-grey-5);
  text-underline-offset: 0.2em;
  color: inherit;
}

a:not([href]) {
  color: inherit;
}

a:hover {
  text-decoration-color: var(--apt-primary);
}

.text-14, .text-14 p, .text-14 a, .text-14 span {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.text-16, .text-16 p, .text-16 a, .text-16 span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
}

p, .text-18, .text-18 p, .text-18 a, .text-18 span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
}
@media (min-width: 768px) {
  p, .text-18, .text-18 p, .text-18 a, .text-18 span {
    font-size: var(--18px);
    line-height: 30px;
  }
}

.text-20 {
  font-size: var(--18px);
  line-height: 1.4;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .text-20 {
    font-size: var(--20px);
  }
}

.text-22 {
  font-size: var(--18px);
  line-height: 1.4;
  letter-spacing: 0;
}
@media (min-width: 768px) {
  .text-22 {
    font-size: var(--22px);
  }
}

b, strong {
  font-weight: var(--fw-semibold);
}

.intro {
  font-weight: var(--fw-medium);
}

ul:not([class]) {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul:not([class]) li {
  position: relative;
  padding-left: 1.55em;
  margin-bottom: 0.2em;
}

ul:not([class]) li::before {
  content: "•";
  position: absolute;
  top: 0.75em;
  left: 0;
  padding-left: 0;
  color: var(--apt-primary);
  font-size: 1em;
  width: 1px;
  height: 1px;
  line-height: 0;
  transform: translateY(-50%);
}

ul:not([class]) li.white::before {
  color: var(--apt-white);
}

ol:not([class]) {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: counter;
}

ol:not([class]) li {
  position: relative;
  padding-left: 0;
  padding-bottom: 0.2em;
}

ol:not([class]) li::before {
  content: counter(counter) ".";
  counter-increment: counter;
  display: inline-block;
  padding-left: 0;
  width: 1.5em;
  color: var(--apt-primary);
}

ul.checks {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

ul.checks li {
  position: relative;
  padding-left: 1.7em;
  margin-bottom: 0.7em;
}

ul.checks li::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%3E%3Cpath%20d%3D%22M15.7321288%201.75174354c-2.5660131%203.25539738-4.4905229%205.69694542-5.77352945%207.32464412-.80188093%201.01731404-2.00470233%202.54328504-3.60846419%204.57791304C6.21906318%2013.8993534%206.00865818%2014%205.79480391%2014c-.21385427%200-.42356942-.102572-.58533982-.3076724L.24252626%207.39097384c-.32336835-.41024439-.32336835-1.0747309%200-1.48519408.32336835-.41046319.84713884-.41024439%201.17067965%200l4.381598%205.56055094L14.590423.3076833c.3233683-.4102444.8471388-.4102444%201.1706796%200%20.3235408.41024438.322851%201.07210533-.0289738%201.44406024z%22%20fill%3D%22%234157FF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 0.95em 100%;
  padding-left: 0;
  width: 1.5em;
  height: 0.7em;
  color: var(--apt-primary);
}

ol:not([class]) {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: counter;
}

p + ul:not([class]),
p + ul.checks,
p + ol {
  margin-top: var(--17px);
}

hr {
  background-color: var(--apt-grey-7);
  border: none;
  font-size: 0;
  line-height: 0;
  height: 1px;
}

.hide-desktop {
  display: block;
}
@media (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
}

.show-tablet {
  display: none;
}
@media (max-width: 991px) {
  .show-tablet {
    display: block;
  }
}

.hide-mobile {
  display: block;
}
@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.bg-grey-8 {
  background-color: var(--apt-grey-8);
}

.bg-grey-8-inset {
  position: relative;
}
.bg-grey-8-inset::before {
  content: "";
  position: absolute;
  top: 150px;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--apt-grey-8);
  border-top: 1px solid var(--apt-grey-7);
}

.bg-grey-9 {
  background-color: var(--apt-grey-9);
}

.bg-white {
  background-color: var(--apt-white);
}

/* default classes for GX WebManager - DO NOT REMOVE */
.overflowHidden {
  overflow: hidden;
}

.overflowHidden[style^="overflow-y: initial"] {
  overflow: hidden !important;
}

@media (max-width: 991px) {
  .mobileOverflowHidden {
    overflow: hidden;
  }
}
.displayNone {
  display: none;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.textcentered {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  margin: auto;
  text-align: center;
}

.aligncenter {
  text-align: center;
}

.clearAll {
  clear: both;
}

.noWrap {
  white-space: nowrap;
}

.vertical-space {
  margin-top: 5em;
  margin-bottom: 5em;
}

.desktop-flex {
  margin-bottom: 40px;
}
@media (min-width: 920px) {
  .desktop-flex {
    display: flex;
    margin-bottom: 120px;
  }
}

.grayscale {
  filter: saturate(0);
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex: 0 0 auto;
  z-index: 5;
}
@media (min-width: 992px) {
  .header {
    transition: top 0.3s linear;
  }
}

.header-container {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--apt-white);
}
@media (min-width: 992px) {
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: initial;
  }
}

.header-static-section {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(var(--50px) + 78px);
  background-color: var(--apt-grey-9);
  z-index: 1;
}
@media (min-width: 992px) {
  .header-static-section {
    position: static;
    height: 40px;
    z-index: 1;
    margin-top: 0;
    transition: margin-top 0.2s ease-in;
    will-change: margin-top;
  }
}

.header-static-section .header-list a {
  font-size: var(--15px);
  line-height: 24px;
  letter-spacing: 0.5px;
}

.header-static-section .header-list a,
.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type=button],
.header-static-section .header-cta input[type=submit] {
  color: var(--apt-grey-2);
}

.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type=button],
.header-static-section .header-cta input[type=submit] {
  font-size: var(--15px);
  line-height: 24px;
}
@media (min-width: 992px) {
  .header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type=button],
.header-static-section .header-cta input[type=submit] {
    font-size: var(--18px);
    line-height: 28px;
  }
}

.header-static-section .header-list a:hover,
.header-static-section .header-cta button:hover,
.header-static-section .header-cta input[type=submit]:hover,
.header-static-section .header-cta input[type=button]:hover {
  color: var(--apt-black);
}

.header-static-section .header-container {
  padding: 0 var(--15px) 0 var(--15px);
}
@media (min-width: 768px) {
  .header-static-section .header-container {
    padding: 0 var(--50px) 0 var(--50px);
  }
}
@media (min-width: 1200px) {
  .header-static-section .header-container {
    padding: 0 var(--85px) 0 var(--85px);
  }
}

.header-static-items-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-static-section nav ul,
.header-static-section .header-cta {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-static-section nav {
  display: none;
  flex: 1 0 auto;
  height: 100%;
}
@media (min-width: 992px) {
  .header-static-section nav {
    display: block;
  }
}

.header-static-section nav ul li {
  padding: 0 0 0 1.15em;
  display: inline-flex;
}

.header-static-section .header-cta {
  flex: 0 0 auto;
  height: 100%;
  /* TODO: Deze zorgen voor de uitlijning van de zoek, social en fav icons in de header. Zet terug als die verwerkt worden. */
}
@media (min-width: 992px) {
  .header-static-section .header-cta {
    transform: none;
    transition: transform 0.3s linear;
  }
}

.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type=button],
.header-static-section .header-cta input[type=submit] {
  position: relative;
  top: 0.075em;
  padding: 0 0 0 0.95em;
  text-align: center;
  align-self: center;
}
@media (min-width: 992px) {
  .header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type=button],
.header-static-section .header-cta input[type=submit] {
    padding: 0 0 0 1.2em;
  }
}

.header-static-section .header-cta > button:last-of-type {
  padding-left: 1.03em;
}

.header-static-section .header-cta a i,
.header-static-section .header-cta button i,
.header-static-section .header-cta input[type=button] i,
.header-static-section .header-cta input[type=submit] i {
  width: 20px;
  font-size: 1.1em;
  line-height: 1;
}

.header-static-section .header-cta a svg,
.header-static-section .header-cta button svg,
.header-static-section .header-cta input[type=button] svg,
.header-static-section .header-cta input[type=submit] svg {
  position: relative;
  top: 0rem;
  width: 0.9rem;
}
@media (min-width: 992px) {
  .header-static-section .header-cta a svg,
.header-static-section .header-cta button svg,
.header-static-section .header-cta input[type=button] svg,
.header-static-section .header-cta input[type=submit] svg {
    top: 0;
    width: 1.1rem;
  }
}

.header-sticky-section {
  width: 100%;
  height: 52px;
  background-color: var(--apt-white);
  z-index: 1;
  transition: height 0.3s linear;
}
@media (min-width: 992px) {
  .header-sticky-section {
    height: 90px;
  }
}

.header-sticky-section::before {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--apt-grey-7);
  z-index: 2;
  pointer-events: none;
}

.header-sticky-section nav > ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: var(--35px);
  padding-bottom: var(--35px);
}
@media (min-width: 992px) {
  .header-sticky-section nav > ul {
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header-sticky-section nav > ul > li {
  padding: 11px 15px 11px 40px;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0;
}
@media (min-width: 992px) {
  .header-sticky-section nav > ul > li {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.62em 0 0.54em;
    transition: font-size 0.2s linear;
  }
}
@media (min-width: 1200px) {
  .header-sticky-section nav > ul > li {
    padding: 0 0.82em 0 0.84em;
  }
}

.header-sticky-section nav > ul > li:not([class]) {
  padding-bottom: 0.33em;
}

@media (min-width: 992px) {
  .header-sticky-section nav > ul > li:last-child {
    padding: 0 0 0 25px;
  }
}

.header-list {
  position: relative;
}

.header-list a,
.header-list-item--primary > button {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  text-decoration: none;
  color: inherit;
}
@media (min-width: 992px) {
  .header-list a,
.header-list-item--primary > button {
    display: inline-block;
    width: auto;
  }
}

.header-list li:not([class]) a::after,
.header-list-item--primary > a::after,
.header-list-item--primary > button::after {
  content: "";
  font-family: var(--fa);
  font-weight: var(--fw-light);
  margin-left: auto;
  color: var(--apt-grey-2);
}
@media (min-width: 992px) {
  .header-list li:not([class]) a::after,
.header-list-item--primary > a::after,
.header-list-item--primary > button::after {
    display: none;
  }
}

.header-list a:hover {
  color: var(--apt-primary);
}

.header .logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--apt-white);
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 var(--10px);
}
@media (min-width: 992px) {
  .header .logo-container {
    width: auto;
    height: auto;
  }
}
@media (min-width: 768px) {
  .header .logo-container {
    padding: 0 0 0 var(--50px);
  }
}
@media (min-width: 1200px) {
  .header .logo-container {
    padding: 0 0 0 var(--85px);
  }
}

.header .logo {
  flex: 0 0 auto;
}

.header .logo img,
.header .logo svg {
  display: block;
  width: 76px;
  position: relative;
  top: 0;
}
@media (min-width: 992px) {
  .header .logo img,
.header .logo svg {
    width: 123px;
  }
}

.header-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--apt-white);
  z-index: 1;
  padding-top: 52px;
  transform: translateY(-100%);
  transition: transform 0.2s ease-in, margin-top 0.2s ease-in;
  padding-right: var(--10px);
}
@media (min-width: 768px) {
  .header-menu {
    padding-right: var(--35px);
  }
}
@media (min-width: 1200px) {
  .header-menu {
    padding-right: var(--70px);
  }
}
@media (min-width: 992px) {
  .header-menu {
    position: static;
    display: block;
    width: auto;
    height: 100%;
    padding-top: 0;
    transform: none;
  }
}

.header-menu nav {
  width: 100%;
  overflow: auto;
  margin-bottom: 83px;
}
@media (min-width: 512px) {
  .header-menu nav {
    margin-bottom: 105px;
  }
}
@media (min-width: 992px) {
  .header-menu nav {
    overflow: visible;
    margin-bottom: 0;
    height: 100%;
  }
}

.header--open .header-menu {
  transform: translateY(0);
}
@media (min-width: 992px) {
  .header--open .header-menu {
    transform: none;
    margin-top: 0;
  }
}

.header--open #headerToggleButton .fa-bars {
  display: none;
}

.header:not(.header--open) #headerToggleButton .fa-times {
  display: none;
}

.header--open #headerToggleButton .fa-times {
  display: block;
}

@media (min-width: 992px) {
  .header #headerToggleButton {
    display: none;
  }
}

.header-sticky-section nav ul li.header-list-item--last-primary {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px 36px 22px;
  background-color: var(--apt-white);
  border-top: 1px solid var(--apt-grey-7);
  overflow: hidden;
  height: 105px;
}
@media (min-width: 375px) {
  .header-sticky-section nav ul li.header-list-item--last-primary {
    height: auto;
  }
}
@media (min-width: 992px) {
  .header-sticky-section nav ul li.header-list-item--last-primary {
    position: static;
    padding: 0 var(--15px) 0 0.7em;
    border-top: 0;
  }
}
@media (min-width: 1200px) {
  .header-sticky-section nav ul li.header-list-item--last-primary {
    padding: 0 var(--15px) 0 1.5em;
  }
}

.header-sticky-section nav ul li.header-list-item--last-primary .button {
  width: 100%;
}
@media (min-width: 992px) {
  .header-sticky-section nav ul li.header-list-item--last-primary .button {
    transform: none;
    transition: transform 0.3s ease-in;
    padding: 6px 20px 8px;
  }
}
@media (min-width: 1100px) {
  .header-sticky-section nav ul li.header-list-item--last-primary .button {
    padding: 6px 26px 8px;
  }
}

.header-sticky-section nav ul li.header-list-item--secondary {
  color: var(--apt-grey-2);
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.37px;
  padding-top: 8px;
  padding-bottom: 8px;
}
@media (min-width: 992px) {
  .header-sticky-section nav ul li.header-list-item--secondary {
    display: none;
  }
}

.header-sticky-section nav ul li.header-list-item--first-secondary {
  border-top: 1px solid var(--apt-grey-7);
  margin-top: 30px;
  padding-top: 30px;
}

.header-sticky-section .header-list > li > a,
.header-sticky-section .header-list > li > button[type=button] {
  text-align: left;
  width: 100%;
  font-weight: var(--fw-medium);
}
@media (min-width: 992px) {
  .header-sticky-section .header-list > li > a,
.header-sticky-section .header-list > li > button[type=button] {
    width: auto;
  }
}

.floating-quantity {
  position: fixed;
  top: 12px;
  transform: translate(0.95em, 0.1em);
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  font-size: 0.6em;
  line-height: 1.1;
  color: white;
  text-align: center;
  font-weight: var(--fw-medium);
  z-index: 7;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s linear, transform 0.3s linear;
  padding-left: 0em;
}
@media (min-width: 992px) {
  .floating-quantity {
    position: fixed;
    top: 8px;
    transform: translate(0.95em, -0.1em);
  }
}

.floating-quantity--visible {
  opacity: 1;
}

.floating-quantity--heart {
  background-color: var(--apt-error-1);
}

.floating-quantity--circle-plus {
  background-color: var(--apt-primary);
}

.a-point-logo__point {
  transform: none;
  will-change: transform;
  transition: transform 0.3s ease-out;
}

@media (min-width: 992px) {
  .header--minified {
    top: -40px;
  }
}

.header--minified .header-sticky-section {
  height: 52px;
}
@media (min-width: 992px) {
  .header--minified .header-sticky-section {
    height: 60px;
  }
}

@media (min-width: 992px) {
  .header--minified .a-point-logo__point {
    transform: translateY(-101%);
  }
}

@media (min-width: 992px) {
  .header--minified .header-sticky-section nav ul li.header-list-item--last-primary .button {
    transform: translateY(140%);
  }
}

@media (min-width: 992px) {
  .header--minified .header-static-section .header-cta {
    transform: translateY(100%) translateY(0.5em);
  }
}

@media (min-width: 992px) {
  .header--minified .header-menu,
.header--open.header--minified .header-menu {
    margin-top: 2px;
  }
}

@media (min-width: 992px) {
  .header--minified ~ .floating-quantity {
    transform: translate(0.95em, 0.8em);
  }
}

.header-sticky-section nav ul li.header-list-item--has-submenu button {
  cursor: default;
}

.submenu {
  position: fixed;
  top: 52px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--apt-white);
  z-index: 5;
  list-style: none;
  padding-bottom: var(--35px);
  margin: 0;
  pointer-events: none;
  overflow: auto;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
}
@media (min-width: 992px) {
  .submenu {
    position: absolute;
    top: 90px;
    left: calc((50px - 0.84em) * -1);
    bottom: unset;
    width: max-content;
    min-height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0 40px 0;
    opacity: 0;
    overflow: visible;
    background: rgba(255, 255, 255, 0.98);
    transform: none;
    transition: top 0.2s ease-in, opacity 0.2s ease;
  }
}
@media (min-width: 1550px) {
  .submenu {
    position: absolute;
    left: calc((50px - 0.84em) * -1);
    right: unset;
  }
}

.submenu-mobile-header {
  margin-bottom: 45px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--apt-grey-7);
}
@media (min-width: 992px) {
  .submenu-mobile-header {
    display: none;
  }
}
.submenu-mobile-header button {
  position: absolute;
  left: 12px;
  width: 24px;
  height: 16px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  color: var(--apt-grey-2);
}

@media (min-width: 992px) {
  .submenu--align-right {
    left: unset;
    right: calc((50px - 0.84em) * -1);
  }
}

@media (min-width: 992px) {
  .header-list-item--has-submenu:hover .submenu {
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
}

.submenu--toggled {
  pointer-events: auto;
  transform: none;
}
@media (min-width: 992px) {
  .submenu--toggled {
    opacity: 1;
  }
}

.submenu-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 17px;
  margin-bottom: 40px;
  flex: 0 0 auto;
}
@media (min-width: 992px) {
  .submenu-column {
    width: fit-content;
    max-width: 240px;
    padding-right: 14px;
    padding-left: 50px;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .submenu-column:last-of-type {
    margin-right: 50px;
  }
}

@media (min-width: 992px) {
  .submenu-column--border {
    border-right: 1px solid var(--apt-grey-5);
  }
}

.submenu-column--centered {
  align-self: center;
}

.submenu-column--centered .button {
  text-align: center;
  justify-content: center;
  align-items: baseline;
}

.submenu-column header {
  font-size: var(--22px);
  line-height: 30px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: 15px;
}

.submenu-list {
  width: 100%;
}
@media (min-width: 992px) {
  .submenu-list {
    width: auto;
  }
}

.submenu-column--border .submenu-list {
  padding-bottom: 23px;
}

.submenu li {
  padding: 11px 0;
  margin: 0;
  font-size: var(--18px);
  line-height: 22px;
  color: var(--apt-black);
}

.submenu li a {
  font-weight: var(--fw-regular);
}

.header--minified .submenu {
  top: 60px;
}

.footer {
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--apt-white);
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  z-index: 2;
}

.footer a:not(:hover) {
  text-decoration-color: var(--apt-white);
}

.footer-container {
  width: 100%;
  max-width: 1440px;
  padding: 0 var(--15px);
  margin: 0 auto;
}
@media (min-width: 768px) {
  .footer-container {
    padding: 0 var(--50px);
  }
}
@media (min-width: 1200px) {
  .footer-container {
    padding: 0 var(--85px);
  }
}

.footer-primary {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 54px;
  padding-bottom: 21px;
}
@media (min-width: 812px) {
  .footer-primary {
    flex-direction: row;
  }
}

.footer-primary__logo-socials {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: var(--40px);
}
@media (min-width: 812px) {
  .footer-primary__logo-socials {
    width: 221px;
    display: block;
    margin-bottom: 0;
  }
}

.footer-primary__logo-socials-container {
  margin-top: var(--5px);
  margin-left: var(--50px);
}
@media (min-width: 812px) {
  .footer-primary__logo-socials-container {
    margin-top: 0;
    margin-left: 0;
  }
}

.footer-primary__lists-container {
  flex: 1 1 auto;
}
@media (max-width: 811px) {
  .footer-primary__lists-container {
    width: 100%;
  }
}

.footer-primary__lists-container .editableContainer {
  width: 100%;
  min-height: 195px;
}
@media (min-width: 812px) {
  .footer-primary__lists-container .editableContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
}

.footer-primary__lists-container > div:first-child {
  flex: 1 1 auto;
}

.footer-primary__lists {
  width: 100%;
  min-height: 195px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.footer-primary b,
.footer-primary strong {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  font-weight: var(--fw-medium);
  margin-bottom: var(--9px);
}
@media (min-width: 812px) {
  .footer-primary b,
.footer-primary strong {
    min-height: var(--31px);
    display: inline-flex;
    align-items: center;
    margin-bottom: var(--25px);
  }
}
@media (min-width: 992px) {
  .footer-primary b,
.footer-primary strong {
    font-size: var(--18px);
  }
}

.footer-primary__first-list {
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: var(--25px);
}
@media (min-width: 1100px) {
  .footer-primary__first-list {
    width: 50%;
    max-width: 330px;
    margin-bottom: 0;
    padding-right: var(--50px);
  }
}

.footer-primary__second-list {
  width: 100%;
  flex: 0 0 auto;
}
@media (min-width: 812px) {
  .footer-primary__second-list {
    padding-right: var(--20px);
  }
}
@media (min-width: 1100px) {
  .footer-primary__second-list {
    width: 50%;
    max-width: 330px;
    padding-right: var(--50px);
  }
}

.footer-primary__newsletter {
  width: 100%;
  flex: 0 0 auto;
  margin-top: var(--25px);
}
@media (min-width: 812px) {
  .footer-primary__newsletter {
    width: 280px;
    max-width: none;
    margin-top: 0;
  }
}

.footer-primary__newsletter .wmpform,
.footer-primary__newsletter .wmpform:only-child {
  margin-top: 0;
}

.footer-primary__newsletter .button {
  text-align: left;
  margin-top: 22px;
}
.footer-primary__newsletter .button i.fal {
  transform: translateY(2px);
}

.footer-primary__newsletter .wmpform input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
  margin-top: 16px;
}
.footer-primary__newsletter .wmpform input[type=text] ~ label, .footer-primary__newsletter .wmpform input[type=search] ~ label, .footer-primary__newsletter .wmpform input[type=email] ~ label, .footer-primary__newsletter .wmpform input[type=password] ~ label, .footer-primary__newsletter .wmpform input[type=tel] ~ label, .footer-primary__newsletter .wmpform input[type=number] ~ label, .footer-primary__newsletter .wmpform select ~ label, .footer-primary__newsletter .wmpform textarea ~ label {
  font-size: var(--14px);
}
.footer-primary__newsletter .wmpform input[type=text]:focus ~ label, .footer-primary__newsletter .wmpform input[type=search]:focus ~ label, .footer-primary__newsletter .wmpform input[type=email]:focus ~ label, .footer-primary__newsletter .wmpform input[type=password]:focus ~ label, .footer-primary__newsletter .wmpform input[type=tel]:focus ~ label, .footer-primary__newsletter .wmpform input[type=number]:focus ~ label, .footer-primary__newsletter .wmpform select:focus ~ label, .footer-primary__newsletter .wmpform textarea:focus ~ label,
.footer-primary__newsletter .wmpform input[type=text]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform input[type=search]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform input[type=email]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform input[type=password]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform input[type=tel]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform input[type=number]:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform select:not(:placeholder-shown) ~ label, .footer-primary__newsletter .wmpform textarea:not(:placeholder-shown) ~ label {
  transform: translateY(-22px) scale(0.9);
}
.footer-primary__newsletter .wmpform input[type=text], .footer-primary__newsletter .wmpform input[type=search], .footer-primary__newsletter .wmpform input[type=email], .footer-primary__newsletter .wmpform input[type=password], .footer-primary__newsletter .wmpform input[type=tel], .footer-primary__newsletter .wmpform input[type=number], .footer-primary__newsletter .wmpform select, .footer-primary__newsletter .wmpform textarea {
  font-size: var(--14px);
  padding: 8px 0px 10px;
  height: 42px;
  line-height: 42px;
}
.footer-primary__newsletter .wmpform .dropdown-toggle {
  margin-top: 13px;
  padding: 8px 15px 0 0;
}
.footer-primary__newsletter .wmpform .dropdown-toggle .brand-label {
  font-size: var(--14px);
}

.footer-primary__newsletter .success-message {
  display: inline-block;
  color: var(--apt-success-1);
  font-weight: var(--fw-medium);
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-top: 22px;
}

.footer-secondary {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 21px 0;
}
@media (min-width: 1280px) {
  .footer-secondary {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-secondary__legal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .footer-secondary__legal {
    flex-direction: row;
    align-items: center;
  }
}

.footer-secondary__brands {
  align-self: flex-start;
  margin-top: 18px;
  margin-bottom: 58px;
}
@media (min-width: 992px) {
  .footer-secondary__brands {
    margin-top: 0;
    margin-bottom: 18px;
  }
}
@media (min-width: 1280px) {
  .footer-secondary__brands {
    margin-bottom: 0;
  }
}

.footer-logo {
  width: calc(100% - 1em);
  display: block;
  margin-bottom: var(--25px);
}

.footer-logo img,
.footer-logo svg {
  display: block;
  width: 29px;
}
@media (min-width: 992px) {
  .footer-logo img,
.footer-logo svg {
    width: 35px;
  }
}

.footer-social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: var(--24px);
  font-size: var(--16px);
  line-height: 20px;
}
@media (min-width: 812px) {
  .footer-social-list {
    margin-top: var(--32px);
    margin-left: 0;
  }
}

.footer-social-list li {
  width: 1em;
  margin-right: var(--25px);
}

.footer-social-list a {
  text-decoration: none;
}

.footer-social-list a i {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.footer-social-list a:hover i {
  opacity: 0.5;
}

.footer-links-list li {
  margin-bottom: var(--10px);
}

.footer-legal-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: var(--apt-grey-4);
}

.footer-legal-list li,
.footer-secondary__legal span {
  padding-right: var(--20px);
  padding-bottom: var(--5px);
}

.footer-secondary__legal span {
  padding-right: var(--24px);
}

.footer-legal-list .credit a,
.footer-legal-list .credit a:hover,
.footer-legal-list .credit a:focus,
.footer-legal-list .credit a:active {
  text-decoration: underline !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 1px;
  text-decoration-skip-ink: auto;
}

.footer-brands-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-brands-list li {
  padding-left: var(--12px);
}
.footer-brands-list li:first-child {
  padding-left: 0;
}
@media (min-width: 1280px) {
  .footer-brands-list li:first-child {
    padding-left: var(--12px);
  }
}

.footer-brands-list a {
  text-decoration: none;
  display: block;
}

.footer-brands-list a img {
  display: block;
  height: 28px;
  filter: grayscale(1);
  opacity: 0.3;
  transition: filter 0.2s linear, opacity 0.2s linear;
}

.footer-brands-list a:hover img {
  filter: grayscale(0);
  opacity: 1;
}

.footer-brand--audi a {
  margin-top: -0.1em;
  margin-right: -0.2em;
}

.footer-brand--audi a img {
  height: var(--26px);
}

.footer-brand--volkswagen a {
  margin-top: -0.7em;
  margin-right: 0.1em;
}

.footer-brand--volkswagen a img {
  height: var(--25px);
}

.footer-brand--seat a {
  margin-top: -0.1em;
}

.footer-brand--skoda a {
  margin-top: -0.2em;
}

.footer-brand--vw-bedrijfswagens a {
  margin-top: -0.2em;
}

.footer-primary__newsletter #naam #wmformfragment_naam {
  margin-top: 0;
}

.footer-primary__newsletter .wm-field-input p {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.dropdown-toggle {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 15px 0 0;
  min-height: 45px;
  line-height: 38px;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-radius: 0;
  box-shadow: 0 0 0 0;
  margin-top: 9px;
  color: var(--apt-grey-3);
  cursor: pointer;
}
.dropdown-toggle .brand-label {
  font-size: var(--14px);
  line-height: 1.55;
}
.dropdown-toggle .fal {
  position: absolute;
  right: 0;
  transition: all 0.2s ease-in-out;
}
.dropdown-toggle .rotation {
  transform: rotate(180deg);
}
.dropdown-toggle--active {
  border-color: var(--apt-primary);
}
.dropdown-toggle .pill {
  background-color: var(--apt-grey-8);
  border-radius: 30px;
  padding: 2px 10px;
  color: var(--apt-grey-2);
  height: 20px;
  display: flex;
  align-items: center;
  margin: 0 2px 4px 2px;
  font-size: var(--12px);
  line-height: 18px;
  font-weight: var(--fw-semibold);
}

.dropdown-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  padding: 15px;
}
.dropdown-content .content-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(45% + 10px);
  padding: 10px 0;
  margin-right: -10px;
}
.dropdown-content .content-wrapper input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(1em + 2px);
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.dropdown-content .content-wrapper label {
  display: flex;
  position: relative;
  color: var(--apt-grey-3);
  font-size: 13px;
}
.dropdown-content .content-wrapper label:before {
  content: "";
  position: relative;
  top: 0em;
  left: 1px;
  width: 1em;
  height: 1em;
  min-width: 15px;
  min-height: 15px;
  display: block;
  line-height: 1em;
  box-shadow: 0 0 0 1px var(--apt-grey-4);
  border-radius: 0.15em;
  color: var(--apt-black);
  background-color: var(--apt-white);
  text-align: center;
  pointer-events: none;
  margin-top: 1px;
  margin-right: 0.8em;
  flex: 0 0 auto;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0, 0, 1, 500);
}
.dropdown-content .content-wrapper input:checked ~ label::before {
  background-color: var(--apt-primary);
  box-shadow: 0 0 0 1px var(--apt-primary);
  color: var(--apt-white);
  transform: scale(1.001);
  transition: transform 0.1s cubic-bezier(0, 0, 1, -500);
}
.dropdown-content .content-wrapper label:after {
  content: "";
  position: absolute;
  top: 0em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  min-width: 17px;
  min-height: 17px;
  display: block;
  line-height: calc(1em + 2px);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011%209%22%3E%3Cpath%20d%3D%22M9.84932246%201.87587177L6.60171214%205.53819383%204.57195102%207.82715036C4.49822304%207.94967669%204.37987023%208%204.2595772%208c-.12029302%200-.2382578-.05128602-.32925365-.15383618l-2.79390253-3.1506769c-.1818947-.20512219-.1818947-.53736545%200-.74259704.1818947-.20523159.4765156-.2051222.6585073%200L4.2595772%206.73316533l4.94753573-5.57932368c.18189469-.2051222.47651559-.2051222.6585073%200%20.18199171.20512219.18160366.53605266-.01629777.72203012z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%22.64%22%2F%3E%3C%2Fsvg%3E");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}
.dropdown-content .content-wrapper input:checked ~ label::after {
  opacity: 1;
}

@media (max-width: 511px) {
  .fancybox-skin .dropdown-content .content-wrapper {
    width: 100%;
  }
}

body:has(.floating-toggle) .footer,
body:has(#autochat-chat-start--bubble) .footer {
  padding-bottom: 70px;
}

.person-scroll-widget {
  position: relative;
  width: 100%;
  max-width: 320px;
  z-index: 0;
}
.person-scroll-widget:before {
  content: "";
  display: block;
  padding-bottom: 133.33333%;
}

.person-scroll-widget .person-scroll-widget__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.action-cards-list-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 34px;
}

.action-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--25px);
}
@media (min-width: 512px) {
  .action-cards-list-container > header {
    margin-bottom: var(--31px);
  }
}
@media (min-width: 1200px) {
  .action-cards-list-container > header {
    flex-wrap: nowrap;
  }
}

.action-cards-list-container .action-card-listing-header h2 {
  padding-right: 1.5em;
  margin-bottom: 0;
}

.action-cards-list-container .action-card-listing-header h1 {
  padding-right: 0.5em;
  margin-bottom: 0;
}
@media (max-width: 899px) {
  .action-cards-list-container .action-card-listing-header h1 {
    font-size: var(--17px);
    line-height: 22px;
  }
}

.action-cards-results-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  padding-top: 1em;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--apt-grey-6);
}
@media (min-width: 512px) {
  .action-cards-results-header {
    flex-direction: row;
    align-items: baseline;
  }
}
.action-cards-results-header .heading {
  padding-right: 0.5em;
}

.action-cards-results-header__quantity {
  flex: 0 0 auto;
  font-size: var(--16px);
  line-height: 1.25;
  color: var(--apt-grey-3);
  font-weight: var(--fw-regular);
  margin-right: 2em;
  margin-bottom: 1em;
}
@media (min-width: 512px) {
  .action-cards-results-header__quantity {
    margin-bottom: 0;
  }
}
.action-cards-results-header__quantity > span:first-child {
  color: var(--apt-grey-2);
  font-weight: var(--fw-medium);
}

.action-cards-results-header .tag-list {
  flex: 1 1 auto;
}

.action-cards-list {
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 920px) {
  .action-cards-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

.action-cards-list .button--primary {
  width: 100%;
  min-height: 40px;
  border-radius: 24px;
  padding: 6px 26px 8px;
  font-weight: var(--fw-medium);
  text-decoration: none;
  box-shadow: none;
  transform: scale(1.000001);
}

.action-cards-list .button--secondary {
  width: 100%;
}

@media (max-width: 919px) {
  .action-cards-list-container .action-cards-list {
    width: calc(100% + 20px);
  }
}

.action-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;
}
@media (min-width: 610px) {
  .action-cards-list-item {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .action-cards-list-item {
    width: 33.333%;
  }
}
@media (min-width: 1440px) {
  .action-cards-list-item {
    width: 25%;
  }
}

.action-cards-list-item .news-card figure {
  width: 100%;
  flex: 1 1 auto;
  padding: 0;
}
.action-cards-list-item .news-card figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.action-cards-list-item .news-card .card__content {
  flex: 0 0 auto;
}

@media (max-width: 659px) {
  .action-cards-list-item .action-card-featured {
    max-height: 700px;
  }
}
@media (min-width: 660px) {
  .action-cards-list-item .action-card-featured {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }
}
.action-cards-list-item .action-card-featured figure {
  width: 100%;
  flex: 1 1 auto;
  padding: 0;
}
.action-cards-list-item .action-card-featured figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.action-cards-list-item .action-card-featured footer {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.action-cards-list-item .action-card-featured .card__content {
  flex: 0 0 auto;
}

.featured-card-link-wrapper {
  position: relative;
}

@media (min-width: 1200px) {
  .action-cards-list-item.swiper-slide {
    width: 100%;
  }
}

.action-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.action-card .card__content > span {
  color: var(--apt-grey-4);
}

.action-card .card__content > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.action-card .card__content > ul li {
  padding-right: 0.5em;
  margin-right: 0.5em;
  position: relative;
}

.action-card .card__content > ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.action-card .card__content .heading-3 {
  margin-bottom: 15px;
  line-height: 27px;
  margin-left: -6px;
}
.action-card .card__content .heading-3 > span {
  background-image: linear-gradient(white, white);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 100%;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding-left: 6px;
  padding-right: 6px;
}

.action-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.action-card {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 659px) {
  .action-cards-list-item--featured {
    position: relative;
    height: 0;
    padding-top: calc(135% + 30px);
  }
}

@media (max-width: 659px) {
  .action-cards-list-item--featured .action-card {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 30px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
}

.action-cards-list-item--featured .action-card figure {
  height: 100%;
  border-bottom-right-radius: var(--10px);
  border-bottom-left-radius: var(--10px);
}
.action-cards-list-item--featured .action-card figure img {
  height: 100%;
  aspect-ratio: none;
}

.action-cards-list-item--featured .action-card .card__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.action-card-listing-wrapper {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -18px;
}

.action-card-listing {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.action-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;
}
@media (max-width: 899px) {
  .action-card-listing > span:first-child {
    font-size: var(--17px);
  }
}

.action-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.action-card-listing > span:last-child s {
  font-size: var(--16px);
  line-height: 28px;
  letter-spacing: 0.89px;
  display: inline-block;
}
@media (min-width: 992px) {
  .action-card-listing > span:last-child s {
    padding-right: 5px;
  }
}

.action-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
  font-size: var(--18px);
  letter-spacing: 1px;
}
@media (max-width: 899px) {
  .action-card-listing > span:last-child strong {
    font-size: var(--17px);
  }
}
@media (min-width: 992px) {
  .action-card-listing > span:last-child strong {
    padding-left: 2px;
  }
}

.action-cards-list-item .action-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.action-cards-list-item .action-card figure,
.action-cards-list-item .action-card .offer-card-slider-container {
  flex: 0 0 auto;
}

.action-cards-list-item .action-card .offer-card-slider-container {
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.action-cards-list-item .action-card .action-card-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.action-cards-list-item .action-card .action-card-content-container .tooltip-button {
  position: absolute;
  top: 22px;
  right: 27px;
  z-index: 1;
}

.action-cards-list-item .action-card .action-card-content-container .flex-grow-column {
  z-index: 0;
}

.action-cards-list-item .action-card .card__content {
  flex: 1 1 auto;
  z-index: 1;
}
@media (max-width: 511px) {
  .action-cards-list-item .action-card .card__content {
    padding-top: 22px;
  }
}

.action-cards-list-item .action-card-listing-wrapper {
  flex: 0 0 auto;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}

.action-card-listing-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.action-card-listing-header > span:first-of-type {
  color: var(--apt-grey-4);
  flex: 1 1 auto;
}
.action-card-listing-header .heading {
  font-weight: var(--fw-semibold);
}
.action-card-listing-header ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.action-card-listing-header ul li {
  white-space: nowrap;
  margin-right: 5px;
}
.action-card-listing-header ul li::after {
  margin-left: 4px;
  content: "|";
}
.action-card-listing-header ul li:last-child::after {
  content: "";
}
.action-card-listing-header .tooltip-button {
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 660px) {
  .action-card-listing-header .tooltip-button {
    top: 0.5em;
  }
}

.action-card-listing-header .gallery-grid__toggle-container {
  position: static;
  width: 100%;
  height: 20px;
  color: var(--apt-primary);
  text-decoration: underline;
  font-size: var(--12px);
  line-height: 1.667;
  font-weight: var(--fw-medium);
  margin-bottom: var(--13px);
}

@media (max-width: 767px) {
  .gallery-grid__primary--360 {
    margin-bottom: 0;
  }
}

.action-price-listing {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid var(--apt-grey-7);
  padding-top: 18px;
  padding-bottom: 16px;
  margin-top: 13px;
}
.action-price-listing__offer {
  font-size: var(--15px);
  line-height: 1.15;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-right: 0.5em;
}
.action-price-listing__offer > strong {
  display: inline-flex;
  justify-content: flex-start;
  align-items: baseline;
  font-weight: var(--fw-semibold);
}
.action-price-listing__offer > strong > span {
  font-size: var(--25px);
  line-height: 1.25;
  margin-left: 0.2em;
}
.action-price-listing__profit {
  font-size: var(--10px);
  line-height: 1.1;
  flex: 0 0 auto;
  background-color: var(--apt-action);
  border-radius: 4.5px;
  color: var(--apt-white);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9px 8px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.action-price-listing__profit > span {
  font-size: var(--18px);
  line-height: 1;
  margin-top: 4px;
}

.action-cards-results-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 13px;
}
@media (min-width: 768px) {
  .action-cards-results-filter {
    flex-direction: row;
  }
}
.action-cards-results-filter__list {
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .action-cards-results-filter__list {
    margin-bottom: 0;
  }
}
.action-cards-results-filter__list span.tag {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  font-size: var(--14px);
  font-weight: var(--fw-medium);
  color: var(--apt-grey-4) !important;
  border-color: var(--apt-grey-4) !important;
}
.action-cards-results-filter__list span.tag--active {
  color: var(--apt-grey-2) !important;
  border-color: var(--apt-grey-2) !important;
}
.action-cards-results-filter__list .tag-list {
  margin-right: 1em;
}
.action-cards-results-filter__list .tag--filter {
  font-size: var(--16px);
  font-weight: var(--fw-medium);
}
.action-cards-results-filter__sort {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--14px);
  line-height: 1.6;
  color: var(--apt-grey-3);
  font-weight: var(--fw-medium);
}
.action-cards-results-filter__sort label {
  position: relative;
  display: inline-flex;
  align-items: baseline;
}
.action-cards-results-filter__sort label > span {
  color: var(--apt-grey-2);
  text-transform: lowercase;
  font-size: inherit;
  font-weight: inherit;
}
.action-cards-results-filter select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: auto;
  background: none;
  border: 0;
  box-shadow: none;
  color: transparent;
  font-size: var(--16px);
  /* 18 */
  line-height: 1.15;
  z-index: 1;
}
.action-cards-results-filter option {
  color: black;
}
.action-cards-results-filter > i {
  display: inline-flex;
  position: relative;
  top: 0.05em;
  text-align: center;
  pointer-events: none;
}

.action-cta .card__content {
  padding-top: 0;
  justify-content: stretch;
  align-items: stretch;
}
.action-cta__content {
  width: 100%;
  display: flex;
  flex: 1 1 auto;
}
.action-cta__content > div {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.action-cta__button {
  flex: 0 0 auto;
}

.action-cta .heading-2,
.action-cta h1 {
  display: block;
  max-width: 250px;
  font-size: var(--22px);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: normal;
  margin-bottom: 25px;
  padding: 0;
}
@media (min-width: 512px) {
  .action-cta .heading-2,
.action-cta h1 {
    font-size: var(--28px);
    line-height: 30px;
    letter-spacing: -0.12px;
  }
}
@media (min-width: 610px) {
  .action-cta .heading-2,
.action-cta h1 {
    max-width: none;
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .action-cta .heading-2,
.action-cta h1 {
    font-size: var(--40px);
    line-height: 48px;
    letter-spacing: -0.5px;
  }
}
.action-cta .heading-2 strong,
.action-cta h1 strong {
  font-weight: var(--fw-semibold);
}

.action-cta .wmpform {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.action-cards-list-item .action-card-wrapper,
.action-cards-list-item .action-card-wrapper > .block-default,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default > .wrapper,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default > .wrapper > .editableContainer {
  height: 100%;
}

.big-steps {
  counter-reset: steps;
}

.big-steps li:first-child h3::before,
.big-steps li:first-child .heading-3::before {
  padding-right: 0.075em;
}

.big-steps li h3::before,
.big-steps li .heading-3::before {
  content: counter(steps);
  position: relative;
  top: -0.1em;
  counter-increment: steps;
  background-color: var(--apt-primary);
  color: var(--apt-white);
  border-radius: 100%;
  width: var(--25px);
  height: var(--25px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--16px);
  font-weight: var(--fw-medium);
  margin-right: var(--14px);
}

.big-steps h3,
.big-steps .heading-3 {
  display: inline-flex;
  align-items: baseline;
}

.content-cards-list-container {
  position: relative;
}

.content-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--10px);
}
@media (min-width: 512px) {
  .content-cards-list-container header {
    margin-bottom: var(--30px);
  }
}

.content-cards-list-container .card {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.content-cards-list-container .card figure {
  flex: 0 0 auto;
}

.content-cards-list-container .card .card__content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
}

.content-cards-list-container .card .card__content .heading {
  flex: 1 1 auto;
}

.content-cards-list-container h1,
.content-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.content-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.content-card .card__content .heading {
  margin-bottom: 32px;
}

.content-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.data-list {
  display: table;
  table-layout: fixed;
  width: 100%;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  border-collapse: collapse;
  padding: 0;
  margin: 0 0 36px;
  border: 0;
}

.data-list tbody {
  display: grid;
  width: calc(100% + 24px);
  margin-left: -12px;
}
@media (min-width: 768px) {
  .data-list tbody {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .data-list tbody {
    display: block;
  }
}
@media (min-width: 1254px) {
  .data-list tbody {
    display: grid;
  }
}
@supports not (padding: max(1vw, 100px)) {
  @media (min-width: 768px) {
    .data-list tbody {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }
}

.detail-content .data-list tbody {
  display: grid;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (min-width: 768px) {
  .detail-content .data-list tbody {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }
}
@supports not (padding: max(1vw, 100px)) {
  @media (min-width: 768px) {
    .detail-content .data-list tbody {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }
}
@media (min-width: 992px) {
  .detail-content .data-list tbody {
    display: grid;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.data-list tr {
  width: calc(100% - 24px);
  height: 100%;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: flex-start;
}

.detail-content .data-list tr {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
}
@media (min-width: 992px) {
  .detail-content .data-list tr {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

.data-list tr th,
.data-list tr td {
  height: 100%;
  margin: 0;
  padding-top: 1.35em;
  padding-bottom: 1.45em;
  border-bottom: 1px solid var(--apt-grey-7);
  padding-right: 1em;
  font-weight: var(--fw-regular);
  text-align: left;
}

.data-list tr:nth-child(1) {
  border-top: 1px solid var(--apt-grey-7);
}

@media (min-width: 768px) and (max-width: 991px), (min-width: 1138px) {
  .data-list tr:nth-child(2) {
    border-top: 1px solid var(--apt-grey-7);
  }
}

.detail-content .data-list tr:nth-child(1),
.detail-content .data-list tr:nth-child(2) {
  padding-top: 1.15em;
}

.data-list tr th {
  width: 110px;
}

.data-list tr td {
  width: calc(100% - 110px);
}

.datepicker {
  max-width: 517px;
}
.datepicker .qs-datepicker-container {
  width: 100%;
  font-family: var(--fellix);
  border-color: var(--apt-grey-7);
  border-radius: 0;
  box-shadow: none;
  padding: 17px 0;
}
.datepicker .qs-controls {
  position: relative;
  width: calc(((100% - ((100% / 7) - 38px)) - (0.821em) * 2) - 4.5%);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: none;
  filter: blur(0);
  transition: filter 0.3s;
  padding: 12px 0 12px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid var(--apt-grey-7);
}
@media (min-width: 375px) {
  .datepicker .qs-controls {
    width: calc(((100% - ((100% / 7) - 38px)) - (1.85em) * 2) - 1%);
  }
}
.datepicker .qs-arrow {
  width: 1.5em;
}
.datepicker .qs-arrow.qs-left {
  left: -0.5em;
}
.datepicker .qs-arrow.qs-left::after {
  content: "";
  font-family: var(--fa);
  font-weight: var(--fw-light);
  font-size: var(--20px);
  border: 0;
  transform: translate(50%, -50%);
  width: 1.5em;
  text-align: center;
}
.datepicker .qs-arrow.qs-right {
  right: -0.25em;
}
.datepicker .qs-arrow.qs-right::after {
  content: "";
  font-family: var(--fa);
  font-weight: var(--fw-light);
  font-size: var(--20px);
  border: 0;
  transform: translate(-50%, -50%);
  width: 1.5em;
  text-align: center;
}
.datepicker .qs-squares {
  padding-top: 14px;
  padding-bottom: 10px;
  padding-left: 0.821em;
  padding-right: 0.821em;
}
@media (min-width: 375px) {
  .datepicker .qs-squares {
    padding-top: 28px;
    padding-left: 1.65em;
    padding-right: 1.65em;
  }
}
.datepicker .qs-month-year {
  position: absolute;
  left: 0;
}
.datepicker .qs-month-year:hover {
  border: 0;
}
.datepicker .qs-month, .datepicker .qs-year {
  font-size: var(--20px);
  line-height: 27px;
  font-weight: var(--fw-medium);
}
@media (min-width: 375px) {
  .datepicker .qs-month, .datepicker .qs-year {
    font-size: var(--24px);
    line-height: 30px;
  }
}
.datepicker .qs-year {
  display: none;
}
.datepicker .qs-day {
  color: var(--apt-grey-2);
  font-weight: normal;
  margin-bottom: -2px;
}
@media (min-width: 375px) {
  .datepicker .qs-day {
    margin-bottom: 7px;
  }
}
.datepicker .qs-square {
  position: relative;
  z-index: 0;
  height: 38px;
  font-size: var(--11px);
  line-height: 38px;
}
@media (min-width: 375px) {
  .datepicker .qs-square {
    font-size: var(--14px);
  }
}
.datepicker .qs-square.qs-day {
  font-size: var(--11px);
}
.datepicker .qs-current {
  font-weight: normal;
  text-decoration: none;
}
.datepicker .qs-active {
  background: none;
  color: var(--apt-white);
  font-weight: var(--fw-medium);
}
.datepicker .qs-active::before {
  content: "";
  position: absolute;
  top: 0;
  width: 38px;
  height: 100%;
  z-index: -1;
  background: var(--apt-primary);
  border-radius: 38px;
}
.datepicker .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
  background: none;
}
.datepicker .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover::before {
  content: "";
  position: absolute;
  top: 0;
  width: 38px;
  height: 100%;
  z-index: -1;
  background: var(--apt-grey-8);
  border-radius: 38px;
}

.datepicker-scroll-target {
  position: relative;
  top: -130px;
  width: 0px;
  height: 0px;
}

.dialogs {
  margin-top: 20px;
  margin-bottom: 43px;
  border-top: 1px solid var(--apt-grey-7);
}
@media (min-width: 768px) {
  .dialogs {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

.dialog {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 var(--6px);
  border-bottom: 1px solid var(--apt-grey-7);
  box-shadow: 0 0 0 0;
  min-height: 80px;
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 40px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.dialog__title:hover + .dialog__content {
  border-bottom-color: var(--apt-grey-2);
}

.dialog__title:focus + .dialog__content,
.dialog__title:active + .dialog__content {
  border-bottom-color: var(--apt-grey-1);
  color: inherit;
}

.dialog__title:focus {
  border-color: var(--apt-grey-7);
}

.dialog__title .far {
  position: relative;
  width: var(--21px);
  right: var(--24px);
  color: var(--apt-grey-3);
  font-size: var(--18px);
  align-self: center;
  font-weight: var(--fw-light);
}

.dialog .dialog__title .fa-chevron-down {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
}

.dialog--toggled .dialog__title .fa-chevron-down {
  transform: rotate(180deg);
}

.dialog .dialog__title .fa-times {
  display: none;
}

@supports (padding: max(0px)) {
  .dialog__content {
    transition: max-height 0.2s linear;
  }
}
.dialog:not(.dialog--toggled) .dialog__content {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .dialog--toggled .dialog__content {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}
.dialog__content span {
  display: block;
}

.dialog--toggled {
  position: relative;
  z-index: 2;
}

.dialog--toggled .dialog__title {
  border-bottom-color: var(--transparent);
}

.filter-dropdown {
  position: relative;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
}

.filter-dialog {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.filter-dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: var(--12px) 0;
  border-bottom: 1px solid var(--apt-grey-7);
  box-shadow: 0 0 0 0;
  height: var(--42px);
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.filter-dialog__title--filter-active {
  border-bottom-color: var(--apt-primary);
}

.filter-dialog__content-wrapper {
  padding: 22px 0 8px;
}

.filter-dialog__content-wrapper .form__group:first-child {
  margin-top: 0;
}

.filter-dialog__content-wrapper .checkbox-wrapper {
  margin-top: 0;
}

.filter-dialog__content-wrapper .checkbox-wrapper ul li {
  margin-bottom: 18px;
}

.filter-dialog__title:focus + .filter-dialog__content,
.filter-dialog__title:active + .filter-dialog__content {
  color: inherit;
}

.filter-dialog__title .far {
  color: var(--apt-black);
  align-self: center;
}

@supports (padding: max(0px)) {
  .filter-dialog__content {
    transition: max-height 0.2s linear;
  }
}
.filter-dialog:not(.filter-dialog--toggled) .filter-dialog__content {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .filter-dialog--toggled .filter-dialog__content {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}
.filter-dialog__content span {
  display: block;
}

.filter-dialog--toggled {
  position: relative;
  z-index: 2;
}

.filter-dialog .filter-dialog__title:not(.filter-dialog__title--filter-active):focus {
  border-bottom-color: var(--apt-grey-7);
}

.filter-dialog .filter-dialog__title .far {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
  font-size: var(--18px);
  line-height: 18px;
  font-weight: var(--fw-light);
  color: var(--apt-grey-3);
}

.filter-dialog .filter-dialog__title .fa-times {
  display: none;
}

.filter-dialog--toggled .filter-dialog__title .far {
  transform: rotate(180deg);
}

.wmpform .filter-dialog .form__checkbox {
  margin-top: 10px;
  margin-bottom: 15px;
}

.dropdown {
  position: relative;
  width: 100%;
  margin-bottom: var(--39px);
}

.dropdown--large {
  margin-top: var(--17px);
  margin-bottom: var(--10px);
}

.dropdown--shown {
  position: relative;
  z-index: 3;
}

.dropdown__toggle {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 12px;
  border-bottom: 1px solid var(--apt-grey-4);
  box-shadow: 0 0 0 0;
  height: var(--36px);
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0.3px;
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
}
.dropdown__toggle::after {
  content: "⌃";
  font-family: var(--fa);
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
  transform-origin: center;
  transition: transform 0.1s ease;
  position: absolute;
  bottom: 16px;
  right: 20px;
  pointer-events: none;
  color: var(--apt-grey-3);
  line-height: 0;
  text-align: center;
  font-weight: var(--fw-light);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.dropdown--large .dropdown__toggle {
  height: var(--56px);
  padding: 0 0 var(--25px);
}

.dropdown__menu {
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  background-color: var(--apt-white);
  border: 1px solid var(--apt-grey-7);
  z-index: 0;
  overflow: auto;
  max-height: 264px;
}
.dropdown__menu > * {
  position: relative;
  width: 100%;
  height: var(--40px);
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 7px 9px 4px;
  margin: 0;
  color: var(--apt-black);
  border: 0;
  font-size: var(--16px);
  line-height: 1.15;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
  transition: background-color 0.2s linear;
}
.dropdown__menu > *:hover {
  background-color: var(--apt-grey-9);
}
.dropdown__menu > *:focus {
  background-color: var(--apt-grey-9);
}
.dropdown__menu img {
  margin-right: 12px;
}

.tab-optimized .dropdown__menu > *:focus {
  background-color: lightgray;
}

.dropdown--shown .dropdown__toggle::after {
  transform: rotate(0deg);
}
.dropdown--shown > .dropdown__menu {
  height: auto;
  opacity: 1;
  pointer-events: auto;
}
.dropdown--shown > .dropdown__menu:focus-within {
  z-index: 1;
}
.dropdown--shown .dropdown__option:focus-visible {
  box-shadow: 0 0 0 2px var(--apt-primary) !important;
}

.mobile-dropdown {
  position: relative;
}
.mobile-dropdown::after {
  content: "⌃";
  font-family: var(--fa);
  width: 12px;
  height: 12px;
  transform: rotate(180deg);
  transform-origin: center;
  transition: transform 0.1s ease;
  position: absolute;
  bottom: 16px;
  right: 20px;
  pointer-events: none;
  color: var(--apt-grey-3);
  line-height: 0;
  text-align: center;
  font-weight: var(--fw-light);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.mobile-dropdown select {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 7px 0px 4px;
  letter-spacing: normal;
  border: 0;
  border-bottom: 1px solid var(--apt-grey-4);
  border-radius: 0;
  height: var(--40px);
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--16px);
  line-height: 1.15;
  color: var(--apt-black);
  background-color: inherit;
  appearance: none;
  text-indent: 0;
  z-index: 1;
}
.mobile-dropdown select option:not([hidden]) {
  padding: 2px;
  display: block;
}

@media (hover: none), (pointer: none), (max-width: 767px) {
  [x-cloak=desktop],
.desktop-dropdown {
    display: none;
  }
}
@media (hover: hover) and (min-width: 767px), (pointer: fine) and (min-width: 767px) {
  [x-cloak=mobile],
.mobile-dropdown {
    display: none;
  }
}
.wm-field-dropdown--inline {
  margin-bottom: 0 !important;
}

.dropdown-wrapper--crop {
  height: var(--40px);
}

.wmpform .from-grid-item--self-end {
  align-self: flex-end;
}

.wmpform .dropdown-wrapper--crop select:not(:placeholder-shown) ~ label {
  transform: translateY(-39px) scale(0.78);
}

.dropdown-wrapper .wm-field-input.wm-field-input {
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: inherit;
  border: 0;
  border-bottom: 1px solid var(--apt-grey-4);
  border-radius: 0;
  color: var(--apt-black);
  font-family: inherit;
  font-size: var(--18px);
  height: var(--40px);
  justify-content: stretch;
  letter-spacing: normal;
  line-height: 28px;
  margin: 0;
  padding: 0 34px 12px 0;
  text-indent: 0;
  touch-action: manipulation;
  user-select: none;
  z-index: 1;
}
.dropdown-wrapper .wm-field-input.wm-field-input > span {
  position: absolute;
  pointer-events: none;
}
.dropdown-wrapper .wm-field-input.wm-field-input select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: auto;
  background: none;
  border: 0;
  box-shadow: none;
  color: transparent;
  z-index: 1;
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-size: var(--16px);
  /*18 */
  line-height: 1.15;
  outline: revert;
}
.dropdown-wrapper .wm-field-input.wm-field-input option {
  color: black;
}
.dropdown-wrapper .wm-field-input.wm-field-input::after {
  content: "⌃";
  position: absolute;
  right: 20px;
  bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  font-family: var(--fa);
  font-weight: var(--fw-light);
  line-height: 0;
  color: var(--apt-grey-3);
  pointer-events: none;
  text-align: center;
  transform: rotate(180deg);
  transform-origin: center;
  transition: transform 0.1s ease;
  z-index: 1;
}

.dropdown-wrapper {
  position: relative;
}
.dropdown-wrapper--has-placeholder .wm-field-input.wm-field-input > span {
  color: var(--apt-grey-3) !important;
}
.dropdown-wrapper--has-value .wm-field-input.wm-field-input > span {
  color: var(--apt-black) !important;
}

.info-toggle-select--mobile.dropdown-wrapper select {
  appearance: none !important;
}

_::-webkit-full-page-media, _:future, :root select {
  appearance: none !important;
}

.embed-figure {
  margin-bottom: 78px;
}
@media (min-width: 1024px) {
  .embed-figure {
    margin-bottom: 158px;
  }
}

.embed-lightbox-container {
  width: calc(100% + 0px);
  margin-left: 0px;
}
@media (min-width: 920px) {
  .embed-lightbox-container {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.embed-lightbox-toggle {
  position: relative;
  display: block;
  width: 100%;
  max-width: 140vh;
  padding-top: 50px;
  margin-top: -50px;
  margin-left: auto;
  margin-bottom: 18px;
  margin-right: auto;
  padding-right: 10px;
  padding-bottom: 50px;
  padding-left: 10px;
}
@media (min-width: 992px) {
  .embed-lightbox-toggle {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .embed-lightbox-toggle {
    margin-bottom: 108px;
  }
}
@supports not (aspect-ratio: 3/2) {
  .embed-lightbox-toggle {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc((2 / 3) * 100%);
  }
}
@media (min-width: 768px) {
  @supports not (aspect-ratio: 3/2) {
    .embed-lightbox-toggle {
      padding-bottom: calc((var(--aspect-height, 9) / var(--aspect-width, 16)) * 100%);
    }
  }
}

.embed-lightbox-toggle-align {
  position: relative;
}

.embed-lightbox-toggle-align .btn-wrap {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  transform: translateY(-50%);
}

.embed-lightbox-toggle-align .btn-wrap .button {
  min-width: 0;
  width: 50px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

.embed-lightbox-toggle-align:focus-visible .btn-wrap .button {
  border-radius: 25px;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  outline: none;
  text-decoration: none;
}

.embed-lightbox-toggle.embed-lightbox-toggle:focus-visible,
.embed-lightbox-toggle-align.embed-lightbox-toggle-align:focus-visible {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: inherit;
}

.embed-lightbox-toggle .img-wrap {
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
  display: block;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  object-fit: cover;
  overflow: hidden;
}
@media (min-width: 768px) {
  .embed-lightbox-toggle .img-wrap {
    aspect-ratio: var(--aspect-width, 16)/var(--aspect-height, 9);
  }
}
@supports not (aspect-ratio: 3/2) {
  .embed-lightbox-toggle .img-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
}

.embed-lightbox-toggle > .btn-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.embed-lightbox-toggle .btn-wrap i {
  display: inline-block;
  padding-right: 20px;
}

.embed-lightbox-toggle:focus-visible .button {
  outline: none;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  text-decoration: none;
  border-radius: 25px;
}

.embed-lightbox-toggle .btn-wrap .button {
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  width: max-content;
  max-width: 400px;
}

.embed-lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100vw;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.3s linear, left 0s linear 0.3s;
}

.embed-lightbox--shown {
  left: 0;
  opacity: 1;
  transition: opacity 0.3s linear, left 0s linear 0s;
}

.embed-lightbox .video {
  width: 100%;
  max-width: 1250px;
  z-index: 2;
}
@media (min-width: 1440px) {
  .embed-lightbox .video {
    position: relative;
  }
}

.embed-lightbox-align {
  width: 100%;
  max-width: 140vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0px 25px;
}
@media (min-width: 1440px) {
  .embed-lightbox-align {
    max-width: 1300px;
  }
}

.embed-lightbox-align button {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--apt-white);
  z-index: 2;
  border-radius: 50px;
  background-color: var(--apt-black);
}
@media (min-width: 1440px) {
  .embed-lightbox-align button {
    top: -75px;
    right: -75px;
  }
}

.embed-lightbox-align button:focus-visible {
  border-radius: 50px;
}

.embed-lightbox-close-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.embed-figure img {
  width: 100%;
}

.embed-figure figcaption {
  font-size: var(--14px);
  line-height: 20px;
  margin-top: 1.5em;
  margin-bottom: 1em;
  text-align: center;
  color: var(--apt-grey-2);
}

@supports not (aspect-ratio: 3/2) {
  .embed-figure .video,
.embed-lightbox .video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc((var(--aspect-height, 2) / var(--aspect-width, 3)) * 100%);
  }
}

.embed-figure .video iframe,
.embed-figure .video video,
.embed-lightbox .video iframe,
.embed-lightbox .video video {
  width: 100%;
  height: auto;
  aspect-ratio: var(--aspect-width, 3)/var(--aspect-height, 2);
  display: block;
}
@supports not (aspect-ratio: 3/2) {
  .embed-figure .video iframe,
.embed-figure .video video,
.embed-lightbox .video iframe,
.embed-lightbox .video video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
}

.expandable-wrapper {
  position: relative;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 42px;
}
@media (min-width: 768px) {
  .expandable-wrapper {
    margin-top: 25px;
    margin-bottom: 70px;
  }
}

.expandable {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.expandable__button {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  user-select: none;
  touch-action: manipulation;
  box-shadow: 0 0 0 0;
  padding: 0 0 0;
  margin: var(--16px) 0;
  font-family: inherit;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-grey-3);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.expandable__visible {
  overflow: hidden;
}

.expandable__visible p:last-of-type {
  margin-bottom: 0.7em;
}

.expandable__button:focus + .expandable__hidden,
.expandable__button:active + .expandable__hidden {
  color: inherit;
}

.expandable__button .fal {
  color: var(--apt-black);
  margin-left: auto;
  padding-left: var(--8px);
  padding-right: var(--8px);
}

@supports (padding: max(0px)) {
  .expandable__hidden {
    transition: max-height 0.2s linear;
  }
}
.expandable:not(.expandable--toggled) .expandable__hidden {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .expandable--toggled .expandable__hidden {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}
.expandable__hidden span {
  display: block;
}

.expandable--toggled {
  position: relative;
  z-index: 2;
}

.expandable .expandable__button:focus {
  border-bottom-color: var(--apt-grey-7);
}

.expandable .expandable__button .fal {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
  font-size: var(--14px);
  line-height: 14px;
  color: var(--apt-grey-3);
}

.expandable--toggled .expandable__button .fal {
  transform: rotate(180deg);
}

.expandable__hidden-content-wrapper {
  padding: 0 0 8px;
}

.wmpform {
  width: 100%;
  margin-top: 52px;
}
.wmpform:only-child {
  margin-top: 5px;
}
.wmpform::before {
  content: "";
  clear: both;
  display: table;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number],
.wmpform select,
.wmpform textarea {
  appearance: none;
  display: block;
  padding: 10px 0 12px 0;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: var(--18px);
  letter-spacing: normal;
  color: var(--apt-black);
  background: none;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number] {
  word-break: normal;
}

.wmpform textarea {
  line-height: 30px;
  margin: 39px 0 0;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number],
.wmpform select {
  line-height: 50px;
  margin: 29px 0 0;
}

.wmpform input[type=date],
.wmpform input[type=time] {
  display: block;
}

.wmpform input::-webkit-date-and-time-value {
  text-align: left !important;
}

.wmpform input[type=text]:focus-visible,
.wmpform input[type=search]:focus-visible,
.wmpform input[type=email]:focus-visible,
.wmpform input[type=password]:focus-visible,
.wmpform input[type=tel]:focus-visible,
.wmpform input[type=date]:focus-visible,
.wmpform input[type=time]:focus-visible,
.wmpform input[type=number]:focus-visible,
.wmpform select:focus-visible,
.wmpform textarea:focus-visible {
  outline: none;
}
.wmpform input[type=text]:focus,
.wmpform input[type=search]:focus,
.wmpform input[type=email]:focus,
.wmpform input[type=password]:focus,
.wmpform input[type=tel]:focus,
.wmpform input[type=date]:focus,
.wmpform input[type=time]:focus,
.wmpform input[type=number]:focus,
.wmpform select:focus,
.wmpform textarea:focus {
  border-color: var(--apt-primary);
}
.wmpform input[type=text]:disabled,
.wmpform input[type=search]:disabled,
.wmpform input[type=email]:disabled,
.wmpform input[type=password]:disabled,
.wmpform input[type=tel]:disabled,
.wmpform input[type=date]:disabled,
.wmpform input[type=time]:disabled,
.wmpform input[type=number]:disabled,
.wmpform select:disabled,
.wmpform textarea:disabled {
  border-color: var(--apt-grey-6);
  cursor: not-allowed;
}
.wmpform input[type=text]:focus ~ label,
.wmpform input[type=search]:focus ~ label,
.wmpform input[type=email]:focus ~ label,
.wmpform input[type=password]:focus ~ label,
.wmpform input[type=tel]:focus ~ label,
.wmpform input[type=date]:focus ~ label,
.wmpform input[type=time]:focus ~ label,
.wmpform input[type=number]:focus ~ label,
.wmpform select:focus ~ label,
.wmpform textarea:focus ~ label {
  transform: translateY(-1.44em) scale(0.78);
  overflow: visible;
  text-overflow: initial;
  padding: 8px 12px 0 0;
}
.wmpform input[type=text]:required ~ label::after,
.wmpform input[type=search]:required ~ label::after,
.wmpform input[type=email]:required ~ label::after,
.wmpform input[type=password]:required ~ label::after,
.wmpform input[type=tel]:required ~ label::after,
.wmpform input[type=date]:required ~ label::after,
.wmpform input[type=time]:required ~ label::after,
.wmpform input[type=number]:required ~ label::after,
.wmpform select:required ~ label::after,
.wmpform textarea:required ~ label::after {
  content: "*";
}
.wmpform input[type=text]::placeholder,
.wmpform input[type=search]::placeholder,
.wmpform input[type=email]::placeholder,
.wmpform input[type=password]::placeholder,
.wmpform input[type=tel]::placeholder,
.wmpform input[type=date]::placeholder,
.wmpform input[type=time]::placeholder,
.wmpform input[type=number]::placeholder,
.wmpform select::placeholder,
.wmpform textarea::placeholder {
  opacity: 0;
}
.wmpform input[type=text]:not(:placeholder-shown) ~ label,
.wmpform input[type=search]:not(:placeholder-shown) ~ label,
.wmpform input[type=email]:not(:placeholder-shown) ~ label,
.wmpform input[type=password]:not(:placeholder-shown) ~ label,
.wmpform input[type=tel]:not(:placeholder-shown) ~ label,
.wmpform input[type=date]:not(:placeholder-shown) ~ label,
.wmpform input[type=time]:not(:placeholder-shown) ~ label,
.wmpform input[type=number]:not(:placeholder-shown) ~ label,
.wmpform select:not(:placeholder-shown) ~ label,
.wmpform textarea:not(:placeholder-shown) ~ label {
  transform: translateY(-1.44em) scale(0.78);
}
.wmpform input[type=text] ~ label,
.wmpform input[type=search] ~ label,
.wmpform input[type=email] ~ label,
.wmpform input[type=password] ~ label,
.wmpform input[type=tel] ~ label,
.wmpform input[type=date] ~ label,
.wmpform input[type=time] ~ label,
.wmpform input[type=number] ~ label,
.wmpform select ~ label,
.wmpform textarea ~ label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  line-height: 28px;
  height: 50px;
  color: var(--apt-grey-3);
  font-size: var(--18px);
  display: block;
  padding: 8px 30px 0 0;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: left top;
  transform: translateY(0) scale(1);
  transition: transform 0.2s linear;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wmpform input[type=text]:autofill, .wmpform input[type=text]:autofill:hover, .wmpform input[type=text]:autofill:focus, .wmpform input[type=text]:autofill:active,
.wmpform input[type=search]:autofill,
.wmpform input[type=search]:autofill:hover,
.wmpform input[type=search]:autofill:focus,
.wmpform input[type=search]:autofill:active,
.wmpform input[type=email]:autofill,
.wmpform input[type=email]:autofill:hover,
.wmpform input[type=email]:autofill:focus,
.wmpform input[type=email]:autofill:active,
.wmpform input[type=password]:autofill,
.wmpform input[type=password]:autofill:hover,
.wmpform input[type=password]:autofill:focus,
.wmpform input[type=password]:autofill:active,
.wmpform input[type=tel]:autofill,
.wmpform input[type=tel]:autofill:hover,
.wmpform input[type=tel]:autofill:focus,
.wmpform input[type=tel]:autofill:active,
.wmpform input[type=date]:autofill,
.wmpform input[type=date]:autofill:hover,
.wmpform input[type=date]:autofill:focus,
.wmpform input[type=date]:autofill:active,
.wmpform input[type=time]:autofill,
.wmpform input[type=time]:autofill:hover,
.wmpform input[type=time]:autofill:focus,
.wmpform input[type=time]:autofill:active,
.wmpform input[type=number]:autofill,
.wmpform input[type=number]:autofill:hover,
.wmpform input[type=number]:autofill:focus,
.wmpform input[type=number]:autofill:active,
.wmpform select:autofill,
.wmpform select:autofill:hover,
.wmpform select:autofill:focus,
.wmpform select:autofill:active,
.wmpform textarea:autofill,
.wmpform textarea:autofill:hover,
.wmpform textarea:autofill:focus,
.wmpform textarea:autofill:active {
  box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
}
.wmpform input[type=text]:-webkit-autofill, .wmpform input[type=text]:-webkit-autofill:hover, .wmpform input[type=text]:-webkit-autofill:focus, .wmpform input[type=text]:-webkit-autofill:active,
.wmpform input[type=search]:-webkit-autofill,
.wmpform input[type=search]:-webkit-autofill:hover,
.wmpform input[type=search]:-webkit-autofill:focus,
.wmpform input[type=search]:-webkit-autofill:active,
.wmpform input[type=email]:-webkit-autofill,
.wmpform input[type=email]:-webkit-autofill:hover,
.wmpform input[type=email]:-webkit-autofill:focus,
.wmpform input[type=email]:-webkit-autofill:active,
.wmpform input[type=password]:-webkit-autofill,
.wmpform input[type=password]:-webkit-autofill:hover,
.wmpform input[type=password]:-webkit-autofill:focus,
.wmpform input[type=password]:-webkit-autofill:active,
.wmpform input[type=tel]:-webkit-autofill,
.wmpform input[type=tel]:-webkit-autofill:hover,
.wmpform input[type=tel]:-webkit-autofill:focus,
.wmpform input[type=tel]:-webkit-autofill:active,
.wmpform input[type=date]:-webkit-autofill,
.wmpform input[type=date]:-webkit-autofill:hover,
.wmpform input[type=date]:-webkit-autofill:focus,
.wmpform input[type=date]:-webkit-autofill:active,
.wmpform input[type=time]:-webkit-autofill,
.wmpform input[type=time]:-webkit-autofill:hover,
.wmpform input[type=time]:-webkit-autofill:focus,
.wmpform input[type=time]:-webkit-autofill:active,
.wmpform input[type=number]:-webkit-autofill,
.wmpform input[type=number]:-webkit-autofill:hover,
.wmpform input[type=number]:-webkit-autofill:focus,
.wmpform input[type=number]:-webkit-autofill:active,
.wmpform select:-webkit-autofill,
.wmpform select:-webkit-autofill:hover,
.wmpform select:-webkit-autofill:focus,
.wmpform select:-webkit-autofill:active,
.wmpform textarea:-webkit-autofill,
.wmpform textarea:-webkit-autofill:hover,
.wmpform textarea:-webkit-autofill:focus,
.wmpform textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
}
.wmpform input[type=text]:autofill::first-line,
.wmpform input[type=search]:autofill::first-line,
.wmpform input[type=email]:autofill::first-line,
.wmpform input[type=password]:autofill::first-line,
.wmpform input[type=tel]:autofill::first-line,
.wmpform input[type=date]:autofill::first-line,
.wmpform input[type=time]:autofill::first-line,
.wmpform input[type=number]:autofill::first-line,
.wmpform select:autofill::first-line,
.wmpform textarea:autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--18px);
  line-height: 50px;
  font-weight: normal;
  color: inherit;
}
.wmpform input[type=text]:-webkit-autofill::first-line,
.wmpform input[type=search]:-webkit-autofill::first-line,
.wmpform input[type=email]:-webkit-autofill::first-line,
.wmpform input[type=password]:-webkit-autofill::first-line,
.wmpform input[type=tel]:-webkit-autofill::first-line,
.wmpform input[type=date]:-webkit-autofill::first-line,
.wmpform input[type=time]:-webkit-autofill::first-line,
.wmpform input[type=number]:-webkit-autofill::first-line,
.wmpform select:-webkit-autofill::first-line,
.wmpform textarea:-webkit-autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--18px);
  line-height: 50px;
  font-weight: normal;
  color: inherit;
}

.wmpform textarea {
  border-width: 1px;
  padding: 15px 30px;
}
.wmpform textarea ~ label {
  top: 15px;
  right: 30px;
  left: 30px;
  bottom: auto;
}
.wmpform textarea:focus ~ label {
  transform: translateY(-50px) translateX(-30px) scale(0.78);
}
.wmpform textarea:not(:placeholder-shown) ~ label {
  transform: translateY(-50px) translateX(-30px) scale(0.78);
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
.hs-fieldtype-date .input .hs-dateinput:before {
  color: var(--apt-grey-4);
  box-shadow: 0;
}

.wmpform input[type=file] {
  background-color: transparent;
  border: initial;
  padding: initial;
}

.wmpform textarea {
  display: block;
  resize: both;
  height: 120px;
}

.wmpform fieldset {
  margin-top: 12px;
  width: 100%;
  padding: 0;
}

.wmpform fieldset > section {
  margin-bottom: 16px;
}

@media screen and (min-width: 1140px) {
  .wmpform fieldset > section {
    margin-bottom: 20px;
  }
}
.wmpform .text-input-wrapper--error p,
.wmpform .text-input-wrapper--error input,
.wmpform .dropdown-wrapper--error select {
  border-color: var(--apt-error-1);
  color: var(--apt-error-1);
}

.wmpform .text-input-wrapper--error input:focus,
.wmpform .dropdown-wrapper--error select:focus {
  color: var(--apt-black);
}

.wmpform .dropdown-wrapper--error .form__error-message {
  display: block;
}

.wmpform .text-input-wrapper--error::after {
  content: "";
  visibility: visible;
}

.wmpform .form__error-message,
.wmpform .text-input-wrapper--error p,
.wmpform .dropdown-wrapper--error p {
  padding-top: 0.5em;
  pointer-events: none;
  color: var(--apt-error-1);
  font-size: var(--18px);
  line-height: 1.15;
  display: none;
}

.wmpform .form__notification-icon,
.wmpform .text-input-wrapper--error::after {
  position: absolute;
  top: 10px;
  right: 8px;
  font-family: var(--fa);
  font-size: var(--18px);
  line-height: 28px;
  color: var(--apt-error-1);
  font-weight: var(--fw-light);
}

.wmpform .form__notification-icon {
  display: none;
}

.wmpform label {
  display: block;
  font-weight: 400;
  clear: both;
}

.wmpform .form__alert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin-top: var(--12px);
}

.wmpform .form__alert > div:first-child:not(:only-child) {
  width: var(--24px);
  text-align: center;
}

.wmpform .form__alert--error > div:first-child:not(:only-child) {
  color: var(--apt-error-1);
}

.wmpform .dropdown-wrapper .wm-field-label {
  padding-bottom: 5px;
}

.wmpform .dropdown-wrapper .wm-field-label label {
  display: block;
  transform: scale(0.78);
  transform-origin: bottom left;
  overflow: visible;
  text-overflow: initial;
  color: var(--apt-grey-3);
  font-size: var(--18px);
  padding: 8px 12px 0 0;
}

.checkbox-wrapper .wm-field-label {
  margin-bottom: 39px;
}

.checkbox-wrapper .wm-field-label label,
.radio-wrapper .wm-field-label label,
.wm-static-label label {
  font-size: var(--24px);
  line-height: var(--29px);
  font-weight: var(--fw-medium);
  letter-spacing: -0.1px;
}

.wm-static-label + .wm-field-input textarea::placeholder {
  opacity: 1;
  font-size: var(--18px);
  line-height: var(--30px);
}

.checkbox-wrapper {
  margin: 36px auto;
}
.checkbox-wrapper .custom-checkbox-margin {
  margin-top: 0 !important;
  margin-bottom: 22px;
}

.checkbox-wrapper--tiny-text label span {
  font-size: var(--14px);
  letter-spacing: 0.3px;
  line-height: 20px;
  color: var(--apt-grey-2);
}

.checkbox-wrapper ul li {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}
.checkbox-wrapper ul li:last-child {
  margin-bottom: 0;
}

.checkbox-wrapper ul li input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(1em + 2px);
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.checkbox-wrapper ul li label > span {
  padding-top: 2px;
}

.checkbox-wrapper ul li label::before {
  content: "";
  position: relative;
  top: 0em;
  left: 1px;
  width: 1em;
  height: 1em;
  min-width: 18px;
  min-height: 18px;
  display: block;
  line-height: 1em;
  box-shadow: 0 0 0 1px var(--apt-grey-4);
  border-radius: 0.15em;
  color: var(--apt-black);
  background-color: var(--apt-white);
  text-align: center;
  pointer-events: none;
  margin-top: 1px;
  margin-right: 1.2em;
  flex: 0 0 auto;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0, 0, 1, 500);
}
@media (prefers-reduced-motion: reduce) {
  .checkbox-wrapper ul li label::before {
    transition: none;
    transform: none;
  }
}

.checkbox-wrapper--tiny-text ul li label::before {
  margin-right: 0.8em;
}

.checkbox-wrapper ul li label::after {
  content: "";
  position: absolute;
  top: 0em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  min-width: 20px;
  min-height: 20px;
  display: block;
  line-height: calc(1em + 2px);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011%209%22%3E%3Cpath%20d%3D%22M9.84932246%201.87587177L6.60171214%205.53819383%204.57195102%207.82715036C4.49822304%207.94967669%204.37987023%208%204.2595772%208c-.12029302%200-.2382578-.05128602-.32925365-.15383618l-2.79390253-3.1506769c-.1818947-.20512219-.1818947-.53736545%200-.74259704.1818947-.20523159.4765156-.2051222.6585073%200L4.2595772%206.73316533l4.94753573-5.57932368c.18189469-.2051222.47651559-.2051222.6585073%200%20.18199171.20512219.18160366.53605266-.01629777.72203012z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%22.64%22%2F%3E%3C%2Fsvg%3E");
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

.checkbox-wrapper ul li label {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0;
  letter-spacing: 0;
  font-size: var(--16px);
  line-height: 1;
}

.checkbox-wrapper ul li input:disabled,
.checkbox-wrapper ul li input:disabled ~ label {
  cursor: not-allowed;
}

.checkbox-wrapper ul li input:disabled ~ label {
  color: var(--apt-grey-6);
}

.checkbox-wrapper ul li label a {
  font-weight: 400;
  text-decoration: underline;
}

.checkbox-wrapper ul li input:checked ~ label::before {
  background-color: var(--apt-primary);
  box-shadow: 0 0 0 1px var(--apt-primary);
  color: var(--apt-white);
  transform: scale(1.001);
  transition: transform 0.1s cubic-bezier(0, 0, 1, -500);
}

.checkbox-wrapper ul li input:checked ~ label::after {
  opacity: 1;
}

.checkbox-wrapper ul li input:disabled ~ label::before {
  box-shadow: 0 0 0 1px var(--apt-grey-6);
}

.checkbox-wrapper ul li input:focus-visible ~ label::before {
  box-shadow: 0 0 0 2px var(--apt-primary);
}

.radio-wrapper .wm-field-label {
  margin-bottom: 16px;
}
.radio-wrapper .wm-field-label label {
  font-weight: var(--fw-medium);
}

.radio-wrapper ul li {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.radio-wrapper ul li:first-of-type {
  margin-top: 0;
}

.radio-wrapper ul li:last-of-type {
  margin-bottom: 20px;
}

.radio-wrapper ul li input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1em;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.radio-wrapper ul li label::before {
  content: "";
  position: relative;
  top: 0.1em;
  left: 1px;
  width: 1em;
  height: 1em;
  display: block;
  line-height: 1em;
  margin-right: 0.7em;
  border: 0.3em solid var(--apt-white);
  border-radius: 100%;
  box-shadow: 0 0 0 1px var(--apt-grey-4);
  color: var(--apt-black);
  background-color: var(--apt-white);
  text-align: center;
  pointer-events: none;
  flex: 0 0 auto;
  transform-origin: center;
}
@media (prefers-reduced-motion: reduce) {
  .radio-wrapper ul li label::before {
    animation: none;
  }
}

.radio-wrapper ul li label {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
  padding-left: 0;
  padding-top: 0;
  letter-spacing: 0;
  line-height: 1;
}

.radio-wrapper ul li input:checked ~ label::before {
  background-color: var(--apt-primary);
  color: var(--apt-white);
  animation: bounce 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .radio-wrapper ul li input:checked ~ label::before {
    animation: none;
  }
}

.radio-wrapper ul li input:disabled {
  cursor: not-allowed;
}

.radio-wrapper ul li input:disabled ~ label::before {
  box-shadow: 0 0 0 1px var(--apt-grey-6);
}

.radio-wrapper ul li input:disabled ~ label {
  color: var(--apt-grey-6);
  cursor: not-allowed;
}

.radio-wrapper ul li input:focus-visible ~ label::before {
  box-shadow: 0 0 0 2px var(--apt-primary);
}

.radio-wrapper--inline {
  margin-top: 28px;
}

@media (min-width: 992px) {
  .radio-wrapper--inline ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }
  .radio-wrapper--inline ul li {
    flex: 1 0 auto;
    margin-bottom: 0;
  }
  .radio-wrapper--inline ul li label {
    padding-right: 0.4em;
  }
}
.radio-wrapper--inline ul li:last-of-type {
  margin-bottom: 0;
}

/* TODO: check elsewhere */
@media (min-width: 992px) {
  .radio-wrapper--inline .wm-field-label {
    margin-bottom: 12px;
  }
}

.radio-wrapper ul li address {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.radio-wrapper ul li address span:first-child {
  color: var(--apt-grey-2);
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
}

.radio-wrapper ul li address span:nth-child(2) {
  color: var(--apt-grey-2);
  font-size: var(--14px);
  line-height: 22px;
  font-weight: var(--fw-regular);
}

.toggle-wrapper ul li,
.toggle-dialog-wrapper > div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: fit-content;
}
.toggle-wrapper ul li input,
.toggle-dialog-wrapper > div input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 2em;
  height: 2em;
}
.toggle-wrapper ul li input:checked ~ label,
.toggle-dialog-wrapper > div input:checked ~ label {
  color: var(--apt-black);
}
.toggle-wrapper ul li input:checked ~ label::before,
.toggle-dialog-wrapper > div input:checked ~ label::before {
  content: "";
  border: 1px solid var(--apt-primary);
  background-color: white;
}
.toggle-wrapper ul li input:checked ~ label::after,
.toggle-dialog-wrapper > div input:checked ~ label::after {
  content: "";
  background-color: var(--apt-primary);
  transform: translateX(0);
}
.toggle-wrapper ul li input:disabled ~ label,
.toggle-dialog-wrapper > div input:disabled ~ label {
  color: var(--apt-grey-6);
}
.toggle-wrapper ul li input:disabled ~ label::before,
.toggle-dialog-wrapper > div input:disabled ~ label::before {
  border-color: var(--apt-grey-6);
}
.toggle-wrapper ul li input:disabled ~ label::after,
.toggle-dialog-wrapper > div input:disabled ~ label::after {
  background-color: var(--apt-grey-6);
}
.toggle-wrapper ul li input:focus-visible ~ label::before,
.toggle-dialog-wrapper > div input:focus-visible ~ label::before {
  border-color: var(--apt-primary);
}
.toggle-wrapper ul li input:focus-visible ~ label::after,
.toggle-dialog-wrapper > div input:focus-visible ~ label::after {
  background-color: var(--apt-primary);
}
.toggle-wrapper ul li label,
.toggle-dialog-wrapper > div label {
  position: relative;
  display: block;
  padding-right: 3.35em;
  margin-bottom: 21px;
  transition: color 0.2s linear;
  cursor: pointer;
  line-height: 1;
}
.toggle-wrapper ul li label::before, .toggle-wrapper ul li label::after,
.toggle-dialog-wrapper > div label::before,
.toggle-dialog-wrapper > div label::after {
  position: absolute;
  right: 0;
  border-radius: 1em;
  transition: background-color 0.3s, right 0.3s, border-color 0.3s;
}
.toggle-wrapper ul li label::before,
.toggle-dialog-wrapper > div label::before {
  content: "";
  top: 0.3em;
  padding-left: 0;
  width: 1.8em;
  height: 0.7em;
  border-radius: 1em;
  border: 1px solid var(--apt-grey-4);
  background-color: rgba(0, 0, 0, 0);
}
@media screen and (-ms-high-contrast: active), (prefers-contrast: forced), (forced-colors: active) {
  .toggle-wrapper ul li label::before,
.toggle-dialog-wrapper > div label::before {
    border: 1px solid var(--apt-grey-2);
    background-color: var(--apt-white);
    box-shadow: inset 0 0 0 2px var(--apt-white);
  }
}
.toggle-wrapper ul li label::after,
.toggle-dialog-wrapper > div label::after {
  content: "";
  top: 0.17em;
  background-color: var(--apt-grey-4);
  height: 0.95em;
  width: 0.95em;
  transform: translateX(-0.95em);
  transition: transform 0.2s ease, background-color 0.2s linear;
}
@media screen and (-ms-high-contrast: active), (prefers-contrast: forced), (forced-colors: active) {
  .toggle-wrapper ul li label::after,
.toggle-dialog-wrapper > div label::after {
    background-color: var(--apt-black);
  }
}

.form-toggle-dialog {
  margin-top: 48px;
}
.form-toggle-dialog .toggle-dialog-wrapper > div label::before {
  top: 0.65em;
}
.form-toggle-dialog .toggle-dialog-wrapper > div label::after {
  top: 0.53em;
}

.text-input-wrapper,
.checkbox-wrapper,
.radio-wrapper,
.toggle-dialog-wrapper,
.dropdown-wrapper,
.toggle-wrapper,
.registry-plate-wrapper {
  position: relative;
}
.text-input-wrapper .heading,
.checkbox-wrapper .heading,
.radio-wrapper .heading,
.toggle-dialog-wrapper .heading,
.dropdown-wrapper .heading,
.toggle-wrapper .heading,
.registry-plate-wrapper .heading {
  margin: 0;
}

.registry-plate-wrapper .wm-field-input {
  background-color: var(--registry-plate-yellow);
  border: 1px solid var(--apt-grey-2);
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  margin-left: 28px;
}
.registry-plate-wrapper input[type=text] {
  appearance: none;
  display: block;
  padding: 11px 9px 14px 13px;
  margin: 0 !important;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: 0 0 0 0;
  width: 100%;
  height: 48px;
  line-height: 25px;
  font-size: var(--18px);
  letter-spacing: normal;
  color: var(--apt-black);
  text-align: center;
  text-transform: uppercase;
}
.registry-plate-wrapper input[type=text]::placeholder {
  color: var(--apt-black);
  opacity: 0.5;
}
.registry-plate-wrapper input[type=text]::selection {
  background-color: #48E2FF;
}
.registry-plate-wrapper input[type=text]:autofill, .registry-plate-wrapper input[type=text]:autofill:hover, .registry-plate-wrapper input[type=text]:autofill:focus, .registry-plate-wrapper input[type=text]:autofill:active {
  box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
  -webkit-box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
}
.registry-plate-wrapper input[type=text]:-webkit-autofill, .registry-plate-wrapper input[type=text]:-webkit-autofill:hover, .registry-plate-wrapper input[type=text]:-webkit-autofill:focus, .registry-plate-wrapper input[type=text]:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
}
.registry-plate-wrapper input[type=text]:autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--18px);
  line-height: 25px;
  font-weight: normal;
  color: inherit;
}
.registry-plate-wrapper input[type=text]:-webkit-autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--18px);
  line-height: 25px;
  font-weight: normal;
  color: inherit;
}
.registry-plate-wrapper label {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 50px;
  background-color: var(--apt-blue-1);
  color: var(--apt-white);
  z-index: 1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid var(--apt-grey-2);
  border-right: 0;
}
.registry-plate-wrapper label::before {
  content: attr(data-country);
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: var(--12px);
  line-height: 25px;
  font-weight: var(--fw-medium);
}
.registry-plate-wrapper label::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 7px;
  width: 16px;
  height: 16px;
  border: 2px dotted var(--registry-plate-yellow);
  border-radius: 50%;
}

.trade-card .wmpform {
  position: relative;
  margin-top: 0;
}
.trade-card h2 {
  display: block;
  font-size: var(--22px);
  line-height: 30px;
  letter-spacing: -0.09px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--13px);
}

.trade-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.trade-card-wrapper .wm-field:last-child {
  margin-top: 29px;
}
.trade-card-wrapper .registry-plate-wrapper {
  max-width: 251px;
}
.trade-card-wrapper .registry-plate-wrapper .wm-field-input {
  background-image: linear-gradient(-45deg, #FFC81F 53%, #ffe185 60%, #FFC81F 67%);
  background-size: 1200% 100%;
  animation: shine 30s infinite;
  animation-delay: 0s;
  animation-timing-function: linear;
}

.trade-card-wrapper + .trade-card-button-wrapper {
  padding-top: 16px;
}
@media (min-width: 400px) {
  .trade-card-wrapper + .trade-card-button-wrapper {
    position: absolute;
    left: 275px;
    bottom: 0;
    padding-top: 0;
  }
}
.trade-card-wrapper + .trade-card-button-wrapper .button-wrapper {
  margin: 0;
}
.trade-card-wrapper + .trade-card-button-wrapper .button.button,
.trade-card-wrapper + .trade-card-button-wrapper input[type=submit] {
  display: block;
  align-items: center;
  margin-top: 0;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
}
.trade-card-wrapper + .trade-card-button-wrapper .button.button i,
.trade-card-wrapper + .trade-card-button-wrapper input[type=submit] i {
  text-align: center;
}
.trade-card-wrapper + .trade-card-button-wrapper .button-wrapper.button-wrapper--primary {
  position: relative;
  width: 50px;
  height: 50px;
}
.trade-card-wrapper + .trade-card-button-wrapper .button-wrapper.button-wrapper--primary::before {
  content: "";
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  font-size: inherit;
  line-height: 1;
  color: var(--apt-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  z-index: 2;
  pointer-events: none;
}

[type=range] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));
  --sx: calc(.5*0.5em + var(--ratio)*(100% - 0.5em));
  margin: 0;
  padding: 0;
  width: 100%;
  height: 0.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
}
[type=range], [type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
[type=range]::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.175em;
  background: var(--apt-grey-7);
}
[type=range]::-webkit-slider-runnable-track {
  background: linear-gradient(270deg, var(--apt-primary), #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd) 0/var(--sx) 100% no-repeat var(--apt-grey-7);
}
[type=range]::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.175em;
  background: var(--apt-grey-7);
}
[type=range]::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.175em;
  background: var(--apt-grey-7);
}
[type=range]::-moz-range-progress {
  height: 0.25em;
  background: linear-gradient(270deg, var(--apt-primary), #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);
}
[type=range]::-ms-fill-lower {
  height: 0.25em;
  background: linear-gradient(270deg, var(--apt-primary), #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);
}
[type=range]::-webkit-slider-thumb {
  margin-top: -0.1625em;
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--apt-primary);
}
[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--apt-primary);
}
[type=range]::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: var(--apt-primary);
}
[type=range]::-ms-tooltip {
  display: none;
}

.range-wrapper {
  margin-bottom: 33px;
}

.range-wrapper label {
  font-weight: var(--fw-medium);
  margin-bottom: 10px;
}

.range-labels {
  position: relative;
  width: 100%;
  height: 20px;
  font-size: var(--14px);
  line-height: 20px;
  color: var(--apt-grey-4);
}
.range-labels span {
  position: absolute;
}
.range-labels span:first-child {
  left: 0;
}
.range-labels span:last-child {
  right: 0;
}
.range-labels span:not(:first-child):not(:last-child) {
  left: calc(((((var(--i) - var(--min)) * 100) / (var(--max) - var(--min))) * 1%) - 0.25em);
  transform: translateX(-50%);
}
.range-labels span.selected {
  color: var(--apt-grey-2);
}

@media (max-width: 511px) {
  .range-labels--mobile-outline-correction span:nth-child(even) {
    top: 1.5em;
  }
  .range-labels--mobile-outline-correction span:nth-child(even):before {
    content: "";
    position: absolute;
    top: -1.5em;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 1.5em;
    background-color: var(--apt-grey-4);
  }
}

.price-indication {
  margin-top: 38px;
}

.form-table-wrapper,
.wmpform > .gridelement.gridelement_style {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -14px;
  width: calc(100% + 28px);
}
@media (min-width: 768px) {
  .form-table-wrapper,
.wmpform > .gridelement.gridelement_style {
    width: calc(100% - 6px);
  }
}
.form-table-wrapper td.transparent-column-8px,
.wmpform > .gridelement.gridelement_style td.transparent-column-8px {
  display: none;
}
.form-table-wrapper td[width="1"],
.wmpform > .gridelement.gridelement_style td[width="1"] {
  display: none;
}
.form-table-wrapper .gridtable,
.wmpform > .gridelement.gridelement_style .gridtable {
  display: flex;
  width: 100%;
  /* Overrides */
}
.form-table-wrapper .gridtable tbody,
.wmpform > .gridelement.gridelement_style .gridtable tbody {
  display: flex;
  width: 100%;
}
.form-table-wrapper .gridtable tr:only-child,
.form-table-wrapper .gridtable tr:nth-child(2),
.wmpform > .gridelement.gridelement_style .gridtable tr:only-child,
.wmpform > .gridelement.gridelement_style .gridtable tr:nth-child(2) {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
.form-table-wrapper .gridtable tr:not(:only-child):nth-child(1),
.form-table-wrapper .gridtable tr:nth-child(3),
.wmpform > .gridelement.gridelement_style .gridtable tr:not(:only-child):nth-child(1),
.wmpform > .gridelement.gridelement_style .gridtable tr:nth-child(3) {
  display: none;
}
@media (max-width: 511px) {
  .form-table-wrapper .gridtable tbody,
.wmpform > .gridelement.gridelement_style .gridtable tbody {
    width: 100%;
  }
  .form-table-wrapper .gridtable tr,
.wmpform > .gridelement.gridelement_style .gridtable tr {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.form-table-wrapper .gridtable .radio-wrapper--inline,
.wmpform > .gridelement.gridelement_style .gridtable .radio-wrapper--inline {
  margin-top: 0;
}
.form-table-wrapper .gridtable .checkbox-wrapper,
.wmpform > .gridelement.gridelement_style .gridtable .checkbox-wrapper {
  margin-top: 0;
}
.form-table-wrapper .gridtable .tiny-print,
.wmpform > .gridelement.gridelement_style .gridtable .tiny-print {
  padding: 0 14px;
}
.form-table-wrapper .gridcontentareastyle,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle {
  width: 50%;
}
@media (max-width: 511px) {
  .form-table-wrapper .gridcontentareastyle,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle {
    width: 100% !important;
    display: block !important;
  }
}
.form-table-wrapper .gridcontentareastyle:only-child,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle:only-child {
  width: 100%;
}
.form-table-wrapper .gridcontentareastyle table,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle table {
  width: 100%;
}
.form-table-wrapper .gridcontentareastyle .contentelement,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle .contentelement {
  width: 100%;
  padding: 14px;
}
.form-table-wrapper .gridcontentareastyle .jq_gridcontentarea,
.wmpform > .gridelement.gridelement_style .gridcontentareastyle .jq_gridcontentarea {
  width: 100%;
}
.form-table-wrapper .text-input-wrapper,
.wmpform > .gridelement.gridelement_style .text-input-wrapper {
  margin-top: -3px;
  display: flex;
  flex-direction: column;
}
.form-table-wrapper .text-input-wrapper div[id^=error_],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_] {
  order: 2;
}
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number],
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea] {
  border-color: var(--apt-error-1);
}
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number] ~ label,
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number] ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea] ~ label {
  color: var(--apt-error-1);
}
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul {
  color: var(--apt-error-1);
}
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li {
  padding-left: 0;
}
.form-table-wrapper .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before,
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before {
  display: none;
}
.form-table-wrapper .text-input-wrapper div[id^=info_],
.wmpform > .gridelement.gridelement_style .text-input-wrapper div[id^=info_] {
  display: none;
}
.form-table-wrapper .text-input-wrapper .wm-field-input,
.wmpform > .gridelement.gridelement_style .text-input-wrapper .wm-field-input {
  order: 1;
}
.form-table-wrapper .text-input-wrapper .wm-field-helptext,
.wmpform > .gridelement.gridelement_style .text-input-wrapper .wm-field-helptext {
  order: 3;
}
.form-table-wrapper .text-input-wrapper input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]),
.wmpform > .gridelement.gridelement_style .text-input-wrapper input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
  margin-top: 0;
}
.form-table-wrapper .text-input-wrapper textarea ~ label,
.wmpform > .gridelement.gridelement_style .text-input-wrapper textarea ~ label {
  top: 54px;
}
.form-table-wrapper .text-input-wrapper input + p,
.wmpform > .gridelement.gridelement_style .text-input-wrapper input + p {
  display: none;
}

.checkbox-wrapper, .radio-wrapper, .toggle-dialog-wrapper, .dropdown-wrapper, .toggle-wrapper, .registry-plate-wrapper {
  display: flex;
  flex-direction: column;
}
.checkbox-wrapper div[id^=error_], .radio-wrapper div[id^=error_], .toggle-dialog-wrapper div[id^=error_], .dropdown-wrapper div[id^=error_], .toggle-wrapper div[id^=error_], .registry-plate-wrapper div[id^=error_] {
  order: 2;
}
.checkbox-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul, .radio-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul, .toggle-dialog-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul, .dropdown-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul, .toggle-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul, .registry-plate-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul {
  color: var(--apt-error-1);
}
.checkbox-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li, .radio-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li, .toggle-dialog-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li, .dropdown-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li, .toggle-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li, .registry-plate-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li {
  padding-left: 0;
}
.checkbox-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before, .radio-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before, .toggle-dialog-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before, .dropdown-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before, .toggle-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before, .registry-plate-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before {
  display: none;
}
.checkbox-wrapper div[id^=info_], .radio-wrapper div[id^=info_], .toggle-dialog-wrapper div[id^=info_], .dropdown-wrapper div[id^=info_], .toggle-wrapper div[id^=info_], .registry-plate-wrapper div[id^=info_] {
  display: none;
}
.checkbox-wrapper .wm-field-input, .radio-wrapper .wm-field-input, .toggle-dialog-wrapper .wm-field-input, .dropdown-wrapper .wm-field-input, .toggle-wrapper .wm-field-input, .registry-plate-wrapper .wm-field-input {
  order: 1;
}
.checkbox-wrapper .wm-field-helptext, .radio-wrapper .wm-field-helptext, .toggle-dialog-wrapper .wm-field-helptext, .dropdown-wrapper .wm-field-helptext, .toggle-wrapper .wm-field-helptext, .registry-plate-wrapper .wm-field-helptext {
  order: 3;
}

.gridelement.gridelement_style .gridelement.gridelement_style,
.gridelement.formsectioncompound.gridelement_style {
  margin-left: 0;
  width: 100%;
}

.upload-section div[id^=error_] ul {
  padding-left: 0;
}
.upload-section .wm-field > div {
  display: flex;
  flex-direction: column;
}
.upload-section .wm-field > div .heading {
  order: 1;
}
.upload-section .wm-field > div div[id^=error_] {
  order: 4;
  color: var(--apt-error-1);
}
.upload-section .wm-field > div .wm-field-input ~ ul {
  order: 3;
  padding-left: 0;
}
.upload-section .wm-field > div .wm-field-label {
  order: 2;
}

.wm-field-input {
  position: relative;
}

.file-upload {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
}

.file-upload input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  margin-top: 0 !important;
  /* no */
}

.file-upload label {
  position: static;
  transform: none;
  cursor: pointer;
  display: inline-flex;
  align-items: baseline;
  font-weight: var(--fw-medium);
  padding: 7px 26px 8px;
}
.file-upload label svg {
  fill: currentColor;
  padding-right: 0.5em;
}
.file-upload label svg.upload-svg {
  height: 1em;
  position: relative;
  top: 0.4ex;
}
.file-upload label svg.check-svg {
  height: 0.75em;
}

/* Attempt to style the upload button */
.upload-section .wm-field[id] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 54px;
}
@media (min-width: 768px) {
  .upload-section .wm-field[id] {
    flex-direction: row;
  }
}

.upload-section .wm-field[id] label,
.upload-section .wm-field[id] span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  z-index: 1;
}
.upload-section .wm-field[id] label svg,
.upload-section .wm-field[id] span svg {
  fill: currentColor;
  padding-right: 0.5em;
}
.upload-section .wm-field[id] label svg.upload-svg,
.upload-section .wm-field[id] span svg.upload-svg {
  height: 1em;
  position: relative;
  top: 0.4ex;
}
.upload-section .wm-field[id] label svg.check-svg,
.upload-section .wm-field[id] span svg.check-svg {
  height: 0.75em;
}

.upload-section .wm-field[id] :hover label::before {
  transition: background-position 0.4s ease;
  background-position: 0 0;
}

.upload-section .wm-field[id] .wm-field-input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.upload-section .wm-field[id] .wm-field-input::before {
  content: "";
}

.upload-section .wm-field[id] input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  background: none;
  opacity: 0;
  padding: 0;
  margin: 0 !important;
  border: 0;
  line-height: 1;
  cursor: pointer;
}

@media (min-width: 768px) {
  .upload-section {
    width: calc(100% + 50px);
  }
}

.upload-section .heading {
  position: absolute;
  top: -36px;
}

.upload-section .wm-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
}
@media (min-width: 768px) {
  .upload-section .wm-field {
    flex-direction: row;
  }
}

.upload-section ul {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
@media (min-width: 768px) {
  .upload-section ul {
    margin-top: 0 !important;
    padding-left: 34px;
  }
}

.upload-section ul li {
  padding-left: 0;
  margin-bottom: 0;
  font-size: var(--18px);
  line-height: 30px;
}
.upload-section ul li::before {
  display: none;
}

.upload-section ul li button {
  padding-left: 6px;
}

.upload-section .wm-field > div {
  align-items: flex-start;
}

.form-grid {
  width: calc(100% + 28px);
  display: flex;
  flex-wrap: wrap;
  margin-left: -14px;
}

.form-grid-item {
  width: 100%;
  padding: 0 14px 14px;
}

.form-grid-item--added-space:not(:last-child) {
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .form-grid-item--50 {
    width: 50%;
  }
}

@media (min-width: 576px) {
  .form-grid-item--25 {
    width: 25%;
  }
}

.wmpform .datepicker input:focus::placeholder {
  opacity: 1;
}

.search-input-section {
  padding-top: 39px;
  padding-bottom: 49px;
}
@media (min-width: 768px) {
  .search-input-section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
.search-input-section .wmpform {
  margin-top: 0;
}

.search-input {
  width: 100%;
  max-width: 609px;
}
.search-input::before {
  content: "";
  position: absolute;
  top: 0.32em;
  left: 0.1em;
  font-family: var(--fa);
  font-size: var(--20px);
  line-height: 20px;
  font-weight: var(--fw-light);
}
@media (min-width: 768px) {
  .search-input::before {
    font-size: var(--40px);
    line-height: 40px;
  }
}
.search-input input[type=text] {
  font-size: var(--14px);
  line-height: 42px;
  font-weight: var(--fw-medium);
  height: 42px;
  padding: 5px 0 14px 2.8em;
  margin-top: 0 !important;
}
@media (min-width: 768px) {
  .search-input input[type=text] {
    padding: 10px 0 22px 2em;
    height: 70px;
    line-height: 70px;
    font-size: var(--40px);
  }
}
.search-input input[type=text]::placeholder {
  color: var(--apt-grey-3);
  opacity: 1;
  font-weight: var(--fw-light);
}

.toggle-slider-checkbox {
  position: relative;
  margin-top: 38px;
  display: flex;
}
.toggle-slider-checkbox__input {
  position: absolute;
  top: 0.15em;
  left: 3.2em;
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  width: 2.5em;
  height: 1.5em;
}
.toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__left {
  color: var(--apt-grey-4);
  pointer-events: auto;
}
.toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__right {
  color: var(--apt-primary);
  pointer-events: none;
}
.toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__toggle {
  color: var(--apt-primary);
}
.toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__toggle:after {
  transform: translateX(1em);
}
.toggle-slider-checkbox__input:focus-visible + .toggle-slider-checkbox__label {
  box-shadow: 0 0 0 2px var(--apt-grey-9), 0 0 0 4px var(--apt-primary);
}
.toggle-slider-checkbox__input:focus:not(:focus-visible) + .toggle-slider-checkbox__label {
  outline: 2px solid transparent;
  box-shadow: none;
}
.toggle-slider-checkbox__label {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-weight: var(--fw-regular);
  font-size: var(--20px);
  border-radius: 0.1em;
  line-height: 1.5;
  cursor: pointer;
  user-select: none;
}
.toggle-slider-checkbox__toggle {
  position: relative;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding-left: 0;
  background: var(--apt-grey-9);
  width: 1.6em;
  height: 0.6em;
  border-radius: 0.6em;
  margin-left: 1em;
  margin-right: 1em;
  box-shadow: inset 0px 0px 0px 1px var(--apt-primary);
  transition: background-color 0.3s, right 0.3s, border-color 0.3s;
}
.toggle-slider-checkbox__toggle:after {
  position: absolute;
  content: "";
  background: var(--apt-primary);
  width: 0.8em;
  height: 0.8em;
  top: -0.1em;
  left: -0.1em;
  border-radius: 0.8em;
  transform: translateX(0);
  transition: transform 0.2s ease, background-color 0.2s linear;
}
.toggle-slider-checkbox__left {
  color: var(--apt-primary);
  transition: color 0.2s linear;
  pointer-events: none;
}
.toggle-slider-checkbox__right {
  color: var(--apt-grey-4);
  transition: color 0.2s linear;
  pointer-events: auto;
}

.button-wrapper.processing:not([style^=background]),
.button-container.processing:not([style^=background]) {
  display: flex;
}
.button-wrapper.processing:not([style^=background])::after,
.button-container.processing:not([style^=background])::after {
  content: "";
  margin: auto 1rem;
  width: 22px;
  height: 22px;
  background: none;
  transform: rotate(0deg);
  border: 2px solid var(--apt-primary);
  border-right-color: transparent;
  border-radius: 100%;
  animation-name: loadCircle;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.button-wrapper[style^=background],
.button-container[style^=background] {
  background-size: 0;
}

.button-wrapper.processing[style^=background],
.button-container.processing[style^=background] {
  display: flex;
}
.button-wrapper.processing[style^=background]::after,
.button-container.processing[style^=background]::after {
  content: "";
  margin: auto 1rem;
  width: 22px;
  height: 22px;
  background-image: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 29px;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper ul {
  padding-left: 0;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_] {
  order: 2;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul {
  color: var(--apt-error-1);
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li {
  padding-left: 0;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ul li::before {
  display: none;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input {
  border-color: var(--apt-error-1);
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=error_]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input label {
  color: var(--apt-error-1);
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper div[id^=info_] {
  display: none;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper .wm-field-input {
  order: 1;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
  margin-top: 0;
}
.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper .wm-field-helptext {
  order: 3;
}

.jq_gridcontentarea {
  width: 100%;
}

.wmpform .dropdown-toggle {
  margin-top: 22px;
  padding: 10px 15px 12px 0;
}

.wmpform .dropdown-toggle .brand-label {
  font-size: var(--18px);
}

@media (max-width: 511px) {
  .lightbox {
    padding: 16px;
  }
}
.gallery-grid {
  width: 100%;
  max-width: 100%;
  display: flex;
}
@media (min-width: 768px) {
  .gallery-grid {
    margin-bottom: 38px;
  }
}
@media (min-width: 1100px) {
  .gallery-grid {
    max-width: 764px;
  }
}

.gallery-grid__primary {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 0;
}
@media (min-width: 768px) {
  .gallery-grid__primary {
    border-radius: 10px;
  }
}

.gallery-grid__side {
  flex: 0 0 auto;
  width: 20%;
  max-width: 154px;
  display: none;
}
@media (min-width: 768px) {
  .gallery-grid__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.gallery-grid__side-item {
  position: relative;
  width: calc(100% - 20px);
  max-height: 22%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.gallery-grid__side-item img {
  cursor: pointer;
}

.gallery-grid__side-item button,
.gallery-grid__side-item input[type=button],
.gallery-grid__side-item input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 7% 7% 16%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
  background-position: bottom;
  background-size: 100% 180%;
  background-repeat: no-repeat;
  color: var(--apt-white);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;
}
@media (min-width: 992px) {
  .gallery-grid__side-item button,
.gallery-grid__side-item input[type=button],
.gallery-grid__side-item input[type=submit] {
    font-size: var(--12px);
    line-height: 14px;
  }
}
@media (min-width: 1100px) {
  .gallery-grid__side-item button,
.gallery-grid__side-item input[type=button],
.gallery-grid__side-item input[type=submit] {
    padding: 7% 12% 11%;
    font-size: var(--16px);
    line-height: 18px;
  }
}
.gallery-grid__side-item button::after,
.gallery-grid__side-item input[type=button]::after,
.gallery-grid__side-item input[type=submit]::after {
  content: "";
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%) translateY(0) rotate(45deg);
  background: none;
  width: 10px;
  height: 10px;
  transform-origin: center;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  transition: transform 0.2s ease;
}
@media (min-width: 992px) {
  .gallery-grid__side-item button::after,
.gallery-grid__side-item input[type=button]::after,
.gallery-grid__side-item input[type=submit]::after {
    bottom: 7px;
    width: 6px;
    height: 6px;
  }
}
@media (min-width: 1100px) {
  .gallery-grid__side-item button::after,
.gallery-grid__side-item input[type=button]::after,
.gallery-grid__side-item input[type=submit]::after {
    bottom: 9px;
  }
}
@media (min-width: 1300px) {
  .gallery-grid__side-item button::after,
.gallery-grid__side-item input[type=button]::after,
.gallery-grid__side-item input[type=submit]::after {
    bottom: 14px;
    width: 10px;
    height: 10px;
  }
}
.gallery-grid__side-item button:hover::after,
.gallery-grid__side-item input[type=button]:hover::after,
.gallery-grid__side-item input[type=submit]:hover::after {
  transform: translateX(-50%) translateY(3px) rotate(45deg);
}

.gallery-grid__side-item:last-of-type {
  margin-bottom: 0;
}

.gallery-grid img,
.gallery-grid canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-slider {
  position: relative;
  padding: 0 0 0;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .gallery-slider {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.gallery-slider__slider-container {
  width: 100%;
}

.gallery-slider__pagination {
  position: relative;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  overflow-x: auto;
  padding-left: var(--15px);
  padding-right: var(--15px);
  padding-bottom: 15px;
  font-size: var(--14px);
  line-height: 20px;
  color: var(--apt-grey-4);
}
@media (min-width: 768px) {
  .gallery-slider__pagination {
    display: none;
  }
}
.gallery-slider__pagination.swiper-pagination-fraction {
  bottom: 0 !important;
  right: 0;
  left: unset;
  width: auto;
}

.gallery-slider .swiper-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .gallery-slider .swiper-wrapper {
    margin-bottom: 0;
  }
}

.gallery-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.gallery-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;
}

.gallery-slider figure button {
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.gallery-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 8;
  width: 100%;
  color: var(--apt-white);
  padding: 50px 0 0 0;
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}
@media (min-width: 1100px) {
  .gallery-overlay {
    padding: 80px 110px 0 0;
  }
}

.gallery-overlay--hidden {
  opacity: 0;
  pointer-events: none;
}

.gallery-overlay__close {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: var(--apt-black);
  text-align: center;
  z-index: 2;
  border-radius: 40px;
}
@media (min-width: 1100px) {
  .gallery-overlay__close {
    top: 50px;
    right: 40px;
    width: 45px;
    height: auto;
    padding: 0;
    background: none;
    border-radius: 0;
  }
}

.gallery-overlay__header {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.gallery-overlay__header--full {
  max-width: 1380px;
}
@media (min-width: 512px) and (min-height: 600px) {
  .gallery-overlay__header--full {
    padding-left: 70px;
  }
}
@media (min-width: 1100px) {
  .gallery-overlay__header--full {
    padding-left: 110px;
  }
}

.gallery-overlay__header span {
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.5px;
  font-weight: var(--fw-medium);
}

.gallery-overlay__header span button {
  display: block;
}
@media (min-width: 512px) {
  .gallery-overlay__header span button {
    display: inline-block;
  }
}

.gallery-overlay__header span input[type=button],
.gallery-overlay__header span input[type=submit],
.gallery-overlay__header span button {
  font-size: 0.75em;
  line-height: 1;
  font-weight: var(--fw-light);
  margin-right: 0.51em;
}

.gallery-overlay__header .detail-sidebar__characteristics {
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-top: 1em;
  margin-bottom: 56px;
}
@media (min-width: 512px) {
  .gallery-overlay__header .detail-sidebar__characteristics {
    margin-left: var(--48px);
  }
}

.gallery-overlay__content {
  width: 100%;
  height: auto;
  padding: 0 0 20px;
  margin: 0 auto;
}
@media (min-width: 512px) and (min-height: 600px) {
  .gallery-overlay__content {
    padding: 0 70px 60px 70px;
  }
}
@media (min-width: 512px) and (max-height: 599px) {
  .gallery-overlay__content {
    padding: 0 0 20px 0;
  }
}
@media (min-width: 1100px) {
  .gallery-overlay__content {
    padding: 0 0 100px 110px;
    margin: 0;
  }
}

@media (max-width: 767px) {
  .gallery-overlay__content--full {
    padding-left: 0;
    padding-right: 0;
  }
}

.gallery-overlay__content .swiper-slide {
  height: auto;
}

.gallery-overlay__gallery {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.gallery-overlay__gallery figure {
  margin-bottom: 52px;
}
@media (min-width: 1100px) {
  .gallery-overlay__gallery figure {
    margin-bottom: 66px;
  }
}

.gallery-overlay__gallery img {
  max-width: 100%;
  width: auto;
  max-height: calc(90vh - 40px);
  margin: auto;
  display: block;
}
@media (min-height: 600px) {
  .gallery-overlay__gallery img {
    width: 100%;
  }
}
@media (min-width: 512px) {
  .gallery-overlay__gallery img {
    border-radius: var(--10px);
  }
}

@media (max-width: 767px) {
  .gallery-slider__buttons {
    display: none;
  }
}

.gallery-slider-button {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  line-height: 30px;
  font-size: var(--13px);
  border-radius: 30px;
  color: var(--apt-grey-4);
  z-index: 1;
}
@media (min-width: 768px) {
  .gallery-slider-button {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.gallery-slider-prev-button {
  left: 10px;
}
@media (min-width: 768px) {
  .gallery-slider-prev-button {
    left: 20px;
  }
}

.gallery-slider-next-button {
  right: 10px;
}
@media (min-width: 768px) {
  .gallery-slider-next-button {
    right: 20px;
  }
}

.gallery-slider-button {
  opacity: 0 !important;
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.2s linear;
}

.offer-card-slider-container .gallery-slider-prev-button {
  left: 10px;
}

.offer-card-slider-container .gallery-slider-next-button {
  right: 10px;
}

.gallery-grid:hover .gallery-slider-button,
.offer-card-slider-container:hover .gallery-slider-button {
  opacity: 1 !important;
}

.gallery-slider-button:focus {
  border-radius: 30px;
  opacity: 1 !important;
}

.gallery-slider-button:hover {
  opacity: 1 !important;
}
@media (min-width: 768px) {
  .gallery-slider-button:hover {
    background-color: white;
  }
}

.gallery-slider-button[disabled] {
  opacity: 0.2;
}

.gallery-grid:hover:hover .gallery-slider-button[disabled],
.offer-card-slider-container:hover .gallery-slider-button[disabled] {
  opacity: 0.2 !important;
}

.gallery-grid--force-reload {
  border-radius: 0 !important;
}

.gallery-overlay-slider {
  width: 100%;
}
@media (min-height: 599px) {
  .gallery-overlay-slider {
    max-width: 720px;
  }
}
@media (min-width: 1100px) {
  .gallery-overlay-slider {
    max-width: 830px;
  }
}

.gallery-overlay-slider-button {
  position: absolute;
  top: calc(50% - 26px);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--apt-grey-4);
  transform: translateY(-50%);
  font-size: var(--13px);
  line-height: 30px;
  border-radius: 30px;
}
@media (min-width: 1100px) {
  .gallery-overlay-slider-button {
    top: calc(50% - 33px);
  }
}
.gallery-overlay-slider-button:focus {
  border-radius: 30px;
  background-color: white;
}
.gallery-overlay-slider-button:hover {
  background-color: white;
}

.gallery-overlay-slider-next-button {
  right: 22px;
}
@media (max-width: 767px) {
  .gallery-overlay-slider-next-button {
    right: 16px;
  }
}

.gallery-overlay-slider-prev-button {
  left: 22px;
}
@media (max-width: 767px) {
  .gallery-overlay-slider-prev-button {
    left: 16px;
  }
}

.gallery-overlay-slider__pagination {
  color: var(--apt-grey-4);
}
@media (min-width: 1100px) {
  .gallery-overlay-slider__pagination {
    bottom: 0 !important;
  }
}

.card-slider {
  position: relative;
  padding: 0 0 0;
}

.card-slide figure {
  border-radius: 0;
}

.card-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.card-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;
}

.card-slider figure button {
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.card-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-grid__toggle-container {
  position: absolute;
  bottom: 15px;
  left: 22px;
  z-index: 1;
}
@media (max-width: 767px) {
  .gallery-grid__toggle-container {
    left: 17px;
  }
}

.gallery-grid__toggle-container button {
  display: block;
}

.gallery-grid__toggle-container svg,
.gallery-grid__toggle-container img {
  width: 30px;
  height: 30px;
  display: block;
}

@media (max-width: 767px) {
  .gallery-grid__toggle-container--photo {
    bottom: 82px;
    transform: translateY(50%);
  }
}

.offer-card-listing-header .gallery-grid__toggle-container {
  position: static;
  width: 100%;
  height: 20px;
  color: var(--apt-primary);
  text-decoration: underline;
  font-size: var(--12px);
  line-height: 1.667;
  font-weight: var(--fw-medium);
  margin-bottom: var(--13px);
}

@media (max-width: 767px) {
  .gallery-grid__primary--360 {
    margin-bottom: 0;
  }
}

.gallery-grid__fullscreen-container {
  position: absolute;
  bottom: 15px;
  right: 22px;
  z-index: 1;
}
@media (max-width: 767px) {
  .gallery-grid__fullscreen-container {
    right: 17px;
  }
}

.gallery-grid__fullscreen-container button {
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--apt-white);
  border-radius: 30px;
  border: 1px solid var(--apt-grey-4);
}

.gallery-grid__fullscreen-container button svg {
  width: 13px;
  height: 13px;
}

.gallery-grid__fullscreen-container button svg path {
  fill: var(--apt-grey-4);
}

@media (max-width: 767px) {
  .gallery-grid__fullscreen-container--photo {
    bottom: 82px;
    transform: translateY(50%);
  }
}

@media (min-width: 992px) {
  .gallery-grid__user-interaction {
    display: none;
  }
}
.gallery-grid__user-interaction .user-interaction-dialog__button {
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--apt-white);
  border-radius: 30px;
  border: 1px solid var(--apt-grey-4);
  font-size: 75%;
  color: var(--apt-grey-4);
}
.gallery-grid__user-interaction.user-interaction-dialog--toggled .user-interaction-dialog__button {
  border-color: var(--apt-primary);
}

.gallery-overlay-spinner {
  position: relative;
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .gallery-overlay-spinner {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .gallery-overlay-spinner .gallery-grid__sirv-container {
    order: 1;
  }
}

@media (max-width: 767px) {
  .gallery-overlay-spinner .gallery-button-list {
    position: static;
    order: 2;
    padding: 44px 15px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .gallery-overlay-spinner .gallery-button-list li {
    margin: 0 10px;
  }
}

@media (max-width: 767px) {
  .gallery-container--spinner {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .gallery-container--spinner .gallery-button-list {
    position: static;
    order: 2;
    padding: 18px 10px 6px;
    margin-bottom: 32px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 319px) {
  .gallery-container--spinner .gallery-button-list {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .gallery-container--spinner .gallery-button-list li {
    margin: 0 5px 12px;
  }
}

@media (max-width: 511px) {
  .gallery-container--spinner .gallery-overlay-spinner .gallery-button-list {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 511px) {
  .gallery-container--spinner .gallery-overlay-spinner .gallery-button-list li {
    margin: 0 9px 18px;
  }
}
@media (min-width: 768px) {
  .gallery-container--spinner .gallery-overlay-spinner .gallery-button-list li {
    margin: 0 var(--15px) var(--12px) 0;
  }
}

.generic-slider-grid-container {
  margin-bottom: var(--25px);
}
@media (min-width: 768px) {
  .generic-slider-grid-container {
    margin-bottom: var(--25px);
  }
}
@media (min-width: 992px) {
  .generic-slider-grid-container {
    margin-bottom: var(--10px);
  }
}

@media (min-width: 768px) {
  .generic-slider-grid-container--paginated header {
    padding-right: 120px;
  }
}

.generic-slider-grid {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;
}
@media (min-width: 992px) {
  .generic-slider-grid {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@media (min-width: 1200px) {
  .generic-slider-grid {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.generic-slider-grid--static .generic-slider .swiper-wrapper {
  flex-direction: column;
}

@media (min-width: 992px) {
  .generic-slider-grid--static .generic-slider .swiper-wrapper {
    flex-direction: row;
  }
}
.generic-slider-grid--static .generic-slide {
  flex-shrink: 1;
  max-width: 632px;
}

.generic-slider-grid-container header .tag-list {
  margin-top: 15px;
  margin-bottom: -8px;
}

.generic-slider-grid__primary {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
}

.generic-slider-grid__side {
  flex: 0 0 auto;
  width: 22%;
  max-width: 154px;
  display: none;
}
@media (min-width: 768px) {
  .generic-slider-grid__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.generic-slider-grid__side-item {
  position: relative;
  width: calc(100% - 20px);
  max-height: 22%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.generic-slider-grid__side-item img {
  cursor: pointer;
}

.generic-slider-grid__side-item button,
.generic-slider-grid__side-item input[type=button],
.generic-slider-grid__side-item input[type=submit] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 7% 12% 11%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
  background-position: bottom;
  background-size: 100% 180%;
  background-repeat: no-repeat;
  color: var(--apt-white);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;
}
@media (min-width: 1100px) {
  .generic-slider-grid__side-item button,
.generic-slider-grid__side-item input[type=button],
.generic-slider-grid__side-item input[type=submit] {
    font-size: var(--16px);
    line-height: 18px;
  }
}

.generic-slider-grid__side-item:last-of-type {
  margin-bottom: 0;
}

.generic-slider-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.generic-slider {
  position: relative;
  padding: 0 0 0;
}

.generic-slider__slider-container {
  width: 100%;
}

.generic-slider__pagination {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .generic-slider__pagination {
    display: none;
  }
}
.generic-slider__pagination.swiper-pagination-bullets {
  bottom: 0 !important;
  width: 100%;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.generic-slider__pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: var(--apt-grey-5);
  border: 4px solid var(--apt-grey-9);
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 1;
}
.generic-slider__pagination .swiper-pagination-bullet:focus {
  outline: none;
  box-shadow: none;
}
.generic-slider__pagination .swiper-pagination-bullet:focus-visible {
  outline: none;
  box-shadow: none;
}
.generic-slider__pagination .swiper-pagination-bullet-active {
  background-color: var(--apt-primary);
}
@media (min-width: 768px) {
  .generic-slider__pagination--visible {
    position: absolute;
    top: -45px;
    right: 25px;
    display: flex;
    justify-content: flex-end;
    max-width: 120px;
  }
}

.grid-item.bg-white .swiper-pagination-bullet {
  border: 4px solid var(--apt-white);
}

.generic-slider .swiper-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .generic-slider .swiper-wrapper {
    margin-bottom: 0;
  }
}

.generic-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.generic-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}

.generic-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.car-types-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 8;
  width: 100%;
  height: 100%;
  color: var(--apt-white);
  padding: 50px 0 0 0;
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;
}
@media (min-width: 1100px) {
  .car-types-overlay {
    padding: 80px 110px 0 0;
  }
}

.car-types-overlay--hidden {
  opacity: 0;
  pointer-events: none;
}

.car-types-overlay__close {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: var(--apt-black);
  text-align: right;
  padding: 0 20px;
}
@media (min-width: 1100px) {
  .car-types-overlay__close {
    position: fixed;
    top: 50px;
    right: 40px;
    text-align: center;
    width: 45px;
    height: auto;
    padding: 0;
    background: none;
  }
}

.car-types-overlay__header {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.car-types-overlay__header span {
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.5px;
  font-weight: var(--fw-medium);
}

.car-types-overlay__header span input[type=button],
.car-types-overlay__header span input[type=submit],
.car-types-overlay__header span button {
  font-size: 0.75em;
  line-height: 1;
  font-weight: var(--fw-light);
  margin-right: 0.51em;
}

.car-types-overlay__header .detail-sidebar__characteristics {
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-top: 1em;
  margin-left: var(--48px);
  margin-bottom: 56px;
}

.car-types-overlay__content {
  width: 100%;
  height: auto;
  padding: 0 0 100px 0;
}

.car-types-overlay__gallery {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.car-types-overlay__gallery figure {
  margin-bottom: 20px;
}
@media (min-width: 1100px) {
  .car-types-overlay__gallery figure {
    margin-bottom: 50px;
  }
}

.car-types-overlay__gallery img {
  width: 100%;
  border-radius: var(--10px);
}

.generic-slider-prev-button-container {
  position: absolute;
  top: 0;
  left: -25px;
  width: 0;
  height: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .generic-slider-prev-button-container {
    display: none;
  }
}
@media (min-width: 1440px) {
  .generic-slider-prev-button-container {
    left: 0;
  }
}

.generic-slider-next-button-container {
  position: absolute;
  top: 0;
  right: -25px;
  width: 0;
  height: 100%;
  z-index: 1;
}
@media (max-width: 767px) {
  .generic-slider-next-button-container {
    display: none;
  }
}
@media (min-width: 1440px) {
  .generic-slider-next-button-container {
    right: 0;
  }
}

.generic-slider-button {
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  background: none;
  font-size: var(--25px);
  line-height: 22px;
  color: var(--apt-grey-4);
  background: var(--apt-grey-9);
  border-radius: 100%;
}
@media (min-width: 768px) {
  .generic-slider-button {
    width: 45px;
    height: 45px;
    font-size: var(--45px);
    line-height: 28px;
  }
}
.generic-slider-button:focus, .generic-slider-button:active {
  border-radius: 100%;
  background: var(--apt-grey-9);
}

.grid-item.bg-white .generic-slider-button {
  background: var(--apt-white);
}
.grid-item.bg-white .generic-slider-button:focus, .grid-item.bg-white .generic-slider-button:active {
  background: var(--apt-white);
}

@media (min-width: 1440px) {
  .generic-slider-prev-button-container .generic-slider-button {
    transform: translateX(-100%) translateY(-50%);
  }
}

@media (min-width: 1440px) {
  .generic-slider-next-button-container .generic-slider-button {
    transform: translateX(0%) translateY(-50%);
  }
}

.generic-slider-button[disabled] {
  color: rgba(178, 178, 176, 0.2);
}

.generic-slider-grid--force-reload {
  border-radius: 0 !important;
}

.generic-slider .generic-slide {
  padding: 10px;
}
@media (min-width: 992px) {
  .generic-slider .generic-slide {
    padding: 15px;
  }
}
@media (min-width: 1200px) {
  .generic-slider .generic-slide {
    padding: 21px;
  }
}
@media (min-width: 1440px) {
  .generic-slider .generic-slide {
    padding: 25px;
  }
}

.generic-slide a:hover > .card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .generic-slide a:hover > .card {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 1200px) {
  .generic-slide a:hover > .card {
    box-shadow: var(--shadow-hover);
  }
}

.generic-slider-grid-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--10px);
}
@media (min-width: 512px) {
  .generic-slider-grid-container header {
    margin-bottom: var(--30px);
  }
}

.generic-slider-grid-container h1,
.generic-slider-grid-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.generic-slider-grid-link {
  display: block;
  padding-top: 1.5em;
}

.line-cta-container {
  display: block;
  text-decoration: none;
}
.line-cta-container:hover {
  text-decoration: none;
}
.line-cta-container:hover .inline-cta {
  border-color: var(--apt-primary);
}

.inline-cta {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--apt-grey-6);
}
@media (min-width: 768px) {
  .inline-cta {
    align-items: stretch;
  }
}

.inline-cta__figure {
  flex: 0 0 auto;
  width: 135px;
  height: 110px;
  padding: 15px;
}
@media (min-width: 512px) {
  .inline-cta__figure {
    width: 210px;
    height: 160px;
    padding: 30px;
  }
}
@media (min-width: 768px) {
  .inline-cta__figure {
    width: 100%;
    max-width: 250px;
    height: auto;
    padding: 0;
  }
}

.inline-cta__figure img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inline-cta__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;
  padding: 15px 15px 15px 0px;
}
@media (min-width: 512px) {
  .inline-cta__content {
    padding: 30px 40px 30px 0px;
  }
}
@media (min-width: 768px) {
  .inline-cta__content {
    padding: 30px 40px;
    min-height: 170px;
  }
}
.inline-cta__content .tag-list {
  margin: 0;
}
.inline-cta__content .tag-list span {
  font-size: var(--9px);
  line-height: 1.1em;
}
@media (min-width: 512px) {
  .inline-cta__content .tag-list span {
    font-size: var(--12px);
  }
}
.inline-cta__content .heading {
  margin: 0;
  font-size: var(--12px);
  line-height: 16px;
}
@media (min-width: 512px) {
  .inline-cta__content .heading {
    font-size: var(--22px);
    line-height: 26px;
  }
}

@media (min-width: 992px) {
  .inline-photo-gallery {
    padding-bottom: 40px;
  }
}

@media (min-width: 992px) {
  .inline-photo-gallery .generic-slider-prev-button-container {
    left: -25px;
  }
}

@media (min-width: 992px) {
  .inline-photo-gallery .generic-slider-next-button-container {
    right: -25px;
  }
}

.inline-photo-gallery .generic-slider-grid {
  width: calc(100% + 18px);
  margin-left: -9px;
}

@media (min-width: 992px) {
  .inline-photo-gallery .generic-slider__pagination {
    position: absolute;
    top: initial;
    right: 0;
    bottom: -33px !important;
    left: 0;
  }
}

.inline-photo-gallery .generic-slider__pagination .swiper-pagination-bullet {
  position: relative;
  width: 4px;
  height: 4px;
  border-width: 0;
  margin-left: 8px !important;
  margin-right: 8px !important;
  z-index: 1;
}
.inline-photo-gallery .generic-slider__pagination .swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  top: -6px;
  left: -6px;
  width: 16px;
  height: 15px;
  background: rgba(0, 0, 0, 0);
  z-index: 0;
}

.inline-photo-gallery .generic-slide {
  padding: 9px;
}

.inline-photo-gallery .generic-slide figure {
  position: static;
  width: 100%;
  height: auto;
  max-height: none;
  display: block;
  margin: 0;
  padding: 0;
}

.inline-photo-gallery .generic-slide img {
  position: static;
  width: 100%;
  max-width: 830px;
  height: auto;
  max-height: calc(100vh - 160px);
  object-fit: contain;
  margin: auto;
  display: block;
}

@media (min-width: 1100px) {
  .inline-photo-gallery .gallery-overlay {
    padding: 80px 110px 80px 110px;
  }
}

.inline-photo-gallery .gallery-overlay__content {
  display: flex;
  height: 100%;
  padding: 0;
}

@media (min-width: 768px) {
  .inline-photo-gallery .generic-slider__pagination {
    display: block;
  }
}

.inline-photo-gallery .gallery-overlay .generic-slider-grid {
  width: 100%;
  margin: auto;
}

.inline-photo-gallery .generic-slider-button {
  background-color: transparent;
}

.model-cards-list-container {
  position: relative;
  padding-top: 32px;
  padding-bottom: 34px;
}
@media (min-width: 992px) {
  .model-cards-list-container {
    padding-top: 46px;
    padding-bottom: 66px;
  }
}

.model-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 22px;
}
@media (min-width: 768px) {
  .model-cards-list-container > header {
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .model-cards-list-container > header {
    flex-wrap: nowrap;
  }
}

.model-cards-list-container > header .tag-list {
  align-content: flex-start;
  margin-top: var(--34px);
  margin-bottom: 0;
  min-height: 56px;
}
@media (min-width: 992px) {
  .model-cards-list-container > header .tag-list {
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: -15px;
    margin-bottom: -18px;
    height: 196px;
  }
}

.model-cards-list-container h1,
.model-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
  max-width: 550px;
  flex: 1 1 auto;
}

.model-cards-list-container .model-card header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 1 auto;
}
@media (min-width: 1200px) {
  .model-cards-list-container .model-card header {
    flex-wrap: nowrap;
  }
}

.model-cards-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.model-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;
}
@media (min-width: 660px) {
  .model-cards-list-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .model-cards-list-item {
    width: 33.333%;
  }
}

.model-cards-list-item .card figure {
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  flex: 0 0 auto;
}

.model-cards-list-item .card figure img {
  position: static;
  width: 100%;
  height: 164px;
  object-fit: contain;
  min-height: 0;
  aspect-ratio: unset;
}

.model-cards-list-item .card .card__content {
  padding: 16px 22px;
}
@media (min-width: 992px) {
  .model-cards-list-item .card .card__content {
    padding: 16px 36px 36px 34px;
  }
}

.model-cards-list-item .read-more-button {
  position: absolute;
  top: 26px;
  right: 30px;
  font-size: var(--16px);
  line-height: 1.5px;
}

.model-cards-list-item .read-more-button > span {
  text-align: right;
}

.model-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.model-card .card__content > span {
  color: var(--apt-grey-4);
}

.model-card .card__content > ul:not(.checks) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.model-card .card__content > ul:not(.checks) li {
  padding-right: 0.5em;
  position: relative;
}

.model-card .card__content > ul:not(.checks) li:not(:first-child) {
  padding-left: 0.5em;
}

.model-card .card__content > ul:not(.checks) li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.model-card .card__content .heading {
  margin-bottom: 15px;
}

.model-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.model-card {
  position: relative;
  padding-top: 44px;
  display: flex;
  flex-direction: column;
}

.model-card-listing-wrapper {
  width: 100%;
  margin-top: 28px;
  flex: 0 0 auto;
}
.model-card-listing-wrapper:last-child {
  margin-bottom: -18px;
}
@media (min-width: 768px) {
  .model-card-listing-wrapper:last-child {
    margin-bottom: -28px;
  }
}

.model-card-listing-wrapper:not(:last-child) .model-card-listing:last-child {
  border-bottom: 1px solid var(--apt-grey-7);
  margin-bottom: 33px;
}

.model-card-listing {
  position: relative;
  width: 100%;
  padding: 14px 0 19px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.model-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;
}

.model-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
}

.model-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
}

.model-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 0;
}

.model-title-merk {
  font-size: var(--14px);
  line-height: 19px;
  letter-spacing: -0.1px;
  font-weight: var(--fw-regular);
}
@media (min-width: 768px) {
  .model-title-merk {
    font-size: var(--18px);
    line-height: 30px;
    letter-spacing: -0.3px;
  }
}

.model-title-model {
  font-size: var(--31px);
  line-height: 38px;
  letter-spacing: -0.4px;
  font-weight: var(--fw-medium);
}
@media (min-width: 768px) {
  .model-title-model {
    font-size: var(--40px);
    line-height: 44px;
    letter-spacing: -0.5px;
  }
}

.extended-model-card {
  padding-top: 74px;
  overflow: hidden;
}

.extended-model-card .card__content > b,
.extended-model-card-overlay div > b {
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
  padding-bottom: 16px;
  display: block;
}

.extended-model-card .card__content > span {
  color: var(--apt-grey-4);
}

.extended-model-card-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: var(--apt-white);
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease-in;
}

.extended-model-card-overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.2s ease-in 0s;
}

.extended-model-card-overlay-content > div {
  width: 100%;
  flex: 1 1 auto;
  padding: 44px 33px 33px;
  overflow: auto;
}
@media (min-width: 768px) {
  .extended-model-card-overlay-content > div {
    padding: 74px 33px 33px;
  }
}

.extended-model-card-overlay-content button {
  flex: 0 0 auto;
}

.extended-model-card-overlay--toggled {
  transform: none;
}

.extended-model-card-overlay--toggled .extended-model-card-overlay-content {
  opacity: 1;
  transition: opacity 0.2s ease-in 0.2s;
}

.show-more-button {
  color: var(--apt-grey-4);
  padding-left: 2.1em;
  margin-top: 1em;
  font-size: var(--14px);
  line-height: 1;
}

.show-less-button {
  width: 100%;
  padding: 33px;
  text-align: left;
  color: var(--apt-grey-4);
  font-size: var(--14px);
  line-height: 1;
}

.news-list-container h2 {
  margin-bottom: var(--55px);
}

.card.news-card {
  display: flex;
  flex-direction: column;
}
.card.news-card figure {
  flex: 0 0 auto;
}
.card.news-card .card__content {
  flex: 1 1 auto;
}
.card.news-card .card__content h1.heading.heading-3 {
  flex: 1 1 auto;
}
.card.news-card .card__content footer {
  flex: 0 0 auto;
}

.news-card .card__content .heading {
  margin-bottom: 27px;
}

.news-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: var(--16px);
  line-height: 24px;
  padding-right: 0.5em;
}
.news-card footer .read-more-button .fal {
  padding-left: 0.5em;
}

.news-cards-list-container {
  position: relative;
  padding-top: 56px;
  padding-bottom: 34px;
}
@media (min-width: 992px) {
  .news-cards-list-container {
    padding-top: 46px;
    padding-bottom: 64px;
  }
}

.news-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--34px);
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .news-cards-list-container header {
    flex-wrap: nowrap;
    margin-bottom: 112px;
  }
}

.news-cards-list-container header .tag-list {
  align-content: flex-start;
  margin-top: var(--34px);
  margin-bottom: 0;
  min-height: 56px;
}
@media (min-width: 992px) {
  .news-cards-list-container header .tag-list {
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: -15px;
    margin-bottom: -5px;
    height: 200px;
  }
}

.news-cards-list-container h1,
.news-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
  max-width: 550px;
  flex: 1 1 auto;
}

.news-cards-list-container .news-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.news-list-item {
  padding: 0 15px 30px;
  margin-bottom: 0;
  width: 100%;
}
@media (min-width: 612px) {
  .news-list-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .news-list-item {
    width: 33.333%;
  }
}

.opening-hours-card {
  width: 100%;
  background-color: var(--apt-white);
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;
}
@media (min-width: 768px) {
  .opening-hours-card {
    display: flex;
  }
}

.opening-hours-card--bottom-radius {
  border-bottom-left-radius: var(--10px);
  border-bottom-right-radius: var(--10px);
}

.opening-hours-card__address {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .opening-hours-card__address {
    width: 331px;
  }
}

.opening-hours-card__address figure {
  width: 100%;
  max-height: 335px;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .opening-hours-card__address figure {
    height: 174px;
  }
}

.opening-hours-card__address figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 335px;
}

.opening-hours-card__address address {
  padding: 20px;
  flex: 1 0 auto;
}
@media (min-width: 768px) {
  .opening-hours-card__address address {
    padding: 30px 30px 20px;
  }
}

.opening-hours-card__address address span:last-of-type {
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
}
.opening-hours-card__address address span:last-of-type br {
  display: none;
}

.opening-hours-card__address address span:last-of-type:not(:only-of-type) {
  margin-top: var(--7px);
}

.opening-hours-card__address address > span:last-of-type:not(:only-of-type) > span:first-of-type:not(:only-of-type),
.opening-hours-card__address address > span:last-of-type:not(:only-of-type) > a {
  display: block;
}

.opening-hours-card__address address a {
  text-decoration: none;
}

.opening-hours-card__listing {
  width: auto;
  flex: 1 1 auto;
  padding: 20px;
}
@media (min-width: 768px) {
  .opening-hours-card__listing {
    padding: 45px 50px 55px;
    border-left: 1px solid var(--apt-grey-7);
  }
}

.opening-hours-card__listing .heading-4 {
  margin-bottom: var(--10px);
}

.opening-hours-card__listing .dropdown__toggle {
  font-weight: var(--fw-medium);
  font-size: var(--16px);
  letter-spacing: 0.1px;
}

.opening-hours-card__listing .dropdown-wrapper::after {
  right: 0;
}

.opening-hours-card__single-service {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 var(--10px);
  box-shadow: 0 0 0 0;
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  height: var(--40px);
  letter-spacing: normal;
  line-height: 28px;
  font-weight: normal;
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
}

.opening-hours-card__listing table.visually-hidden {
  position: absolute;
  top: 0;
}

.opening-hours-card__listing table {
  width: 100%;
  table-layout: fixed;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  margin: 26px 0 0;
  text-align: left;
  border: 0;
  border-collapse: collapse;
}

.opening-hours-card__listing th {
  font-weight: var(--fw-regular);
  padding: 0;
  margin: 0;
}

.opening-hours-card__listing td {
  width: 46%;
  min-width: 110px;
  max-width: 138px;
}

.opening-hours-ctas {
  width: 100%;
  margin-top: 43px;
}
@media (min-width: 768px) {
  .opening-hours-ctas {
    display: flex;
    flex-wrap: wrap;
  }
}

.opening-hours-ctas__blue-btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding-left: var(--15px);
  padding-right: var(--15px);
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .opening-hours-ctas__blue-btns {
    width: 331px;
    padding-left: 0;
    padding-right: 50px;
  }
}

.opening-hours-ctas__blue-btns a,
.opening-hours-ctas__blue-btns input[type=button],
.opening-hours-ctas__blue-btns input[type=submit],
.opening-hours-ctas__blue-btns button {
  margin-bottom: var(--11px);
}

.opening-hours__footer {
  width: 100%;
  flex: 1 0 auto;
  padding: 0px 15px 2px;
  font-size: var(--13px);
  line-height: 14px;
  letter-spacing: 0.28px;
  font-weight: var(--fw-medium);
  background-color: var(--apt-grey-6);
  color: var(--apt-grey-2);
  text-align: center;
  margin-top: 44px;
}
@media (min-width: 768px) {
  .opening-hours__footer {
    font-size: var(--14px);
    line-height: 20px;
  }
}

.opening-hours-card--compact .opening-hours-card__listing {
  padding: 20px;
  background-color: var(--apt-white);
}
@media (min-width: 768px) {
  .opening-hours-card--compact .opening-hours-card__listing {
    padding: 23px 29px 37px;
    background-color: var(--apt-grey-9);
  }
}

.opening-hours-card--compact .opening-hours-card__address address {
  padding: 20px;
  line-height: 22px;
}
@media (min-width: 768px) {
  .opening-hours-card--compact .opening-hours-card__address address {
    padding: 20px 30px 20px;
  }
}

.block-overlay {
  position: absolute;
}

.overlay {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
  right: 0;
}

.overlay-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s linear;
}

.overlay-content {
  height: 100vh;
  background-color: var(--apt-grey-9);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  box-shadow: 8px 0 18px 0 rgba(0, 0, 0, 0.2), 18px 0 48px 2px rgba(0, 0, 0, 0.2);
  transform: translateX(calc(100% + 48px));
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 636px) {
  .overlay--narrow .overlay-content {
    width: 636px;
  }
}
@media (max-width: 635px) {
  .overlay--narrow .overlay-content {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 636px) {
  .overlay--narrow .overlay-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: translateX(calc(100% + 48px));
  }
}

@media (min-width: 636px) {
  .overlay--narrow.overlay--open .overlay-content {
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  .overlay:not(.overlay--narrow) .overlay-content {
    width: 1024px;
  }
}
@media (max-width: 1023px) {
  .overlay:not(.overlay--narrow) .overlay-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1024px) {
  .overlay:not(.overlay--narrow) .overlay-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: translateX(calc(100% + 48px));
  }
}

@media (min-width: 1024px) {
  .overlay:not(.overlay--narrow).overlay--open .overlay-content {
    transform: translateX(0);
  }
}

.overlay-content__header {
  position: relative;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: var(--apt-white);
  border-bottom: 1px solid var(--apt-grey-7);
}

@media (max-width: 767px) {
  .overlay-content-progress {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 38px 0 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--apt-grey-5);
  }
}

@media (min-width: 768px) {
  .overlay-content-progress__current-step {
    display: none;
  }
}

.overlay-content-progress__breadcrumbs-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overlay-content-progress__breadcrumbs-container input[type=button],
.overlay-content-progress__breadcrumbs-container input[type=submit],
.overlay-content-progress__breadcrumbs-container button {
  font-size: var(--16px);
  color: var(--apt-grey-5);
}
@media (min-width: 768px) {
  .overlay-content-progress__breadcrumbs-container input[type=button],
.overlay-content-progress__breadcrumbs-container input[type=submit],
.overlay-content-progress__breadcrumbs-container button {
    display: none;
  }
}
.overlay-content-progress__breadcrumbs-container input[type=button]:first-of-type,
.overlay-content-progress__breadcrumbs-container input[type=submit]:first-of-type,
.overlay-content-progress__breadcrumbs-container button:first-of-type {
  margin-right: 8px;
}
.overlay-content-progress__breadcrumbs-container input[type=button]:last-of-type,
.overlay-content-progress__breadcrumbs-container input[type=submit]:last-of-type,
.overlay-content-progress__breadcrumbs-container button:last-of-type {
  margin-left: 8px;
}

.overlay-content-progress__breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-size: var(--16px);
  line-height: 24px;
}
@media (min-width: 768px) {
  .overlay-content-progress__breadcrumbs {
    position: absolute;
    top: 0;
    right: 0;
    justify-content: flex-end;
    margin: 0 50px 0 20px;
    height: 60px;
  }
}
.overlay-content-progress__breadcrumbs li {
  padding: 0 7px;
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li {
    padding: 0;
  }
}
.overlay-content-progress__breadcrumbs li > a {
  color: var(--apt-grey-3);
  text-decoration: none;
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li > a {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border: 8px solid var(--apt-grey-9);
    background-color: var(--apt-grey-7);
    border-radius: 50%;
  }
}
.overlay-content-progress__breadcrumbs li > a:hover {
  color: var(--apt-black);
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li > a:hover > span {
    display: block;
  }
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li > a.done {
    background-color: var(--apt-grey-5);
  }
}
.overlay-content-progress__breadcrumbs li > a.active {
  color: var(--apt-black);
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li > a.active {
    background-color: var(--apt-primary);
  }
}
@media (max-width: 767px) {
  .overlay-content-progress__breadcrumbs li > a > span {
    position: absolute;
    bottom: 100%;
    left: 50%;
    background-color: var(--apt-black);
    width: max-content;
    padding: 0.65em 0.65em calc(0.65em + 10px) 0.65em;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), calc(50% + 10px) calc(100% - 10px), 50% 100%, calc(50% - 10px) calc(100% - 10px), 0% calc(100% - 10px));
    font-size: var(--14px);
    color: var(--apt-white);
    transform: translateY(-2px) translateX(-50%);
    display: none;
  }
}

.overlay-content__close-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 60px;
  background: none;
  border: 0;
  padding: 0;
  margin: 0 16px;
  box-shadow: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  color: black;
  font-size: var(--14px);
  line-height: 20px;
}
@media (min-width: 768px) {
  .overlay-content__close-btn {
    margin: 0 35px;
  }
}
.overlay-content__close-btn i {
  margin-right: 17px;
  font-size: var(--20px);
  line-height: 21px;
}

.overlay-content-header {
  position: relative;
  width: 100%;
  z-index: 3;
}
@media (min-width: 768px) {
  .overlay-content-header {
    flex: 0 0 auto;
  }
}

.overlay-content-article {
  width: 100%;
  overflow-y: auto;
}

.overlay-content-article__wrapper {
  width: 100%;
}
@media (min-width: 768px) {
  .overlay-content-article__wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 auto;
  }
}

.overlay-content-article__aside {
  width: 100%;
  background-color: var(--apt-grey-8);
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .overlay-content-article__aside {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 45.7%;
    min-width: 300px;
    min-height: calc(100vh - 60px);
    border-top: 0;
    border-right: 1px solid var(--apt-grey-7);
  }
}
@media (min-width: 1024px) {
  .overlay-content-article__aside {
    width: 389px;
    flex: 0 0 auto;
  }
}
.overlay-content-article__aside figure img {
  width: 100%;
}

.overlay-content-article__figure {
  width: 100%;
  height: 265px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--apt-white);
}
.overlay-content-article__figure img {
  display: block;
  max-width: 250px;
  max-height: 250px;
}

.overlay-content-article__section {
  position: relative;
  padding: 15px;
  font-size: var(--14px);
  line-height: 22px;
  font-weight: var(--fw-regular);
  width: 100%;
  max-width: 296px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1024px) {
  .overlay-content-article__section {
    padding: 32px 58px 15px 61px;
    width: auto;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .overlay-content-article__tooltip-container + .overlay-content-article__section {
    padding-top: 15px;
  }
}

.overlay-content-article__heading {
  display: block;
  margin-bottom: 10px;
}

.overlay-content-article__price {
  display: block;
  font-size: var(--40px);
  line-height: 63px;
  font-weight: var(--fw-semibold);
  color: var(--apt-black);
  margin-bottom: 10px;
}
.overlay-content-article__price span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

.overlay-content-article__description {
  display: block;
  color: var(--apt-grey-2);
  margin-bottom: 10px;
}

.overlay-content-article__tooltip-container {
  position: relative;
  z-index: 1;
}

.overlay-content-article__tooltip-container + .overlay-content-article__section .overlay-content-article__heading {
  padding-right: 24px;
}

.overlay-content-article__tooltip {
  position: absolute;
  top: 15px;
  right: 23px;
}

.overlay-content-article ul:not(.wm-field-list),
.overlay-content-article ol {
  margin-top: 18px;
  margin-bottom: 10px;
}

.overlay-content-article main {
  padding: 38px 16px;
  flex: 1 1 auto;
  align-self: flex-start;
}
@media (min-width: 768px) {
  .overlay-content-article main {
    padding: 50px;
  }
}
.overlay-content-article main .subheading {
  font-size: var(--18px);
  line-height: 30px;
  color: var(--apt-grey-3);
  margin-top: -4px;
}
.overlay-content-article main p {
  margin-bottom: var(--38px);
}
.overlay-content-article main .button-container {
  margin-top: var(--44px);
}

.overlay-content-article .form-grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -14px;
  width: calc(100% + 28px);
}
@media (min-width: 768px) {
  .overlay-content-article .form-grid {
    width: calc(100% - 6px);
  }
}
.overlay-content-article .form-grid .text-input-wrapper {
  margin-top: -25px;
}
.overlay-content-article .form-grid-item {
  width: 100%;
  padding: 14px;
}
.overlay-content-article .form-grid-item--50 {
  width: 50%;
}
.overlay-content-article .form-grid-item--25 {
  width: 25%;
}
@media (max-width: 511px) {
  .overlay-content-article .form-grid-item {
    width: 100%;
  }
}

.overlay-content-article__warning-banner {
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 430px;
  display: block;
  margin: 0 auto;
}

.overlay-table {
  width: 100%;
  table-layout: fixed;
  margin: 26px 0 0;
  text-align: left;
  border: 0;
  border-collapse: collapse;
}
.overlay-table caption {
  padding: 0 61px 31px;
  font-size: var(--14px);
  line-height: 22px;
  text-align: left;
}
.overlay-table tr:nth-child(odd) {
  background-color: var(--apt-white);
}
.overlay-table th {
  width: 50%;
  font-size: var(--14px);
  line-height: var(--22px);
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  font-weight: var(--fw-regular);
  padding: 9.5px 5px 9.5px 15px;
}
@media (min-width: 1024px) {
  .overlay-table th {
    padding: 9.5px 5px 9.5px 60px;
  }
}
.overlay-table td {
  width: 50%;
  font-size: var(--16px);
  line-height: var(--24px);
  letter-spacing: 0.1px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  padding: 7px 15px 7px 5px;
}
@media (min-width: 1024px) {
  .overlay-table td {
    padding: 7px 60px 7px 22px;
  }
}

.overview-cards-list-container {
  position: relative;
  padding-top: 56px;
  padding-bottom: 34px;
}
@media (min-width: 992px) {
  .overview-cards-list-container {
    padding-top: 101px;
    padding-bottom: 71px;
  }
}

.overview-cards-list-container .content-card {
  display: flex;
  flex-direction: column;
}

.overview-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--25px);
}
@media (min-width: 512px) {
  .overview-cards-list-container header {
    margin-bottom: var(--68px);
  }
}

.overview-cards-list-container h1,
.overview-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.overview-cards-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.overview-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;
}
@media (min-width: 612px) {
  .overview-cards-list-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .overview-cards-list-item {
    width: 25%;
  }
}

.overview-cards-list-item--featured {
  width: 100%;
}
@media (min-width: 612px) {
  .overview-cards-list-item--featured {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .overview-cards-list-item--featured {
    width: 33.333%;
  }
}

.overview-cards-list-item--cta {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1200px) {
  .overview-cards-list-item--cta {
    width: 50%;
  }
}
.overview-cards-list-item--cta div {
  max-width: 333px;
}
.overview-cards-list-item--cta a {
  margin-top: 1em;
}

.overview-cards-list-item:not(.overview-cards-list-item--featured) .card__content .heading {
  flex: 1 1 auto;
}
@media (min-width: 1200px) {
  .overview-cards-list-item:not(.overview-cards-list-item--featured) .card__content .heading {
    font-size: var(--18px);
    line-height: 28px;
  }
}

.overview-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.overview-card .card__content .heading {
  margin-bottom: 32px;
}

.overview-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.offer-cards-list-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 34px;
}
@media (min-width: 992px) {
  .offer-cards-list-container {
    padding-top: 71px;
    padding-bottom: 71px;
  }
}

.offer-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--25px);
}
@media (min-width: 512px) {
  .offer-cards-list-container > header {
    margin-bottom: var(--68px);
  }
}
@media (min-width: 1200px) {
  .offer-cards-list-container > header {
    flex-wrap: nowrap;
  }
}

.offer-cards-list-container h1,
.offer-cards-list-container h2 {
  padding-right: 1.5em;
  margin-bottom: 0;
}

.offer-cards-results-sort {
  width: 100%;
  max-width: 400px;
  flex: 0 0 auto;
  padding-top: 1em;
}

.offer-cards-results-header {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  padding-top: 1em;
}
@media (min-width: 512px) {
  .offer-cards-results-header {
    flex-direction: row;
  }
}

.offer-cards-results-header__quantity {
  flex: 0 0 auto;
  font-size: var(--18px);
  line-height: 30px;
  color: var(--apt-black);
  font-weight: var(--fw-regular);
  margin-right: 2em;
  margin-bottom: 1em;
}
@media (min-width: 512px) {
  .offer-cards-results-header__quantity {
    margin-bottom: 0;
  }
}

.offer-cards-results-header .tag-list {
  flex: 1 1 auto;
}

.offer-cards-results-header__quantity > span {
  display: inline-block;
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
  padding-right: 0.25em;
}

.offer-cards-list {
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 920px) {
  .offer-cards-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

@media (max-width: 919px) {
  .offer-cards-list-container .offer-cards-list {
    width: calc(100% + 20px);
  }
}

.offer-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;
}
@media (min-width: 660px) {
  .offer-cards-list-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .offer-cards-list-item {
    width: 33.333%;
  }
}

.offer-cards-list-item .card__content {
  padding-top: 20px;
}

.offer-cards-list-item .news-card figure {
  width: 100%;
  flex: 1 1 auto;
  padding: 0;
}
.offer-cards-list-item .news-card figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.offer-cards-list-item .news-card .card__content {
  flex: 0 0 auto;
}

@media (max-width: 659px) {
  .offer-cards-list-item .offer-card-featured {
    max-height: 700px;
  }
}
@media (min-width: 660px) {
  .offer-cards-list-item .offer-card-featured {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }
}
.offer-cards-list-item .offer-card-featured figure {
  width: 100%;
  flex: 1 1 auto;
  padding: 0;
}
.offer-cards-list-item .offer-card-featured figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.offer-cards-list-item .offer-card-featured footer {
  flex-wrap: wrap;
  justify-content: flex-start;
}
.offer-cards-list-item .offer-card-featured .card__content {
  flex: 0 0 auto;
}

.featured-card-link-wrapper {
  position: relative;
}

@media (min-width: 1200px) {
  .offer-cards-list-item.swiper-slide {
    width: 100%;
  }
}

.offer-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.offer-card .card__content > span {
  color: var(--apt-grey-4);
}

.offer-card .card__content > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.offer-card .card__content > ul li {
  padding-right: 0.5em;
  margin-right: 0.5em;
  position: relative;
}

.offer-card .card__content > ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.offer-card .card__content .heading {
  margin-bottom: 15px;
}

.offer-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.offer-card {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: 659px) {
  .offer-cards-list-item--featured {
    position: relative;
    height: 0;
    padding-top: calc(135% + 30px);
  }
}

@media (max-width: 659px) {
  .offer-cards-list-item--featured .offer-card {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 30px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
}

.offer-cards-list-item--featured .offer-card figure {
  height: 100%;
  border-bottom-right-radius: var(--10px);
  border-bottom-left-radius: var(--10px);
}
.offer-cards-list-item--featured .offer-card figure img {
  height: 100%;
  aspect-ratio: none;
}

.offer-cards-list-item--featured .offer-card .card__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.offer-card-listing-wrapper {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -18px;
}

.offer-card-listing {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.offer-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;
}

.offer-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.offer-card-listing > span:last-child s {
  font-size: var(--16px);
  line-height: 28px;
  letter-spacing: 0.89px;
  display: inline-block;
}
@media (min-width: 992px) {
  .offer-card-listing > span:last-child s {
    padding-right: 5px;
  }
}

.offer-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
  font-size: var(--18px);
  letter-spacing: 1px;
}
@media (min-width: 992px) {
  .offer-card-listing > span:last-child strong {
    padding-left: 2px;
  }
}

/* Exception for Zakelijk leasen where the grow-segment is removed, so the grow has to be repositioned */
.zakelijk-leasen-compact-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.zakelijk-leasen-compact-card .apply-cta {
  margin-bottom: 0;
}

.zakelijk-leasen-compact-card .offer-card-listing-wrapper {
  margin-top: 0;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  z-index: 20;
  background: none;
  border: 0;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.popup::backdrop {
  background: none;
}

.popup-spacing {
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.popup-box-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow: auto;
}
@media (min-width: 768px) {
  .popup-box-container {
    padding: 2.5rem;
  }
}

.popup-box {
  position: relative;
  width: 100%;
  padding: 35px 20px 20px 20px;
  margin: auto;
  background-color: var(--apt-white);
  border-radius: 10px;
}
@media (min-width: 768px) {
  .popup-box {
    padding: 75px 50px 34px 60px;
  }
}

.popup-box .wmpform {
  margin-top: 0;
}

.popup-box__cross {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--22px);
}
@media (min-width: 768px) {
  .popup-box__cross {
    top: 2rem;
    right: 2rem;
    width: 1.25rem;
    height: 1.25rem;
    font-size: var(--28px);
  }
}

.popup-box .heading strong {
  font-weight: 600;
}

.popup-box .heading-2 {
  line-height: 1.1;
  font-weight: 400;
  margin-bottom: 1.4rem;
}

.popup-box .button.button--primary {
  min-height: 35px;
  padding: 4px 24px 6px;
}

.popup-box .dropdown-toggle {
  margin-top: 12px;
  padding-top: 8px;
  padding-bottom: 2px;
  min-height: 45px;
}

.popup-box .dropdown-toggle .fal {
  font-size: 0.66em;
}

.popup-box .dropdown-toggle .brand-label {
  font-size: var(--14px);
  line-height: 1.55;
}

.popup-box form.wmpform input[type=text],
.popup-box form.wmpform input[type=search],
.popup-box form.wmpform input[type=email],
.popup-box form.wmpform input[type=password],
.popup-box form.wmpform input[type=tel],
.popup-box form.wmpform input[type=number],
.popup-box form.wmpform select,
.popup-box form.wmpform textarea {
  height: 45px !important;
  margin-top: 9px !important;
  font-size: var(--14px);
  line-height: 45px !important;
  padding: 1.15em 0 0.6em !important;
}
.popup-box form.wmpform input[type=text]:autofill, .popup-box form.wmpform input[type=text]:autofill:hover, .popup-box form.wmpform input[type=text]:autofill:focus, .popup-box form.wmpform input[type=text]:autofill:active, .popup-box form.wmpform input[type=text]:-webkit-autofill, .popup-box form.wmpform input[type=text]:-webkit-autofill:hover, .popup-box form.wmpform input[type=text]:-webkit-autofill:focus, .popup-box form.wmpform input[type=text]:-webkit-autofill:active,
.popup-box form.wmpform input[type=search]:autofill,
.popup-box form.wmpform input[type=search]:autofill:hover,
.popup-box form.wmpform input[type=search]:autofill:focus,
.popup-box form.wmpform input[type=search]:autofill:active,
.popup-box form.wmpform input[type=search]:-webkit-autofill,
.popup-box form.wmpform input[type=search]:-webkit-autofill:hover,
.popup-box form.wmpform input[type=search]:-webkit-autofill:focus,
.popup-box form.wmpform input[type=search]:-webkit-autofill:active,
.popup-box form.wmpform input[type=email]:autofill,
.popup-box form.wmpform input[type=email]:autofill:hover,
.popup-box form.wmpform input[type=email]:autofill:focus,
.popup-box form.wmpform input[type=email]:autofill:active,
.popup-box form.wmpform input[type=email]:-webkit-autofill,
.popup-box form.wmpform input[type=email]:-webkit-autofill:hover,
.popup-box form.wmpform input[type=email]:-webkit-autofill:focus,
.popup-box form.wmpform input[type=email]:-webkit-autofill:active,
.popup-box form.wmpform input[type=password]:autofill,
.popup-box form.wmpform input[type=password]:autofill:hover,
.popup-box form.wmpform input[type=password]:autofill:focus,
.popup-box form.wmpform input[type=password]:autofill:active,
.popup-box form.wmpform input[type=password]:-webkit-autofill,
.popup-box form.wmpform input[type=password]:-webkit-autofill:hover,
.popup-box form.wmpform input[type=password]:-webkit-autofill:focus,
.popup-box form.wmpform input[type=password]:-webkit-autofill:active,
.popup-box form.wmpform input[type=tel]:autofill,
.popup-box form.wmpform input[type=tel]:autofill:hover,
.popup-box form.wmpform input[type=tel]:autofill:focus,
.popup-box form.wmpform input[type=tel]:autofill:active,
.popup-box form.wmpform input[type=tel]:-webkit-autofill,
.popup-box form.wmpform input[type=tel]:-webkit-autofill:hover,
.popup-box form.wmpform input[type=tel]:-webkit-autofill:focus,
.popup-box form.wmpform input[type=tel]:-webkit-autofill:active,
.popup-box form.wmpform input[type=number]:autofill,
.popup-box form.wmpform input[type=number]:autofill:hover,
.popup-box form.wmpform input[type=number]:autofill:focus,
.popup-box form.wmpform input[type=number]:autofill:active,
.popup-box form.wmpform input[type=number]:-webkit-autofill,
.popup-box form.wmpform input[type=number]:-webkit-autofill:hover,
.popup-box form.wmpform input[type=number]:-webkit-autofill:focus,
.popup-box form.wmpform input[type=number]:-webkit-autofill:active,
.popup-box form.wmpform select:autofill,
.popup-box form.wmpform select:autofill:hover,
.popup-box form.wmpform select:autofill:focus,
.popup-box form.wmpform select:autofill:active,
.popup-box form.wmpform select:-webkit-autofill,
.popup-box form.wmpform select:-webkit-autofill:hover,
.popup-box form.wmpform select:-webkit-autofill:focus,
.popup-box form.wmpform select:-webkit-autofill:active,
.popup-box form.wmpform textarea:autofill,
.popup-box form.wmpform textarea:autofill:hover,
.popup-box form.wmpform textarea:autofill:focus,
.popup-box form.wmpform textarea:autofill:active,
.popup-box form.wmpform textarea:-webkit-autofill,
.popup-box form.wmpform textarea:-webkit-autofill:hover,
.popup-box form.wmpform textarea:-webkit-autofill:focus,
.popup-box form.wmpform textarea:-webkit-autofill:active {
  font-size: var(--14px);
  line-height: 45px !important;
}
.popup-box form.wmpform input[type=text]:autofill::first-line,
.popup-box form.wmpform input[type=search]:autofill::first-line,
.popup-box form.wmpform input[type=email]:autofill::first-line,
.popup-box form.wmpform input[type=password]:autofill::first-line,
.popup-box form.wmpform input[type=tel]:autofill::first-line,
.popup-box form.wmpform input[type=number]:autofill::first-line,
.popup-box form.wmpform select:autofill::first-line,
.popup-box form.wmpform textarea:autofill::first-line {
  font-size: var(--14px);
  line-height: 45px !important;
}
.popup-box form.wmpform input[type=text]:-webkit-autofill::first-line,
.popup-box form.wmpform input[type=search]:-webkit-autofill::first-line,
.popup-box form.wmpform input[type=email]:-webkit-autofill::first-line,
.popup-box form.wmpform input[type=password]:-webkit-autofill::first-line,
.popup-box form.wmpform input[type=tel]:-webkit-autofill::first-line,
.popup-box form.wmpform input[type=number]:-webkit-autofill::first-line,
.popup-box form.wmpform select:-webkit-autofill::first-line,
.popup-box form.wmpform textarea:-webkit-autofill::first-line {
  font-size: var(--14px);
  line-height: 45px !important;
}

.popup-box form.wmpform input[type=text] ~ label,
.popup-box form.wmpform input[type=search] ~ label,
.popup-box form.wmpform input[type=email] ~ label,
.popup-box form.wmpform input[type=password] ~ label,
.popup-box form.wmpform input[type=tel] ~ label,
.popup-box form.wmpform input[type=number] ~ label,
.popup-box form.wmpform select ~ label,
.popup-box form.wmpform textarea ~ label {
  top: 20px !important;
  height: 24px !important;
  font-size: var(--14px);
  padding: 0 !important;
  line-height: 24px !important;
}

.popup-box .dropdown-content {
  position: absolute;
  top: 100%;
  z-index: 2;
  background-color: var(--apt-white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 22px 18px 22px 22px;
}

.popup-box .dropdown-content .content-wrapper label {
  font-size: var(--12px);
  line-height: 16px;
}

.search {
  width: 100%;
  margin-top: var(--48px);
}
@media (min-width: 992px) {
  .search {
    display: flex;
    margin-top: var(--94px);
  }
}

.search .dialog__title,
.search .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);
}
@media (min-width: 768px) {
  .search .dialog__title,
.search .opening-hours-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search-list {
  width: 100%;
  flex: 1 1 auto;
  margin-top: -0.7em;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
}
@media (min-width: 992px) {
  .search-list {
    max-width: 670px;
    margin-bottom: 96px;
  }
}

.search-results-list__item {
  position: relative;
  width: 100%;
  padding: 0 0 var(--6px);
  box-shadow: 0 0 0 0;
  margin: 0 0 0;
  font-size: var(--18px);
  line-height: 40px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
}
.search-results-list__item .heading {
  margin-bottom: var(--10px);
}
.search-results-list__item a {
  text-decoration: none;
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--apt-grey-7);
  transition: border-bottom-color 0.2s linear;
  padding-top: 29px;
  padding-bottom: 27px;
}
@media (min-width: 992px) {
  .search-results-list__item a {
    padding-top: 35px;
    padding-bottom: 43px;
  }
}
.search-results-list__item a:hover, .search-results-list__item a:focus, .search-results-list__item a:active {
  border-bottom-color: var(--apt-primary);
}
.search-results-list__item p {
  color: var(--apt-grey-3);
  font-weight: var(--fw-regular);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.search-results-list__item p strong, .search-results-list__item p b {
  font-weight: var(--fw-bold);
}

@media (max-width: 991px) {
  .search-results-list__item:last-child a {
    border-bottom: 0;
  }
}

.search__filters {
  flex: 0 0 auto;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 12px;
  background: var(--apt-white);
  border-radius: 10px;
}
@media (min-width: 992px) {
  .search__filters {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.search__filters .heading-2 {
  text-align: left;
  width: 200px;
  margin-bottom: 38px;
}
@media (max-width: 991px) {
  .search__filters .heading-2 {
    max-width: 50%;
    margin-bottom: 14px;
  }
}
@media (min-width: 512px) {
  .search__filters .heading-2 {
    width: auto;
  }
}
@media (min-width: 992px) {
  .search__filters .heading-2 {
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .search__filter-container .filter-dropdown {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 991px) {
  .search__filter-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--apt-white);
    z-index: 4;
    padding-top: 111px;
    transform: translateY(-100%);
    transition: transform 0.2s ease-in, margin-top 0.2s ease-in;
    padding-left: var(--15px);
    padding-right: var(--15px);
    overflow: auto;
  }
}

.search__filter-container--toggled {
  transform: none;
}

.search__filter-container .heading-2 {
  display: none;
}
@media (max-width: 991px) {
  .search__filter-container .heading-2 {
    display: block;
    margin-bottom: var(--39px);
  }
}

@media (max-width: 991px) {
  .search__filter-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.search__filter-open-button {
  display: none;
}
@media (max-width: 991px) {
  .search__filter-open-button {
    display: block;
    font-size: var(--16px);
    line-height: 24px;
    font-weight: var(--fw-regular);
    color: var(--apt-primary);
    margin-bottom: 14px;
  }
}

.search__filter-close-button {
  display: none;
}
@media (max-width: 991px) {
  .search__filter-close-button {
    position: absolute;
    top: 72px;
    right: var(--15px);
    display: block;
    font-size: var(--20px);
    line-height: 24px;
    font-weight: var(--fw-light);
    color: var(--apt-grey-2);
  }
}

@media (max-width: 991px) {
  .search__sticky-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 511px) {
  .search__sticky-container {
    max-width: none;
  }
}
@media (min-width: 992px) {
  .search__sticky-container {
    margin-bottom: 40px;
  }
}

.search__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;
}
@media (min-width: 768px) {
  .search__results {
    width: 100%;
    left: 0;
    max-width: 670px;
  }
}
@media (min-width: 992px) {
  .search__results {
    width: 100%;
    max-width: none;
    margin-top: calc(96px + 28px);
    margin-bottom: 96px;
  }
}

.search .dialog__title,
.search .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);
}
@media (min-width: 768px) {
  .search .dialog__title,
.search .opening-hours-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .search__result {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .search__result .dialog {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }
}
@media (min-width: 992px) {
  .search__result .dialog {
    padding-right: 0;
  }
}

.search__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.search__result .dialog .dialog__title .fa-times {
  display: none;
}

.search__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.search__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}

.tablist-container {
  width: 100%;
  margin-top: -5px;
}
.tablist-container--wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .tablist-container--wrapper .tabs--hide-on-mobile:first-of-type {
    display: none;
  }
  .tablist-container--wrapper .tabs:last-of-type {
    padding-right: var(--15px);
  }
  .tablist-container--wrapper .tabs:last-of-type .tab-button:first-of-type {
    margin-left: 0;
  }
}

.tab-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-align: left;
  margin-top: 4px;
}

.tab-panel .button:not(:last-child) {
  margin-bottom: 30px;
}

.tab-panel .heading {
  margin-top: -3px;
}

.tab-button {
  color: var(--apt-grey-2);
  text-decoration: none;
  font-size: var(--16px);
  letter-spacing: 0.3px;
  line-height: 24px;
  padding-bottom: 0.2em;
}

.tab-button:not(:last-child) {
  margin-right: 20px;
}

.tab-button--active {
  color: var(--apt-primary);
  text-decoration: underline;
  text-decoration-color: var(--apt-primary);
  text-underline-offset: 0.5em;
}

.tab-panel .arrow-btns:last-child {
  margin-bottom: -20px;
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 991px) {
  .tab-panel .arrow-btns:last-child {
    margin-bottom: -22px;
  }
}

.tab-panel .arrow-btns:last-child a:last-of-type {
  border-bottom: 0;
}

.nav-tablist {
  position: sticky;
  top: 53px;
  width: 100%;
  z-index: 2;
  padding-right: 0 !important;
  padding-left: 0 !important;
  background-color: var(--apt-white);
}
@media (max-width: 767px) {
  .nav-tablist--vdp .tab-button {
    color: var(--apt-grey-2) !important;
    font-size: var(--14px) !important;
  }
}
@media (min-width: 992px) {
  .nav-tablist {
    top: 60px;
  }
}

.navigatable:has(> .nav-tablist):not(.main-header__nav-bar) {
  display: contents;
}

.nav-tablist .tablist-container {
  margin-top: 0;
  overflow-x: auto;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.nav-tablist--vdp .tablist-container {
  padding-left: 10px;
}
@media (min-width: 768px) {
  .nav-tablist--vdp .tablist-container {
    padding-left: 0;
  }
}

.nav-tablist .tabs {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (min-width: 992px) {
  .nav-tablist .tabs {
    padding-top: 27px;
    padding-bottom: 27px;
    margin-bottom: 20px;
  }
}

.nav-tablist .tabs:first-child {
  padding-left: var(--15px);
}
@media (min-width: 768px) {
  .nav-tablist .tabs:first-child {
    padding-left: var(--50px);
  }
}
@media (min-width: 1200px) {
  .nav-tablist .tabs:first-child {
    padding-left: var(--85px);
  }
}
.nav-tablist .tabs:last-child {
  padding-right: calc(15px - 0.6rem);
}
@media (min-width: 768px) {
  .nav-tablist .tabs:last-child {
    padding-right: calc(50px - 0.6rem);
  }
}
@media (min-width: 1200px) {
  .nav-tablist .tabs:last-child {
    padding-right: calc(85px - 0.6rem);
  }
}

.navigatable.main-header__nav-bar .nav-tablist .tabs {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.nav-tablist .tab-button {
  font-size: var(--12px);
  letter-spacing: 0.25px;
  color: var(--apt-grey-3);
  flex: 0 0 auto;
  margin-right: 12px;
}
@media (min-width: 512px) {
  .nav-tablist .tab-button {
    font-size: var(--18px);
    letter-spacing: 0.5px;
    margin-right: 20px;
  }
}
.nav-tablist .tab-button--tag {
  border: 1px solid var(--apt-grey-3);
  border-radius: 1rem;
  padding: 0.25em 1em 0.45em !important;
  margin: 0 0.6rem;
}
.nav-tablist .tab-button--tag:hover, .nav-tablist .tab-button--tag:active {
  border-color: var(--apt-black);
}
.nav-tablist .tab-button--tag:focus-visible {
  border-radius: 1rem;
}
@media (max-width: 991px) {
  .nav-tablist .tab-button:last-child {
    padding-right: var(--15px);
  }
}

.nav-tablist .tab-button:hover,
.nav-tablist .tab-button:active,
.nav-tablist .tab-button:focus {
  color: var(--apt-black);
  text-decoration: none;
}

.tablist-card-container .card {
  border-top-left-radius: 0;
}

.tablist-card-container {
  position: relative;
  z-index: 1;
}
.tablist-card-container .tabs {
  display: flex;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: -2px;
  z-index: 0;
  overflow-x: auto;
}
.tablist-card-container .tab-button {
  position: relative;
  display: block;
  padding: 6px 10px 7px;
  margin-right: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: var(--apt-grey-2);
  background-color: var(--apt-grey-9);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, transparent 20%, transparent 100%);
  box-shadow: inset 0px 0px 0px 1px var(--apt-grey-6);
  white-space: nowrap;
  text-decoration: none;
}
@media (min-width: 375px) {
  .tablist-card-container .tab-button {
    font-size: 16px;
    padding: 8px 20px 9px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.tablist-card-container .tab-button:focus-visible {
  background-color: var(--apt-grey-6);
  text-transform: uppercase;
  text-underline-color: var(--apt-primary);
}
.tablist-card-container .tab-button:first-child {
  z-index: 1;
}
.tablist-card-container .tab-button:nth-child(2) {
  z-index: 2;
}
.tablist-card-container .tab-button:nth-child(3) {
  z-index: 3;
}
.tablist-card-container .tab-button:nth-child(4) {
  z-index: 4;
}
.tablist-card-container .tab-button:nth-child(5) {
  z-index: 5;
}
.tablist-card-container .tab-button:focus-visible {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
}
.tablist-card-container .tab-button:focus:not(:focus-visible) {
  box-shadow: inset 0px 0px 0px 1px var(--apt-grey-6);
}
.tablist-card-container .tab-button--active {
  border: 0;
  box-shadow: inset 0px 4px 6px 0px #2739B8;
  color: white;
  background-color: var(--apt-primary);
  z-index: 9;
}
.tablist-card-container .tab-button--active:focus-visible {
  background-color: #2739B8;
  text-underline-color: var(--apt-primary);
}
.tablist-card-container .tab-button--active:focus:not(:focus-visible) {
  box-shadow: inset 0px 4px 6px 0px #2739B8;
}
.tablist-card-container .tab-button::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0px -1px 11px 0px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 1px;
}
.tablist-card-container .card {
  z-index: 11;
}
.tablist-card-container .tab-panel .button:not(:last-child) {
  margin-bottom: 18px;
}
.tablist-card-container .button--primary-orange {
  background-color: var(--apt-action);
  border-color: var(--apt-action);
}
.tablist-card-container .button--secondary {
  padding: 11px 24px 13px;
}

.tag-list {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--7px);
}

.tag-list li {
  margin: 0 var(--6px) var(--6px) 0;
  line-height: 0;
}

.tag-list li a,
.tag-list li button[type=button],
.tag-list li input[type=button],
.tag-list li > span {
  display: inline-flex;
  font-size: var(--12px);
  line-height: 1.1em;
  letter-spacing: 0.46px;
  text-decoration: none;
  padding: 0.23em 0.75em 0.35em;
  background-color: var(--apt-grey-9);
  color: var(--apt-grey-3);
  border-radius: 2em;
  font-weight: var(--fw-semibold);
  transition: filter 0.2s ease, background-color 0.2s ease;
}

.tag-list--large li {
  margin: 0 var(--10px) var(--10px) 0;
}
@media (min-width: 512px) {
  .tag-list--large li {
    margin: 0 var(--15px) var(--15px) 0;
  }
}

.tag-list--large li a,
.tag-list--large li button[type=button],
.tag-list--large li input[type=button],
.tag-list--large li span {
  font-size: var(--16px);
  line-height: 1.25;
  padding: 0.29em 0.75em 0.39em;
  font-weight: var(--fw-medium);
}
@media (min-width: 512px) {
  .tag-list--large li a,
.tag-list--large li button[type=button],
.tag-list--large li input[type=button],
.tag-list--large li span {
    font-size: var(--18px);
    line-height: 1.22;
    padding: 0.29em 0.75em 0.39em;
  }
}

.tag-list--filters .tag {
  align-items: baseline;
  border: 1px solid var(--apt-grey-4);
  color: var(--apt-grey-3);
}

.tag-list--filters .tag-close {
  width: 1em;
  text-align: center;
  line-height: 1;
  padding: 0 0 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0.8em;
  position: relative;
  bottom: -0.05em;
}

.tag-list--filters .tag-close i {
  line-height: 0.7em;
  color: var(--apt-grey-3) !important;
}

.tag-list li a.tag,
.tag-list li button[type=button].tag,
.tag-list li input[type=button].tag,
.tag-list li > span.tag {
  background-color: var(--apt-grey-7);
  color: var(--apt-grey-3);
}

.tag-list li a.tag--used,
.tag-list li button[type=button].tag--used,
.tag-list li input[type=button].tag--used,
.tag-list li > span.tag--used {
  background-color: var(--apt-grey-2);
  color: var(--apt-white);
}

.tag-list li a.tag--filter,
.tag-list li a.tag--secondary,
.tag-list li button[type=button].tag--filter,
.tag-list li button[type=button].tag--secondary,
.tag-list li input[type=button].tag--filter,
.tag-list li input[type=button].tag--secondary,
.tag-list li > span.tag--filter,
.tag-list li > span.tag--secondary {
  background-color: var(--apt-grey-9);
  color: var(--apt-grey-3);
}

.tag-list li a.tag--active,
.tag-list li a.tag--primary,
.tag-list li button[type=button].tag--active,
.tag-list li button[type=button].tag--primary,
.tag-list li input[type=button].tag--active,
.tag-list li input[type=button].tag--primary,
.tag-list li > span.tag--active,
.tag-list li > span.tag--primary {
  background-color: var(--apt-primary);
  color: var(--apt-white);
}

.tag-list li a.tag--action,
.tag-list li button[type=button].tag--action,
.tag-list li input[type=button].tag--action,
.tag-list li > span.tag--action {
  background-color: var(--apt-action);
  background-repeat: no-repeat;
  color: var(--apt-white);
}

.tag-list li a:not([class]):hover,
.tag-list li a.tag:hover,
.tag-list li button:not([class])[type=button]:hover,
.tag-list li button[type=button].tag:hover,
.tag-list li input:not([class])[type=button]:hover,
.tag-list li input[type=button].tag:hover {
  text-decoration: none;
  background-color: var(--apt-grey-8);
}

.tag-list li a.tag--used:hover,
.tag-list li button[type=button].tag--used:hover,
.tag-list li input[type=button].tag--used:hover {
  background-color: var(--apt-grey-3);
}

.tag-list li a.tag--active:hover,
.tag-list li button[type=button].tag--active:hover,
.tag-list li input[type=button].tag--active:hover {
  filter: brightness(1.3);
  background-color: var(--apt-primary);
}

.tag-list li a.tag--primary:hover,
.tag-list li button[type=button].tag--primary:hover,
.tag-list li input[type=button].tag--primary:hover {
  filter: brightness(1.3);
  background-color: var(--apt-primary);
}

.tag-list li a.tag--action:hover,
.tag-list li button[type=button].tag--action:hover,
.tag-list li input[type=button].tag--action:hover {
  filter: brightness(1.2);
  background-color: var(--apt-action);
}

.tag-list--secondary {
  max-width: 280px;
}

.tag-list--secondary li {
  margin: 0 var(--7px) var(--7px) 0;
}

.tag-list--secondary li span.tag {
  border-width: 1px;
  border-style: solid;
  border-color: var(--apt-primary);
  border-radius: 12px;
  color: var(--apt-primary);
  background: none;
}

.tag-list--secondary li span.tag--disabled,
.tag-list--secondary li a.tag:not([href]),
.tag-list--secondary li button[type=button].tag:disabled {
  border-color: var(--apt-grey-5);
  color: #DBDBD8;
  cursor: not-allowed;
}
.tag-list--secondary li span.tag--disabled > svg .ov-icon__square,
.tag-list--secondary li a.tag:not([href]) > svg .ov-icon__square,
.tag-list--secondary li button[type=button].tag:disabled > svg .ov-icon__square {
  stroke: var(--apt-grey-5);
}
.tag-list--secondary li span.tag--disabled > svg .ov-icon__text,
.tag-list--secondary li a.tag:not([href]) > svg .ov-icon__text,
.tag-list--secondary li button[type=button].tag:disabled > svg .ov-icon__text {
  fill: var(--apt-grey-5);
}
.tag-list--secondary li span.tag--disabled:hover, .tag-list--secondary li span.tag--disabled:focus,
.tag-list--secondary li a.tag:not([href]):hover,
.tag-list--secondary li a.tag:not([href]):focus,
.tag-list--secondary li button[type=button].tag:disabled:hover,
.tag-list--secondary li button[type=button].tag:disabled:focus {
  background: none;
}

.main-header-container {
  position: sticky;
  top: 130px;
  z-index: 3;
  transition: top 0.2s ease-in;
}
@media (max-width: 991px) {
  .main-header-container {
    top: 52px;
  }
}

.header--minified ~ main .main-header-container {
  top: 60px;
}
@media (max-width: 991px) {
  .header--minified ~ main .main-header-container {
    top: 52px;
  }
}

.main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 991px) {
  .main-header--vdp {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .main-header--vdp .navigatable {
    margin-right: -10px;
    margin-left: -10px;
  }
}
@media (max-width: 991px) {
  .main-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .main-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-header__back {
  flex: 1 0 auto;
  padding: 4px 0 8px;
}
@media (min-width: 992px) {
  .main-header__back {
    padding: 15px 0;
  }
}

.main-header__back a {
  display: inline-flex;
  align-items: center;
  color: var(--apt-grey-3);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;
  text-decoration: none;
}
@media (min-width: 768px) {
  .main-header__back a {
    font-size: var(--14px);
    line-height: 20px;
    letter-spacing: 0.3px;
  }
}

.main-header__back a i {
  font-size: var(--16px);
  line-height: 20px;
}
@media (min-width: 992px) {
  .main-header__back a i {
    font-size: var(--20px);
    line-height: 21px;
  }
}

.main-header__back a i:first-child {
  color: var(--apt-grey-2);
  padding-right: 0.4em;
}
@media (min-width: 992px) {
  .main-header__back a i:first-child {
    padding-right: 1em;
  }
}

.main-header__nav {
  display: none;
}
@media (min-width: 992px) {
  .main-header__nav {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 13px 0 17px;
    font-size: var(--16px);
    line-height: 24px;
  }
}

.main-header__nav span {
  font-weight: var(--fw-medium);
}

.main-header__nav a {
  text-decoration: none;
  color: inherit;
}

.main-header__nav a:hover {
  color: var(--apt-primary);
}

.main-header__nav-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-header__nav-list li {
  margin-left: 1.3em;
  letter-spacing: 0.3px;
}

@media (max-width: 767px) {
  .main-header__nav-bar {
    width: calc(100% + 20px);
    background-color: var(--apt-grey-8);
    border-top: 1px solid var(--apt-grey-6);
    border-bottom: 1px solid var(--apt-grey-6);
  }
}

.main-header__nav-bar .nav-tablist {
  background: none;
}

.user-interaction-icons {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--apt-grey-4);
  font-size: var(--14px);
  line-height: 18px;
  z-index: 2;
}
@media (min-width: 768px) {
  .user-interaction-icons {
    font-size: var(--18px);
    line-height: 18px;
  }
}

.user-interaction-icons li {
  margin: 10px 0 0;
}
@media (min-width: 768px) {
  .user-interaction-icons li {
    margin: 0 0 0 12px;
  }
}

.user-interaction-icons > li > button,
.user-interaction-icons > li > input[type=button],
.user-interaction-icons > li > input[type=submit] {
  height: 20px;
  width: 18px;
}
@media (max-width: 767px) {
  .user-interaction-icons > li > button,
.user-interaction-icons > li > input[type=button],
.user-interaction-icons > li > input[type=submit] {
    height: 24px;
    width: 24px;
    border-radius: 10px;
  }
}

.user-interaction-dialog {
  position: relative;
}

.user-interaction-dialog__button {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.user-interaction-dialog__content {
  position: absolute;
  top: 100%;
  right: 0;
  width: 7.8rem;
  padding: 11px 16px 13px 18px;
  margin: 10px 0 0;
  background-color: var(--apt-white);
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.2s ease;
  list-style: none;
  border: 1px solid var(--apt-grey-7);
  border-top: 1px solid var(--apt-primary);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.user-interaction-dialog__content li {
  margin: 0 0 0.3rem;
}

.user-interaction-dialog__content li:last-child {
  margin: 0;
}

.user-interaction-dialog__content a,
.user-interaction-dialog__content input[type=submit],
.user-interaction-dialog__content input[type=button],
.user-interaction-dialog__content button {
  font-size: var(--14px);
  line-height: 18px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-4);
  text-align: left;
  text-decoration: none;
}

.user-interaction-dialog__content a:hover,
.user-interaction-dialog__content button:hover,
.user-interaction-dialog__content input[type=submit]:hover,
.user-interaction-dialog__content input[type=button]:hover {
  color: var(--apt-primary);
}

.user-interaction-dialog--toggled .user-interaction-dialog__button {
  transform: rotate(90deg);
  color: var(--apt-primary);
}

.user-interaction-dialog--toggled .user-interaction-dialog__content {
  transform: scale(1);
}

.zebra-header-container {
  position: relative;
  width: 100%;
  height: 60vw;
  min-height: 360px;
  max-height: 450px;
  z-index: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .zebra-header-container {
    max-height: 450px;
  }
}

.zebra-header-container a {
  text-decoration: none;
}

.zebra-header-container figure {
  width: 100%;
  height: 100%;
}

.zebra-header-container picture,
.zebra-header-container video {
  position: relative;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .zebra-header-container picture,
.zebra-header-container video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.zebra-header-gradient {
  position: absolute;
  top: 20%;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--black-gradient);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.6;
  z-index: 1;
}

.zebra-header-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zebra-header-separator--left {
  width: 100%;
  max-width: 570px;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .zebra-header-separator--left {
    padding-right: 2em;
    max-width: 715px;
  }
}

.zebra-header-separator--right {
  flex: 0 0 auto;
}

.zebra-header {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1440px;
  padding: 3.4em var(--15px) 0;
  margin: auto auto var(--10px);
  z-index: 2;
}
@media (min-width: 512px) {
  .zebra-header {
    margin: auto auto var(--32px);
  }
}
@media (min-width: 768px) {
  .zebra-header {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4em var(--45px) 0;
  }
}

.zebra-header h1 {
  max-width: 680px;
}
@media (min-width: 1200px) {
  .zebra-header h1 {
    margin-bottom: 31px;
  }
}

.zebra-header .zebra-header__content {
  color: var(--apt-white);
}
@media (min-width: 1200px) {
  .zebra-header .zebra-header__content {
    line-height: 1.7;
  }
}

.zebra-header__content-cta {
  margin-top: 30px;
}

.zebra-header__price-list {
  color: var(--apt-white);
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
  margin-top: 2em;
}
@media (min-width: 768px) {
  .zebra-header__price-list {
    align-self: end;
    justify-self: end;
    padding-right: 2em;
  }
}
@media (min-width: 1200px) {
  .zebra-header__price-list {
    padding-right: calc((1510px - 100vw) / 2);
  }
}

.zebra-header__price-item span {
  display: inline-block;
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.05px;
  margin-top: var(--6px);
}

.zebra-header__price-item:not(:last-child) span {
  margin-bottom: var(--19px);
}

.home-hero-main__cta .zebra-header-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 920px) {
  .home-hero-main__cta .zebra-header-container {
    padding-bottom: 56.25%;
  }
}
.home-hero-main__cta .zebra-header-container a {
  text-decoration: none;
}
.home-hero-main__cta .zebra-header-container figure {
  width: 100%;
  height: 100%;
}
.home-hero-main__cta .zebra-header-container picture,
.home-hero-main__cta .zebra-header-container video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (min-width: 768px) {
  .home-hero-main__cta .zebra-header {
    position: absolute;
  }
}

.offer-card-list {
  margin-top: -15px;
}
@media (max-width: 919px) {
  .offer-card-list {
    display: flex;
    justify-content: space-between;
  }
}
.offer-card-list.swiper-wrapper {
  width: 100%;
  height: auto;
}
@media (min-width: 920px) {
  .offer-card-list.swiper-wrapper {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}
@media (min-width: 1200px) {
  .offer-card-list.swiper-wrapper {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}
.offer-card-list > * {
  padding: 15px 10px 30px;
  height: 100%;
}
@media (min-width: 920px) {
  .offer-card-list > * {
    padding: 15px 15px 30px;
  }
}
@media (min-width: 1200px) {
  .offer-card-list > * {
    padding: 15px 25px 30px;
  }
}
@media (max-width: 919px) {
  .offer-card-list .swiper-pagination {
    display: none;
  }
}
.offer-card-list > .swiper-slide {
  height: auto;
}
@media (min-width: 768px) and (max-width: 921px) {
  .offer-card-list--two > .swiper-slide {
    width: 50%;
  }
}
.offer-card-list > a {
  height: 100%;
}
.offer-card-list .content-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.offer-card-list .card__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-grow: 1;
}
.offer-card-list .card__content .heading {
  flex-grow: 1;
}
.offer-card-list .tag-list li {
  text-transform: uppercase;
}
.offer-card-list .tag-list li span {
  padding: 0.3em 0.75em 0.3em;
}

.offer-card__aside {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 45%;
  flex: 0 0 auto;
}
.offer-card__aside > * {
  max-width: 322px;
}
@media (max-width: 919px) {
  .offer-card__aside {
    width: 100%;
    padding: 40px 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.offer-card__aside .heading, .offer-card__aside h2 {
  margin-bottom: 33px;
}

.offer-card__cards {
  width: 55%;
}
@media (max-width: 919px) {
  .offer-card__cards {
    width: 100%;
  }
}

@media (min-width: 920px) {
  .desktop-flex-1 .offer-card__aside {
    padding: 0 0 15px 30px;
  }
}

@media (max-width: 919px) {
  .desktop-flex-1 {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 920px) {
  .desktop-flex-2 .offer-card__aside {
    padding: 0 30px 15px 0;
  }
}

@media (max-width: 919px) {
  .desktop-flex-2 {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (min-width: 920px) {
  .offer-card-list[data-cols="1"] > * {
    max-width: 100%;
  }
}

@media (min-width: 920px) {
  .offer-card-list[data-cols="2"] > * {
    max-width: 50%;
  }
}

@media (min-width: 920px) {
  .offer-card-list[data-cols="3"] > * {
    max-width: 33.333%;
  }
}

@media (min-width: 920px) {
  .offer-card-list[data-cols="4"] > * {
    max-width: 25%;
  }
}

.offer-card-listing-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.offer-card-listing-header > span:first-of-type {
  color: var(--apt-grey-4);
  flex: 1 1 auto;
}
.offer-card-listing-header ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.offer-card-listing-header ul li {
  white-space: nowrap;
  margin-right: 5px;
}
.offer-card-listing-header ul li::after {
  margin-left: 4px;
  content: "|";
}
.offer-card-listing-header ul li:last-child::after {
  content: "";
}
.offer-card-listing-header .tooltip-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.page-wide-cta {
  background-color: var(--apt-grey-9);
  margin: 3em 0;
  padding: 2.5em 1em 2.85em;
  align-items: center;
  justify-content: center;
  display: flex;
}
.page-wide-cta__container {
  width: 100%;
  max-width: 1040px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-wide-cta a {
  margin-top: 1.1ch;
  margin-left: 2em;
  flex-shrink: 0;
}
.page-wide-cta h1, .page-wide-cta h2, .page-wide-cta h3, .page-wide-cta h4, .page-wide-cta h5, .page-wide-cta h6 {
  margin-bottom: 0;
  font-weight: var(--fw-regular);
}
.page-wide-cta strong {
  display: inline-block;
  font-weight: var(--fw-medium);
  margin-right: 0.42em;
}

.sirv-hint,
.sirv-spin-hint {
  width: 62px !important;
  height: 62px !important;
  background-image: url("/rel/stylesheet/image/svg-360-spin-hint.png") !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  box-shadow: none !important;
  border: 0 !important;
  opacity: 0 !important;
  border-radius: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate3d(-50%, -50%, 1px) !important;
}

@media (min-width: 512px) {
  .sirv-hint,
.sirv-spin-hint {
    width: 92px !important;
    height: 92px !important;
  }
}
.sirv-spin-hint > *,
.sirv-hint > * {
  display: none !important;
}

.sirv-hint.show,
.sirv-spin-hint.show {
  opacity: 1 !important;
}

.sirv-spin-button.fullscreen,
.smv-button-fullscreen {
  display: none !important;
}

.sirv-spin-container,
.sirv-spin {
  display: block !important;
}

.gallery-grid__sirv-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
@media (min-width: 768px) {
  .gallery-grid__sirv-container {
    border-radius: var(--10px);
  }
}

.gallery-grid__sirv-container .spin-container .spin-figure {
  max-width: 100% !important;
  max-height: 100% !important;
}

.gallery-grid__sirv-container .spin-container .spin-figure > canvas {
  width: 100% !important;
  height: 100% !important;
}

.gallery-button-list {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 2;
  max-width: calc(100% - 100px);
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--7px);
}
.gallery-button-list li {
  margin: 0 var(--4px) var(--4px) 0;
  line-height: 0;
}
@media (min-width: 512px) {
  .gallery-button-list li {
    margin: 0 var(--6px) var(--6px) 0;
  }
}

.gallery-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 4px;
  border-radius: 20px;
  background-color: var(--apt-grey-7);
  color: var(--apt-grey-3);
  font-size: var(--11px);
  line-height: 1.145;
  font-weight: var(--fw-medium);
  text-align: center;
  text-decoration: none;
}
@media (min-width: 512px) {
  .gallery-button {
    font-size: var(--14px);
    padding: 3px 12px 6px;
  }
}
.gallery-button:hover {
  text-decoration: none;
}
.gallery-button:focus {
  border-radius: 20px;
}
.gallery-button--active {
  background-color: var(--apt-primary);
  color: var(--apt-white);
}

.gallery-grid__sirv-container .smv-slides-box .smv-slides .smv-slide .smv-content > * {
  display: block !important;
}

.gallery-grid__sirv-container .Sirv {
  width: 100%;
  height: 100%;
  object-fit: cover;
  appearance: none;
  border: 0;
}

.gallery-grid .pnlm-controls-container,
.gallery-overlay .pnlm-controls-container {
  display: none;
}

.appointment-tool-container .grid-item > article {
  padding-top: 20px;
}
@media (min-width: 576px) {
  .appointment-tool-container .grid-item > article {
    padding-top: 40px;
  }
}
@media (min-width: 992px) {
  .appointment-tool-container .grid-item > article {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .appointment-tool-container .grid-item > article {
    padding-top: 180px;
  }
}

.appointment-step {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-top: 0;
}
@media (min-width: 992px) {
  .appointment-step {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 76px;
    padding-bottom: 130px;
    overflow: hidden;
  }
}

.appointment-step__header-content {
  width: 100%;
  padding-right: 15px;
  max-width: 445px;
}

.appointment-step__header-content .tag-list {
  margin-top: 20px;
}

.appointment-step__header-content p {
  margin-top: 30px;
}

.appointment-step__header-content hr {
  margin-top: 45px;
  margin-bottom: 45px;
}

.appointment-step__header {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .appointment-step__header {
    width: 50%;
    flex: 0 0 auto;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .appointment-step__main {
    flex: 1 1 auto;
    padding-top: 1.5em;
  }
}

.appointment-step__main div:first-child,
.appointment-step__main div:first-child input {
  margin-top: 0;
}

.appointment-step__main div:first-child select {
  margin-top: 32px;
}

.appointment-step .button {
  margin-top: 49px;
}

@media (min-width: 992px) {
  .appointment-step--narrow .appointment-step__header {
    width: 42%;
  }
}
.appointment-step--narrow .appointment-step__header-content {
  max-width: 400px;
}

.appointment-form-divide {
  width: 100%;
}
@media (min-width: 768px) {
  .appointment-form-divide {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
}

@media (min-width: 768px) {
  .appointment-form-divide > div {
    width: 50%;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) {
  .appointment-form-divide > div:first-child {
    padding-right: 25px;
  }
}
@media (min-width: 768px) {
  .appointment-form-divide > div:last-child {
    padding-left: 25px;
  }
}

/* Some overrides */
.appointment-form-divide .wm-field > div {
  margin-top: 36px;
}

@media (min-width: 768px) {
  .appointment-form-divide .wm-field:first-child div {
    margin-top: 0;
  }
}

.appointment-step .dropdown-wrapper,
.appointment-step .dropdown-wrapper select {
  margin-top: 38px;
}

.appointment-form-divide .checkbox-wrapper .wm-field-label ul li label {
  font-size: 16px;
  color: var(--apt-grey-2);
}

.calendar {
  width: 100%;
  margin-top: 53px;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid var(--apt-grey-7);
  padding-bottom: 15px;
}
.calendar__header span {
  font-size: var(--24px);
  line-height: 29px;
  letter-spacing: -0.1px;
  font-weight: var(--fw-medium);
}
.calendar__header button {
  font-size: var(--20px);
  line-height: 24px;
  margin-left: 15px;
}
@media (min-width: 576px) {
  .calendar__header button {
    font-size: var(--24px);
    line-height: 29px;
  }
}

.calendar__header-arrows {
  display: flex;
}

.calendar__transport-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 15px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--apt-grey-7);
}
@media (min-width: 512px) {
  .calendar__transport-options {
    flex-direction: row;
    align-items: center;
  }
}
.calendar__transport-options .heading {
  color: var(--apt-grey-4);
  display: block;
  font-size: var(--16px);
  line-height: 20px;
  letter-spacing: 0;
  font-weight: normal;
  margin-bottom: 1em;
  padding-right: 10px;
}
@media (min-width: 512px) {
  .calendar__transport-options .heading {
    margin-bottom: 0;
  }
}
.calendar__transport-options .heading > span {
  display: none;
}
@media (min-width: 512px) {
  .calendar__transport-options .heading > span {
    display: inline;
  }
}

.calendar__transport-options .tag-list {
  margin-bottom: 0;
}
.calendar__transport-options .tag-list li {
  margin-bottom: 0;
}
.calendar__transport-options .tag-list .tag {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: var(--15px);
  line-height: 1;
}
@media (min-width: 512px) {
  .calendar__transport-options .tag-list .tag {
    width: 35px;
    height: 35px;
    font-size: var(--17px);
  }
}
.calendar__transport-options .tag-list .tag > svg {
  width: 20px;
  height: 14px;
}

.calendar__thead {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 25px;
  margin-bottom: 9px;
  width: 110%;
  margin-left: -5%;
}
.calendar__thead > div {
  width: 14.2857143%;
  text-align: center;
  font-size: var(--11px);
  line-height: 20px;
  letter-spacing: 0.24px;
  color: var(--apt-grey-2);
}
.calendar__thead > div:nth-last-child(2) {
  color: var(--apt-grey-6);
}
.calendar__thead > div:last-child {
  color: var(--apt-grey-6);
}

.calendar__days {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  width: 110%;
  margin-left: -5%;
}

.calendar__day {
  width: 14.2857143%;
  text-align: center;
  margin-top: 26px;
}
.calendar__day button {
  position: relative;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: var(--16px);
  color: var(--apt-grey-2);
  text-align: center;
  border-radius: 100%;
}
.calendar__day button:hover:not(:disabled) {
  background-color: var(--apt-grey-8);
}
.calendar__day button::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: var(--apt-success-1);
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateY(-5px) translateX(-50%);
}
.calendar__day button[data-availability="1"]::after {
  background-color: var(--apt-warning-1);
}
.calendar__day button[data-availability="0"]::after {
  background-color: var(--apt-error-1);
}
.calendar__day button:disabled {
  color: var(--apt-grey-6);
  cursor: not-allowed;
}
.calendar__day button:disabled::after {
  background-color: var(--apt-grey-6);
}
.calendar__day--selected button {
  background: var(--apt-primary);
  color: var(--apt-white);
  font-weight: var(--fw-medium);
}
.calendar__day--selected button:hover:not(:disabled) {
  background-color: var(--apt-blue-1);
  border-color: var(--apt-blue-1);
  color: var(--apt-white);
}
.calendar__day--selected button::after {
  opacity: 0;
}

.calendar__times {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 68px;
}

.calendar__time {
  width: calc(50% - 9px);
  margin-bottom: 18px;
}
@media (max-width: 767px) {
  .calendar__time:nth-child(odd) {
    margin-left: 9px;
  }
  .calendar__time:nth-child(even) {
    margin-right: 9px;
  }
}
@media (min-width: 768px) {
  .calendar__time {
    width: calc(33% - 10.5px);
    margin-right: 18px;
  }
  .calendar__time:nth-of-type(3n+3) {
    margin-right: 0;
  }
}
.calendar__time button {
  width: 100%;
  border-radius: 100px;
  text-align: center;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
  color: var(--apt-grey-2);
  padding: 7px 5px 9px;
  border: 1px solid var(--apt-grey-5);
}
.calendar__time button:hover:not(:disabled) {
  color: var(--apt-black);
  border-color: var(--apt-black);
}
.calendar__time button:disabled {
  border-color: var(--apt-grey-6);
  color: var(--apt-grey-6);
  cursor: not-allowed;
}
.calendar__time--selected button {
  border-color: var(--apt-primary);
  background-color: var(--apt-primary);
  color: var(--apt-white);
  font-weight: var(--fw-medium);
}
.calendar__time--selected button:hover:not(:disabled) {
  background-color: var(--apt-blue-1);
  border-color: var(--apt-blue-1);
  color: var(--apt-white);
}

.appointment-radio-list input[type=radio]:disabled ~ label > span,
.appointment-radio-list input[type=radio]:disabled ~ label > span > span:nth-child(2) {
  color: var(--apt-grey-5);
}

.appointment-step__main .appointment-radio-list {
  margin-top: -1.5em;
}

@media (min-width: 992px) {
  .appointment-radio-list .radio-wrapper ul {
    margin-top: -24px;
  }
}
.appointment-radio-list .radio-wrapper .wm-field-input ul li {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--apt-grey-7);
}

.appointment-radio-list label > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  color: var(--apt-grey-2);
  font-weight: var(--fw-medium);
}
@media (min-width: 576px) {
  .appointment-radio-list label > span {
    flex-direction: row;
    justify-content: space-between;
  }
}

.appointment-radio-list label > span > span:nth-child(2) {
  color: var(--apt-primary);
  margin-top: 4px;
  font-size: var(--14px);
  line-height: 28px;
}
@media (min-width: 576px) {
  .appointment-radio-list label > span > span:nth-child(2) {
    margin-top: 0;
  }
}

.appointment-radio-list input:checked + label address span:first-child {
  color: var(--apt-black);
}

.appointment-step .button--primary {
  width: auto;
  min-width: 160px;
}
@media (min-width: 992px) {
  .appointment-step .button--primary {
    min-width: 182px;
  }
}

.appointment-step .radio-wrapper .wm-field-label {
  margin-bottom: 39px;
}

.registry-plate-wrapper {
  max-width: 236px;
}

.form-grid-item--compact .wm-field > div {
  margin-top: 25px;
}

.form-grid-item--compact .checkbox-wrapper {
  margin-bottom: 0;
}

.customer-waiting {
  margin-top: 25px !important;
}
.customer-waiting-label {
  font-size: 15px;
}
.customer-waiting-label-wrapper {
  flex-direction: column;
  display: flex;
}

h2 + .appointment-radio-list .radio-wrapper {
  margin: 36px auto;
}

.appointment-radio-list .radio-wrapper {
  margin-bottom: 11px;
}

.appointment--internal-spacing h2:not(:first-of-type) {
  margin-top: 60px;
}

.appointment-options .checkbox-wrapper,
.appointment-options .radio-wrapper {
  margin: 8px 0;
}

.appointment-option {
  justify-content: stretch;
  padding: 0px 12px;
  margin-bottom: 10px;
  background-color: var(--apt-white);
  border-radius: 5px;
  overflow: hidden;
}

.appointment-accordeon {
  justify-content: stretch;
  padding: 0;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  background-color: var(--apt-white);
  overflow: hidden;
}

.appointment-accordeon .checkbox-wrapper ul li:last-of-type,
.appointment-accordeon .radio-wrapper ul li:last-of-type {
  margin-bottom: 0;
}

.appointment-accordeon .checkbox-wrapper ul li:last-of-type,
.appointment-accordeon .radio-wrapper ul li:last-of-type {
  margin-bottom: 0;
}

.appointment-options .checkbox-wrapper ul li label > span,
.appointment-options .radio-wrapper ul li label > span {
  position: relative;
  flex: 1 1 auto;
}

.appointment-accordeon--expanded {
  border-color: var(--apt-primary);
}

.appointment-accordeon--expanded .expand-arrow {
  transform-origin: center;
  transform: rotate(180deg);
}

.appointment-accordeon > label {
  position: relative;
}

.appointment-accordeon > div {
  width: 100%;
}

.appointment-accordeon .expand {
  width: 100%;
  background-color: var(--apt-white);
  padding: 8px 12px;
}

.appointment-accordeon .expanded {
  width: 100%;
  background-color: var(--apt-grey-8);
  padding: 8px 12px;
}

.error-box {
  position: relative;
  border: 1px solid var(--apt-error-1);
  border-radius: 5px;
  padding: 15px 50px 21px 30px;
  color: var(--apt-error-1);
  font-size: var(--14px);
  line-height: 1.4;
  margin-top: 50px;
}
.error-box p {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.error-box i.fal {
  position: absolute;
  bottom: 21px;
  right: 18px;
  font-size: var(--18px);
  line-height: 1;
  color: var(--apt-error-1);
}

.expand {
  width: 100%;
  display: flex;
  font-size: var(--16px);
}

.expanded {
  font-size: var(--16px);
}

.expand-arrow {
  position: relative;
  top: 0em;
  left: 1px;
  width: 1em;
  height: 1em;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  box-shadow: 0 0 0 1px var(--apt-grey-7);
  border-radius: 0.15em;
  color: var(--apt-grey-2);
  background-color: var(--apt-grey-7);
  text-align: center;
  pointer-events: none;
  margin-top: 1px;
  margin-right: 1.2em;
  flex: 0 0 auto;
}
.expand-arrow svg {
  display: block;
  width: 10px;
  height: 13px;
}
.expand-arrow svg path {
  fill: var(--apt-grey-2);
}

.custom-checkbox-label__text {
  padding-right: 1em;
  flex-grow: 1;
  flex-shrink: 1;
}

.custom-checkbox-label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.custom-checkbox-label__price {
  font-size: var(--14px);
  color: var(--apt-primary);
  font-weight: var(--fw-medium);
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}

.expand-label {
  position: relative;
  flex: 1 1 auto;
  text-align: left;
}

.expand-label__quantity {
  color: var(--apt-grey-5);
}

.expand-label__price {
  position: absolute;
  right: 0;
  font-size: var(--14px);
  color: var(--apt-primary);
  font-weight: var(--fw-medium);
}

.content-header {
  width: 100%;
  padding: 0;
}
.content-header--narrow .zebra-header {
  max-width: 1218px;
  padding-left: var(--15px);
  padding-right: var(--15px);
}
@media (min-width: 768px) {
  .content-header--narrow .zebra-header {
    padding-left: var(--50px);
    padding-right: var(--50px);
  }
}
@media (min-width: 1200px) {
  .content-header--narrow .zebra-header {
    padding-left: var(--85px);
    padding-right: var(--85px);
  }
}
@media (min-width: 992px) {
  .content-header--narrow .zebra-header .zebra-header-separator--left {
    max-width: 50%;
  }
}

.content-hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
}
@media (min-width: 992px) {
  .content-hero {
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    width: calc(100% + 25px);
    margin-left: -25px;
  }
}

.content-hero.has-bottom-padding {
  padding-bottom: 35px;
}
@media (min-width: 992px) {
  .content-hero.has-bottom-padding {
    padding-bottom: 80px;
  }
}

.content-hero.has-top-padding {
  padding-top: 35px;
  padding-bottom: 35px;
}
@media (min-width: 992px) {
  .content-hero.has-top-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.content-hero__figure {
  position: relative;
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .content-hero__figure {
    width: calc(50% + 25px);
    padding-right: 25px;
    margin-bottom: 0;
    align-self: flex-start;
    position: sticky;
    top: 100px;
  }
}
.content-hero__figure img {
  width: 100%;
  max-width: 420px;
  height: auto;
}

.video-figure-wrapper {
  width: 100%;
  max-width: 500px;
}

.video-figure {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.video-figure iframe,
.video-figure video {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.content-hero__text {
  position: relative;
}
@media (min-width: 992px) {
  .content-hero__text {
    width: 50%;
    padding-left: 25px;
    padding-right: 50px;
    margin-left: -0.45em;
  }
}
@media (min-width: 992px) {
  .content-hero__text--no-right-padding {
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .content-hero__text .heading-1 {
    margin-bottom: var(--36px);
  }
}

.content-hero__text .button {
  margin-top: 25px;
}
@media (min-width: 992px) {
  .content-hero__text .button {
    margin-top: 43px;
    margin-left: 0.05em;
  }
}

.zebra-grid {
  position: relative;
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .zebra-grid {
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .zebra-grid {
    grid-template-columns: 19fr 23fr;
  }
}
@media (min-width: 1200px) {
  .zebra-grid {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 100px;
  }
}

.zebra-grid__content .heading {
  max-width: 21ch;
  padding-top: 20px;
  letter-spacing: -0.75px;
}
@media (min-width: 768px) {
  .zebra-grid__content .heading {
    width: calc(100% + 0.5ch);
    padding-top: 50px;
    font-size: var(--40px);
    line-height: 48px;
  }
}

.zebra-grid__box {
  background-color: var(--apt-white);
  box-shadow: 0 4px 6px #DBDBD8;
  border-radius: 10px;
  padding: 34px 22px;
  max-width: 632px;
}
@media (min-width: 612px) {
  .zebra-grid__box {
    padding: 44px 56px;
  }
}
@media (min-width: 1440px) {
  .zebra-grid__box {
    height: calc(100% + 110px);
    padding: 50px 56px;
    margin-top: -110px;
  }
}

.zebra-grid__box .wmpform .heading {
  padding-bottom: 4px;
}
@media (min-width: 1200px) {
  .zebra-grid__box .wmpform .heading {
    padding-bottom: 24px;
  }
}

.zebra-grid__box .wmpform .form-table-wrapper {
  width: calc(100% + 28px);
  margin-bottom: -16px;
}

@media (max-width: 611px) {
  .zebra-grid__box .form-table-wrapper .gridcontentareastyle {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .zebra-grid__box .form-table-wrapper .gridcontentareastyle {
    width: 100% !important;
  }
}
@media (min-width: 1440px) {
  .zebra-grid__box .form-table-wrapper .gridcontentareastyle {
    width: 50% !important;
  }
}

@media (max-width: 611px) {
  .zebra-grid__box .wmpform .form-table-wrapper .gridtable tr {
    flex-direction: column !important;
  }
}
@media (min-width: 992px) {
  .zebra-grid__box .wmpform .form-table-wrapper .gridtable tr {
    flex-direction: column !important;
  }
}
@media (min-width: 1440px) {
  .zebra-grid__box .wmpform .form-table-wrapper .gridtable tr {
    flex-direction: row !important;
  }
}

.zebra-block {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 35px;
  padding-bottom: 35px;
}
@media (min-width: 992px) {
  .zebra-block {
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.zebra-block img {
  max-width: 100%;
}

.zebra-block--inversed {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: stretch;
}
@media (min-width: 992px) {
  .zebra-block--inversed {
    flex-direction: row-reverse;
  }
}
@media (min-width: 992px) {
  .zebra-block--inversed .zebra-block__text {
    padding-right: 0;
    padding-left: 85px;
  }
}
@media (min-width: 992px) {
  .zebra-block--inversed .zebra-block__figure {
    padding-right: 25px;
    padding-left: 0;
  }
}
.zebra-block--inversed .zebra-block__figure img {
  float: none;
}

@media (min-width: 1440px) {
  .grid-item--inline-offset .zebra-block--inversed .zebra-block__text {
    padding-left: 66.5px;
  }
}

@media (min-width: 992px) {
  .zebra-block--wide-figure {
    margin-right: -85px;
  }
}
@media (min-width: 1200px) {
  .zebra-block--wide-figure {
    margin-right: -105px;
  }
}

@media (min-width: 992px) {
  .zebra-block--extra-top-padding {
    padding-top: 185px;
  }
}

.zebra-block__text {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
@media (min-width: 992px) {
  .zebra-block__text {
    width: 50%;
    padding-right: 85px;
    padding-left: 0;
  }
}
.zebra-block__text .heading {
  margin-bottom: var(--26px);
}
@media (min-width: 992px) {
  .zebra-block__text .heading {
    margin-bottom: var(--38px);
  }
}
.zebra-block__text .button {
  margin-top: var(--32px);
}

@media (min-width: 1440px) {
  .grid-item--inline-offset .zebra-block:not(.zebra-block--inversed) .zebra-block__text {
    padding-right: 66.5px;
  }
}

@media (min-width: 992px) {
  .zebra-block--wide-figure .zebra-block__text {
    width: 35%;
    margin-top: 62px;
    padding-right: 40px;
  }
}

.zebra-block__figure {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .zebra-block__figure {
    width: 50%;
    padding-right: 0;
    padding-left: 25px;
    margin-bottom: 0;
  }
}
.zebra-block__figure img {
  width: 100%;
  max-width: 500px;
}
@media (min-width: 992px) {
  .zebra-block__figure img {
    float: right;
  }
}

.zebra-block--wide-figure .zebra-block__figure {
  width: 100%;
  max-width: 718px;
}
@media (min-width: 992px) {
  .zebra-block--wide-figure .zebra-block__figure {
    width: 65%;
    max-width: none;
  }
}

.zebra-block ul.checks li {
  margin-bottom: 0.7em;
  line-height: 1;
}

.zebra-block ul.checks li::before {
  top: 0.2em;
}

.zebra-cards {
  position: relative;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 5px;
}
@media (min-width: 512px) {
  .zebra-cards {
    padding-top: 48px;
  }
}
@media (min-width: 992px) {
  .zebra-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 106px;
    padding-bottom: 97px;
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.zebra-cards > p {
  display: none;
}

.zebra-cards > div {
  width: 100%;
  padding-bottom: 20px;
}
@media (min-width: 512px) {
  .zebra-cards > div {
    padding-bottom: 35px;
  }
}
@media (min-width: 992px) {
  .zebra-cards > div {
    width: calc(50% - 50px);
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 50px;
    padding-bottom: 0;
  }
}
.zebra-cards > div .card:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .zebra-cards > div .card:hover {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 1200px) {
  .zebra-cards > div .card:hover {
    box-shadow: var(--shadow-hover);
  }
}

.zebra-card--clickable a,
.zebra-card--clickable a:hover,
.zebra-card--clickable a:active,
.zebra-card--clickable a:focus {
  text-decoration: none;
}

/* intentionally duplicated */
.zebra-card__header.zebra-card__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
}

.zebra-card__header.zebra-card__header img {
  width: auto;
  height: 56px;
  padding-right: var(--33px);
}

.zebra-card__header.zebra-card__header span {
  color: var(--apt-primary);
  margin-bottom: 0.45em;
}

@media (min-width: 512px) {
  .zebra-card .card__content {
    padding: 32px 26px 30px;
  }
}
@media (min-width: 992px) {
  .zebra-card .card__content {
    padding: 52px 46px 50px;
  }
}

.zebra-card .zebra-card__text {
  flex: 1 1 auto;
  max-width: 360px;
  margin-top: 40px;
}

.nav-tablist-horizontal-ruler {
  width: 100%;
  height: 10px;
  background: var(--apt-grey-8);
  margin-top: -20px;
}

.full-width-zebra-block {
  width: 100%;
}
@media (min-width: 992px) {
  .full-width-zebra-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.full-width-zebra-block__title {
  width: 100%;
  padding-right: 20px;
}
@media (min-width: 992px) {
  .full-width-zebra-block__title {
    width: 44%;
    padding-left: calc((100vw - 1218px) / 2);
  }
}
@media (min-width: 1440px) {
  .full-width-zebra-block__title {
    width: 50%;
    padding-left: calc((1440px - 1218px) / 2);
  }
}
.full-width-zebra-block__title .heading {
  width: 100%;
  max-width: 400px;
  margin-bottom: var(--30px);
}

.full-width-zebra-block__content {
  width: 100%;
}
@media (min-width: 992px) {
  .full-width-zebra-block__content {
    width: 60%;
    padding-top: 10px;
  }
}
@media (min-width: 1440px) {
  .full-width-zebra-block__content {
    width: 50%;
  }
}

.table-container {
  max-width: 830px;
  width: calc(100% + 15px);
  overflow: auto;
}
@media (min-width: 992px) {
  .table-container {
    display: block;
    width: 100%;
    margin: 0 auto;
    overflow: visible;
  }
}

.data-list-container .data-list {
  max-width: 636px;
  margin-left: auto;
  margin-right: auto;
}
.data-list-container .data-list caption {
  text-align: left;
  margin-bottom: 33px;
}
@media (min-width: 768px) {
  .data-list-container .data-list tbody {
    display: grid;
  }
}
@media (min-width: 768px) {
  .data-list-container .data-list tbody tr:nth-child(2) {
    border-top: 1px solid var(--apt-grey-7);
  }
}

.table-mobile-spacing {
  position: relative;
  width: 15px;
  min-width: 15px;
  background: none;
  z-index: 1;
}
@media (min-width: 992px) {
  .table-mobile-spacing {
    display: none;
  }
}

.table {
  width: auto;
  min-width: calc(100% - 15px);
  border-collapse: collapse;
  display: table;
  table-layout: fixed;
  padding: 0;
  margin: 0 15px 0 0;
  border: 0;
  text-align: left;
}
@media (min-width: 992px) {
  .table {
    width: 100%;
    padding: 0;
  }
}
.table .caption {
  text-align: left;
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
}
.table [role=cell] {
  font-size: var(--20px);
  line-height: 22px;
}
.table [role=rowgroup] {
  display: table-row-group;
}
.table tr,
.table [role=row] {
  display: table-row;
}
.table [role=cell], .table [role=rowheader], .table [role=columnheader] {
  display: table-cell;
}
.table [role=rowheader] {
  font-size: var(--14px);
  line-height: 22px;
  font-weight: var(--fw-regular);
  text-align: left;
  color: var(--apt-grey-2);
}
.table [role=columnheader] {
  font-weight: var(--fw-medium);
  text-align: left;
  font-size: var(--16px);
  line-height: 26px;
}
.table tr:first-child td:first-child,
.table tr:first-child [role=cell]:first-child,
.table [role=row]:first-child td:first-child,
.table [role=row]:first-child [role=cell]:first-child {
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
}
.table > tr:first-child td, .table > tr:first-child th,
.table thead tr td,
.table thead tr th,
.table [role=rowgroup]:first-child div {
  padding-top: 0;
  padding-bottom: 33px;
  position: sticky;
  left: 0;
  background: var(--apt-grey-9);
}
.table > tr:first-child, .table > tr:first-child,
.table thead tr,
.table thead tr,
.table [role=rowgroup]:first-child [role=row] {
  box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
}
.table > tr:first-child td:first-child, .table > tr:first-child th:first-child,
.table thead tr td:first-child,
.table thead tr th:first-child,
.table [role=rowgroup]:first-child [role=row] div:first-child {
  background: var(--apt-grey-9);
  min-width: 100px;
  z-index: 1;
}
@media (max-width: 991px) {
  .table > tr:first-child td:first-child, .table > tr:first-child th:first-child,
.table thead tr td:first-child,
.table thead tr th:first-child,
.table [role=rowgroup]:first-child [role=row] div:first-child {
    box-shadow: inset -1px 0 0 0 var(--apt-grey-7);
  }
}
.table > tr:not(:first-child) td, .table > tr:not(:first-child) th,
.table tbody tr td,
.table tbody tr th,
.table [role=rowgroup]:not(:first-child) div {
  padding-top: 15px;
  padding-bottom: 18px;
  position: sticky;
  left: 0;
  background: var(--apt-grey-9);
  box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
}
.table > tr:not(:first-child) td:first-child, .table > tr:not(:first-child) th:first-child,
.table tbody tr td:first-child,
.table tbody tr th:first-child,
.table [role=rowgroup]:not(:first-child) [role=row] div:first-child {
  background: var(--apt-grey-9);
  min-width: 100px;
  z-index: 1;
  box-shadow: inset 0 -1px 0 0 var(--apt-grey-7), inset -1px 0 0 0 var(--apt-grey-7);
}
@media (min-width: 991px) {
  .table > tr:not(:first-child) td:first-child, .table > tr:not(:first-child) th:first-child,
.table tbody tr td:first-child,
.table tbody tr th:first-child,
.table [role=rowgroup]:not(:first-child) [role=row] div:first-child {
    box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
  }
}
.table tr td:not(:first-child),
.table tr th:not(:first-child),
.table [role=row] [role=cell]:not(:first-child),
.table [role=row] [role=rowheader]:not(:first-child),
.table [role=row] [role=columnheader]:not(:first-child) {
  text-align: center;
  min-width: 100px;
  box-shadow: inset 0 -1px 0 0 var(--apt-grey-7);
}
.table .fa-check {
  color: var(--apt-success-1);
}
.table .fa-times {
  color: var(--apt-error-1);
}
.table .fa-dash {
  color: var(--apt-grey-5);
}

.narrow-content-spacing {
  width: 100%;
}
.narrow-content-spacing h2 {
  margin-bottom: -15px;
}

.narrow-content-container {
  display: grid;
  grid-template-columns: auto minmax(65.353%, 830px) auto;
  row-gap: var(--43px);
  width: 100%;
}
.narrow-content-container > * {
  grid-column: 2/span 1;
}
.narrow-content-container > hr {
  grid-column: 2/span 2;
}

.narrow-content-buttons {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: var(--20px);
  padding-bottom: 40px;
}
@media (min-width: 512px) {
  .narrow-content-buttons {
    margin-top: var(--30px);
    padding-bottom: 10px;
  }
}
@media (min-width: 1200px) {
  .narrow-content-buttons {
    padding-bottom: 0;
  }
}

.home .header:not(.header--minified) .header-sticky-section::before {
  opacity: 0;
}

.home-line {
  width: 100%;
}

.home-hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .home-hero {
    margin-bottom: 160px;
  }
}

@media (min-width: 920px) {
  .home-hero {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.home-hero-main {
  width: calc(100% + 15px);
  margin-right: -15px;
  margin-top: 0;
  flex: 1 1 auto;
}
@media (min-width: 512px) {
  .home-hero-main {
    width: 100%;
    margin-right: 0;
  }
}
@media (min-width: 920px) {
  .home-hero-main {
    max-width: calc(100% - 245px);
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .home-hero-main {
    max-width: 69.3%;
  }
}
@media (min-width: 1440px) {
  .home-hero-main {
    padding-right: 50px;
  }
}
.home-hero-main header {
  width: 100%;
  padding-left: 15px;
  padding-right: 0;
}
@media (min-width: 512px) {
  .home-hero-main header {
    padding-right: 15px;
  }
}
@media (min-width: 920px) {
  .home-hero-main header {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1060px) {
  .home-hero-main header {
    width: 67vw;
  }
}
@media (min-width: 1200px) {
  .home-hero-main header {
    width: 100%;
  }
}
@media (max-width: 919px) {
  .home-hero-main .home-hero-main__cta {
    display: none;
  }
}

.home-hero-main-cta-header {
  width: 100%;
  max-width: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
}
.home-hero-main-cta-header .heading {
  padding-right: 1em;
  color: white;
}
.home-hero-main-cta-header .button {
  width: 50px;
  height: 50px;
  min-width: 50px;
  padding-left: 0;
  padding-right: 0;
}

.home-hero-aside {
  width: 100%;
  max-width: 335px;
  flex: 0 0 auto;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 920px) {
  .home-hero-aside {
    width: 245px;
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .home-hero-aside {
    margin-top: 0;
    width: 30.7%;
  }
}
.home-hero-aside .card {
  padding: 24px;
  margin-top: 40px;
}
@media (min-width: 1200px) {
  .home-hero-aside .card {
    padding: 38px;
    margin-top: 50px;
  }
}
.home-hero-aside .card .heading {
  font-size: 16px;
  line-height: 20px;
}
@media (min-width: 1200px) {
  .home-hero-aside .card .heading {
    font-size: 21px;
    line-height: 30px;
  }
}
.home-hero-aside form, .home-hero-aside .wmpform {
  margin-top: 0;
}
.home-hero-aside .heading {
  margin-bottom: 0;
}
.home-hero-aside .wm-field:last-of-type {
  margin-bottom: 28px;
}
.home-hero-aside .wmpform .dropdown-wrapper {
  margin-top: 22px;
}

.wmpform input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
  margin-top: 22px;
}

.wmpform select {
  margin-bottom: 22px;
}

.home-hero-slide .heading {
  margin-bottom: var(--25px);
}
@media (min-width: 768px) {
  .home-hero-slide .heading {
    margin-bottom: var(--40px);
  }
}

@media (min-width: 920px) {
  .home-hero-slide {
    width: 100%;
    height: 400px;
  }
}
.home-hero-slide__content {
  position: relative;
}

.home-hero-slide__figure {
  width: 72vw;
  max-width: 640px;
  margin-top: 120px;
  margin-right: -2vw;
  float: right;
  shape-image-threshold: 0.5;
  shape-rendering: crispEdges;
  shape-margin: 1em;
  opacity: 1;
  position: absolute;
  top: -9999px;
  transition: opacity 0.2s ease 0.1s;
}
@media (max-width: 511px) {
  .home-hero-slide__figure {
    margin-top: 65px;
    margin-right: -10vw;
  }
}
@media (min-width: 920px) {
  .home-hero-slide__figure {
    width: 40vw;
    margin-right: 0;
  }
}
@media (min-width: 768px) {
  .home-hero-slide__figure {
    margin-top: 120px;
  }
}
@media (min-width: 992px) {
  .home-hero-slide__figure {
    margin-top: 120px;
    width: 44vw;
  }
}
@media (min-width: 1060px) {
  .home-hero-slide__figure {
    margin-top: 95px;
    width: 50vw;
  }
}
@media (min-width: 1200px) {
  .home-hero-slide__figure {
    width: 44.4vw;
    margin-right: 0;
  }
}
@media (min-width: 1440px) {
  .home-hero-slide__figure {
    width: 645px;
    margin-right: 28px;
  }
}
.home-hero-slide__figure--loading {
  width: 0;
  opacity: 0;
}
.home-hero-slide__figure.home-hero-slide__figure--done {
  position: static !important;
  top: 0;
}

.home-line {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201389%201887%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22a%22%20x1%3D%2269.9580641%25%22%20x2%3D%2228.6649486%25%22%20y1%3D%2270.0294806%25%22%20y2%3D%2227.4475335%25%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%234157FF%22%2F%3E%3Cstop%20offset%3D%2286.1688863%25%22%20stop-color%3D%22%2306C5F5%22%2F%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%23FFF%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cpath%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22url(%23a)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%225%22%20d%3D%22M0%20295.399665c56.046875%20123.717333%20159.278646%20159.32%20309.695313%20106.808%20225.625-78.768%20338.774167-216.808%20526.025007-336.8080001%20187.25084-120%20454.98281-95.75%20435.81874%20250.3750001C1252.375%20661.899665%20853.2352%20787.782477%20622.75%20770.282477c-230.4852-17.5-448.856473%20159.734497-471.414062%20417.570313-33.632813%20384.42687%20306.599992%20654.20958%20663.992187%20693.95312%22%20transform%3D%22translate(3.296875%202.600335)%22%2F%3E%3C%2Fsvg%3E");
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: calc(4vw - 10px);
  overflow: hidden;
  background-size: 800px;
}
@media (min-width: 768px) {
  .home-line {
    background-size: 1000px;
  }
}
@media (min-width: 920px) {
  .home-line {
    background-size: 1200px;
  }
}
@media (min-width: 1024px) {
  .home-line {
    background-size: 1300px;
  }
}
@media (min-width: 1200px) {
  .home-line {
    background-size: 1400px;
  }
}

.home-hero-main__cta {
  display: block;
  margin-top: 60px;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  overflow: hidden;
}
@media (min-width: 920px) {
  .home-hero-main__cta {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 1060px) {
  .home-hero-main__cta {
    margin-top: 40px;
  }
}
@media (min-width: 1200px) {
  .home-hero-main__cta {
    margin-top: 100px;
  }
}

.home-hero-main__cta,
.home-hero-main__cta:hover,
.home-hero-main__cta:active,
.home-hero-main__cta:focus {
  text-decoration: none;
}

@media (min-width: 920px) {
  .home-hero-main__cta--mobile {
    display: none;
  }
}

.home-carrousel:not(.swiper-container-initialized)::before,
.home-hero-slide__figure--loading + .home-hero-slide__content::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  background: none;
  transform: translate(-50%, -50%) rotate(0deg);
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 100%;
  animation-name: loadCircle;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.home-carrousel:not(.swiper-container-initialized)::before {
  left: 50%;
}

.home-hero-slide__figure--loading + .home-hero-slide__content::before {
  left: 75%;
}

.home-carrousel:not(.swiper-container-initialized) .swiper-slide {
  opacity: 0 !important;
}
.home-carrousel:not(.swiper-container-initialized) .swiper-slide:not(:first-child) {
  display: none;
}

.home-carrousel.swiper-container-initialized .swiper-slide {
  opacity: 0 !important;
}

.home-carrousel.swiper-container-initialized .swiper-slide-active {
  opacity: 1 !important;
}

.home-hero-slide__content .heading {
  white-space: nowrap;
  text-shadow: -1px -1px 0 #FFF, 0 -1px 0 #FFF, 1px -1px 0 #FFF, 1px 0 0 #FFF, 1px 1px 0 #FFF, 0 1px 0 #FFF, -1px 1px 0 #FFF, -1px 0 0 #FFF;
}

.home-hero-slide__content .button {
  padding: 7px 1.3em 8px 1.3em;
  min-height: 40px;
}
@media (max-width: 919px) {
  .home-hero-slide__content .button span {
    display: none !important;
  }
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):hover, .home-hero-slide__content .button:is(a)[href]:hover {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):hover::before, .home-hero-slide__content .button:is(a)[href]:hover::before {
  background-position: 0 0;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):hover::after, .home-hero-slide__content .button:is(a)[href]:hover::after {
  width: 40px;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):hover i, .home-hero-slide__content .button:is(a)[href]:hover i {
  transform: translateX(0.6em) scale(1.2);
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):hover, .home-hero-slide__content .button:-webkit-any(a)[href]:hover {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):hover::before, .home-hero-slide__content .button:-webkit-any(a)[href]:hover::before {
  background-position: 0 0;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):hover::after, .home-hero-slide__content .button:-webkit-any(a)[href]:hover::after {
  width: 40px;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):hover i, .home-hero-slide__content .button:-webkit-any(a)[href]:hover i {
  transform: translateX(0.6em) scale(1.2);
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):active, .home-hero-slide__content .button:is(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):active::before, .home-hero-slide__content .button:is(a)[href]:active::before {
  background-position: 0 0;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):active::after, .home-hero-slide__content .button:is(a)[href]:active::after {
  width: 40px;
}
.home-hero-slide__content .button:is(:not(a)):not(:disabled):active i, .home-hero-slide__content .button:is(a)[href]:active i {
  transform: translateX(0.6em) scale(1.2);
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):active, .home-hero-slide__content .button:-webkit-any(a)[href]:active {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, 0.2);
  background-position: 0 0;
  text-decoration: none;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):active::before, .home-hero-slide__content .button:-webkit-any(a)[href]:active::before {
  background-position: 0 0;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):active::after, .home-hero-slide__content .button:-webkit-any(a)[href]:active::after {
  width: 40px;
}
.home-hero-slide__content .button:-webkit-any(:not(a)):not(:disabled):active i, .home-hero-slide__content .button:-webkit-any(a)[href]:active i {
  transform: translateX(0.6em) scale(1.2);
}

.home-gradient-bg-container {
  position: relative;
  z-index: -1;
}

.home-gradient-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(#FFFFFF, #F5F5F2, #FFFFFF);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-25%);
}

.double-heading-spacing {
  margin-bottom: 44px;
}
@media (min-width: 768px) {
  .double-heading-spacing {
    margin-bottom: 121px;
  }
}

.home-slider-container {
  overflow: hidden;
  width: calc(100% + 30px);
  padding-top: 15px;
  margin-top: -15px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.home-slider-container .grid-item > * {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--15px);
  padding-right: var(--15px);
}
@media (min-width: 768px) {
  .home-slider-container .grid-item > * {
    padding-left: var(--50px);
    padding-right: var(--50px);
  }
}
@media (min-width: 1200px) {
  .home-slider-container .grid-item > * {
    padding-left: var(--85px);
    padding-right: var(--85px);
  }
}

.hr-video {
  width: 100%;
  max-width: 2000px;
  margin: -50px auto -18px;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
}
@media (min-width: 700px) {
  .hr-video {
    margin: -60px auto -40px;
  }
}
@media (min-width: 1200px) {
  .hr-video {
    width: 2000px;
    transform: translateX(calc(50vw - 1000px));
    margin: -100px auto -70px;
  }
}
@media (min-width: 2000px) {
  .hr-video {
    transform: translateX(0);
  }
}
.hr-video video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (min-width: 1200px) {
  .hr-video video {
    height: 720px;
  }
}
.hr-video__wide-gradient {
  display: none;
  height: 100%;
  width: 140px;
  position: absolute;
  top: 0;
  background-color: white;
  background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.hr-video__wide-gradient--left {
  left: 0;
}
.hr-video__wide-gradient--right {
  right: 0;
  transform: rotate(180deg);
}
@media (min-width: 2000px) {
  .hr-video__wide-gradient {
    display: block;
  }
}

.news-detail section {
  margin-top: var(--20px);
  margin-bottom: var(--28px);
  display: flow-root;
}

.news-detail section .editableContainer img,
.news-detail section .editableContainer iframe {
  max-width: 100%;
  margin-bottom: var(--20px);
}

.news-detail p {
  margin-bottom: var(--20px);
}

.news-detail hr {
  margin: var(--50px) 0;
}

.news-detail hr:last-child {
  margin: var(--50px) 0 var(--20px);
}

.news-detail .news-detail__header hr {
  margin-bottom: 0;
}

.news-detail__header--block {
  margin-top: 0;
  margin-bottom: 116px;
  padding-left: 0;
  padding-right: 0;
}

.news-detail__header--inline {
  position: relative;
  width: 100%;
  margin-top: 104px;
  margin-bottom: 30px;
}
@supports not (display: grid) {
  .news-detail__header--inline {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}

.news-detail__content {
  margin-bottom: var(--50px);
  font-size: var(--22px);
  font-weight: var(--fw-medium);
  line-height: 30px;
}

.news-detail__intro-text {
  font-size: var(--22px);
  line-height: 30px;
  font-weight: var(--fw-medium);
}

.news-detail__timestamp {
  color: var(--apt-grey-4);
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
}

.news-detail__header--block .news-detail__timestamp {
  position: relative;
  width: 100%;
  max-width: 710px;
  margin: 0 auto;
}
@supports not (display: grid) {
  .news-detail__header--block .news-detail__timestamp {
    max-width: 610px;
  }
}
@media (min-width: 768px) {
  .news-detail__header--block .news-detail__timestamp {
    text-align: right;
    max-width: 610px;
  }
}

.news-detail__header--block .news-detail__timestamp time {
  position: absolute;
  top: var(--48px);
  left: var(--15px);
}
@media (min-width: 768px) {
  .news-detail__header--block .news-detail__timestamp time {
    right: 0;
    left: 0;
  }
}

.news-detail__header--inline .news-detail__timestamp {
  position: absolute;
  top: -2.75em;
}
@media (min-width: 768px) {
  .news-detail__header--inline .news-detail__timestamp {
    right: 50px;
  }
}

.news-detail .embed-figure {
  margin-top: var(--30px);
}

.detail-grid {
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: grid;
  grid-template-columns: 100%;
}
@media (min-width: 768px) {
  .detail-grid {
    padding-top: 50px;
  }
}
@media (min-width: 992px) {
  .detail-grid {
    grid-template-columns: 1fr 390px;
    grid-template-rows: auto 1fr;
    padding-top: 60px;
  }
}

.detail-gallery {
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/span 1;
  min-width: 0;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media (min-width: 768px) {
  .detail-gallery {
    width: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .detail-gallery {
    grid-column: 1/2;
    grid-row: 1/2;
    width: calc(100% - 50px);
    margin-right: 50px;
    margin-left: 0;
  }
}

.detail-gallery .tag-list {
  position: absolute;
  top: 15px;
  left: 18px;
  z-index: 2;
  max-width: calc(100% - 100px);
}
@media (min-width: 768px) {
  .detail-gallery .tag-list {
    top: 31px;
    left: 30px;
  }
}

.detail-gallery .user-interaction-icons {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 24px;
  align-items: flex-start;
  justify-content: center;
}
@media (min-width: 768px) {
  .detail-gallery .user-interaction-icons {
    display: none;
  }
}
.detail-gallery .user-interaction-icons button svg {
  margin: 0 auto;
}

@media (max-width: 767px) {
  .detail-gallery .gallery-container--spinner .gallery-button-list {
    margin-bottom: 10px;
  }
}

.detail-content {
  grid-column: 1/-1;
  grid-row: 3/span 1;
  min-width: 0;
}
@media (min-width: 992px) {
  .detail-content {
    grid-column: 1/2;
    grid-row: 2/3;
    margin-right: 50px;
  }
}

.detail-content__top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  gap: 20px;
}
@media (min-width: 992px) {
  .detail-content__top {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 20px;
  }
}
@media (min-width: 1200px) {
  .detail-content__top {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
}

.detail-content__top p a {
  white-space: nowrap;
}

.detail-content > div:first-child {
  margin-bottom: 30px;
}

.detail-content > .data-list {
  margin-bottom: 73px;
}

.detail-content > .two-columns {
  row-gap: 2rem;
}

.detail-sidebar {
  position: relative;
  grid-column: 1/-1;
  grid-row: 2/span 1;
  padding-top: 2px;
  min-width: 0;
}
@media (min-width: 992px) {
  .detail-sidebar {
    grid-column: 2/3;
    grid-row: 1/-1;
    padding-top: 0;
  }
}
.detail-sidebar .card {
  height: auto;
  box-shadow: var(--shadow-b);
}

@media (max-width: 991px) {
  .detail-sidebar .tag-list {
    display: none !important;
  }
}

.detail-sidebar .user-interaction-icons {
  display: inline-flex;
}
@media (max-width: 991px) {
  .detail-sidebar .user-interaction-icons {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .detail-sidebar .user-interaction-icons {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.detail-sidebar .card {
  margin-bottom: 40px;
}

.detail-sidebar > .heading-2 {
  margin-top: 27px;
  margin-bottom: 6px;
}
@media (min-width: 512px) {
  .detail-sidebar > .heading-2 {
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) {
  .detail-sidebar > .heading-2 {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
  .detail-sidebar > .heading-2 {
    margin-top: 23px;
    margin-bottom: 20px;
  }
}

.detail-sidebar .button--primary {
  width: 100%;
  margin-top: 34px;
}

.detail-sidebar__stats-bar {
  display: inline-flex;
  gap: 11px;
  align-items: center;
}
@media (max-width: 991px) {
  .detail-sidebar__stats-bar {
    display: none !important;
  }
}

.detail-sidebar__supply {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  display: block;
}
@media (min-width: 768px) {
  .detail-sidebar__supply {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .detail-sidebar__supply {
    display: none;
  }
}

.detail-sidebar__supply a {
  display: block;
}
@media (min-width: 768px) {
  .detail-sidebar__supply a {
    display: inline;
  }
}

.detail-sidebar__tag {
  background-color: transparent;
  border-radius: 2em;
  border: 1px solid var(--apt-grey-3);
  color: var(--apt-grey-3);
  display: inline-flex;
  font-size: var(--11px);
  font-weight: var(--fw-semibold);
  letter-spacing: 0.46px;
  line-height: 1.1;
  padding: 0.23em 0.75em 0.35em;
  text-decoration: none;
  text-transform: uppercase;
  transition: filter 0.2s ease, background-color 0.2s ease;
}

.detail-sidebar__views {
  position: absolute;
  top: 30px;
  left: 0;
  font-size: var(--14px);
  line-height: 1.2;
  letter-spacing: 0.26px;
  color: var(--apt-grey-4);
  z-index: 2;
}
@media (max-width: 991px) {
  .detail-sidebar__views {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .detail-sidebar__views {
    top: 40px;
    right: initial;
  }
}
@media (min-width: 992px) {
  .detail-sidebar__views {
    position: relative;
    top: -1px;
  }
}

.detail-sidebar__class {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
  color: var(--apt-grey-4);
  margin-bottom: 9px;
}

.detail-sidebar__characteristics {
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.detail-sidebar__characteristics li:not(:last-child)::after {
  content: "|";
  margin-left: 1ch;
  margin-right: 1ch;
}

.two-columns {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: grid;
}
@media (min-width: 768px) {
  .two-columns {
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    justify-content: space-between;
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .two-columns {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}
@supports not (padding: max(1vw, 100px)) {
  @media (min-width: 768px) {
    .two-columns {
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    }
  }
}

.two-columns > div {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 992px) {
  .two-columns > div {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.two-columns .heading {
  margin-bottom: var(--25px);
}

.vdp-gallery-grid-align-hack.gallery-grid__toggle-container {
  display: flex;
  gap: 10px;
  bottom: 0;
  z-index: 2;
}
@media (max-width: 767px) {
  .vdp-gallery-grid-align-hack.gallery-grid__toggle-container {
    position: absolute;
    top: 16px;
    right: var(--15px);
    bottom: auto;
    left: auto;
    transform: none !important;
  }
}
@media (min-width: 768px) {
  .vdp-gallery-grid-align-hack.gallery-grid__toggle-container {
    left: 0;
    bottom: -12px;
  }
}
@media (min-width: 992px) {
  .vdp-gallery-grid-align-hack.gallery-grid__toggle-container {
    left: 0;
    bottom: -25px;
  }
}

.vdp-gallery-grid-align-hack.gallery-grid__toggle-container .gallery-grid__fullscreen-container {
  position: static;
}

@media (max-width: 767px) {
  .vdp-gallery-grid-align-hack ~ .gallery-grid__primary .gallery-grid__sirv-container {
    margin-top: 62px;
  }
}

@media (max-width: 767px) {
  .vdp-gallery-grid-align-hack ~ .gallery-grid__primary .swiper-wrapper {
    margin-top: 62px;
    margin-bottom: 38px;
  }
}

@media (max-width: 767px) {
  .vdp-gallery-grid-align-hack ~ .gallery-grid__primary .gallery-slider__pagination.swiper-pagination-fraction {
    top: 21px !important;
    bottom: auto !important;
    left: 0;
    right: auto;
  }
}

.gallery-slider__pagination.swiper-pagination-fraction {
  position: absolute;
}

.floating-toggle {
  position: fixed;
  bottom: -75px;
  right: 20px;
  z-index: 3;
}
@media (max-width: 767px) {
  .floating-toggle {
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
.floating-toggle .floating-toggle-button {
  position: relative;
  transform: translate3d(0px, 0px, 0px) translateY(0);
  transition: transform 0.4s ease;
}
@media (max-width: 767px) {
  .floating-toggle .button.button--primary {
    padding: 7px 32px 9px;
    min-height: 42px;
  }
}

.floating-toggle--visible .floating-toggle-button {
  transform: translate3d(0px, 0px, 0px) translateY(-95px);
}
@media (max-width: 767px) {
  .floating-toggle--visible .floating-toggle-button {
    transform: translate3d(0px, 0px, 0px) translateY(-107px);
  }
}

[data-cy=message-input-group] [class^=App_counter__] {
  flex-grow: 0;
  flex-shrink: 0;
}

.direct-phone-link {
  font-family: var(--fellix);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.3px;
  color: var(--apt-primary);
}

.direct-phone-location {
  position: relative;
  width: 100%;
  max-width: 324px;
  margin: 29px 0 0;
}
.direct-phone-location > span:first-of-type {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  line-height: 28px;
  height: 50px;
  color: var(--apt-grey-3);
  font-size: var(--18px);
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: left top;
  transition: transform 0.2s linear;
  transform: translateY(-1.44em) scale(0.78);
  overflow: visible;
  text-overflow: initial;
  padding: 8px 12px 0 0;
}
.direct-phone-location > span:last-of-type {
  display: block;
  padding: 10px 0 12px 0;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  min-height: 50px;
  line-height: initial;
  font-size: var(--18px);
  letter-spacing: normal;
  color: var(--apt-black);
  background: none;
}

.map-widget {
  padding: 0;
  z-index: 1;
}

#gmap {
  width: 100%;
  height: 460px;
  background-color: var(--apt-grey-7);
}
@media (max-height: 501px) {
  #gmap {
    height: calc(100vh - 62px);
  }
}

.map-overlay {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media (min-width: 992px) {
  .map-overlay {
    justify-content: flex-end;
    align-items: center;
    margin-top: -460px;
    height: 460px;
  }
}

.map-overlay-card {
  position: relative;
  width: 100%;
  max-width: 608px;
  border-radius: var(--10px);
  box-shadow: 0 4px 6px 0 var(--apt-grey-6);
  margin-top: -200px;
  margin-bottom: 10px;
  z-index: 1;
}
@media (min-width: 992px) {
  .map-overlay-card {
    top: -7px;
    right: 0;
    margin-top: 0;
  }
}

.map-overlay-card--hidden {
  display: none;
  position: absolute;
  left: -100vw;
}

.map-overlay-card .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 26px;
  height: 26px;
  background: var(--apt-white);
  border-radius: 100%;
  text-align: center;
  font-size: var(--20px);
  line-height: 25px;
  color: black;
}

.map-overlay-card .close-button:focus {
  border-radius: 100%;
}

.map-overlay-card__footer {
  background-color: var(--apt-primary);
  width: 100%;
  text-align: center;
  border-bottom-left-radius: var(--10px);
  border-bottom-right-radius: var(--10px);
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  color: var(--apt-white);
  font-weight: var(--fw-medium);
  padding-top: var(--1px);
  padding-right: 20px;
  padding-bottom: var(--4px);
  padding-left: 20px;
}

@media (min-width: 992px) {
  .map-overlay-card .opening-hours-card__address {
    width: 304px;
  }
}

@media (min-width: 992px) {
  .map-overlay-card .opening-hours-card__address figure {
    height: 160px;
  }
}

@media (min-width: 992px) {
  .map-overlay-card .opening-hours-card__address address {
    padding: 15px 30px 20px;
  }
}

@media (min-width: 992px) {
  .map-overlay-card .opening-hours-card__listing .heading-4 {
    margin-bottom: var(--8px);
  }
}

@media (min-width: 992px) {
  .map-overlay-card .opening-hours-card__listing .dropdown__toggle {
    padding-bottom: var(--4px);
    height: var(--38px);
  }
}

.map-overlay-card .opening-hours-card__listing table {
  table-layout: auto;
  margin-top: 14px;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  text-align: left;
}

.map-overlay-card .opening-hours-card__listing td {
  width: 1%;
  white-space: nowrap;
  min-width: 0;
  padding-right: 10px;
}

.vestigingen {
  width: 100%;
  margin-top: var(--48px);
}
@media (min-width: 992px) {
  .vestigingen {
    display: flex;
    margin-top: var(--94px);
  }
}

.vestigingen__filters {
  flex: 0 0 auto;
  width: 100%;
  max-width: 608px;
  margin: 0 auto;
  padding: 44px 30px 35px;
  background: var(--apt-white);
  border-radius: 10px;
}
@media (min-width: 992px) {
  .vestigingen__filters {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.vestigingen__filters .heading-2 {
  text-align: center;
  width: 200px;
  margin-right: auto;
  margin-bottom: 38px;
  margin-left: auto;
}
@media (min-width: 512px) {
  .vestigingen__filters .heading-2 {
    width: auto;
  }
}
@media (min-width: 992px) {
  .vestigingen__filters .heading-2 {
    text-align: left;
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .vestigingen__sticky-container {
    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 511px) {
  .vestigingen__sticky-container {
    max-width: none;
  }
}
@media (min-width: 992px) {
  .vestigingen__sticky-container {
    margin-bottom: 40px;
  }
}

.vestigingen__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;
}
@media (min-width: 768px) {
  .vestigingen__results {
    width: 100%;
    left: 0;
    max-width: 670px;
  }
}
@media (min-width: 992px) {
  .vestigingen__results {
    width: 100%;
    max-width: none;
    margin-top: calc(96px + 28px);
    margin-bottom: 96px;
  }
}

.vestigingen .dialog__title,
.vestigingen .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);
}
@media (min-width: 768px) {
  .vestigingen .dialog__title,
.vestigingen .opening-hours-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.vestigingen__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .vestigingen__result {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .vestigingen__result .dialog {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }
}
@media (min-width: 992px) {
  .vestigingen__result .dialog {
    padding-right: 0;
  }
}

.vestigingen__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.vestigingen__result .dialog .dialog__title .fa-times {
  display: none;
}

.vestigingen__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.vestigingen__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .filter {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.filter-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 16px;
}
@media (min-width: 512px) {
  .filter-header {
    flex-direction: row;
    padding: 55px 0 16px;
  }
}
@media (min-width: 768px) {
  .filter-header {
    justify-content: center;
    align-items: center;
    padding: 85px 0 26px;
  }
}

.filter-header > span {
  display: block;
  line-height: 1.25;
  padding: 0.29em 36px 0.39em 0;
  margin-bottom: var(--22px);
}
@media (min-width: 768px) {
  .filter-header > span {
    padding: 0.29em 36px 0.39em 15px;
  }
}

.filter-grid {
  width: calc(100% + 24px);
  max-width: 1294px;
  margin-left: -12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.filter-grid-item {
  width: 100%;
  padding: 20px 12px;
}
@media (min-width: 768px) {
  .filter-grid-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .filter-grid-item {
    width: 33.333%;
  }
}

#input-select,
#input-number {
  padding: 7px;
  margin: 15px 5px 5px;
  width: 70px;
}

.filter-container {
  width: calc(100% + 24px);
  margin-left: -12px;
}

.filter-container .expandable__visible {
  padding-left: 12px;
  padding-right: 12px;
}

.filter-container .expandable__button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 43px 0 73px;
}

.filter-container .expandable__button {
  margin: 0;
}

.double-range-selector-container {
  width: 100%;
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
@media (min-width: 768px) {
  .double-range-selector-container {
    height: 40px;
  }
}

.double-range-selector-label {
  letter-spacing: normal;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  color: var(--apt-grey-3);
  text-indent: 0;
  z-index: 1;
}

.double-range-selector {
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .double-range-selector {
    display: block;
  }
}

.double-range-selector #slider1-skip-min {
  position: absolute;
  left: 0;
}

.double-range-selector #slider1-skip-max {
  position: absolute;
  right: 0;
}

.double-range-selector .slider-value {
  top: 10px;
  color: var(--apt-grey-2);
}
@media (max-width: 767px) {
  .double-range-selector .slider-value {
    top: 12px;
  }
}
.double-range-selector .slider-value--min {
  position: absolute;
  left: 0;
}
.double-range-selector .slider-value--max {
  position: absolute;
  right: 0;
}

.double-range-selector .noUi-target {
  background: var(--apt-grey-7);
  border-radius: 0;
  border: 0;
  box-shadow: none;
  height: 3px;
}

.double-range-selector .noUi-handle {
  border: 0;
  border-radius: 50%;
  background: #01CEF4;
  cursor: default;
  box-shadow: none;
  outline: none;
}
.double-range-selector .noUi-handle::before, .double-range-selector .noUi-handle::after {
  content: "";
  display: none;
}

.double-range-selector .noUi-base .noUi-origin:last-child .noUi-handle {
  background: var(--apt-primary);
}

.double-range-selector .noUi-horizontal .noUi-handle {
  width: 13px;
  height: 13px;
  top: -5px;
}
@media (min-width: 768px) {
  .double-range-selector .noUi-horizontal .noUi-handle {
    width: 9px;
    height: 9px;
    top: -3px;
  }
}

.double-range-selector .noUi-horizontal .noUi-handle-lower {
  right: 0;
  transform: translateX(50%);
}

.double-range-selector .noUi-horizontal .noUi-handle-upper {
  right: 0;
  transform: translateX(50%);
}

.double-range-selector .noUi-horizontal .noUi-touch-area:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background: transparent;
  opacity: 0;
  z-index: -1;
}

.double-range-selector .slider-value {
  font-size: 14px;
  line-height: 1;
}

.double-range-selector .noUi-tooltip {
  top: calc(100% + 3px);
  bottom: unset !important;
  border: 0;
  border-radius: 0;
  background: var(--apt-grey-9);
  color: var(--apt-grey-2);
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1;
  padding: 0 1.4em;
}

.double-range-selector .noUi-connect {
  background-image: linear-gradient(270deg, var(--apt-primary), #2F78FC, #2193F9, #11B0F7, #01CEF4);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.mobile-range-selector {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 14px;
}
@media (min-width: 768px) {
  .mobile-range-selector {
    display: none;
  }
}

.mobile-range-selector__from, .mobile-range-selector__to {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: baseline;
  padding: 0.5em 0;
  font-size: var(--14px);
  line-height: 1.4;
  border-bottom: 1px solid var(--apt-grey-4);
}

.mobile-range-selector__from {
  width: calc(50% - 35px);
  margin-right: 15px;
}

.mobile-range-selector__to {
  width: calc(50% - 35px);
  margin-left: 15px;
}

.mobile-range-selector__button {
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.mobile-range-selector__button button {
  width: 28px;
}

.mobile-range-selector__input {
  width: 100%;
  flex: 1 1 auto;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  color: var(--apt-grey-2);
}
.mobile-range-selector__input:autofill, .mobile-range-selector__input:autofill:hover, .mobile-range-selector__input:autofill:focus, .mobile-range-selector__input:autofill:active {
  box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  font-size: var(--14px);
  line-height: 1.4;
}
.mobile-range-selector__input:-webkit-autofill, .mobile-range-selector__input:-webkit-autofill:hover, .mobile-range-selector__input:-webkit-autofill:focus, .mobile-range-selector__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  font-size: var(--14px);
  line-height: 1.4;
}
.mobile-range-selector__input:autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--14px);
  line-height: 1.4;
  font-weight: normal;
  color: inherit;
}
.mobile-range-selector__input:-webkit-autofill::first-line {
  font-family: var(--fellix);
  font-size: var(--14px);
  line-height: 1.4;
  font-weight: normal;
  color: inherit;
}

.mobile-range-selector__label {
  flex: 0 0 auto;
  padding-right: 0.5em;
  color: var(--apt-grey-3);
}

.werken-bij {
  width: 100%;
  margin-top: var(--48px);
}
@media (min-width: 992px) {
  .werken-bij {
    display: flex;
    margin-top: 120px;
  }
}
.werken-bij .arrow-btns a:hover, .werken-bij .arrow-btns button:hover,
.werken-bij .arrow-btns a:focus, .werken-bij .arrow-btns button:focus,
.werken-bij .arrow-btns a:active, .werken-bij .arrow-btns button:active {
  font-weight: var(--fw-medium);
}
.werken-bij .arrow-btns a:hover .fal, .werken-bij .arrow-btns button:hover .fal,
.werken-bij .arrow-btns a:focus .fal, .werken-bij .arrow-btns button:focus .fal,
.werken-bij .arrow-btns a:active .fal, .werken-bij .arrow-btns button:active .fal {
  font-weight: var(--fw-medium);
}

.werken-bij__filters {
  flex: 0 0 auto;
  width: 100%;
  max-width: 670px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .werken-bij__filters {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.werken-bij__filters .heading-2 {
  text-align: center;
  width: 200px;
  margin-right: auto;
  margin-bottom: 38px;
  margin-left: auto;
}
@media (min-width: 512px) {
  .werken-bij__filters .heading-2 {
    width: auto;
  }
}
@media (min-width: 992px) {
  .werken-bij__filters .heading-2 {
    text-align: left;
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 991px) {
  .werken-bij__sticky-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 992px) {
  .werken-bij__sticky-container {
    margin-bottom: 40px;
  }
}

.werken-bij__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;
}
@media (min-width: 768px) {
  .werken-bij__results {
    width: 100%;
    left: 0;
    max-width: 670px;
  }
}
@media (min-width: 992px) {
  .werken-bij__results {
    width: 100%;
    max-width: none;
    margin-top: calc(104px + 28px);
    margin-bottom: 96px;
  }
}

.werken-bij .dialog__title,
.werken-bij .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);
}
@media (min-width: 768px) {
  .werken-bij .dialog__title,
.werken-bij .opening-hours-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.werken-bij__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .werken-bij__result {
    max-width: none;
  }
}

@media (min-width: 768px) {
  .werken-bij__result .dialog {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }
}
@media (min-width: 992px) {
  .werken-bij__result .dialog {
    padding-right: 0;
  }
}

.werken-bij__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.werken-bij__result .dialog .dialog__title .fa-times {
  display: none;
}

.werken-bij__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.werken-bij__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}

.werken-bij__detail-content {
  flex: 1 1 auto;
  padding-right: 30px;
  padding-bottom: 50px;
}
@media (min-width: 992px) {
  .werken-bij__detail-content {
    padding-bottom: 160px;
  }
}

.werken-bij__detail-content-wrapper {
  width: 100%;
  max-width: 523px;
}
.werken-bij__detail-content-wrapper img {
  max-width: 100%;
}
.werken-bij__detail-content-wrapper h1 {
  display: block;
  font-size: var(--22px);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--12px);
}
@media (min-width: 512px) {
  .werken-bij__detail-content-wrapper h1 {
    font-size: var(--28px);
    line-height: 30px;
    letter-spacing: -0.12px;
  }
}
@media (min-width: 768px) {
  .werken-bij__detail-content-wrapper h1 {
    font-size: var(--40px);
    line-height: 48px;
    letter-spacing: -0.5px;
  }
}
.werken-bij__detail-content-wrapper h2 {
  display: block;
  font-size: var(--22px);
  line-height: 30px;
  letter-spacing: -0.09px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--17px);
}
.werken-bij__detail-content-wrapper h3 {
  display: block;
  font-size: var(--18px);
  line-height: 26px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

.werken-bij__detail-aside {
  flex: 0 0 auto;
  width: 100%;
  max-width: 390px;
  padding-bottom: 1.2em;
}
.werken-bij__detail-aside .block-default, .werken-bij__detail-aside .pagesection-default, .werken-bij__detail-aside .wrapper, .werken-bij__detail-aside .editableContainer {
  height: 100%;
}
.werken-bij__detail-aside .card {
  position: sticky;
  top: 120px;
  height: auto;
}
@media (min-width: 512px) {
  .werken-bij__detail-aside .card__content {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.werken-bij__detail-aside .card__content > div {
  width: 100%;
}
.werken-bij__detail-aside h2 {
  display: block;
  font-size: var(--22px);
  line-height: 30px;
  letter-spacing: -0.09px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--17px);
}

.werken-bij .card__figure figure {
  width: 100%;
}
@supports not (aspect-ratio: 3/1.9) {
  .werken-bij .card__figure figure {
    margin: 0;
    height: auto;
  }
}
.werken-bij .card__figure figure img {
  width: 100%;
  aspect-ratio: 0;
  max-height: 200px;
  object-fit: cover;
}
@supports not (aspect-ratio: 3/1.9) {
  .werken-bij .card__figure figure img {
    height: auto;
    position: static;
    margin: 0;
  }
}

.werken-bij .card .button {
  width: 100%;
  margin-bottom: 20px;
}

.werken-bij .card__content p {
  font-size: var(--14px);
  line-height: 22px;
  color: var(--apt-grey-2);
}

.werken-bij .card__content hr {
  width: 100%;
  margin-top: 46px;
  margin-bottom: 38px;
}

/* TAKEN FROM https://www.wur.nl/ */
.bcpNotificationBar {
  background-color: #ffffff;
}

.bcpNotificationBar:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: block;
  content: " ";
  z-index: 1;
}

.bcpNotificationBarContent {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 30px 30px 0px;
  color: #000000;
  font-size: 13px;
  line-height: 21px;
  padding-bottom: 10px;
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.bcpNotificationBarTemplate {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  max-width: 940px;
}

.bcpNotificationBarTemplate > h1 {
  text-align: left;
  line-height: 1.1em;
  font-size: 18px;
  margin-bottom: 15px;
}

.bcpNotificationBarGDPR {
  min-width: 768px;
  background-color: #f3fcff;
  line-height: 1;
  overflow-wrap: break-word;
  /* only supported by a few browsers */
  overflow-wrap: hyphenate-word;
}

.bcpNotificationBarGDPR .bcpGDPRContent {
  margin: 0;
  color: #666;
  padding: 0px;
}

.bcpNotificationBarButtons {
  padding: 10px 0;
  padding-right: 10px;
}

.bcpNotificationBarButtons > div {
  display: inline-block;
  float: left;
}

.bcpNotificationBarButtons .bcpObjectiveButtonsTemplate {
  padding: 0px 0px;
}

.bcpObjectiveTemplate button,
.bcpObjectiveTemplate button:hover,
.bcpObjectiveTemplate button:focus,
.bcpObjectiveTemplate button:active {
  outline: none !important;
}

.bcObjectiveUitklap {
  max-height: 0px;
  width: 100%;
  transition: max-height 0.3s;
  overflow: hidden;
}

.bcpNotificationBarContent.show-objectives .bcObjectiveUitklap {
  max-height: 500px;
}

.bcGDPR .bcpObjectiveTemplate .bcpObjective:hover {
  background-color: transparent;
}

.bccomplexobject {
  margin: 0px auto;
  max-width: 940px;
}

/* styles from screens lower than 768px; */
@media screen and (max-width: 768px) {
  .bcpNotificationBarGDPR {
    width: 100%;
    min-width: 0;
  }

  .bcpNotificationBarContent {
    border-top: 1px solid #f2f2f2;
  }

  .bcpNotificationBarTemplate {
    flex-direction: column;
  }

  .bcpNotificationBarButtons {
    margin-top: 5px;
    min-width: 100%;
    width: 100%;
  }

  .bcpObjectives {
    margin-top: 15px;
  }
}
/* styles from screens lower than 513px */
@media screen and (max-width: 513px) {
  .bcObjective {
    /*transform: translateX(10px);*/
  }

  .bcpNotificationBarGDPR {
    max-width: 480px;
  }

  .bcpNotificationBarGDPR .bcpGDPRContent {
    padding: 0px 20px;
  }

  .bcpNotificationBarTemplate {
    padding: 20px 20px;
  }

  .bcpNotificationBarCloseVerticalCenter {
    margin-top: 10px;
  }

  .bcpNotificationBarContent {
    padding: 0px;
  }

  .bcpNotificationBarButtons > div {
    display: unset;
    float: inherit;
  }
}
input.buttonInput.primaryAction:hover,
input.buttonInput.primaryAction:focus {
  background-color: #dedccf;
}

/* Extra door Marjolijn: Styling neccesary for the dialogue after changing close interaction to refresh page */
.bcGDPR {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.bcGDPR:after {
  content: "";
  display: block;
  clear: both;
}

.bcGDPR .bcAfterSubmitTextWrapper {
  padding: 20px;
}

.bcpObjectiveTemplate {
  padding: 10px 18px 20px 0;
  width: auto;
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.bcpObjectiveButtonsTemplate {
  padding: 0 15px 20px;
}

.bcpObjectiveTemplate .bcpObjectives {
  padding: 0px;
  margin: 0px 0px 8px;
  list-style-type: none;
  font-family: Verdana;
}

.bcpObjectiveTemplate ul li {
  margin: 0;
}

.bcpObjectiveTemplate .bcpObjective {
  min-height: 30px;
  line-height: 20px;
  padding: 5px 0px;
}

.bcpObjectiveTemplate .bcpObjective:hover,
.bcpObjectiveTemplate .bcpObjective:focus {
  background-color: #f7f7f7;
}

.bcpObjectiveTemplate .bcpObjective input[type=checkbox] {
  margin: 4px 3px;
}

.bcpObjectiveTemplate .bcpObjective input[type=checkbox],
.bcpObjectiveTemplate .bcpObjective label {
  line-height: 20px;
  width: 20px;
  font-weight: normal;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.bcpObjectiveTemplate .bcpObjective label {
  padding-bottom: 5px;
  font-size: 13px;
  color: #000000;
  width: calc(100% - 60px);
  margin-top: -3px;
  font-weight: 700;
}

.bcpObjectiveTemplate .bcpObjective label.bcpDescription {
  color: #000000;
  margin-left: 30px;
  font-weight: 100;
  font-size: 13px;
}

.bcpObjectiveTemplate .bcpObjective .bcpObjectiveCheckbox {
  width: 16px;
  height: 16px;
  margin: 6px 0px;
}

.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton:hover,
.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton:focus,
.bcpObjectiveTemplate .bcpConsentButton:hover,
.bcpObjectiveTemplate .bcpConsentButton:focus {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #ddd;
}

.bcpObjectiveTemplate .bcpOkButton:hover,
.bcpObjectiveTemplate .bcpOkButton:focus,
.bcpObjectiveTemplate .bcpConsentOKButton:hover,
.bcpObjectiveTemplate .bcpConsentOKButton:focus {
  box-shadow: inset 0 0px 0px 0 rgba(0, 0, 0, 0);
  background-color: #007c00;
  opacity: 0.8;
}

.bcGDPR .bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpConsentOKButton {
  background-color: #007c00;
  color: #fff;
  padding: 13px 25px;
  border-radius: 25px/25px;
  font-size: 18px;
  font-weight: normal;
  box-shadow: none;
  border: none;
}

.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentCancelButton {
  background-color: #eeeeee;
  color: #555555;
  padding: 13px 25px;
  border-radius: 25px/25px;
  font-size: 16px;
  font-weight: normal;
  box-shadow: none;
  border: none;
}

.bcGDPR .bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpOkButton,
.bcpObjectiveTemplate .bcpConsentOKButton,
.bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentCancelButton {
  word-break: break-all;
  max-width: 400px;
  margin: 10px 0px;
}

/* styles from screens lower 768px; */
@media screen and (max-width: 768px) {
  .bcpObjectiveTemplate .bcpObjective label {
    padding-left: 5px;
  }
}
/* styles from screens lower than 513px */
@media screen and (max-width: 513px) {
  .bcpObjectiveTemplate {
    font-family: Arial;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px 10px;
  }

  .bcpObjectiveTemplate .bcpObjective {
    color: #666666;
    min-height: 30px;
  }

  .bcpObjectiveTemplate .bcpObjective .bcpObjectiveCheckbox {
    width: 32px;
    height: 32px;
  }

  .bcpObjectiveTemplate .bcpObjective label {
    width: calc(100% - 50px);
    padding-left: 15px;
  }

  .bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentButton {
    margin: 5px;
  }

  .bcpConsentOKButton,
.bcpConsentCancelButton {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .bcGDPR .bcAfterSubmitTextWrapper .bcClose .bcCloseButton,
.bcpObjectiveTemplate .bcpConsentButton,
.bcpObjectiveTemplate .bcpOkButton {
    border-radius: 25px;
    font-size: 16px;
    min-height: 45px;
    text-align: center;
    padding: 5px 10px;
    width: 100%;
  }
}
.bcInitialConsent {
  display: grid;
  grid-template-columns: 1fr !important;
  justify-content: start !important;
  gap: 8px !important;
}

.bcInitialConsent p:last-of-type {
  margin-bottom: 12px !important;
}

.bcInitialConsent a:not(.button) {
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--apt-grey-5);
  text-decoration-color: var(--apt-grey-5);
  text-underline-offset: 0.2em;
  box-sizing: border-box;
}

.bcInitialConsent a:not(.button):hover,
.bcInitialConsent a:not(.button):focus-visible {
  -webkit-text-decoration-color: var(--apt-primary);
  text-decoration-color: var(--apt-primary);
}

.bcInitialConsent .button.button.button,
.bcpNotificationBar .button.button.button {
  display: flex !important;
  align-items: center !important;
  justify-self: center !important;
  margin: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 3px !important;
  min-height: 40px !important;
}

@media (min-width: 376px) {
  .bcInitialConsent .button.button.button {
    justify-self: start !important;
  }
}
@media (min-width: 768px) {
  .bcInitialConsent {
    grid-template-columns: auto auto auto 1fr !important;
    grid-template-rows: auto auto auto !important;
    gap: 0px 16px !important;
  }

  .bcInitialConsent h2,
.bcInitialConsent p {
    grid-column: 1/-1 !important;
  }

  .bcInitialConsent p:last-of-type {
    margin-bottom: 20px !important;
  }

  .bcInitialConsent .button.button.button {
    grid-column: span 1/span 1 !important;
    grid-row: 3/4 !important;
  }
}
.bcGiveConsentWrapper {
  display: flex;
  gap: 16px !important;
  margin-top: 20px !important;
  flex-wrap: wrap !important;
}