.news-list-container h2 {
  margin-bottom: var(--55px);
}

.card.news-card {
  display: flex;
  flex-direction: column;

  figure {
    flex: 0 0 auto;
  }

  .card__content {
    flex: 1 1 auto;

    h1.heading.heading-3 {
      flex: 1 1 auto;
    }

    footer {
      flex: 0 0 auto;
    }
  }
}

.news-card .card__content .heading {
  margin-bottom: 27px;
}

.news-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: var(--16px);
  line-height: 24px;
  padding-right: 0.5em;

  .read-more-button .fal {
    padding-left: 0.5em;
  }
}

.news-cards-list-container {
  position: relative;
  padding-top: 56px;
  padding-bottom: 34px;

  @media (min-width: 992px) {
    padding-top: 46px;
    padding-bottom: 64px;
  }
}

.news-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--34px);
  flex-wrap: wrap;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    margin-bottom: 112px;
  }
}

.news-cards-list-container header .tag-list {
  align-content: flex-start;
  margin-top: var(--34px);
  margin-bottom: 0;
  min-height: 56px;

  @media (min-width: 992px) {
    justify-content: flex-end;
    align-content: flex-end;
    margin-right: -15px;
    margin-bottom: -5px;
    height: 200px;
  }
}
.news-cards-list-container h1,
.news-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
  max-width: 550px;
  flex: 1 1 auto;
}

.news-cards-list-container .news-list {
  flex-wrap: wrap;
  width: calc(100% + 30px);
  margin-left: -15px;
}

.news-list-item {
  padding: 0 15px 30px;
  margin-bottom: 0;
  width: 100%;

  @include min-media(612px) {
    width: 50%;
  }
  @include min-media(1200px) {
    width: 33.333%;
  }
}