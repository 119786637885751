.display {
  font-size: var(--43px);
  line-height: 43px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-semibold);

  @include min-media(768px) {
    font-size: var(--70px);
    line-height: 70px;
    letter-spacing: -1.25px;
  }
}

h1, .heading-1 {
  display: block;
  font-size: var(--28px);
  line-height: 30px;
  letter-spacing: -0.35px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--10px);

  @include min-media(512px) {
    font-size: var(--40px);
    line-height: 43px;
    letter-spacing: -0.5px;
  }

  @include min-media(768px) {
    font-size: var(--50px);
    line-height: 56px;
    letter-spacing: -0.63px;
    margin-bottom: var(--35px);
  }

  @include min-media(992px) {
    font-size: var(--60px);
    line-height: 63px;
    letter-spacing: -0.75px;
  }
}

h2, .heading-2 {
  display: block;
  font-size: var(--22px);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--12px);

  @include min-media(512px) {
    font-size: var(--28px);
    line-height: 30px;
    letter-spacing: -0.12px;
  }

  @include min-media(768px) {
    font-size: var(--40px);
    line-height: 48px;
    letter-spacing: -0.5px;
  }
}

h3, .heading-3 {
  display: block;
  font-size: var(--22px);
  line-height: 30px;
  letter-spacing: -0.09px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: var(--17px);
}

h4, .heading-4 {
  display: block;
  font-size: var(--18px);
  line-height: 26px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

h5, .heading-5 {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

h6, .heading-6 {
  display: block;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
}

.display--black, .heading--black {
   color: var(--apt-black);
 }

.display--white, .heading--white {
  color: var(--apt-white);
}

.display--primary, .heading--primary {
  color: var(--apt-primary);
}

a {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: var(--apt-grey-5);
  text-underline-offset: 0.2em;
  color:inherit;
}

a:not([href]) {
  color: inherit;
}

a:hover {
  text-decoration-color: var(--apt-primary);
}

.text-14, .text-14 p, .text-14 a, .text-14 span {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.text-16, .text-16 p, .text-16 a, .text-16 span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;
}

p, .text-18, .text-18 p, .text-18 a, .text-18 span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.3px;

  @include min-media(768px) {
    font-size: var(--18px);
    line-height: 30px;
  }
}

.text-20 {
  font-size: var(--18px);
  line-height: 1.4;
  letter-spacing: 0;

  @include min-media(768px) {
    font-size: var(--20px);
  }
}

.text-22 {
  font-size: var(--18px);
  line-height: 1.4;
  letter-spacing: 0;

  @include min-media(768px) {
    font-size: var(--22px);
  }
}

b, strong {
  font-weight: var(--fw-semibold);
}

.intro {
  font-weight: var(--fw-medium);
}

ul:not([class]) {
  list-style:none;
  padding: 0;
  margin: 0;
}

ul:not([class]) li {
  position: relative;
  //padding-left: 28px;
  padding-left: 1.55em;
  margin-bottom: 0.2em;
}

ul:not([class]) li::before {
  content: "•";
  position: absolute;
  top: 0.75em;
  left: 0;
  padding-left: 0;
  color: var(--apt-primary);
  font-size: 1em;
  width: 1px;
  height: 1px;
  line-height: 0;
  transform: translateY(-50%);
}

ul:not([class]) li.white::before {
  color: var(--apt-white);
}

ol:not([class]) {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: counter;
}

ol:not([class]) li {
  position: relative;
  padding-left: 0;
  padding-bottom: 0.2em;
}

ol:not([class]) li::before {
  content: counter(counter) ".";
  counter-increment: counter;
  display: inline-block;
  padding-left: 0;
  width: 1.5em;
  color: var(--apt-primary);
}

ul.checks {
  list-style:none;
  padding: 0;
  margin: 0;
  font-size: inherit;
}

ul.checks li {
  position: relative;
  padding-left: 1.7em;
  margin-bottom: 0.7em;
}

ul.checks li::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2014%22%3E%3Cpath%20d%3D%22M15.7321288%201.75174354c-2.5660131%203.25539738-4.4905229%205.69694542-5.77352945%207.32464412-.80188093%201.01731404-2.00470233%202.54328504-3.60846419%204.57791304C6.21906318%2013.8993534%206.00865818%2014%205.79480391%2014c-.21385427%200-.42356942-.102572-.58533982-.3076724L.24252626%207.39097384c-.32336835-.41024439-.32336835-1.0747309%200-1.48519408.32336835-.41046319.84713884-.41024439%201.17067965%200l4.381598%205.56055094L14.590423.3076833c.3233683-.4102444.8471388-.4102444%201.1706796%200%20.3235408.41024438.322851%201.07210533-.0289738%201.44406024z%22%20fill%3D%22%234157FF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-size: 0.95em 100%;
  padding-left: 0;
  width: 1.5em;
  height: 0.7em;
  color: var(--apt-primary);
}

ol:not([class]) {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: counter;
}

p + ul:not([class]),
p + ul.checks,
p + ol {
  margin-top: var(--17px);
}

hr {
  background-color: var(--apt-grey-7);
  border: none;
  font-size: 0;
  line-height: 0;
  height: 1px;
}
