// Mixins
@mixin min-media($media) {
  @media (min-width: $media) {
    @content;
  }
}
@mixin max-media($media) {
  @media (max-width: $media) {
    @content;
  }
}

@mixin reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}

@mixin high-contrast {
  @media screen and (-ms-high-contrast: active),
  (prefers-contrast: forced),
  (forced-colors: active) {
    @content;
  }
}

@mixin move-special-button {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,0.2);
  background-position: 0 0;
  text-decoration: none;

  &::before {
    background-position: 0 0;
  }

  &::after {
    width: 50px;
  }

  i {
    transform: translateX(1em) scale(1.2);
  }
}

@mixin move-special-button-small {
  transform: scale(1.000001);
  box-shadow: inset 1px 1px 1px 0 rgba(0,0,0,0.2);
  background-position: 0 0;
  text-decoration: none;

  &::before {
    background-position: 0 0;
  }

  &::after {
    width: 40px;
  }

  i {
    transform: translateX(0.6em) scale(1.2);
  }
}

@mixin is-not-disabled-hover {

  &:is(:not(a)):not(:disabled):hover,
  &:is(a)[href]:hover {
    @content;
  }

  &:-webkit-any(:not(a)):not(:disabled):hover,
  &:-webkit-any(a)[href]:hover {
    @content;
  }
}

@mixin is-not-disabled-active {
  &:is(:not(a)):not(:disabled):active,
  &:is(a)[href]:active {
    @content;
  }

  &:-webkit-any(:not(a)):not(:disabled):active,
  &:-webkit-any(a)[href]:active {
    @content;
  }
}

@mixin is-disabled-or-no-href {
  &:disabled, &:is(a):not([href]) {
    @content;
  }

  &:-webkit-any(a):not([href]) {
    @content;
  }
}

@mixin focus-visible {
  @if & {
    &:-moz-focusring,
    &:-moz-focusring,
    &:-moz-focusring {
      @content;
    }
  }
  @else {
    a[href]:-moz-focusring,
    button:-moz-focusring,
    [tabindex="0"]:-moz-focusring {
      @content;
    }
  }
  @if & {
    &:focus-visible,
    &:focus-visible,
    &:focus-visible {
      @content;
    }
  }
  @else {
    a[href]:focus-visible,
    button:focus-visible,
    [tabindex="0"]:focus-visible {
      @content;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shine {
  0% {
    background-position-x: 462%;
  }
  50% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: -462%;
  }
}

@keyframes floatingIconScaleDesktop {
  0% {
    transform: scale(1) translateX(0);
  }
  60% {
    transform: scale(2) translateX(-60%);
  }
  100% {
    transform: scale(1.25) translateX(0);
  }
}

@keyframes floatingIconScaleMobile {
  0% {
    transform: scale(1) translateX(0);
  }
  60% {
    transform: scale(2) translateX(-60%);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

@keyframes bounceHeart {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.5);
  }
  20%, 40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.4);
  }
  80%, 100% {
    transform: scale(1);
  }
}

@keyframes bounceCirclePlus {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loadCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
