.main-header-container {
  position: sticky;
  top: 130px;
  z-index: 3;
  transition: top 0.2s ease-in;

  @media (max-width: 991px) {
    top: 52px;
  }
}

.header--minified ~ main .main-header-container {
  top: 60px;

  @media (max-width: 991px) {
    top: 52px;
  }
}

.main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--vdp { // main-header--vdp
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 767px) {
      .navigatable {
        margin-right: -10px;
        margin-left: -10px;
      }
    }
  }

  @media (max-width: 991px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-header__back {
  flex: 1 0 auto;
  padding: 4px 0 8px;

  @media (min-width: 992px) {
    padding: 15px 0;
  }
}

.main-header__back a {
  display: inline-flex;
  align-items: center;
  color: var(--apt-grey-3);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;
  text-decoration: none;

  @media (min-width: 768px) {
    font-size: var(--14px);
    line-height: 20px;
    letter-spacing: 0.3px;
  }
}

.main-header__back a i {
  font-size: var(--16px);
  line-height: 20px;

  @media (min-width: 992px) {
    font-size: var(--20px);
    line-height: 21px;
  }
}

.main-header__back a i:first-child {
  color: var(--apt-grey-2);
  padding-right: 0.4em;

  @media (min-width: 992px) {
    padding-right: 1em;
  }
}

.main-header__nav {
  display: none;

  @media (min-width: 992px) {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 13px 0 17px;
    font-size: var(--16px);
    line-height: 24px;
  }
}

.main-header__nav span {
  font-weight: var(--fw-medium);
}

.main-header__nav a {
  text-decoration: none;
  color: inherit;
}

.main-header__nav a:hover {
  color: var(--apt-primary);
}

.main-header__nav-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-header__nav-list li {
  margin-left: 1.3em;
  letter-spacing: 0.3px;
}

.main-header__nav-bar {
  @media (max-width: 767px) {
    width: calc(100% + 20px);
    background-color: var(--apt-grey-8);
    border-top: 1px solid var(--apt-grey-6);
    border-bottom: 1px solid var(--apt-grey-6);
  }
}
.main-header__nav-bar .nav-tablist {
  background: none;
}
