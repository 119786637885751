.line-cta-container {
  display: block;
  text-decoration: none;

  &:hover { // .line-cta-container:hover
    text-decoration: none;

    .inline-cta { // .line-cta-container:hover .inline-cta
      border-color: var(--apt-primary);
    }
  }
}

.inline-cta {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--apt-grey-6);

  @include min-media(768px) {
    align-items: stretch;
  }
}

.inline-cta__figure {
  flex: 0 0 auto;
  width: 135px;
  height: 110px;
  padding: 15px;

  @include min-media(512px) {
    width: 210px;
    height: 160px;
    padding: 30px;
  }

  @include min-media(768px) {
    width: 100%;
    max-width: 250px;
    height: auto;
    padding: 0;
  }
}

.inline-cta__figure img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inline-cta__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;
  padding: 15px 15px 15px 0px;

  @include min-media(512px) {
    padding: 30px 40px 30px 0px;
  }

  @include min-media(768px) {
    padding: 30px 40px ;
    min-height: 170px;
  }

  .tag-list { // .inline-cta__content .tag-list
    margin: 0;

    span { // .inline-cta__content .tag-list span
      font-size: var(--9px);
      line-height: 1.1em;

      @include min-media(512px) {
        font-size: var(--12px);
      }
    }
  }

  .heading { // .inline-cta__content .heading
    margin: 0;
    font-size: var(--12px);
    line-height: 16px;

    @include min-media(512px) {
      font-size: var(--22px);
      line-height: 26px;
    }
  }
}
.inline-photo-gallery {
  @include min-media(992px) {
    padding-bottom: 40px;
  }
}
.inline-photo-gallery .generic-slider-prev-button-container {
  @include min-media(992px) {
    left: -25px;
  }
}
.inline-photo-gallery .generic-slider-next-button-container {
  @include min-media(992px) {
    right: -25px;
  }
}

.inline-photo-gallery .generic-slider-grid {
  width: calc(100% + 18px);
  margin-left: -9px;
}

.inline-photo-gallery .generic-slider__pagination {
  @include min-media(992px) {
    position: absolute;
    top: initial;
    right: 0;
    bottom: -33px !important;
    left: 0;
  }
}

.inline-photo-gallery .generic-slider__pagination .swiper-pagination-bullet {
  position: relative;
  width: 4px;
  height: 4px;
  border-width: 0;
  margin-left: 8px !important;
  margin-right: 8px !important;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    width: 16px;
    height: 15px;
    background: rgba(0,0,0,0);
    z-index: 0;
  }
}

.inline-photo-gallery .generic-slide {
  padding: 9px;
}

.inline-photo-gallery .generic-slide figure {
  position: static;
  width: 100%;
  height: auto;
  max-height: none;
  display: block;
  margin: 0;
  padding: 0;
}

.inline-photo-gallery .generic-slide img {
  position: static;
  width: 100%;
  max-width: 830px;
  height: auto;
  max-height: calc(100vh - 160px);
  object-fit: contain;
  margin: auto;
  display: block;
}

.inline-photo-gallery .gallery-overlay {
  @media (min-width: 1100px) {
    padding: 80px 110px 80px 110px;
  }
}

.inline-photo-gallery .gallery-overlay__content {
  display: flex;
  height: 100%;
  padding: 0;
}

.inline-photo-gallery .generic-slider__pagination {
  @media (min-width: 768px) {
    display: block;
  }
}

.inline-photo-gallery .gallery-overlay .generic-slider-grid {
  width: 100%;
  margin: auto;
}

.inline-photo-gallery .generic-slider-button {
  background-color: transparent;
}