.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  z-index: 20;
  background: none;
  border: 0;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.popup::backdrop {
  background: none;
}

.popup-spacing {
  width: 100%;
  height: 100%;
  display: flex;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.25);
  backdrop-filter: blur(5px);
}

.popup-box-container {
  width: 100%;
  padding: 1rem;
  display: flex;
  overflow: auto;

  @include min-media(768px) {
    padding: 2.5rem;
  }
}

.popup-box {
  position: relative;
  width: 100%;
  padding: 35px 20px 20px 20px;
  margin: auto;
  background-color: var(--apt-white);
  border-radius: 10px;

  @include min-media(768px) {
    padding: 75px 50px 34px 60px;
  }
}

.popup-box .wmpform {
  margin-top: 0;
}

.popup-box__cross {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--22px);

  @include min-media(768px) {
    top: 2rem;
    right: 2rem;
    width: 1.25rem;
    height: 1.25rem;
    font-size: var(--28px);
  }
}

.popup-box .heading strong {
  font-weight: 600;
}
.popup-box .heading-2 {
  line-height: 1.1;
  font-weight: 400;
  margin-bottom: 1.4rem;
}

.popup-box .button.button--primary {
  min-height: 35px;
  padding: 4px 24px 6px;
}

.popup-box .dropdown-toggle {
  margin-top: 12px;
  padding-top: 8px;
  padding-bottom: 2px;
  min-height: 45px;
}

.popup-box .dropdown-toggle .fal {
  font-size: 0.66em;
}

.popup-box .dropdown-toggle .brand-label {
  font-size: var(--14px);
  line-height: 1.55;
}

.popup-box form.wmpform input[type=text],
.popup-box form.wmpform input[type=search],
.popup-box form.wmpform input[type=email],
.popup-box form.wmpform input[type=password],
.popup-box form.wmpform input[type=tel],
.popup-box form.wmpform input[type=number],
.popup-box form.wmpform select,
.popup-box form.wmpform textarea {
  height: 45px !important;
  margin-top: 9px !important;
  font-size: var(--14px);
  line-height: 45px !important;
  padding: 1.15em 0 0.6em !important;

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-size: var(--14px);
    line-height: 45px !important;
  }

  &:autofill::first-line {
    font-size: var(--14px);
    line-height: 45px !important;
  }
  &:-webkit-autofill::first-line {
    font-size: var(--14px);
    line-height: 45px !important;
  }
}

.popup-box form.wmpform input[type=text] ~ label,
.popup-box form.wmpform input[type=search] ~ label,
.popup-box form.wmpform input[type=email] ~ label,
.popup-box form.wmpform input[type=password] ~ label,
.popup-box form.wmpform input[type=tel] ~ label,
.popup-box form.wmpform input[type=number] ~ label,
.popup-box form.wmpform select ~ label,
.popup-box form.wmpform textarea ~ label {
  top: 20px !important;
  height: 24px !important;
  font-size: var(--14px);
  padding: 0 !important;
  line-height: 24px !important;
}

.popup-box .dropdown-content {
  position: absolute;
  top: 100%;
  z-index: 2;
  background-color: var(--apt-white);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 22px 18px 22px 22px;
}
.popup-box .dropdown-content .content-wrapper label {
  font-size: var(--12px);
  line-height: 16px;
}