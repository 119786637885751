.expandable-wrapper {
  position: relative;
  width: 100%;
  margin-top: 36px;
  margin-bottom: 42px;

  @media (min-width: 768px) {
    margin-top: 25px;
    margin-bottom: 70px;
  }
}

.expandable {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.expandable__button {
  display: inline-flex;
  align-items: baseline;
  position: relative;
  user-select: none;
  touch-action: manipulation;
  box-shadow: 0 0 0 0;
  padding: 0 0 0;
  margin: var(--16px) 0;
  font-family: inherit;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-grey-3);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.expandable__visible {
  overflow: hidden;
}

.expandable__visible p:last-of-type {
  margin-bottom: 0.7em;
}

.expandable__button:focus + .expandable__hidden,
.expandable__button:active + .expandable__hidden {
  color: inherit;
}

.expandable__button .fal {
  color: var(--apt-black);
  margin-left: auto;
  padding-left: var(--8px);
  padding-right: var(--8px);
}

@supports (padding: max(0px)) {
  .expandable__hidden {
    transition: max-height 0.2s linear;
  }
}

.expandable:not(.expandable--toggled) .expandable__hidden {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .expandable--toggled .expandable__hidden {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}

.expandable__hidden span {
  display: block;
}

.expandable--toggled {
  position: relative;
  z-index: 2;
}

.expandable .expandable__button:focus {
  border-bottom-color: var(--apt-grey-7);
}

.expandable .expandable__button .fal {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
  font-size: var(--14px);
  line-height: 14px;
  color: var(--apt-grey-3);
}

.expandable--toggled .expandable__button .fal {
  transform: rotate(180deg);
}

.expandable__hidden-content-wrapper {
  padding: 0 0 8px;
}
