.appointment-tool-container {

  .grid-item > article { // .appointment-tool-container .grid-item > article
    padding-top: 20px;

    @include min-media(576px) {
      padding-top: 40px;
    }

    @include min-media(992px) {
      padding-top: 100px;
    }

    @include min-media(1200px) {
      padding-top: 180px;
    }
  }
}

.appointment-step {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
  margin-top: 0;

  @include min-media(992px) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 76px;
    padding-bottom: 130px;
    overflow: hidden;
  }
}

.appointment-step__header-content {
  width: 100%;
  padding-right: 15px;
  max-width: 445px;
}

.appointment-step__header-content .tag-list {
  margin-top:20px;
}

.appointment-step__header-content p {
  margin-top: 30px;
}

.appointment-step__header-content hr {
  margin-top:45px;
  margin-bottom:45px;
}

.appointment-step__header {
  margin-bottom: 20px;

  @include min-media(992px) {
    width: 50%;
    flex: 0 0 auto;
    margin-bottom: 0;
  }
}

.appointment-step__main {

  @include min-media(992px) {
    flex: 1 1 auto;
    padding-top: 1.5em;
  }
}

.appointment-step__main div:first-child,
.appointment-step__main div:first-child input {
  margin-top: 0;
}

.appointment-step__main div:first-child select {
  margin-top: 32px;
}

.appointment-step .button {
  margin-top: 49px;
}

.appointment-step--narrow {

  .appointment-step__header {

    @include min-media(992px) {
      width: 42%;
    }
  }

  .appointment-step__header-content {
    max-width: 400px;
  }
}

.appointment-form-divide {
  width: 100%;

  @include min-media(768px) {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.appointment-form-divide > div {
  @include min-media(768px) {
    width: 50%;
    flex: 0 0 auto;
  }

  &:first-child {
    @include min-media(768px) {
      padding-right: 25px;
    }
  }

  &:last-child {
    @include min-media(768px) {
      padding-left: 25px;
    }
  }
}

/* Some overrides */

.appointment-form-divide .wm-field > div {
  margin-top: 36px;
}

.appointment-form-divide .wm-field:first-child div {
  @include min-media(768px) {
    margin-top: 0;
  }
}

.appointment-step .dropdown-wrapper,
.appointment-step .dropdown-wrapper select {
  margin-top: 38px;
}


.appointment-form-divide .checkbox-wrapper .wm-field-label ul li label {
  font-size: 16px;
  color: var(--apt-grey-2);
}

.calendar {
  width: 100%;
  margin-top: 53px;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid var(--apt-grey-7);
  padding-bottom: 15px;

  span { // .calendar__header span
    font-size: var(--24px);
    line-height: 29px;
    letter-spacing: -0.1px;
    font-weight: var(--fw-medium);
  }

  button { // .calendar__header button
    font-size: var(--20px);
    line-height: 24px;
    margin-left: 15px;

    @include min-media(576px) {
      font-size: var(--24px);
      line-height: 29px;
    }
  }
}

.calendar__header-arrows {
  display: flex;
}

.calendar__transport-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 15px;
  padding-bottom: 14px;
  border-bottom: 1px solid var(--apt-grey-7);

  @media (min-width: 512px) {
    flex-direction: row;
    align-items: center;
  }

  .heading {
    color: var(--apt-grey-4);
    display: block;
    font-size: var(--16px);
    line-height: 20px;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 1em;
    padding-right: 10px;

    @media (min-width: 512px) {
      margin-bottom: 0;
    }

    & > span {
      display: none;

      @media (min-width: 512px) {
        display: inline;
      }
    }
  }
}
.calendar__transport-options .tag-list {
  margin-bottom: 0;

  li {
    margin-bottom: 0;
  }
  .tag {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: var(--15px);
    line-height: 1;

    @media (min-width: 512px) {
      width: 35px;
      height: 35px;
      font-size: var(--17px);
    }
  }

  .tag > svg {
    width: 20px;
    height: 14px;
  }
}

.calendar__thead {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 25px;
  margin-bottom: 9px;
  width: 110%;
  margin-left: -5%;

  & > div {
    width: 14.2857143%;
    text-align: center;
    font-size: var(--11px);
    line-height: 20px;
    letter-spacing: 0.24px;
    color: var(--apt-grey-2);

    &:nth-last-child(2) {
      color: var(--apt-grey-6);
    }
    &:last-child {
      color: var(--apt-grey-6);
    }
  }
}

.calendar__days {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  width: 110%;
  margin-left: -5%;
}

.calendar__day {
  width: 14.2857143%;
  text-align: center;
  margin-top: 26px;

  button {
    position: relative;
    padding: 0;
    width: 38px;
    height: 38px;
    font-size: var(--16px);
    color: var(--apt-grey-2);
    text-align: center;
    border-radius: 100%;

    &:hover:not(:disabled) {
      background-color: var(--apt-grey-8);
    }

    &::after {
      content: "";
      width: 5px;
      height: 5px;
      background-color: var(--apt-success-1);
      border-radius: 100%;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateY(-5px) translateX(-50%);
    }

    &[data-availability="1"] {
      &::after {
        background-color: var(--apt-warning-1);
      }
    }

    &[data-availability="0"] {
        &::after {
          background-color: var(--apt-error-1);
        }
      }

    &:disabled {
      color: var(--apt-grey-6);
      cursor: not-allowed;

      &::after {
        background-color: var(--apt-grey-6);
      }
    }
  }

  &--selected {

    button {
      background: var(--apt-primary);
      color: var(--apt-white);
      font-weight: var(--fw-medium);

      &:hover:not(:disabled) {
        background-color: var(--apt-blue-1);
        border-color: var(--apt-blue-1);
        color: var(--apt-white);
      }

      &::after {
        opacity: 0;
      }
    }
  }
}

.calendar__times {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: 68px;
}

.calendar__time {
  width: calc(50% - 9px);
  margin-bottom: 18px;

  @include max-media(767px) {
    &:nth-child(odd) {
      margin-left: 9px;
    }
    &:nth-child(even) {
      margin-right: 9px;
    }
  }

  @include min-media(768px) {
    width: calc(33% - 10.5px);
    margin-right: 18px;

    &:nth-of-type(3n+3) {
      margin-right: 0;
    }
  }

  button { // .calendar__time button
    width: 100%;
    border-radius: 100px;
    text-align: center;
    font-size: var(--16px);
    line-height: 24px;
    letter-spacing: 0.3px;
    color: var(--apt-grey-2);
    padding: 7px 5px 9px;
    border: 1px solid var(--apt-grey-5);

    &:hover:not(:disabled) {
      color: var(--apt-black);
      border-color: var(--apt-black);
    }

    &:disabled {
      border-color: var(--apt-grey-6);
      color: var(--apt-grey-6);
      cursor: not-allowed;
    }
  }

  &--selected { // .calendar__time--select

    button { // .calendar__time--select button
      border-color: var(--apt-primary);
      background-color: var(--apt-primary);
      color: var(--apt-white);
      font-weight: var(--fw-medium);

      &:hover:not(:disabled) {
        background-color: var(--apt-blue-1);
        border-color: var(--apt-blue-1);
        color: var(--apt-white);
      }
    }
  }
}

.appointment-radio-list input[type="radio"]:disabled ~ label > span,
.appointment-radio-list input[type="radio"]:disabled ~ label > span > span:nth-child(2) {
  color: var(--apt-grey-5);
}

.appointment-step__main .appointment-radio-list {
  margin-top: -1.5em;
}

@media (min-width: 992px) {
  .appointment-radio-list .radio-wrapper ul {
    margin-top: -24px;
  }
}

.appointment-radio-list .radio-wrapper .wm-field-input ul li {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--apt-grey-7);
}

.appointment-radio-list label > span {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  color: var(--apt-grey-2);
  font-weight: var(--fw-medium);

  @include min-media(576px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.appointment-radio-list label > span > span:nth-child(2) {
  color: var(--apt-primary);
  margin-top: 4px;
  font-size: var(--14px);
  line-height: 28px;

  @include min-media(576px) {
    margin-top: 0;
  }
}

.appointment-radio-list input:checked + label address span:first-child {
  color: var(--apt-black);
}

.appointment-step .button--primary {
  width: auto;
  min-width: 160px;

  @include min-media(992px) {
    min-width: 182px;
  }
}

.appointment-step .radio-wrapper .wm-field-label {
  margin-bottom: 39px;
}

.registry-plate-wrapper {
  max-width: 236px;
}

.form-grid-item--compact .wm-field > div {
  margin-top: 25px;
}

.form-grid-item--compact .checkbox-wrapper {
  margin-bottom: 0;
}

.customer-waiting{
    margin-top: 25px !important;

    &-label{
        font-size: 15px;
    }

    &-label-wrapper{
        flex-direction: column;
        display: flex;
    }
}

h2 + .appointment-radio-list .radio-wrapper {
  margin: 36px auto;
}

.appointment-radio-list .radio-wrapper {
  margin-bottom: 11px;
}

.appointment--internal-spacing h2:not(:first-of-type) {
  margin-top: 60px;
}
.appointment-options .checkbox-wrapper,
.appointment-options .radio-wrapper {
  margin: 8px 0;
}

.appointment-option {
  justify-content: stretch;
  padding: 0px 12px;
  margin-bottom: 10px;
  background-color: var(--apt-white);
  border-radius: 5px;
  overflow: hidden;
}

.appointment-accordeon {
  justify-content: stretch;
  padding: 0;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  background-color: var(--apt-white);
  overflow: hidden;
}

.appointment-accordeon .checkbox-wrapper ul li:last-of-type,
.appointment-accordeon .radio-wrapper ul li:last-of-type {
  margin-bottom:0;
}

.appointment-accordeon .checkbox-wrapper ul li:last-of-type,
.appointment-accordeon .radio-wrapper ul li:last-of-type {
  margin-bottom:0;
}

.appointment-options .checkbox-wrapper ul li label > span,
.appointment-options .radio-wrapper ul li label > span {
  position: relative;
  flex: 1 1 auto;
}

.appointment-accordeon--expanded {
  border-color: var(--apt-primary)
}

.appointment-accordeon--expanded .expand-arrow {
  transform-origin: center;
  transform: rotate(180deg);
}

.appointment-accordeon > label {
  position: relative;
}

.appointment-accordeon > div {
  width: 100%;
}

.appointment-accordeon .expand {
  width: 100%;
  background-color: var(--apt-white);
  padding: 8px 12px;
}

.appointment-accordeon .expanded {
  width: 100%;
  background-color: var(--apt-grey-8);
  padding: 8px 12px;
}

.error-box {
  position: relative;
  border: 1px solid var(--apt-error-1);
  border-radius: 5px;
  padding: 15px 50px 21px 30px;
  color: var(--apt-error-1);
  font-size: var(--14px);
  line-height: 1.4;
  margin-top: 50px;

  p {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }

  i.fal {
    position: absolute;
    bottom: 21px;
    right: 18px;
    font-size: var(--18px);
    line-height: 1;
    color: var(--apt-error-1);
  }
}

.expand {
  width: 100%;
  display: flex;
  font-size: var(--16px);
}

.expanded {
  font-size: var(--16px);
}

.expand-arrow {
  position: relative;
  top: 0em;
  left: 1px;
  width: 1em;
  height: 1em;
  min-width: 18px;
  min-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  box-shadow: 0 0 0 1px var(--apt-grey-7);
  border-radius: 0.15em;
  color: var(--apt-grey-2);
  background-color: var(--apt-grey-7);
  text-align: center;
  pointer-events: none;
  margin-top: 1px;
  margin-right: 1.2em;
  flex: 0 0 auto;

  svg {
    display: block;
    width: 10px;
    height: 13px;

    path {
      fill: var(--apt-grey-2);
    }
  }
}

.custom-checkbox-label__text {
    padding-right: 1em;
    flex-grow: 1;
    flex-shrink: 1;
}

.custom-checkbox-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.custom-checkbox-label__price {
    font-size: var(--14px);
    color: var(--apt-primary);
    font-weight: var(--fw-medium);
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;
}

.expand-label {
  position: relative;
  flex: 1 1 auto;
  text-align: left;
}

.expand-label__quantity {
  color: var(--apt-grey-5);
}

.expand-label__price {
  position: absolute;
  right: 0;
  font-size: var(--14px);
  color: var(--apt-primary);
  font-weight: var(--fw-medium);
}
