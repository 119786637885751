.sirv-hint,
.sirv-spin-hint {
  width: 62px !important;
  height: 62px !important;
  background-image: url("/rel/stylesheet/image/svg-360-spin-hint.png") !important;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  box-shadow: none !important;
  border: 0 !important;
  opacity: 0 !important;
  border-radius: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate3d(-50%,-50%,1px) !important;
}

@media (min-width: 512px) {
  .sirv-hint,
  .sirv-spin-hint {
    width: 92px !important;
    height: 92px !important;
  }
}

.sirv-spin-hint > *,
.sirv-hint > * {
  display: none !important;
}

.sirv-hint.show,
.sirv-spin-hint.show {
  opacity: 1 !important;
}

.sirv-spin-button.fullscreen,
.smv-button-fullscreen {
  display: none !important;
}

.sirv-spin-container,
.sirv-spin {
  display: block !important;
}

.gallery-grid__sirv-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;

  @media (min-width: 768px) {
    border-radius: var(--10px);
  }
}

// TODO: DIT KLOPT NIET
//.gallery-grid__sirv-container .spin-container {
//aspect-ratio: 653/348;
//aspect-ratio: 764/368;
//}

.gallery-grid__sirv-container .spin-container .spin-figure {
  max-width: 100% !important;
  max-height: 100% !important;
}
.gallery-grid__sirv-container .spin-container .spin-figure > canvas {
  width: 100% !important;
  height: 100% !important;
}

.gallery-button-list {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 2;
  max-width: calc(100% - 100px);
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--7px);

  li { // .gallery-button-list li
    margin: 0 var(--4px) var(--4px) 0;
    line-height: 0;

    @media (min-width: 512px) {
      margin: 0 var(--6px) var(--6px) 0;
    }
  }
}

.gallery-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px 4px;
  border-radius: 20px;
  background-color: var(--apt-grey-7);
  color: var(--apt-grey-3);
  font-size: var(--11px);
  line-height: 1.145;
  font-weight: var(--fw-medium);
  text-align: center;
  text-decoration: none;

  @media (min-width: 512px) {
    font-size: var(--14px);
    padding: 3px 12px 6px;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border-radius: 20px;
  }

  &--active { // .gallery-button--active
    background-color: var(--apt-primary);
    color: var(--apt-white);
  }
}

.gallery-grid__sirv-container .smv-slides-box .smv-slides .smv-slide .smv-content > * {
  display: block !important;
}

.gallery-grid__sirv-container .Sirv {
  width: 100%;
  height: 100%;
  object-fit: cover;
  appearance: none;
  border: 0;
}

.gallery-grid .pnlm-controls-container,
.gallery-overlay .pnlm-controls-container {
  display: none;
}