.content-cards-list-container {
  position: relative;
}


.content-cards-list-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--10px);

  @media (min-width: 512px) {
    margin-bottom: var(--30px);
  }
}

.content-cards-list-container .card {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.content-cards-list-container .card figure {
  flex: 0 0 auto;
}
.content-cards-list-container .card .card__content {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1 1 auto;
}
.content-cards-list-container .card .card__content .heading {
  flex: 1 1 auto;
}

.content-cards-list-container h1,
.content-cards-list-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.content-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.content-card .card__content .heading {
  margin-bottom: 32px;
}

.content-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}