.generic-slider-grid-container {
  margin-bottom: var(--25px);

  @include min-media(768px) {
    margin-bottom: var(--25px);
  }

  @include min-media(992px) {
    margin-bottom: var(--10px);
  }
}

.generic-slider-grid-container--paginated header {
  @include min-media(768px) {
    padding-right: 120px;
  }
}

.generic-slider-grid {
  width: calc(100% + 30px);
  margin-left: -15px;
  display: flex;

  @media (min-width: 992px) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  @media (min-width: 1200px) {
    width: calc(100% + 50px);
    margin-left: -25px;
  }
}

.generic-slider-grid--static .generic-slider .swiper-wrapper {
    flex-direction: column;
}

@media (min-width: 992px) {
  .generic-slider-grid--static .generic-slider .swiper-wrapper {
    flex-direction: row;
  }
}

.generic-slider-grid--static .generic-slide {
  flex-shrink: 1;
  max-width: 632px;
}

.generic-slider-grid-container header .tag-list {
  margin-top: 15px;
  margin-bottom: -8px;
}

.generic-slider-grid__primary {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;
}

.generic-slider-grid__side {
  flex: 0 0 auto;
  width: 22%;
  max-width: 154px;
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.generic-slider-grid__side-item {
  position: relative;
  width: calc(100% - 20px);
  max-height: 22%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.generic-slider-grid__side-item img {
  cursor: pointer;
}

.generic-slider-grid__side-item button,
.generic-slider-grid__side-item input[type="button"],
.generic-slider-grid__side-item input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 7% 12% 11%;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
  background-position: bottom;
  background-size: 100% 180%;
  background-repeat: no-repeat;
  color: var(--apt-white);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;

  @media (min-width: 1100px) {
    font-size: var(--16px);
    line-height: 18px;
  }
}

.generic-slider-grid__side-item:last-of-type {
  margin-bottom: 0;
}

.generic-slider-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.generic-slider {
  position: relative;
  padding: 0 0 0;
}

.generic-slider__slider-container {
  width: 100%;
}

.generic-slider__pagination {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-x: auto;

  @media (min-width: 768px) {
    display: none;
  }

  &.swiper-pagination-bullets { // .generic-slider__pagination.swiper-pagination-bullets
    bottom: 0 !important;
    width: 100%;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-pagination-bullet { // .generic-slider__pagination .swiper-pagination-bullet
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--apt-grey-5);
    border: 4px solid var(--apt-grey-9);
    margin-left: auto !important;
    margin-right: auto !important;
    opacity: 1;

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: none;
    }

    &-active { // .generic-slider__pagination .swiper-pagination-bullet-active
      background-color: var(--apt-primary);
    }
  }

  &--visible { // .generic-slider__pagination--visible
    @media (min-width: 768px) {
      position: absolute;
      top: -45px;
      right: 25px;
      display: flex;
      justify-content: flex-end;
      max-width: 120px;
    }
  }
}

.grid-item.bg-white .swiper-pagination-bullet {
  border: 4px solid var(--apt-white);
}

.generic-slider .swiper-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.generic-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.generic-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
}

.generic-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.car-types-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 8;
  width: 100%;
  height: 100%;
  color: var(--apt-white);
  padding: 50px 0 0 0;
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;

  @media (min-width: 1100px) {
    padding: 80px 110px 0 0;
  }
}

.car-types-overlay--hidden {
  opacity: 0;
  pointer-events: none;
}

.car-types-overlay__close {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: var(--apt-black);
  text-align: right;
  padding: 0 20px;

  @media (min-width: 1100px) {
    position: fixed;
    top: 50px;
    right: 40px;
    text-align: center;
    width: 45px;
    height: auto;
    padding: 0;
    background: none;
  }
}

.car-types-overlay__header {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.car-types-overlay__header span {
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.5px;
  font-weight: var(--fw-medium);
}

.car-types-overlay__header span input[type="button"],
.car-types-overlay__header span input[type="submit"],
.car-types-overlay__header span button {
  font-size: 0.75em;
  line-height: 1;
  font-weight: var(--fw-light);
  margin-right: 0.51em;
}

.car-types-overlay__header .detail-sidebar__characteristics {
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-top: 1em;
  margin-left: var(--48px);
  margin-bottom: 56px;
}

.car-types-overlay__content {
  width: 100%;
  height: auto;
  padding: 0 0 100px 0;
}
.car-types-overlay__gallery {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.car-types-overlay__gallery figure {
  margin-bottom: 20px;

  @media (min-width: 1100px) {
    margin-bottom: 50px;
  }
}

.car-types-overlay__gallery img {
  width: 100%;
  border-radius: var(--10px);
}

.generic-slider-prev-button-container {
  position: absolute;
  top: 0;
  left: -25px;
  width: 0;
  height: 100%;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1440px) {
    left: 0;
  }
}

.generic-slider-next-button-container {
  position: absolute;
  top: 0;
  right: -25px;
  width: 0;
  height: 100%;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1440px) {
    right: 0;
  }
}

.generic-slider-button {
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  background: none;
  font-size: var(--25px);
  line-height: 22px;
  color: var(--apt-grey-4);
  background: var(--apt-grey-9);
  border-radius: 100%;

  @media (min-width: 768px) {
    width: 45px;
    height: 45px;
    font-size: var(--45px);
    line-height: 28px;
  }

  &:focus, &:active {
    border-radius: 100%;
    background: var(--apt-grey-9);
  }
}

.grid-item.bg-white .generic-slider-button {
  background: var(--apt-white);

  &:focus, &:active {
    background: var(--apt-white);
  }
}

.generic-slider-prev-button-container .generic-slider-button {
  @media (min-width: 1440px) {
    transform: translateX(-100%) translateY(-50%);
  }
}
.generic-slider-next-button-container .generic-slider-button {
  @media (min-width: 1440px) {
    transform: translateX(0%) translateY(-50%);
  }
}

.generic-slider-button[disabled] {
  color: rgba(178,178,176,0.2);
}

.generic-slider-grid--force-reload {
  border-radius: 0 !important;
}

.generic-slider .generic-slide {
  padding: 10px;

  @media (min-width: 992px) {
    padding: 15px;
  }

  @media (min-width: 1200px) {
    padding: 21px;
  }

  @media (min-width: 1440px) {
    padding: 25px;
  }
}

.generic-slide a:hover > .card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 992px) {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1), 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 1200px) {
    box-shadow: var(--shadow-hover);
  }
}

.generic-slider-grid-container header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--10px);

  @media (min-width: 512px) {
    margin-bottom: var(--30px);
  }
}

.generic-slider-grid-container h1,
.generic-slider-grid-container h2 {
  padding-right: 0.5em;
  margin-bottom: 0;
}

.generic-slider-grid-link {
  display: block;
  padding-top: 1.5em;
}