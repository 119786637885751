.home {
  .header:not(.header--minified) .header-sticky-section::before {
    opacity: 0;
  }
}

.home-line {
  width: 100%;

  //width: calc(100% + 30px);
  //margin-left: -15px;
  //
  //@media (min-width: 768px) {
  //  width: calc(100% + 100px);
  //  margin-left: -50px;
  //}
  //
  //@media (min-width: 920px) {
  //  width: 100%;
  //  margin-left: 0;
  //  margin-right: 0;
  //}
}

.home-hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 160px;
  }
}

@media (min-width: 920px) {
  .home-hero {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.home-hero-main {
  width:calc(100% + 15px);
  margin-right: -15px;
  margin-top: 0;
  flex: 1 1 auto;

  @media (min-width: 512px) {
    width: 100%;
    margin-right: 0;
  }

  @media (min-width: 920px) {
    max-width: calc(100% - 245px);
    padding-right: 20px;
  }

  @media (min-width: 1200px) {
    max-width: 69.3%;
  }

  @media (min-width: 1440px) {
    padding-right: 50px;
  }

  header { // .home-hero-main header
    width: 100%;
    padding-left: 15px;
    padding-right: 0;

    @media (min-width: 512px) {
      padding-right: 15px;
    }

    @media (min-width: 920px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: 1060px) {
      width: 67vw;
    }

    @media (min-width: 1200px) {
      width: 100%;
    }
  }

  @media (max-width: 919px) {
    .home-hero-main__cta {
      display: none;
    }
  }
}

.home-hero-main-cta-header {
  width: 100%;
  max-width: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;

  .heading {
    padding-right: 1em;
    color: white;
  }

  .button {
    width: 50px;
    height: 50px;
    min-width: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}

.home-hero-aside {
  width: 100%;
  max-width: 335px;
  flex: 0 0 auto;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: 920px) {
    width: 245px;
    max-width: none;
  }

  @media (min-width: 1200px) {
    margin-top: 0;
    width: 30.7%;
  }

  .card { // .home-hero-aside .card
    padding: 24px;
    margin-top: 40px;

    @media (min-width: 1200px) {
      padding: 38px;
      margin-top: 50px;
    }

    .heading {
      font-size: 16px;
      line-height: 20px;

      @media (min-width: 1200px) {
        font-size: 21px;
        line-height: 30px;
      }
    }
  }

  form, .wmpform {
    margin-top: 0;
  }

  .heading {
    margin-bottom: 0;
  }

  .wm-field:last-of-type {
    margin-bottom: 28px;
  }

  .wmpform .dropdown-wrapper {
    margin-top: 22px;
  }
}

.wmpform input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
    margin-top: 22px;
}

.wmpform select {
    margin-bottom: 22px;
}

.home-hero-slide {

  .heading { // .home-hero-slide .heading
    margin-bottom: var(--25px);

    @media (min-width: 768px) {
      margin-bottom: var(--40px);
    }
  }
}

@media (min-width: 920px) {
  .home-hero-slide {
    width: 100%;
    height: 400px;
  }
}

.home-hero-slide__content {
  position: relative;
}

.home-hero-slide__figure {
  width: 72vw;
  max-width: 640px;
  margin-top: 120px;
  margin-right: -2vw;
  float: right;
  shape-image-threshold: 0.5;
  shape-rendering: crispEdges;
  shape-margin: 1em;
  opacity: 1;
  position:absolute;
  top:-9999px;
  transition: opacity 0.2s ease 0.1s;

  @media (max-width: 511px) {
    margin-top: 65px;
    margin-right: -10vw;
  }

  @media (min-width: 920px) {
    width: 40vw;
    margin-right: 0;
  }

  @media (min-width: 768px) {
    margin-top: 120px;
  }

  @media (min-width: 992px) {
    margin-top: 120px;
    width: 44vw;
  }

  @media (min-width: 1060px) {
    margin-top: 95px;
    width: 50vw;
  }

  @media (min-width: 1200px) {
    width: 44.4vw;
    margin-right: 0;
  }

  @media (min-width: 1440px) {
    width: 645px;
    margin-right: 28px;
  }

  &--loading { // .home-hero-slide__figure--loading
    width: 0;
    opacity: 0;
  }

  &.home-hero-slide__figure--done {
    position:static !important;
    top:0;
  }
}

.home-line {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%201389%201887%22%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22a%22%20x1%3D%2269.9580641%25%22%20x2%3D%2228.6649486%25%22%20y1%3D%2270.0294806%25%22%20y2%3D%2227.4475335%25%22%3E%3Cstop%20offset%3D%220%25%22%20stop-color%3D%22%234157FF%22%2F%3E%3Cstop%20offset%3D%2286.1688863%25%22%20stop-color%3D%22%2306C5F5%22%2F%3E%3Cstop%20offset%3D%22100%25%22%20stop-color%3D%22%23FFF%22%2F%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cpath%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22url(%23a)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%225%22%20d%3D%22M0%20295.399665c56.046875%20123.717333%20159.278646%20159.32%20309.695313%20106.808%20225.625-78.768%20338.774167-216.808%20526.025007-336.8080001%20187.25084-120%20454.98281-95.75%20435.81874%20250.3750001C1252.375%20661.899665%20853.2352%20787.782477%20622.75%20770.282477c-230.4852-17.5-448.856473%20159.734497-471.414062%20417.570313-33.632813%20384.42687%20306.599992%20654.20958%20663.992187%20693.95312%22%20transform%3D%22translate(3.296875%202.600335)%22%2F%3E%3C%2Fsvg%3E');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: calc(4vw - 10px);
  overflow: hidden;
  background-size: 800px;

  @media (min-width: 768px) {
    background-size: 1000px;
  }

  @media (min-width: 920px) {
    background-size: 1200px;
  }

  @media (min-width: 1024px) {
    background-size: 1300px;
  }

  @media (min-width: 1200px) {
    background-size: 1400px;
  }
}

.home-hero-main__cta {
  display: block;
  margin-top: 60px;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: 920px) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: 1060px) {
    margin-top: 40px;
  }

  @include min-media(1200px) {
    margin-top: 100px;
  }
}

.home-hero-main__cta,
.home-hero-main__cta:hover,
.home-hero-main__cta:active,
.home-hero-main__cta:focus {
  text-decoration: none;
}

.home-hero-main__cta--mobile {
  @media (min-width: 920px) {
    display: none;
  }
}

.home-carrousel:not(.swiper-container-initialized)::before,
.home-hero-slide__figure--loading + .home-hero-slide__content::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  background: none;
  transform: translate(-50%,-50%) rotate(0deg);
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 100%;
  animation-name: loadCircle;
  animation-direction: normal;
  animation-play-state: running;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.home-carrousel:not(.swiper-container-initialized)::before {
  left: 50%;
}

.home-hero-slide__figure--loading + .home-hero-slide__content::before {
  left: 75%;
}

.home-carrousel:not(.swiper-container-initialized) .swiper-slide {
  opacity: 0 !important;

  &:not(:first-child) { // .home-carrousel:not(.swiper-container-initialized) .swiper-slide:not(:first-child)
    display: none;
  }
}

.home-carrousel.swiper-container-initialized .swiper-slide {
  opacity: 0 !important;
}

.home-carrousel.swiper-container-initialized .swiper-slide-active {
  opacity: 1 !important;
}

.home-hero-slide__content .heading {
  white-space: nowrap;
  text-shadow:
    -1px -1px 0 #FFF,
    0   -1px 0 #FFF,
    1px -1px 0 #FFF,
    1px  0   0 #FFF,
    1px  1px 0 #FFF,
    0    1px 0 #FFF,
    -1px  1px 0 #FFF,
    -1px  0   0 #FFF;
}

.home-hero-slide__content .button {
  padding: 7px 1.3em 8px 1.3em;
  min-height: 40px;

  @media (max-width: 919px) {
    span {
      display: none !important;
    }
  }

  @include is-not-disabled-hover {
    @include move-special-button-small;
  }

  @include is-not-disabled-active {
    @include move-special-button-small;
  }
}

.home-gradient-bg-container {
  position: relative;
  z-index: -1;
}

.home-gradient-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background-image: linear-gradient(#FFFFFF, #F5F5F2, #FFFFFF);
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-25%);
}

.double-heading-spacing {
  margin-bottom: 44px;

  @include min-media(768px) {
    margin-bottom: 121px;
  }
}

.home-slider-container {
  // Could potentially be used for overflow fixes
  overflow: hidden;
  width: calc(100% + 30px);
  padding-top: 15px;
  margin-top: -15px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;

  .grid-item > * {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--15px);
    padding-right: var(--15px);

    @include min-media(768px) {
      padding-left: var(--50px);
      padding-right: var(--50px);
    }

    @include min-media(1200px) {
      padding-left: var(--85px);
      padding-right: var(--85px);
    }
  }
}

.hr-video {
  width: 100%;
  max-width: 2000px;
  margin: -50px auto -18px;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;

  @include min-media(700px) {
    margin: -60px auto -40px;
  }

  @include min-media(1200px) {
    width: 2000px;
    transform: translateX( calc(50vw - 1000px) );
    margin: -100px auto -70px;
  }

  @include min-media(2000px) {
    transform: translateX(0);
  }

  video { // .hr-video video
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @include min-media(1200px) {
      height: 720px;
    }
  }

  &__wide-gradient { // .hr-video__wide-gradient
    display: none; // Hide by default
    height: 100%;
    width: 140px;
    position: absolute;
    top: 0;
    background-color: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);

    &--left {
        left: 0;
    }
    &--right {
      right: 0;
      transform: rotate(180deg);
    }

    @include min-media(2000px) {
        display: block;
    }
  }
}

// old stuff, pre script
//.hr-video {
//  width: 700px;
//  max-width: 2000px;
//  transform: translateX( calc(50vw - 350px) );
//  margin: -50px auto -18px;
//  padding-right: 0;
//  padding-left: 0;
//  position: relative;
//  z-index: -1;
//  display: flex;
//  justify-content: center;
//
//  @include min-media(512px) {
//    width: 1200px;
//    transform: translateX( calc(50vw - 600px) );
//    margin: -60px auto -40px;
//  }
//
//  @include min-media(768px) {
//    width: 2000px;
//    transform: translateX( calc(50vw - 1000px) );
//    margin: -100px auto -70px;
//  }
//
//  @include min-media(2000px) {
//    transform: translateX(0);
//  }
//}

