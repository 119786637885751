.embed-figure {
  margin-bottom: 78px;

  @include min-media(1024px) {
    margin-bottom: 158px;
  }
}

.embed-lightbox-container {
  width: calc(100% + 0px);
  margin-left: 0px;

  @include min-media(920px) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
}

.embed-lightbox-toggle {
  position: relative;
  display: block;
  width: 100%;
  max-width: 140vh;
  padding-top: 50px;
  margin-top: -50px;
  margin-left: auto;
  margin-bottom: 18px;
  margin-right: auto;
  padding-right: 10px;
  padding-bottom: 50px;
  padding-left: 10px;

  @include min-media(992px) {
    width: 100%;
  }

  @include min-media(1024px) {
    margin-bottom: 108px;
  }

  @supports not (aspect-ratio: 3 / 2) {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc((2 / 3) * 100%); // Damn you CSS
  }

  @media (min-width: 768px) {
    @supports not (aspect-ratio: 3 / 2) {
      padding-bottom: calc((var(--aspect-height, 9) / var(--aspect-width, 16)) * 100%); // Damn you CSS
    }
  }
}

.embed-lightbox-toggle-align {
  position: relative;
}

.embed-lightbox-toggle-align .btn-wrap {
  position: absolute;
  top:50%;
  display: block;
  width: 100%;
  transform: translateY(-50%);
}

.embed-lightbox-toggle-align .btn-wrap .button {
  min-width: 0;
  width:50px;
  padding-right:0;
  padding-left:0;
  text-align: center;
}

.embed-lightbox-toggle-align:focus-visible .btn-wrap .button {
  border-radius: 25px;
  box-shadow: 0 0 0 2px var(--apt-white),0 0 0 4px var(--apt-primary);
  outline: none;
  text-decoration: none;
}

.embed-lightbox-toggle.embed-lightbox-toggle:focus-visible,
.embed-lightbox-toggle-align.embed-lightbox-toggle-align:focus-visible {
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: inherit;
}

.embed-lightbox-toggle .img-wrap {
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  display: block;
  border-radius: 10px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
  object-fit: cover;
  overflow: hidden;

  @include min-media(768px) {
    aspect-ratio: var(--aspect-width, 16) / var(--aspect-height, 9);
  }

  @supports not (aspect-ratio: 3 / 2) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
}

.embed-lightbox-toggle > .btn-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.embed-lightbox-toggle .btn-wrap i {
  display: inline-block;
  padding-right: 20px;
}

.embed-lightbox-toggle:focus-visible .button {
  outline: none;
  box-shadow: 0 0 0 2px var(--apt-white), 0 0 0 4px var(--apt-primary);
  text-decoration: none;
  border-radius: 25px;
}

.embed-lightbox-toggle .btn-wrap .button {
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  width: max-content;
  max-width: 400px;
}

.embed-lightbox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -100vw;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.3s linear, left 0s linear 0.3s;
}

.embed-lightbox--shown {
  left: 0;
  opacity: 1;
  transition: opacity 0.3s linear, left 0s linear 0s;
}

.embed-lightbox .video {
  width: 100%;
  max-width: 1250px;
  z-index: 2;

  @include min-media(1440px) {
    position: relative;
  }
}

.embed-lightbox-align {
  width: 100%;
  max-width: 140vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0px 25px;

  @include min-media(1440px) {
    max-width: 1300px;
  }
}

.embed-lightbox-align button {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--apt-white);
  z-index: 2;
  border-radius: 50px;
  background-color: var(--apt-black);

  @include min-media(1440px) {
    top: -75px;
    right: -75px;
  }
}

.embed-lightbox-align button:focus-visible {
  border-radius: 50px;
}

.embed-lightbox-close-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.embed-figure img {
  width: 100%;
}

.embed-figure figcaption {
  font-size: var(--14px);
  line-height: 20px;
  margin-top: 1.5em;
  margin-bottom: 1em;
  text-align: center;
  color: var(--apt-grey-2);
}

.embed-figure .video,
.embed-lightbox .video {

  @supports not (aspect-ratio: 3 / 2) {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc((var(--aspect-height, 2) / var(--aspect-width, 3)) * 100%); // Damn you CSS
  }
}

.embed-figure .video iframe,
.embed-figure .video video,
.embed-lightbox .video iframe,
.embed-lightbox .video video {
  width: 100%;
  height: auto;
  aspect-ratio: var(--aspect-width, 3) / var(--aspect-height, 2);
  display: block;

  @supports not (aspect-ratio: 3 / 2) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
}