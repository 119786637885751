.action-cards-list-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 34px;
}

.action-cards-list-container > header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: var(--25px);

  @media (min-width: 512px) {
    margin-bottom: var(--31px);
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }
}

.action-cards-list-container .action-card-listing-header h2 {
  padding-right: 1.5em;
  margin-bottom: 0;
}

.action-cards-list-container .action-card-listing-header h1 {
  padding-right: 0.5em;
  margin-bottom: 0;

  @media (max-width: 899px) {
    font-size: var(--17px);
    line-height: 22px;
  }
}

.action-cards-results-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  padding-top: 1em;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--apt-grey-6);

  @media (min-width: 512px) {
    flex-direction: row;
    align-items: baseline;
  }

  .heading {
    padding-right: 0.5em;
  }
}

.action-cards-results-header__quantity {
  flex: 0 0 auto;
  font-size: var(--16px);
  line-height: 1.25;
  color: var(--apt-grey-3);
  font-weight: var(--fw-regular);
  margin-right: 2em;
  margin-bottom: 1em;

  @media (min-width: 512px) {
    margin-bottom: 0;
  }

  & > span:first-child {
    color: var(--apt-grey-2);
    font-weight: var(--fw-medium);
  }
}

.action-cards-results-header .tag-list {
  flex: 1 1 auto;
}

.action-cards-list {
  flex-wrap: wrap;
  width: 100%;

  @include min-media(920px) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

.action-cards-list .button--primary {
  width: 100%;
  min-height: 40px;
  border-radius: 24px;
  padding: 6px 26px 8px;
  font-weight: var(--fw-medium);
  text-decoration: none;
  box-shadow: none;
  transform: scale(1.000001);
}
.action-cards-list .button--secondary {
  width: 100%;
}

.action-cards-list-container .action-cards-list {
  @include max-media(919px) {
    width: calc(100% + 20px);
  }
}

.action-cards-list-item {
  width: 100%;
  padding: 0 15px 30px;
  margin-bottom: 0;

  @include min-media(610px) {
    width: 50%;
  }
  @include min-media(992px) {
    width: 33.333%;
  }
  @include min-media(1440px) {
    width: 25%;
  }
}

.action-cards-list-item .news-card {

  figure {
    width: 100%;
    flex: 1 1 auto;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .card__content {
    flex: 0 0 auto;
  }
}

.action-cards-list-item .action-card-featured {

  @include max-media(659px) {
    max-height: 700px;
  }

  @include min-media(660px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
  }

  figure {
    width: 100%;
    flex: 1 1 auto;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }

  footer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .card__content {
    flex: 0 0 auto;
  }
}

.featured-card-link-wrapper {
  position: relative;
}

.action-cards-list-item.swiper-slide {
  @include min-media(1200px) {
    width: 100%;
  }
}

.action-cards-list-link {
  display: block;
  padding-top: 1.5em;
}

.action-card .card__content > span {
  color: var(--apt-grey-4);
}

.action-card .card__content > ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
}

.action-card .card__content > ul li {
  padding-right: 0.5em;
  margin-right: 0.5em;
  position: relative;
}

.action-card .card__content > ul li:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 100%;
  background-color: var(--apt-grey-2);
  display: block;
}

.action-card .card__content .heading-3 {
  margin-bottom: 15px;
  line-height: 27px;
  margin-left: -6px;

  & > span {
    background-image: linear-gradient(white,white);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 100%;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.action-card footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-size: var(--16px);
  line-height: 24px;
}

.action-card {
  position: relative;
  display: flex;
  flex-direction: column;
}

.action-cards-list-item--featured {
  @media (max-width: 659px) {
    position: relative;
    height: 0;
    padding-top: calc(135% + 30px);
  }
}

.action-cards-list-item--featured .action-card {
  @media (max-width: 659px) {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 30px;
    left: 15px;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
}

.action-cards-list-item--featured .action-card figure {
  height: 100%;
  border-bottom-right-radius: var(--10px);
  border-bottom-left-radius: var(--10px);

  img {
    height: 100%;
    aspect-ratio: none;
  }
}

.action-cards-list-item--featured .action-card .card__content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.action-card-listing-wrapper {
  width: 100%;
  margin-top: 25px;
  margin-bottom: -18px;
}

.action-card-listing {
  position: relative;
  width: 100%;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--apt-grey-7);
}

.action-card-listing > span:first-child {
  color: var(--apt-black);
  flex: 1 0 auto;
  padding-right: 0.4em;

  @media (max-width: 899px) {
    font-size: var(--17px);
  }
}

.action-card-listing > span:last-child {
  color: var(--apt-grey-4);
  flex: 0 1 auto;
  text-align: right;
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

.action-card-listing > span:last-child s {
  font-size: var(--16px);
  line-height: 28px;
  letter-spacing: 0.89px;
  display: inline-block;

  @include min-media(992px) {
    padding-right: 5px;
  }
}

.action-card-listing > span:last-child strong {
  color: var(--apt-black);
  white-space: nowrap;
  font-size: var(--18px);
  letter-spacing: 1px;

  @media (max-width: 899px) {
    font-size: var(--17px);
  }

  @include min-media(992px) {
    padding-left: 2px;
  }
}


.action-cards-list-item .action-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.action-cards-list-item .action-card figure,
.action-cards-list-item .action-card .offer-card-slider-container {
  flex: 0 0 auto;
}

.action-cards-list-item .action-card .offer-card-slider-container {
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.action-cards-list-item .action-card .action-card-content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.action-cards-list-item .action-card .action-card-content-container .tooltip-button {
  position: absolute;
  top: 22px;
  right: 27px;
  z-index: 1;
}

.action-cards-list-item .action-card .action-card-content-container .flex-grow-column {
  z-index: 0;
}

.action-cards-list-item .action-card .card__content {
  flex: 1 1 auto;
  z-index: 1;

  @media (max-width: 511px) {
    padding-top: 22px;
  }
}

.action-cards-list-item .action-card-listing-wrapper {
  flex: 0 0 auto;
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
}


.action-card-listing-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  & > span:first-of-type {
    color: var(--apt-grey-4);
    flex: 1 1 auto;
  }

  .heading {
    font-weight: var(--fw-semibold);
  }

  ul { // .action-card-listing-header ul
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    li {
      white-space: nowrap;
      margin-right: 5px;

      &::after {
        margin-left: 4px;
        content: '|';
      }

      &:last-child {
        &::after {

          content: '';
        }
      }
    }
  }

  .tooltip-button {
    position: absolute;
    top: 0;
    right: 0;

    @media (min-width: 660px) {
      top: 0.5em;
    }
  }
}

.action-card-listing-header .gallery-grid__toggle-container {
  position: static;
  width: 100%;
  height: 20px;
  color: var(--apt-primary);
  text-decoration: underline;
  font-size: var(--12px);
  line-height: 1.667;
  font-weight:var(--fw-medium);
  margin-bottom: var(--13px);
}

.gallery-grid__primary--360 {
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.action-price-listing {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  border-top: 1px solid var(--apt-grey-7);
  padding-top: 18px;
  padding-bottom: 16px;
  margin-top: 13px;

  &__offer { // .action-price-listing__offer
    font-size: var(--15px);
    line-height: 1.15;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-right: 0.5em;

    & > strong {
      display: inline-flex;
      justify-content: flex-start;
      align-items: baseline;
      font-weight: var(--fw-semibold);

      & > span {
        font-size: var(--25px);
        line-height: 1.25;
        margin-left: 0.2em;
      }
    }
  }

  &__profit { // .action-price-listing__profit
    font-size: var(--10px);
    line-height: 1.1;
    flex: 0 0 auto;
    background-color: var(--apt-action);
    border-radius: 4.5px;
    color: var(--apt-white);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 8px;
    text-transform: uppercase;
    margin-bottom: 10px;

    & > span {
      font-size: var(--18px);
      line-height: 1;
      margin-top: 4px;
    }
  }
}

.action-cards-results-filter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 13px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__list { // .action-cards-results-filter__list
    margin-bottom:0.5em;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    span.tag {
      position: relative;
      display: inline-flex;
      align-items: baseline;
      font-size: var(--14px);
      font-weight: var(--fw-medium);
      color: var(--apt-grey-4) !important;
      border-color: var(--apt-grey-4) !important;
    }

    span.tag--active {
      color: var(--apt-grey-2) !important;
      border-color: var(--apt-grey-2) !important;
    }

    .tag-list {
      margin-right: 1em;
    }

    .tag--filter { // ..action-cards-results-filter__list .tag--filter
      font-size: var(--16px);
      font-weight: var(--fw-medium);
    }
  }

  &__sort { // .action-cards-results-filter__sort
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    font-size: var(--14px);
    line-height: 1.6;
    color: var(--apt-grey-3);
    font-weight: var(--fw-medium);

    label {
      position: relative;
      display: inline-flex;
      align-items: baseline;

      & > span {
        color: var(--apt-grey-2);
        text-transform: lowercase;
        font-size: inherit;
        font-weight: inherit;
      }
    }
  }

  select { // .action-cards-results-filter select
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left:0;
    width: 100%;
    height: 100%;
    appearance: auto;
    background: none;
    border: 0;
    box-shadow: none;
    color: transparent;
    font-size: var(--16px); /* 18 */
    line-height: 1.15;
    z-index: 1;
  }

  option { // .action-cards-results-filter option
    color: black;
  }

  & > i {// .action-cards-results-filter > i
    display: inline-flex;
    position: relative;
    top: 0.05em;
    text-align: center;
    pointer-events: none;
  }
}

.action-cta {

  .card__content { // .action-cta .card__content
    padding-top: 0;
    justify-content: stretch;
    align-items: stretch;
  }

  &__content { // .action-cta__content
    width: 100%;
    display: flex;
    flex: 1 1 auto;

    & > div {
      margin-top: auto;
      margin-bottom: auto;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__button { // .action-cta__button
    flex: 0 0 auto;
  }
}

.action-cta .heading-2,
.action-cta h1 { // .action-cta .heading-2
  display: block;
  max-width: 250px;
  font-size: var(--22px);
  line-height: 24px;
  letter-spacing: 0;
  color: var(--apt-black);
  font-weight: normal;
  margin-bottom: 25px;
  padding: 0;

  @include min-media(512px) {
    font-size: var(--28px);
    line-height: 30px;
    letter-spacing: -0.12px;
  }

  @media (min-width: 610px) {
    max-width: none;
    margin-bottom: 50px;
  }

  @include min-media(768px) {
    font-size: var(--40px);
    line-height: 48px;
    letter-spacing: -0.5px;
  }

  strong { // .action-cta .heading-2 strong
    font-weight: var(--fw-semibold);
  }
}

.action-cta .wmpform {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.action-cards-list-item .action-card-wrapper,
.action-cards-list-item .action-card-wrapper > .block-default,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default > .wrapper,
.action-cards-list-item .action-card-wrapper > .block-default > .pagesection-default > .wrapper > .editableContainer {
  height: 100%;
}