.page-wide-cta {
    background-color: var(--apt-grey-9);
    margin: 3em 0;
    padding: 2.5em 1em 2.85em;
    align-items: center;
    justify-content: center;
    display: flex;

    &__container { // .page-wide-cta__container
        width: 100%;
        max-width: 1040px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    a {
        margin-top: 1.1ch;
        margin-left: 2em;
        flex-shrink: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
        font-weight: var(--fw-regular);
        //letter-spacing: 0.8px;
    }

    strong {
        display: inline-block;
        font-weight: var(--fw-medium);
        margin-right: 0.42em;
    }
}
