.opening-hours-card {
  width: 100%;
  background-color: var(--apt-white);
  border-top-left-radius: var(--10px);
  border-top-right-radius: var(--10px);
  overflow: hidden;

  @include min-media(768px) {
    display: flex;
  }
}

.opening-hours-card--bottom-radius {
  border-bottom-left-radius: var(--10px);
  border-bottom-right-radius: var(--10px);
}

.opening-hours-card__address {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  flex: 0 0 auto;

  @include min-media(768px) {
    width: 331px;
  }
}

.opening-hours-card__address figure {
  width: 100%;
  max-height: 335px;
  flex: 0 0 auto;

  @include min-media(768px) {
    height: 174px;
  }
}

.opening-hours-card__address figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 335px;
}

.opening-hours-card__address address {
  padding: 20px;
  flex: 1 0 auto;

  @include min-media(768px) {
    padding: 30px 30px 20px;
  }
}

.opening-hours-card__address address span:last-of-type {
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);

  br {
    display: none;
  }
}

.opening-hours-card__address address span:last-of-type:not(:only-of-type) {
  margin-top:var(--7px);
}

.opening-hours-card__address address > span:last-of-type:not(:only-of-type) > span:first-of-type:not(:only-of-type),
.opening-hours-card__address address > span:last-of-type:not(:only-of-type) > a {
  display: block;
}

.opening-hours-card__address address a {
  text-decoration: none;
}

.opening-hours-card__listing {
  width: auto;
  flex: 1 1 auto;
  padding: 20px;

  @include min-media(768px) {
    padding: 45px 50px 55px;
    border-left: 1px solid var(--apt-grey-7);
  }
}

.opening-hours-card__listing .heading-4 {
  margin-bottom: var(--10px);
}

.opening-hours-card__listing .dropdown__toggle {
  font-weight: var(--fw-medium);
  font-size: var(--16px);
  letter-spacing: 0.1px;
}
.opening-hours-card__listing .dropdown-wrapper::after {
  right: 0;
}

.opening-hours-card__single-service {
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 var(--10px);
  box-shadow: 0 0 0 0;
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  height: var(--40px);
  letter-spacing: normal;
  line-height: 28px;
  font-weight: normal;
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
}
// weird scroll issue
.opening-hours-card__listing table.visually-hidden {
  position: absolute;
  top: 0;
}
.opening-hours-card__listing table {
  width: 100%;
  table-layout: fixed;
  font-size: var(--14px);
  line-height: 22px;
  letter-spacing: 0.26px;
  color: var(--apt-grey-2);
  margin: 26px 0 0;
  text-align: left;
  border: 0;
  border-collapse: collapse;
}

.opening-hours-card__listing th {
  font-weight: var(--fw-regular);
  padding: 0;
  margin: 0;
}

.opening-hours-card__listing td {
  width: 46%;
  min-width: 110px;
  max-width: 138px;
}

.opening-hours-ctas {
  width: 100%;
  margin-top: 43px;

  @include min-media(768px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.opening-hours-ctas__blue-btns {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding-left: var(--15px);
  padding-right: var(--15px);
  flex: 0 0 auto;

  @include min-media(768px) {
    width: 331px;
    padding-left: 0;
    padding-right: 50px;
  }
}

.opening-hours-ctas__blue-btns a,
.opening-hours-ctas__blue-btns input[type="button"],
.opening-hours-ctas__blue-btns input[type="submit"],
.opening-hours-ctas__blue-btns button {
  margin-bottom: var(--11px);
}

.opening-hours__footer {
  width: 100%;
  flex: 1 0 auto;
  padding: 0px 15px 2px;
  font-size: var(--13px);
  line-height: 14px;
  letter-spacing: 0.28px;
  font-weight: var(--fw-medium);
  background-color: var(--apt-grey-6);
  color: var(--apt-grey-2);
  text-align: center;
  margin-top: 44px;

  @include min-media(768px) {
    font-size: var(--14px);
    line-height: 20px;
  }
}

.opening-hours-card--compact .opening-hours-card__listing {
  padding: 20px;
  background-color: var(--apt-white);

  @include min-media(768px) {
    padding: 23px 29px 37px;
    background-color: var(--apt-grey-9);
  }
}

.opening-hours-card--compact .opening-hours-card__address address {
  padding: 20px;
  line-height: 22px;

  @include min-media(768px) {
    padding: 20px 30px 20px;
  }
}