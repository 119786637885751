.big-steps {
  counter-reset: steps;
}

.big-steps li:first-child h3::before,
.big-steps li:first-child .heading-3::before {
  padding-right: 0.075em;
}

.big-steps li h3::before,
.big-steps li .heading-3::before {
  content: counter(steps);
  position: relative;
  top: -0.1em;
  counter-increment: steps;
  background-color: var(--apt-primary);
  color: var(--apt-white);
  border-radius: 100%;
  width: var(--25px);
  height: var(--25px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: var(--16px);
  font-weight: var(--fw-medium);
  margin-right: var(--14px);
}

.big-steps h3,
.big-steps .heading-3 {
  display: inline-flex;
  align-items: baseline;
}