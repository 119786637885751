.wmpform {
  width: 100%;
  margin-top: 52px;

  &:only-child {
    margin-top: 5px;
  }

  &::before { // .wmpform ::before
    content: "";
    clear: both;
    display: table;
  }
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number],
.wmpform select,
.wmpform textarea {
  appearance: none;
  display: block;
  padding: 10px 0 12px 0;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-radius: 0;
  box-shadow: 0 0 0 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: var(--18px);
  letter-spacing: normal;
  color: var(--apt-black);
  background:none;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number] {
  word-break: normal;
}

.wmpform textarea {
  line-height: 30px;
  margin: 39px 0 0;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number],
.wmpform select {
  line-height: 50px;
  margin: 29px 0 0;
}

.wmpform input[type=date],
.wmpform input[type=time]{
  display: block;
}

.wmpform input::-webkit-date-and-time-value {
  text-align: left !important;
}

.wmpform input[type=text],
.wmpform input[type=search],
.wmpform input[type=email],
.wmpform input[type=password],
.wmpform input[type=tel],
.wmpform input[type=date],
.wmpform input[type=time],
.wmpform input[type=number],
.wmpform select,
.wmpform textarea {

  &:focus-visible {
    outline: none;
  }

  &:focus {
    border-color: var(--apt-primary);
  }

  &:disabled {
    border-color: var(--apt-grey-6);
    cursor: not-allowed;
  }

  &:focus ~ label {
    transform: translateY(-1.44em) scale(0.78);
    overflow: visible;
    text-overflow: initial;
    padding: 8px 12px 0 0;
  }

  &:required ~ label::after {
    content: "*";
  }

  &::placeholder {
    opacity: 0;
  }

  &:not(:placeholder-shown) ~ label {
    transform: translateY(-1.44em) scale(0.78);
  }

  & ~ label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    line-height: 28px;
    height: 50px;
    color: var(--apt-grey-3);
    font-size: var(--18px);
    display: block;
    padding: 8px 30px 0 0;
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    transform-origin: left top;
    transform: translateY(0) scale(1);
    transition: transform 0.2s linear;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--apt-grey-9) inset !important;
  }

  &:autofill::first-line {
    font-family: var(--fellix);
    font-size: var(--18px);
    line-height: 50px;
    font-weight: normal;
    color: inherit;
  }
  &:-webkit-autofill::first-line {
    font-family: var(--fellix);
    font-size: var(--18px);
    line-height: 50px;
    font-weight: normal;
    color: inherit;
  }
}

.wmpform textarea {
  border-width: 1px;
  padding: 15px 30px;

  & ~ label {
    top: 15px;
    right: 30px;
    left: 30px;
    bottom: auto;
  }

  &:focus ~ label {
    transform: translateY(-50px) translateX(-30px) scale(0.78);
  }

  &:not(:placeholder-shown) ~ label {
    transform: translateY(-50px) translateX(-30px) scale(0.78);
  }
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
.hs-fieldtype-date .input .hs-dateinput:before {
  color: var(--apt-grey-4);
  box-shadow: 0;
}

.wmpform input[type=file] {
  background-color: transparent;
  border: initial;
  padding: initial;
}

.wmpform textarea {
  display: block;
  resize: both;
  height: 120px;
}

.wmpform fieldset {
  margin-top: 12px;
  width: 100%;
  padding: 0;
}

.wmpform fieldset > section {
  margin-bottom: 16px;
}

@media screen and (min-width: 1140px) {
  .wmpform fieldset > section {
    margin-bottom: 20px;
  }
}

.wmpform .text-input-wrapper--error p,
.wmpform .text-input-wrapper--error input,
.wmpform .dropdown-wrapper--error select {
  border-color: var(--apt-error-1);
  color: var(--apt-error-1);
}

.wmpform .text-input-wrapper--error input:focus,
.wmpform .dropdown-wrapper--error select:focus {
  color: var(--apt-black);
}

.wmpform .dropdown-wrapper--error .form__error-message {
  display: block;
}

.wmpform .text-input-wrapper--error::after {
  content: "\f06a";
  visibility: visible;
}

.wmpform .form__error-message,
.wmpform .text-input-wrapper--error p,
.wmpform .dropdown-wrapper--error p{
  padding-top: 0.5em;
  pointer-events: none;
  color: var(--apt-error-1);
  font-size: var(--18px);
  line-height: 1.15;
  display: none;
}

.wmpform .form__notification-icon,
.wmpform .text-input-wrapper--error::after {
  position: absolute;
  top: 10px;
  right: 8px;
  font-family: var(--fa);
  font-size: var(--18px);
  line-height: 28px;
  color: var(--apt-error-1);
  font-weight: var(--fw-light);
}

.wmpform .form__notification-icon {
  display: none;
}

.wmpform label {
  display: block;
  font-weight: 400;
  clear: both;
}

.wmpform .form__alert {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  margin-top: var(--12px);
}

.wmpform .form__alert > div:first-child:not(:only-child) {
  width: var(--24px);
  text-align: center;
}

//.wmpform .form__alert--success > div:first-child:not(:only-child) {
//  color: var(--green);
//}

.wmpform .form__alert--error > div:first-child:not(:only-child) {
  color: var(--apt-error-1);
}

.wmpform .dropdown-wrapper .wm-field-label {
  padding-bottom: 5px;
}

.wmpform .dropdown-wrapper .wm-field-label label {
  display: block;
  transform: scale(0.78);
  transform-origin: bottom left;
  overflow: visible;
  text-overflow: initial;
  color: var(--apt-grey-3);
  font-size: var(--18px);
  padding: 8px 12px 0 0;
}

.checkbox-wrapper .wm-field-label {
  margin-bottom: 39px;
}

.checkbox-wrapper .wm-field-label label,
.radio-wrapper .wm-field-label label,
.wm-static-label label {
  font-size: var(--24px);
  line-height: var(--29px);
  font-weight: var(--fw-medium);
  letter-spacing: -0.1px;
}

.wm-static-label + .wm-field-input textarea::placeholder {
  opacity: 1;
  font-size: var(--18px);
  line-height: var(--30px);
}

.checkbox-wrapper {
  margin: 36px auto;

  .custom-checkbox-margin{
    margin-top: 0 !important;
    margin-bottom: 22px;
  }
}

.checkbox-wrapper--tiny-text label span {
  font-size: var(--14px);
  letter-spacing: 0.3px;
  line-height: 20px;
  color: var(--apt-grey-2);
}

.checkbox-wrapper ul li {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
}

.checkbox-wrapper ul li input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(1em + 2px);
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.checkbox-wrapper ul li label > span {
  padding-top: 2px;
}

.checkbox-wrapper ul li label::before {
  content: '';
  position: relative;
  top: 0em;
  left: 1px;
  width: 1em;
  height: 1em;
  min-width: 18px;
  min-height: 18px;
  display: block;
  line-height: 1em;
  box-shadow: 0 0 0 1px var(--apt-grey-4);
  border-radius: 0.15em;
  color: var(--apt-black);
  background-color: var(--apt-white);
  text-align: center;
  pointer-events: none;
  margin-top: 1px;
  margin-right: 1.2em;
  flex: 0 0 auto;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0,0,1,500);

  @include reduced-motion {
    transition: none;
    transform: none;
  }
}

.checkbox-wrapper--tiny-text ul li label::before {
  margin-right: 0.8em;
}

.checkbox-wrapper ul li label::after {
  content: "";
  position: absolute;
  top: 0em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  min-width: 20px;
  min-height: 20px;
  display: block;
  line-height: calc(1em + 2px);
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011%209%22%3E%3Cpath%20d%3D%22M9.84932246%201.87587177L6.60171214%205.53819383%204.57195102%207.82715036C4.49822304%207.94967669%204.37987023%208%204.2595772%208c-.12029302%200-.2382578-.05128602-.32925365-.15383618l-2.79390253-3.1506769c-.1818947-.20512219-.1818947-.53736545%200-.74259704.1818947-.20523159.4765156-.2051222.6585073%200L4.2595772%206.73316533l4.94753573-5.57932368c.18189469-.2051222.47651559-.2051222.6585073%200%20.18199171.20512219.18160366.53605266-.01629777.72203012z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%22.64%22%2F%3E%3C%2Fsvg%3E');
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}

.checkbox-wrapper ul li label {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0;
  letter-spacing: 0;
  font-size: var(--16px);
  line-height: 1;
}

.checkbox-wrapper ul li input:disabled,
.checkbox-wrapper ul li input:disabled ~ label {
  cursor: not-allowed;
}

.checkbox-wrapper ul li input:disabled ~ label {
  color: var(--apt-grey-6);
}

.checkbox-wrapper ul li label a {
  font-weight: 400;
  text-decoration: underline;
}

.checkbox-wrapper ul li input:checked ~ label::before {
  background-color: var(--apt-primary);
  box-shadow: 0 0 0 1px var(--apt-primary);
  color: var(--apt-white);
  transform: scale(1.001);
  transition: transform 0.1s cubic-bezier(0,0,1,-500);
}

.checkbox-wrapper ul li input:checked ~ label::after {
  opacity: 1;
}

.checkbox-wrapper ul li input:disabled ~ label::before {
  box-shadow: 0 0 0 1px var(--apt-grey-6);
}

.checkbox-wrapper ul li input:focus-visible ~ label::before {
  box-shadow: 0 0 0 2px var(--apt-primary);
}

.radio-wrapper .wm-field-label {
  margin-bottom: 16px;

  label {
    font-weight: var(--fw-medium);
  }
}

.radio-wrapper ul li {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.radio-wrapper ul li:first-of-type {
  margin-top: 0;
}

.radio-wrapper ul li:last-of-type {
  margin-bottom: 20px;
}

.radio-wrapper ul li input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1em;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.radio-wrapper ul li label::before {
  content: '';
  position: relative;
  top: 0.1em;
  left: 1px;
  width: 1em;
  height: 1em;
  display: block;
  line-height: 1em;
  margin-right: 0.7em;
  border: 0.3em solid var(--apt-white);
  border-radius: 100%;
  box-shadow: 0 0 0 1px var(--apt-grey-4);
  color: var(--apt-black);
  background-color: var(--apt-white);
  text-align: center;
  pointer-events: none;
  flex: 0 0 auto;
  transform-origin: center;

  @include reduced-motion {
    animation: none;
  }
}

.radio-wrapper ul li label {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  cursor: pointer;
  padding-left: 0;
  padding-top: 0;
  letter-spacing: 0;
  line-height: 1;
}

.radio-wrapper ul li input:checked ~ label::before {
  background-color: var(--apt-primary);
  color: var(--apt-white);
  animation: bounce 0.2s;

  @include reduced-motion {
    animation: none;
  }
}

.radio-wrapper ul li input:disabled {
  cursor: not-allowed;
}

.radio-wrapper ul li input:disabled ~ label::before {
  box-shadow: 0 0 0 1px var(--apt-grey-6);
}

.radio-wrapper ul li input:disabled ~ label {
  color: var(--apt-grey-6);
  cursor: not-allowed;
}

.radio-wrapper ul li input:focus-visible ~ label::before {
  box-shadow: 0 0 0 2px var(--apt-primary);
}

.radio-wrapper--inline {
  margin-top: 28px;
}

.radio-wrapper--inline ul {
  @media (min-width: 992px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

    li {
      flex: 1 0 auto;
      margin-bottom: 0;
    }
    li label {
      padding-right: 0.4em;
    }
  }

  li:last-of-type {
    margin-bottom: 0;
  }
}

/* TODO: check elsewhere */
.radio-wrapper--inline .wm-field-label {
  @media (min-width: 992px) {
    margin-bottom: 12px;
    //margin-bottom: -6px;
  }
}

.radio-wrapper ul li address {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.radio-wrapper ul li address span:first-child{
  color: var(--apt-grey-2);
  font-size: var(--18px);
  line-height: 28px;
  font-weight: var(--fw-medium);
}

.radio-wrapper ul li address span:nth-child(2) {
  color: var(--apt-grey-2);
  font-size: var(--14px);
  line-height: 22px;
  font-weight: var(--fw-regular);
}

.toggle-wrapper ul li,
.toggle-dialog-wrapper > div {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: fit-content;

  input { // .form__toggle__input, .toggle-wrapper ul li input
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    width: 2em;
    height: 2em;

    &:checked ~ label { // .form__toggle__input:checked + .form__toggle__label
      color: var(--apt-black);

      &::before {  // .form__toggle__input:checked + .form__toggle__label:before
        content: "";
        border: 1px solid var(--apt-primary);
        background-color: rgba(255,255,255,1);
      }
      &::after { // .form__toggle__input:checked + .form__toggle__label:after
        content: "";
        background-color: var(--apt-primary);
        transform: translateX(0);
      }
    }

    &:disabled ~ label {
      color: var(--apt-grey-6);
    }

    &:disabled ~ label::before {
      border-color: var(--apt-grey-6);
    }

    &:disabled ~ label::after {
      background-color: var(--apt-grey-6);
    }

    &:focus-visible ~ label::before {
      border-color: var(--apt-primary);
    }
    &:focus-visible ~ label::after {
      background-color: var(--apt-primary);
    }
  }

  label { // .form__toggle__label
    position: relative;
    display: block;
    padding-right: 3.35em;
    margin-bottom: 21px;
    transition: color 0.2s linear;
    cursor: pointer;
    line-height: 1;

    &::before, &::after { // .form__toggle__label::before, .form__toggle__label::after
      position: absolute;
      right: 0;
      border-radius: 1em;
      transition: background-color 0.3s, right 0.3s, border-color 0.3s;
    }

    &::before { // .form__toggle__label::before
      content: "";
      top: 0.3em;
      padding-left: 0;
      width: 1.8em;
      height: 0.7em;
      border-radius: 1em;
      border: 1px solid var(--apt-grey-4);
      background-color: rgba(0,0,0,0);

      @include high-contrast {
        border: 1px solid var(--apt-grey-2);
        background-color: var(--apt-white);
        box-shadow: inset 0 0 0 2px var(--apt-white);
      }
    }

    &::after { // .form__toggle__label:after
      content: "";
      top: 0.17em;
      background-color: var(--apt-grey-4);
      height: 0.95em;
      width: 0.95em;
      transform: translateX(-0.95em);
      transition: transform 0.2s ease, background-color 0.2s linear;

      @include high-contrast {
        background-color: var(--apt-black);
      }
    }
  }
}

.form-toggle-dialog {
  margin-top: 48px;

  .toggle-dialog-wrapper > div label::before {
    top: 0.65em;
  }
  .toggle-dialog-wrapper > div label::after {
    top: 0.53em;
  }
}

.text-input-wrapper,
.checkbox-wrapper,
.radio-wrapper,
.toggle-dialog-wrapper,
.dropdown-wrapper,
.toggle-wrapper,
.registry-plate-wrapper {
  position: relative;

  .heading {
    margin: 0;
  }
}

.registry-plate-wrapper {

  .wm-field-input { // .registry-plate-wrapper .wm-field-input
    background-color: var(--registry-plate-yellow);
    border: 1px solid var(--apt-grey-2);
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    margin-left: 28px;
  }

  input[type="text"] { // .registry-plate-wrapper input[type=text]
    appearance: none;
    display: block;
    padding: 11px 9px 14px 13px;
    margin: 0 !important;
    border: 0;
    border-radius: 0;
    background: none;
    box-shadow: 0 0 0 0;
    width: 100%;
    height: 48px;
    line-height: 25px;
    font-size: var(--18px);
    letter-spacing: normal;
    color: var(--apt-black);
    text-align: center;
    text-transform: uppercase;

    &::placeholder {
      color: var(--apt-black);
      opacity: 0.5;
    }

    &::selection {
      background-color: #48E2FF;
    }

    &:autofill,
    &:autofill:hover,
    &:autofill:focus,
    &:autofill:active {
      box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
      -webkit-box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 48px var(--registry-plate-yellow) inset !important;
    }

    &:autofill::first-line {
      font-family: var(--fellix);
      font-size: var(--18px);
      line-height: 25px;
      font-weight: normal;
      color: inherit;
    }

    &:-webkit-autofill::first-line {
      font-family: var(--fellix);
      font-size: var(--18px);
      line-height: 25px;
      font-weight: normal;
      color: inherit;
    }
  }

  label {
    position: absolute;
    top:0;
    left: 0;
    width: 30px;
    height: 50px;
    background-color: var(--apt-blue-1);
    color: var(--apt-white);
    z-index: 1;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid var(--apt-grey-2);
    border-right: 0;

    &::before {
      content: attr(data-country);
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: var(--12px);
      line-height: 25px;
      font-weight: var(--fw-medium);
    }

    &::after {
      content: "";
      position: absolute;
      top: 7px;
      left: 7px;
      width: 16px;
      height: 16px;
      border: 2px dotted var(--registry-plate-yellow);
      border-radius: 50%;
    }
  }
}

.trade-card {

  .wmpform {
    position: relative;
    margin-top: 0;
  }

  h2 {
    display: block;
    font-size: var(--22px);
    line-height: 30px;
    letter-spacing: -0.09px;
    color: var(--apt-black);
    font-weight: var(--fw-medium);
    margin-bottom: var(--13px);
  }
}

.trade-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .wm-field:last-child {
    margin-top: 29px;
  }

  .registry-plate-wrapper { // .registry-form-wrapper .registry-plate-wrapper
    max-width: 251px;

    .wm-field-input {
      background-image: linear-gradient(-45deg, #FFC81F 53%, #ffe185 60%, #FFC81F 67%);
      background-size: 1200% 100%;
      animation: shine 30s infinite;
      animation-delay: 0s;
      animation-timing-function: linear;
    }
  }
}

.trade-card-wrapper + .trade-card-button-wrapper {
  padding-top: 16px;

  @media (min-width: 400px) {
    position: absolute;
    left: 275px;
    bottom: 0;
    padding-top: 0;
  }

  .button-wrapper {
    margin: 0;
  }

  .button.button,
  input[type="submit"] {
    display: block;
    align-items: center;
    margin-top: 0;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;

    i {
      text-align: center;
    }
  }

  .button-wrapper.button-wrapper--primary {
    position: relative;
    width: 50px;
    height: 50px;
  }
  .button-wrapper.button-wrapper--primary::before {
    content:"\f178";
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    font-size: inherit;
    line-height: 1;
    color: var(--apt-white);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    z-index: 2;
    pointer-events: none;
  }
}

$track-w: 100%;
$track-h: .175em;
$thumb-d: 0.5em;
$track-c: var(--apt-grey-7);
$fill-c: linear-gradient(270deg, var(--apt-primary), #0080ff, #00a0ff, #00bbff, #00d4ff, #00e9ff, #79fdfd);

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: $track-h;
  background: $track-c;

  @if $fill == 1 {
    & {
      background: $fill-c 0 / var(--sx) 100% no-repeat $track-c;
    }
  }
}

@mixin fill() {
  height: .25em;
  background: $fill-c;
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: $thumb-d;
  height: $thumb-d;
  border-radius: 50%;
  background: var(--apt-primary);
}

[type='range'] {
  &, &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min)) / var(--range));

  --sx: calc(.5*#{$thumb-d} + var(--ratio)*(100% - #{$thumb-d}));
  margin: 0;
  padding: 0;
  width: $track-w; height: $thumb-d;
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-webkit-slider-runnable-track {
    @include track(1)
  }
  &::-moz-range-track { @include track }
  &::-ms-track { @include track }

  &::-moz-range-progress { @include fill }
  &::-ms-fill-lower { @include fill }

  &::-webkit-slider-thumb {
    margin-top: .5*($track-h - $thumb-d);
    @include thumb
  }
  &::-moz-range-thumb { @include thumb }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb
  }

  &::-ms-tooltip { display: none }
}

.range-wrapper {
  margin-bottom: 33px;
}

.range-wrapper label {
  font-weight: var(--fw-medium);
  margin-bottom: 10px;
}

.range-labels {
  position: relative;
  width: 100%;
  height: 20px;
  font-size: var(--14px);
  line-height: 20px;
  color: var(--apt-grey-4);

  span {
    position: absolute;
  }
  span:first-child {
    left: 0;
  }
  span:last-child {
    right: 0;
  }
  span:not(:first-child):not(:last-child) {
    left: calc(((((var(--i) - var(--min)) * 100) / (var(--max) - var(--min))) * 1%) - 0.25em);
    transform: translateX(-50%);
  }
  span.selected {
    color: var(--apt-grey-2);
  }
}

.range-labels--mobile-outline-correction {

  span:nth-child(even) {
    @media (max-width: 511px) {
      top: 1.5em;

      &:before {
        content: "";
        position: absolute;
        top: -1.5em;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 1.5em;
        background-color: var(--apt-grey-4);
      }
    }
  }
}
.price-indication {
  margin-top: 38px;
}

.form-table-wrapper,
.wmpform > .gridelement.gridelement_style {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -14px;
  width: calc(100% + 28px);

  @media (min-width: 768px) {
    width: calc(100% - 6px);
  }

  td.transparent-column-8px {
    display: none;
  }

  td[width="1"] {
    display: none;
  }

  .gridtable {
    display: flex;
    width: 100%;

    tbody {
      display: flex;
      width: 100%;
    }

    tr:only-child,
    tr:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    tr:not(:only-child):nth-child(1),
    tr:nth-child(3) {
      display: none;
    }

    @media (max-width: 511px) {
      tbody {
        width: 100%;
      }
      tr {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }

    /* Overrides */
    .radio-wrapper--inline {
      margin-top: 0;
    }
    .checkbox-wrapper {
      margin-top: 0;
    }

    .tiny-print {
      padding: 0 14px;
    }
  }

  .gridcontentareastyle {
    width: 50%;

    @media (max-width: 511px) {
      width: 100% !important;
      display: block !important;
    }

    &:only-child {
      width: 100%;
    }

    table {
      width: 100%;
    }

    .contentelement {
      width: 100%;
      padding: 14px;
    }

    .jq_gridcontentarea {
      width: 100%;
    }
  }

  .text-input-wrapper {
    margin-top: -3px;
    display: flex;
    flex-direction: column;

    div[id^="error_"] {
      order: 2;
    }

    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number],
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea] {
      border-color: var(--apt-error-1);
    }

    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=text] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=search] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=email] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=password] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=tel] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=date] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=time] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=number] ~ label,
    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input[type=textarea] ~ label {
      color: var(--apt-error-1);
    }

    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul {
      color: var(--apt-error-1);
    }

    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li {
      padding-left: 0;
    }

    div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li::before {
      display: none;
    }

    div[id^="info_"] {
      display: none;
    }

    .wm-field-input {
      order: 1;
    }

    .wm-field-helptext {
      order: 3;
    }

    input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
      margin-top: 0;
    }

    textarea ~ label {
      top: 15px + 39px;
    }

    input + p {
      display: none;
    }
  }
}

.checkbox-wrapper, .radio-wrapper, .toggle-dialog-wrapper, .dropdown-wrapper, .toggle-wrapper, .registry-plate-wrapper {
  display: flex;
  flex-direction: column;

  div[id^="error_"] {
    order: 2;
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul {
    color: var(--apt-error-1);
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li {
    padding-left: 0;
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li::before {
    display: none;
  }

  div[id^="info_"] {
    display: none;
  }

  .wm-field-input {
    order: 1;
  }

  .wm-field-helptext {
    order: 3;
  }
}

.gridelement.gridelement_style .gridelement.gridelement_style,
.gridelement.formsectioncompound.gridelement_style {
  margin-left: 0;
  width: 100%;
}

.upload-section {

  div[id^="error_"] ul {
    padding-left: 0;
  }

  .wm-field > div {
    display: flex;
    flex-direction: column;

    .heading {
      order: 1;
    }
    div[id^="error_"] {
      order: 4;
      color: var(--apt-error-1);
    }
    .wm-field-input ~ ul {
      order: 3;
      padding-left: 0;
    }
    .wm-field-label {
      order: 2;
    }
  }
}

.wm-field-input {
  position: relative;
}

.file-upload {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 auto;
}

.file-upload input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  margin-top: 0 !important; /* no */
}

.file-upload label {
  position: static;
  transform: none;
  cursor: pointer;
  display: inline-flex;
  align-items: baseline;
  font-weight: var(--fw-medium);
  padding: 7px 26px 8px;

  svg { // .file-upload label svg
    fill: currentColor;
    padding-right: 0.5em;

    &.upload-svg {
      height: 1em;
      position: relative;
      top: 0.4ex;
    }

    &.check-svg {
      height: 0.75em;
    }
  }
}

/* Attempt to style the upload button */
.upload-section .wm-field[id]  {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 54px;

  @include min-media(768px) {
    flex-direction: row;
  }
}
.upload-section .wm-field[id]  label,
.upload-section .wm-field[id]  span {
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  z-index: 1;

  svg { // .file-upload label svg
    fill: currentColor;
    padding-right: 0.5em;

    &.upload-svg {
      height: 1em;
      position: relative;
      top: 0.4ex;
    }

    &.check-svg {
      height: 0.75em;
    }
  }
}
.upload-section .wm-field[id] :hover label::before {
  transition: background-position 0.4s ease;
  background-position: 0 0;
}
.upload-section .wm-field[id]  .wm-field-input {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
  }
}
.upload-section .wm-field[id]  input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  background: none;
  opacity: 0;
  padding: 0;
  margin: 0 !important;
  border: 0;
  line-height: 1;
  cursor: pointer;
}

.upload-section {
  @media (min-width: 768px) {
    width: calc(100% + 50px);
  }
}

.upload-section .heading {
  position: absolute;
  top: -36px;
}

.upload-section .wm-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;

  @include min-media(768px) {
    flex-direction: row;
  }
}

.upload-section ul {
  margin-top: 10px !important;
  margin-bottom: 0 !important;

  @include min-media(768px) {
    margin-top: 0 !important;
    padding-left: 34px;
  }
}

.upload-section ul li {
  padding-left: 0;
  margin-bottom: 0;
  font-size: var(--18px);
  line-height: 30px;

  &::before { // .upload-section ul li::before
    display: none;
  }
}

.upload-section ul li button {
  padding-left: 6px;
}

.upload-section .wm-field>div {
  align-items: flex-start;
}

.form-grid {
  width: calc(100% + 28px);
  display: flex;
  flex-wrap: wrap;
  margin-left: -14px;
}

.form-grid-item {
  width: 100%;
  padding: 0 14px 14px;
}

.form-grid-item--added-space {
  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.form-grid-item--50 {
  @include min-media(576px) {
    width: 50%;
  }
}

.form-grid-item--25 {
  @include min-media(576px) {
    width: 25%;
  }
}

.wmpform .datepicker input:focus::placeholder {
  opacity: 1;
}

.search-input-section {
  padding-top: 39px;
  padding-bottom: 49px;

  @include min-media(768px) {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .wmpform {
    margin-top: 0;
  }
}

.search-input {
  width: 100%;
  max-width: 609px;

  &::before {
    content: '\f002';
    position: absolute;
    top: 0.32em;
    left: 0.1em;
    font-family: var(--fa);
    font-size: var(--20px);
    line-height: 20px;
    font-weight: var(--fw-light);

    @include min-media(768px) {
      font-size: var(--40px);
      line-height: 40px;
    }
  }

  input[type=text] {
    font-size: var(--14px);
    line-height: 42px;
    font-weight: var(--fw-medium);
    height: 42px;
    padding: 5px 0 14px 2.8em;
    margin-top: 0 !important;

    @include min-media(768px) {
      padding: 10px 0 22px 2em;
      height: 70px;
      line-height: 70px;
      font-size: var(--40px);
    }

    &::placeholder {
      color: var(--apt-grey-3);
      opacity: 1;
      font-weight: var(--fw-light);
    }
  }
}

.toggle-slider-checkbox {
  $parent: &;
  position: relative;
  margin-top: 38px;
  display: flex;

  &__input { // .toggle-slider-checkbox__input
    position: absolute;
    top: 0.15em;
    left: 3.2em;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    width: 2.5em;
    height: 1.5em;

    &:checked + #{$parent}__label #{$parent}__left {
      color: var(--apt-grey-4);
      pointer-events: auto;
    }

    &:checked + #{$parent}__label #{$parent}__right {
      color: var(--apt-primary);
      pointer-events: none;
    }

    &:checked + #{$parent}__label #{$parent}__toggle { // .toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__toggle
      color: var(--apt-primary);

      &:after { // .toggle-slider-checkbox__input:checked + .toggle-slider-checkbox__label .toggle-slider-checkbox__toggle:after
        transform: translateX(1em);
      }
    }

    &:focus-visible + #{$parent}__label {
      box-shadow: 0 0 0 2px var(--apt-grey-9), 0 0 0 4px var(--apt-primary);
    }

    &:focus:not(:focus-visible) + #{$parent}__label {
      outline: 2px solid transparent;
      box-shadow: none;
    }
  }

  &__label { // .toggle-slider-checkbox__label
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    font-weight: var(--fw-regular);
    font-size: var(--20px);
    border-radius: 0.1em;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;
  }

  &__toggle {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding-left: 0;
    background: var(--apt-grey-9);
    width: 1.6em;
    height: 0.6em;
    border-radius: 0.6em;
    margin-left: 1em;
    margin-right: 1em;
    box-shadow: inset 0px 0px 0px 1px var(--apt-primary);
    transition: background-color 0.3s, right 0.3s, border-color 0.3s;

    &:after { // .toggle-slider-checkbox__label:after
      position: absolute;
      content: "";
      background: var(--apt-primary);
      width: 0.8em;
      height: 0.8em;
      top: -0.1em;
      left: -0.1em;
      border-radius: 0.8em;
      transform: translateX(0);
      transition: transform 0.2s ease, background-color 0.2s linear;
    }
  }

  &__left {
    color: var(--apt-primary);
    transition: color 0.2s linear;
    pointer-events: none;
  }

  &__right {
    color: var(--apt-grey-4);
    transition: color 0.2s linear;
    pointer-events: auto;
  }
}

.button-wrapper.processing:not([style^="background"]),
.button-container.processing:not([style^="background"]) {
  display: flex;

  &::after {
    content: "";
    margin: auto 1rem;
    width: 22px;
    height: 22px;
    background: none;
    transform: rotate(0deg);
    border: 2px solid var(--apt-primary);
    border-right-color: transparent;
    border-radius: 100%;
    animation-name: loadCircle;
    animation-direction: normal;
    animation-play-state: running;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
}
.button-wrapper[style^="background"],
.button-container[style^="background"] {
  background-size: 0;
}
.button-wrapper.processing[style^="background"],
.button-container.processing[style^="background"] {
  display: flex;

  &::after {
    content: "";
    margin: auto 1rem;
    width: 22px;
    height: 22px;
    background-image: inherit;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.card .wmpform .wm-field.contentelement.wm_form_element.contentelement_style .text-input-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 29px;

  ul {
    padding-left: 0;
  }

  div[id^="error_"] {
    order: 2;
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul {
    color: var(--apt-error-1);
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li {
    padding-left: 0;
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ul li::before {
    display: none;
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input input {
    border-color: var(--apt-error-1);
  }

  div[id^="error_"]:not(.displayNone):not([style="display: none;"]) ~ .wm-field-input label {
    color: var(--apt-error-1);
  }

  div[id^="info_"] {
    display: none;
  }

  .wm-field-input {
    order: 1;
  }

  input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
    margin-top: 0;
  }

  .wm-field-helptext {
    order: 3;
  }
}

.jq_gridcontentarea {
  width: 100%;
}

.wmpform .dropdown-toggle {
  margin-top: 22px;
  padding: 10px 15px 12px 0;
}

.wmpform .dropdown-toggle .brand-label {
  font-size: var(--18px);
}

@media (max-width: 511px) {
  .lightbox {
    padding: 16px;
  }
}