.footer {
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--apt-white);
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
  z-index: 2;
}

.footer a:not(:hover) {
  text-decoration-color: var(--apt-white);
}

.footer-container {
  width: 100%;
  max-width: 1440px;
  padding: 0 var(--15px);
  margin: 0 auto;

  @include min-media(768px) {
    padding: 0 var(--50px);
  }

  @include min-media(1200px) {
    padding: 0 var(--85px);
  }
}

.footer-primary {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 54px;
  padding-bottom: 21px;

  @include min-media(812px) {
    flex-direction: row;
  }
}

.footer-primary__logo-socials {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: var(--40px);

  @include min-media(812px) {
    width: 221px;
    display: block;
    margin-bottom: 0;
  }
}

.footer-primary__logo-socials-container {
  margin-top: var(--5px);
  margin-left: var(--50px);

  @include min-media(812px) {
    margin-top: 0;
    margin-left: 0;
  }
}

.footer-primary__lists-container {
  flex: 1 1 auto;

  @media (max-width: 811px) {
    width: 100%;
  }
}

.footer-primary__lists-container .editableContainer {
  width: 100%;
  min-height: 195px;

  @include min-media(812px) {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
}

.footer-primary__lists-container > div:first-child {
  flex: 1 1 auto;
}

.footer-primary__lists {
  width: 100%;
  min-height: 195px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

.footer-primary b,
.footer-primary strong {
  display: block;
  font-size: var(--16px);
  line-height: 24px;
  font-weight: var(--fw-medium);
  margin-bottom: var(--9px);

  @include min-media(812px) {
    min-height: var(--31px);
    display: inline-flex;
    align-items: center;
    margin-bottom: var(--25px);
  }

  @include min-media(992px) {
    font-size: var(--18px);
  }
}

.footer-primary__first-list {
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: var(--25px);

  @include min-media(1100px) {
    width: 50%;
    max-width: 330px; // 280 + 50
    margin-bottom: 0;
    padding-right: var(--50px);
  }
}

.footer-primary__second-list {
  width: 100%;
  flex: 0 0 auto;

  @include min-media(812px) {
    padding-right: var(--20px);
  }

  @include min-media(1100px) {
    width: 50%;
    max-width: 330px; // 280 + 50
    padding-right: var(--50px);
  }
}

.footer-primary__newsletter {
  width: 100%;
  flex: 0 0 auto;
  margin-top: var(--25px);

  @include min-media(812px) {
    width: 280px;
    max-width: none;
    margin-top: 0;
  }
}

//.footer-primary__newsletter b {
//  margin-bottom: 0;
//}

.footer-primary__newsletter .wmpform,
.footer-primary__newsletter .wmpform:only-child {
  margin-top: 0;
}

.footer-primary__newsletter .button {
  text-align: left;
  margin-top: 22px;

  i.fal {
    transform: translateY(2px);
  }
}

.footer-primary__newsletter {
  .wmpform input:not([type=button]):not([type=submit]):not([type=checkbox]):not([type=radio]) {
    margin-top: 16px;
  }

  .wmpform input[type=text] ~ label, .wmpform input[type=search] ~ label, .wmpform input[type=email] ~ label, .wmpform input[type=password] ~ label, .wmpform input[type=tel] ~ label, .wmpform input[type=number] ~ label, .wmpform select ~ label, .wmpform textarea ~ label {
    font-size: var(--14px);
  }

  .wmpform input[type=text]:focus ~ label, .wmpform input[type=search]:focus ~ label, .wmpform input[type=email]:focus ~ label, .wmpform input[type=password]:focus ~ label, .wmpform input[type=tel]:focus ~ label, .wmpform input[type=number]:focus ~ label, .wmpform select:focus ~ label, .wmpform textarea:focus ~ label,
  .wmpform input[type=text]:not(:placeholder-shown) ~ label, .wmpform input[type=search]:not(:placeholder-shown) ~ label, .wmpform input[type=email]:not(:placeholder-shown) ~ label, .wmpform input[type=password]:not(:placeholder-shown) ~ label, .wmpform input[type=tel]:not(:placeholder-shown) ~ label, .wmpform input[type=number]:not(:placeholder-shown) ~ label, .wmpform select:not(:placeholder-shown) ~ label, .wmpform textarea:not(:placeholder-shown) ~ label {
    transform: translateY(-22px) scale(0.9);
  }

  .wmpform input[type=text], .wmpform input[type=search], .wmpform input[type=email], .wmpform input[type=password], .wmpform input[type=tel], .wmpform input[type=number], .wmpform select, .wmpform textarea {
    font-size: var(--14px);
    padding: 8px 0px 10px;
    height: 42px;
    line-height: 42px;
  }

  .wmpform .dropdown-toggle {
    margin-top: 13px;
    padding: 8px 15px 0 0;
  }

  .wmpform .dropdown-toggle .brand-label {
    font-size: var(--14px);
  }
}

.footer-primary__newsletter .success-message {
  display: inline-block;
  color: var(--apt-success-1);
  font-weight: var(--fw-medium);
  font-size: var(--16px);
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-top: 22px;
}

.footer-secondary {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 21px 0;

  @include min-media(1280px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer-secondary__legal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include min-media(992px) {
    flex-direction: row;
    align-items: center;
  }
}

.footer-secondary__brands {
  align-self: flex-start;
  margin-top: 18px;
  margin-bottom: 58px;

  @include min-media(992px) {
    margin-top: 0;
    margin-bottom: 18px;
  }

  @include min-media(1280px) {
    margin-bottom: 0;
  }
}

.footer-logo {
  width: calc(100% - 1em);
  display: block;
  margin-bottom: var(--25px);
}

.footer-logo img,
.footer-logo svg {
  display: block;
  width: 29px;

  @media (min-width: 992px) {
    width: 35px;
  }
}

.footer-social-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: var(--24px);
  font-size: var(--16px);
  line-height: 20px;

  @include min-media(812px) {
    margin-top: var(--32px);
    margin-left: 0;
  }
}

.footer-social-list li {
  width: 1em;
  margin-right: var(--25px);
}

.footer-social-list a {
  text-decoration: none;
}

.footer-social-list a i {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.footer-social-list a:hover i {
  opacity: 0.5;
}

.footer-links-list li {
  margin-bottom: var(--10px);
}

.footer-legal-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  color: var(--apt-grey-4);
}

.footer-legal-list li,
.footer-secondary__legal span {
  padding-right: var(--20px);
  padding-bottom: var(--5px);
}

.footer-secondary__legal span {
  padding-right: var(--24px);
}

.footer-legal-list .credit a,
.footer-legal-list .credit a:hover,
.footer-legal-list .credit a:focus,
.footer-legal-list .credit a:active {
  text-decoration: underline !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-offset: 1px;
  text-decoration-skip-ink: auto;
}

.footer-brands-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-brands-list li {
  padding-left: var(--12px);

  &:first-child { // .footer-brands-list li:first-child
    padding-left: 0;

    @include min-media(1280px) {
      padding-left: var(--12px);
    }
  }
}

.footer-brands-list a {
  text-decoration: none;
  display: block;
}

.footer-brands-list a img {
  display: block;
  height: 28px;
  filter: grayscale(1);
  opacity: 0.3;
  transition: filter 0.2s linear, opacity 0.2s linear;
}


.footer-brands-list a:hover img {
  filter: grayscale(0);
  opacity: 1;
}

.footer-brand--audi a {
  margin-top: -0.1em;
  margin-right: -0.2em;
}

.footer-brand--audi a img {
  height: var(--26px);
}

.footer-brand--volkswagen a {
  margin-top: -0.7em;
  margin-right: 0.1em;
}

.footer-brand--volkswagen a img {
  height: var(--25px);
}

.footer-brand--seat a {
  margin-top: -0.1em;
}

.footer-brand--skoda a {
  margin-top: -0.2em;
}

.footer-brand--vw-bedrijfswagens a {
  margin-top: -0.2em;
}

.footer-primary__newsletter #naam #wmformfragment_naam {
  margin-top: 0;
}
.footer-primary__newsletter .wm-field-input p {
  font-size: var(--14px);
  line-height: 20px;
  letter-spacing: 0.3px;
}

// Brand dropdown
.dropdown-toggle {
  position: relative;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 15px 0 0;
  min-height: 45px;
  line-height: 38px;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-radius: 0;
  box-shadow: 0 0 0 0;
  margin-top: 9px;
  color: var(--apt-grey-3);
  cursor: pointer;

  .brand-label {
    font-size: var(--14px);
    line-height: 1.55;
  }

  .fal {
    position: absolute;
    right: 0;
    transition: all 0.2s ease-in-out;
  }

  .rotation {
    transform: rotate(180deg);
  
  }

  &--active {
    border-color: var(--apt-primary);
  }

  .pill {
    background-color: var(--apt-grey-8);
    border-radius: 30px;
    padding: 2px 10px;
    color: var(--apt-grey-2);
    height: 20px;
    display: flex;
    align-items: center;
    margin: 0 2px 4px 2px;
    font-size: var(--12px);
    line-height: 18px;
    font-weight: var(--fw-semibold);
  }

}

.dropdown-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-style: solid;
  border-color: var(--apt-grey-4);
  border-top-width: 0px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 1px;
  padding: 15px;

  .content-wrapper { // .dropdown-content .content-wrapper
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(45% + 10px);
    padding: 10px 0;
    margin-right: -10px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: calc(1em + 2px);
      height: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
    }

    label {
      display: flex;
      position: relative;
      color: var(--apt-grey-3);
      font-size: 13px;
    }

    label:before {
      content: '';
      position: relative;
      top: 0em;
      left: 1px;
      width: 1em;
      height: 1em;
      min-width: 15px;
      min-height: 15px;
      display: block;
      line-height: 1em;
      box-shadow: 0 0 0 1px var(--apt-grey-4);
      border-radius: 0.15em;
      color: var(--apt-black);
      background-color: var(--apt-white);
      text-align: center;
      pointer-events: none;
      margin-top: 1px;
      margin-right: 0.8em;
      flex: 0 0 auto;
      transform: scale(1);
      transform-origin: center;
      transition: transform 0.1s cubic-bezier(0,0,1,500);
    }

    input:checked ~ label::before {
      background-color: var(--apt-primary);
      box-shadow: 0 0 0 1px var(--apt-primary);
      color: var(--apt-white);
      transform: scale(1.001);
      transition: transform 0.1s cubic-bezier(0,0,1,-500);
    }

    label:after {
      content: "";
      position: absolute;
      top: 0em;
      width: calc(1em + 2px);
      height: calc(1em + 2px);
      min-width: 17px;
      min-height: 17px;
      display: block;
      line-height: calc(1em + 2px);
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2011%209%22%3E%3Cpath%20d%3D%22M9.84932246%201.87587177L6.60171214%205.53819383%204.57195102%207.82715036C4.49822304%207.94967669%204.37987023%208%204.2595772%208c-.12029302%200-.2382578-.05128602-.32925365-.15383618l-2.79390253-3.1506769c-.1818947-.20512219-.1818947-.53736545%200-.74259704.1818947-.20523159.4765156-.2051222.6585073%200L4.2595772%206.73316533l4.94753573-5.57932368c.18189469-.2051222.47651559-.2051222.6585073%200%20.18199171.20512219.18160366.53605266-.01629777.72203012z%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%23FFF%22%20stroke-width%3D%22.64%22%2F%3E%3C%2Fsvg%3E');
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
    }

    input:checked ~ label::after {
      opacity: 1;
    }
  }
}

.fancybox-skin .dropdown-content .content-wrapper {
  @media (max-width: 511px) {
    width: 100%;
  }
}

body:has(.floating-toggle) .footer,
body:has(#autochat-chat-start--bubble) .footer {
  padding-bottom: 70px;
}