.search {
  //position: static !important;
  width: 100%;
  margin-top: var(--48px);

  @include min-media(992px) {
    display: flex;
    margin-top: var(--94px);
  }
}

.search .dialog__title,
.search .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);

  @include min-media(768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search-list {
  width: 100%;
  flex: 1 1 auto;
  margin-top: -0.7em;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;

  @include min-media(768px) {
  }

  @include min-media(992px) {
    max-width: 670px;
    margin-bottom: 96px;
  }
}

.search-results-list__item {
  position: relative;
  width: 100%;
  padding: 0 0 var(--6px);
  box-shadow: 0 0 0 0;
  margin: 0 0 0;
  font-size: var(--18px);
  line-height: 40px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;

  .heading {
    margin-bottom: var(--10px);
  }

  a {
    text-decoration: none;
    display: block;
    width: 100%;
    border-bottom: 1px solid var(--apt-grey-7);
    transition: border-bottom-color 0.2s linear;
    padding-top: 29px;
    padding-bottom: 27px;

    @include min-media(992px) {
      padding-top: 35px;
      padding-bottom: 43px;
    }

    &:hover, &:focus, &:active {
      border-bottom-color: var(--apt-primary);
    }
  }

  p {
    color: var(--apt-grey-3);
    font-weight: var(--fw-regular);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    strong, b {
      font-weight: var(--fw-bold);
    }
  }
}

.search-results-list__item:last-child a {
  @include max-media(991px) {
    border-bottom: 0;
  }
}

.search__filters {
  flex: 0 0 auto;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 12px;
  background: var(--apt-white);
  border-radius: 10px;

  @include min-media(992px) {
    width: 315px;
    padding: 0 35px 0 0;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.search__filters .heading-2 {
  text-align: left;
  width: 200px;
  margin-bottom: 38px;

  @include max-media(991px) {
    max-width: 50%;
    margin-bottom: 14px;
  }

  @include min-media(512px) {
    width: auto;
  }

  @include min-media(992px) {
    height: 96px;
    margin-right: 0;
    margin-left: 0;
  }
}

.search__filter-container .filter-dropdown {
  @include max-media(991px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.search__filter-container {
  @include max-media(991px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--apt-white);
    z-index: 4;
    padding-top: 52px + 59px;
    transform: translateY(-100%);
    transition: transform 0.2s ease-in, margin-top 0.2s ease-in;
    padding-left: var(--15px);
    padding-right: var(--15px);
    overflow: auto;
  }
}

.search__filter-container--toggled {
  transform: none;
}

.search__filter-container .heading-2 {
  display: none;

  @include max-media(991px) {
    display: block;
    margin-bottom: var(--39px);
  }
}

.search__filter-header {
  @include max-media(991px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.search__filter-open-button {
  display: none;

  @include max-media(991px) {
    display: block;
    font-size: var(--16px);
    line-height: 24px;
    font-weight: var(--fw-regular);
    color: var(--apt-primary);
    margin-bottom: 14px;
  }
}

.search__filter-close-button {
  display: none;

  @include max-media(991px) {
    position: absolute;
    top: 52px + 20px;
    right: var(--15px);
    display: block;
    font-size: var(--20px);
    line-height: 24px;
    font-weight: var(--fw-light);
    color: var(--apt-grey-2);
  }
}

.search__sticky-container {

  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 511px) {
    max-width: none;
  }

  @include min-media(992px) {
    margin-bottom: 40px;
  }
}

.search__results {
  width: calc(100% + 30px);
  flex: 1 1 auto;
  margin-top: 45px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  position: relative;
  left: -15px;

  @include min-media(768px) {
    width: 100%;
    left: 0;
    max-width: 670px;
  }

  @include min-media(992px) {
    width: 100%;
    max-width: none;
    margin-top: calc(96px + 28px);
    margin-bottom: 96px;
  }
}

.search .dialog__title,
.search .opening-hours-card {
  width: calc(100% - 30px);
  margin-left: var(--15px);
  margin-right: var(--15px);

  @include min-media(768px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search__result {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;

  @include min-media(768px) {
    max-width: none;
  }
}

.search__result .dialog {

  @include min-media(768px) {
    padding-left: var(--15px);
    padding-right: var(--15px);
  }

  @include min-media(992px) {
    padding-right: 0;
  }
}

.search__result .dialog .dialog__title .fa-chevron-down {
  display: block;
}

.search__result .dialog .dialog__title .fa-times {
  display: none;
}

.search__result .dialog--toggled .dialog__title .fa-chevron-down {
  display: none;
}

.search__result .dialog--toggled .dialog__title .fa-times {
  display: block;
  transform: rotate(180deg);
}