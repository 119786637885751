.header {
  position: fixed;
  //position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  flex: 0 0 auto;
  z-index: 5;

  //@supports not (position: sticky) {
  //  position: fixed;
  //}

  @include min-media(992px) {
    transition: top 0.3s linear;
    //top: -40px;

    //@supports not (position: sticky) {
    //  top: 0;
    //}
  }
}

.header-container {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: var(--apt-white);

  @include min-media(992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: initial;
  }
}

.header-static-section {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(var(--50px) + 78px);
  background-color: var(--apt-grey-9);
  z-index: 1;

  @include min-media(992px) {
    position: static;
    height: 40px;
    z-index: 1;
    margin-top: 0;
    transition: margin-top 0.2s ease-in;
    will-change: margin-top;
  }
}

.header-static-section .header-list a {
  font-size: var(--15px);
  line-height: 24px;
  letter-spacing: 0.5px;
}

.header-static-section .header-list a,
.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type="button"],
.header-static-section .header-cta input[type="submit"] {
  color: var(--apt-grey-2);
}

.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type="button"],
.header-static-section .header-cta input[type="submit"] {
  font-size: var(--15px);
  line-height: 24px;

  @media (min-width: 992px) {
    font-size: var(--18px);
    line-height: 28px;
  }
}

.header-static-section .header-list a:hover,
.header-static-section .header-cta button:hover,
.header-static-section .header-cta input[type="submit"]:hover,
.header-static-section .header-cta input[type="button"]:hover {
  color: var(--apt-black);
}

.header-static-section .header-container {
  padding: 0 var(--15px) 0 var(--15px);

  @include min-media(768px) {
    padding: 0 var(--50px) 0 var(--50px);
  }

  @include min-media(1200px) {
    padding: 0 var(--85px) 0 var(--85px);
  }
}

.header-static-items-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-static-section nav ul,
.header-static-section .header-cta {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.header-static-section nav {
  display: none;
  flex: 1 0 auto;
  height: 100%;

  @include min-media(992px) {
    display: block;
  }
}

.header-static-section nav ul li {
  padding: 0 0 0 1.15em;
  display: inline-flex;
}

.header-static-section .header-cta {
  flex: 0 0 auto;
  height: 100%;

  /* TODO: Deze zorgen voor de uitlijning van de zoek, social en fav icons in de header. Zet terug als die verwerkt worden. */
  @media (min-width: 992px) {
    transform: none;
    transition: transform 0.3s linear;
  //  margin-right: 6px;
  //  margin-left: 3px;
  }
}

.header-static-section .header-cta a,
.header-static-section .header-cta button,
.header-static-section .header-cta input[type="button"],
.header-static-section .header-cta input[type="submit"] {
  position: relative;
  top: 0.075em;
  padding: 0 0 0 0.95em;
  text-align: center;
  align-self: center;

  @media (min-width: 992px) {
    padding: 0 0 0 1.2em;
  }
}

.header-static-section .header-cta > button:last-of-type {
  padding-left: 1.03em;
}

.header-static-section .header-cta a i,
.header-static-section .header-cta button i,
.header-static-section .header-cta input[type="button"] i,
.header-static-section .header-cta input[type="submit"] i {
  width: 20px;
  font-size: 1.1em;
  line-height: 1;
}

.header-static-section .header-cta a svg,
.header-static-section .header-cta button svg,
.header-static-section .header-cta input[type="button"] svg,
.header-static-section .header-cta input[type="submit"] svg {
  position: relative;
  top: 0rem;
  width: 0.9rem;

  @media (min-width: 992px) {
    top: 0;
    width: 1.1rem;
  }
}

.header-sticky-section {
  width: 100%;
  height: 52px;
  background-color: var(--apt-white);
  z-index: 1;
  transition: height 0.3s linear;

  @include min-media(992px) {
    height: 90px;
  }
}

.header-sticky-section::before {
  content: "";
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  border-bottom: 1px solid var(--apt-grey-7);
  z-index: 2;
  pointer-events: none;
}

//.header-sticky-section .header-container {
//  padding: 0 var(--10px) 0;
//
//  @include min-media(768px) {
//    padding: 0 var(--35px) 0 var(--50px);
//  }
//  @include min-media(1200px) {
//    padding: 0 var(--70px) 0 var(--85px);
//  }
//}

.header-sticky-section nav > ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: var(--35px);
  padding-bottom: var(--35px);

  @include min-media(992px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    flex-wrap: wrap;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header-sticky-section nav > ul > li {
  padding: 11px 15px 11px 40px;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0;

  @include min-media(992px) {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0.62em 0 0.54em;
    transition: font-size 0.2s linear;
  }

  @include min-media(1200px) {
    padding: 0 0.82em 0 0.84em;
  }
}

.header-sticky-section nav > ul > li:not([class]) {
  padding-bottom: 0.33em;
}

.header-sticky-section nav > ul > li:last-child {
  @include min-media(992px) {
    padding: 0 0 0 25px;
  }
}

.header-list {
  position: relative;
}

.header-list a,
.header-list-item--primary > button {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  text-decoration: none;
  color: inherit;

  @media (min-width: 992px) {
    display: inline-block;
    width: auto;
  }
}

.header-list li:not([class]) a::after,
.header-list-item--primary > a::after,
.header-list-item--primary > button::after {
  content: "\f178";
  font-family: var(--fa);
  font-weight: var(--fw-light);
  margin-left: auto;
  color: var(--apt-grey-2);

  @media (min-width: 992px) {
    display: none;
  }
}

.header-list a:hover {
  color: var(--apt-primary);
}

.header .logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--apt-white);
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 var(--10px);

  @include min-media(992px) {
    width: auto;
    height: auto;
  }

  @include min-media(768px) {
    padding: 0 0 0 var(--50px);
  }
  @include min-media(1200px) {
    padding: 0 0 0 var(--85px);
  }
}

.header .logo {
  flex: 0 0 auto;
}

.header .logo img,
.header .logo svg {
  display: block;
  width: 76px;
  position: relative;
  top: 0;

  @include min-media(992px) {
    width: 123px;
  }
}

.header-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--apt-white);
  z-index: 1;
  padding-top: 52px;
  transform: translateY(-100%);
  transition: transform 0.2s ease-in, margin-top 0.2s ease-in;

  padding-right: var(--10px);

  @include min-media(768px) {
    padding-right: var(--35px);
  }
  @include min-media(1200px) {
    padding-right: var(--70px);
  }

  @include min-media(992px) {
    position: static;
    display: block;
    width: auto;
    height: 100%;
    padding-top: 0;
    transform: none;
  }
}

.header-menu nav {
  width: 100%;
  overflow: auto;
  margin-bottom: 83px; // Tijdelijk uit doordat afspraak maken button is weg gehaald

  @media (min-width: 512px) {
    margin-bottom: 105px;
  }

  @media (min-width: 992px) {
    overflow: visible;
    margin-bottom: 0;
    height: 100%;
  }
}

.header--open .header-menu {
  transform: translateY(0);

  @include min-media(992px) {
    transform: none;
    margin-top: 0;
  }
}

.header--open #headerToggleButton .fa-bars {
  display: none;
}

.header:not(.header--open) #headerToggleButton .fa-times {
  display: none;
}

.header--open #headerToggleButton .fa-times {
  display: block;
}

.header #headerToggleButton {
  @include min-media(992px) {
    display: none;
  }
}

.header-sticky-section nav ul li.header-list-item--last-primary {
  position: fixed;
  right: 0;
  bottom:0;
  left: 0;
  padding: 20px 36px 22px;
  background-color: var(--apt-white);
  border-top: 1px solid var(--apt-grey-7);
  overflow: hidden;
  height: 105px;

  @include min-media(375px) {
    height: auto;
  }

  @include min-media(992px) {
    position: static;
    padding: 0 var(--15px) 0 0.7em;
    border-top: 0;
  }

  @include min-media(1200px) {
    padding: 0 var(--15px) 0 1.5em;
  }
}

.header-sticky-section nav ul li.header-list-item--last-primary .button {
  width: 100%;

  @include min-media(992px) {
    transform: none;
    transition: transform 0.3s ease-in;
    padding: 6px 20px 8px;
  }

  @include min-media(1100px) {
    padding: 6px 26px 8px;
  }
}

.header-sticky-section nav ul li.header-list-item--secondary {
  color: var(--apt-grey-2);
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.37px;
  padding-top: 8px;
  padding-bottom: 8px;

  @include min-media(992px) {
    display: none;
  }
}

.header-sticky-section nav ul li.header-list-item--first-secondary {
  border-top: 1px solid var(--apt-grey-7);
  margin-top: 30px;
  padding-top: 30px;
}

.header-sticky-section .header-list > li > a,
.header-sticky-section .header-list > li > button[type="button"] {
  text-align: left;
  width: 100%;
  font-weight: var(--fw-medium);

  @include min-media(992px) {
    width: auto;
  }
}

.floating-quantity {
  position: fixed;
  top: 12px;
  transform: translate(0.95em, 0.1em);
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  font-size: 0.6em;
  line-height: 1.1;
  color: white;
  text-align: center;
  font-weight: var(--fw-medium);
  z-index: 7;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s linear, transform 0.3s linear;
  padding-left: 0em;

  @media (min-width: 992px) {
    position: fixed;
    top: 8px;
    transform: translate(0.95em, -0.1em);
  }
}

.floating-quantity--visible {
  opacity: 1;
}

.floating-quantity--heart {
  background-color: var(--apt-error-1);
}
.floating-quantity--circle-plus {
  background-color: var(--apt-primary);
}

.a-point-logo__point {
  transform: none;
  will-change: transform;
  transition: transform 0.3s ease-out;
}

.header--minified {
  @include min-media(992px) {
    top: -40px;
  }
}

.header--minified .header-static-section {
  //margin-top: -40px;
}

.header--minified .header-sticky-section {
  height: 52px;

  @include min-media(992px) {
    height: 60px;
  }
}

.header--minified .a-point-logo__point {
  @include min-media(992px) {
    transform: translateY(-101%);
  }
}

.header--minified .header-sticky-section nav ul li.header-list-item--last-primary .button {
  @include min-media(992px) {
    transform: translateY(140%);
  }
}

.header--minified .header-static-section .header-cta {
  @include min-media(992px) {
    transform: translateY(100%) translateY(0.5em);
  }
}

.header--minified .header-menu,
.header--open.header--minified .header-menu {
  @include min-media(992px) {
    margin-top: 2px;
  }
}

.header--minified ~ .floating-quantity {
  @include min-media(992px) {
    transform: translate(0.95em, 0.8em);
  }
}

.header-sticky-section nav ul li.header-list-item--has-submenu {
    button{
        cursor: default;
    }
}

.submenu {
  position: fixed;
  top: 52px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--apt-white);
  z-index: 5;
  list-style: none;
  padding-bottom: var(--35px);
  margin: 0;
  pointer-events: none;
  overflow: auto;
  transform: translateX(100%);
  transition: transform 0.2s ease-in;

  @include min-media(992px) {
    position: absolute;
    top: 90px;
    left: calc((50px - 0.84em) * -1);
    bottom: unset;
    width: max-content;
    min-height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 0 40px 0;
    opacity: 0;
    overflow: visible;
    background: rgba(255,255,255,0.98);
    transform: none;
    transition: top 0.2s ease-in, opacity 0.2s ease;
  }

  @include min-media(1550px) {
    position: absolute;
    left: calc((50px - 0.84em) * -1);
    right: unset;
  }
}

.submenu-mobile-header {
  margin-bottom: 45px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--apt-grey-7);

  @include min-media(992px) {
    display: none;
  }

  button {
    position: absolute;
    left: 12px;
    width: 24px;
    height: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: var(--apt-grey-2);
  }
}

.submenu--align-right {
  @include min-media(992px) {
    left: unset;
    right: calc((50px - 0.84em) * -1);
  }
}

.header-list-item--has-submenu:hover .submenu {
  @include min-media(992px) {
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
}

.submenu--toggled {
  pointer-events: auto;
  transform: none;

  @include min-media(992px) {
    opacity: 1;
  }
}

.submenu-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 40px;
  padding-right: 17px;
  margin-bottom: 40px;
  flex: 0 0 auto;

  @media (min-width: 992px) {
    width: fit-content;
    max-width: 240px;
    padding-right: 14px;
    padding-left: 50px;
    margin-bottom: 0;
  }

  &:last-of-type { // .submenu-column:last-of-type
    @media (min-width: 992px) {
      margin-right: 50px;
    }
  }
}

.submenu-column--border {

  @include min-media(992px) {
    border-right: 1px solid var(--apt-grey-5);
  }
}

.submenu-column--centered {
  align-self: center;
}

.submenu-column--centered .button {
    text-align: center;
  justify-content: center;
  align-items: baseline;
}

.submenu-column header {
  font-size: var(--22px);
  line-height: 30px;
  color: var(--apt-black);
  font-weight: var(--fw-medium);
  margin-bottom: 15px;
}

.submenu-list {
  width: 100%;

  @include min-media(992px) {
    width: auto;
  }
}

.submenu-column--border .submenu-list {
  padding-bottom: 23px;
}

.submenu li {
  padding: 11px 0;
  margin: 0;
  font-size: var(--18px);
  line-height: 22px;
  color: var(--apt-black);
}

.submenu li a {
  font-weight: var(--fw-regular);
}

.header--minified .submenu {
  top: 60px;
}
