.dialogs {
  margin-top: 20px;
  margin-bottom: 43px;
  border-top: 1px solid var(--apt-grey-7);

  @media (min-width: 768px) {
    margin-top: 50px;
    margin-bottom: 100px;
  }
}

.dialog {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding: 0 0 var(--6px);
  border-bottom: 1px solid var(--apt-grey-7);
  box-shadow: 0 0 0 0;
  min-height: 80px;
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 40px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.dialog__title:hover + .dialog__content {
  border-bottom-color: var(--apt-grey-2);
}

.dialog__title:focus + .dialog__content,
.dialog__title:active + .dialog__content {
  border-bottom-color: var(--apt-grey-1);
  color: inherit;
}

.dialog__title:focus {
  border-color: var(--apt-grey-7);
}

.dialog__title .far {
  position: relative;
  width: var(--21px);
  right: var(--24px);
  color: var(--apt-grey-3);
  font-size: var(--18px);
  align-self: center;
  font-weight: var(--fw-light);
}

.dialog .dialog__title .fa-chevron-down {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
}

.dialog--toggled .dialog__title .fa-chevron-down {
  transform: rotate(180deg);
}

.dialog .dialog__title .fa-times {
  display: none;
}

@supports (padding: max(0px)) {
  .dialog__content {
    transition: max-height 0.2s linear;
  }
}

.dialog:not(.dialog--toggled) .dialog__content {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .dialog--toggled .dialog__content {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}

.dialog__content span {
  display: block;
}

.dialog--toggled {
  position: relative;
  z-index: 2;
}

.dialog--toggled .dialog__title {
  border-bottom-color: var(--transparent);
}

// Filter dialog

.filter-dropdown {
  position: relative;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
}

.filter-dialog {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.filter-dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  user-select: none;
  touch-action: manipulation;
  padding:  var(--12px) 0;
  border-bottom: 1px solid var(--apt-grey-7);
  box-shadow: 0 0 0 0;
  height: var(--42px);
  margin: 0 0 0;
  font-family: inherit;
  font-size: var(--18px);
  line-height: 28px;
  letter-spacing: 0.3px;
  font-weight: var(--fw-medium);
  color: var(--apt-black);
  background-color: inherit;
  border-radius: 0;
  appearance: none;
  z-index: 1;
  transition: border-bottom-color 0.2s linear;
}

.filter-dialog__title--filter-active {
  border-bottom-color: var(--apt-primary);
}

.filter-dialog__content-wrapper {
  padding: 22px 0 8px;
}

.filter-dialog__content-wrapper .form__group:first-child {
  margin-top: 0;
}

.filter-dialog__content-wrapper .checkbox-wrapper {
  margin-top: 0;
}

.filter-dialog__content-wrapper .checkbox-wrapper ul li {
  margin-bottom: 18px;
}

.filter-dialog__title:focus + .filter-dialog__content,
.filter-dialog__title:active + .filter-dialog__content {
  color: inherit;
}

.filter-dialog__title .far {
  color: var(--apt-black);
  align-self: center;
}

@supports (padding: max(0px)) {
  .filter-dialog__content {
    transition: max-height 0.2s linear;
  }
}

.filter-dialog:not(.filter-dialog--toggled) .filter-dialog__content {
  max-height: 0px !important;
}

@supports (padding: max(0px)) {
  .filter-dialog--toggled .filter-dialog__content {
    --max-height: 0px;
    max-height: var(--max-height);
    overflow-y: visible;
  }
}

.filter-dialog__content span {
  display: block;
}

.filter-dialog--toggled {
  position: relative;
  z-index: 2;
}

.filter-dialog .filter-dialog__title:not(.filter-dialog__title--filter-active):focus {
  border-bottom-color: var(--apt-grey-7);
}

.filter-dialog .filter-dialog__title .far {
  transition: transform 0.25s linear;
  transform: rotate(0deg);
  font-size: var(--18px);
  line-height: 18px;
  font-weight: var(--fw-light);
  color: var(--apt-grey-3);
}

.filter-dialog .filter-dialog__title .fa-times {
  display: none;
}

.filter-dialog--toggled .filter-dialog__title .far {
  transform: rotate(180deg);
}

.wmpform .filter-dialog .form__checkbox {
  margin-top: 10px;
  margin-bottom: 15px;
}