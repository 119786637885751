.gallery-grid {
  width: 100%;
  max-width: 100%;
  display: flex;

  @media (min-width: 768px) {
    margin-bottom: 38px;
  }

  @media (min-width: 1100px) {
    max-width: 764px;
  }
}

.gallery-grid__primary {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 0;

  @media (min-width: 768px) {
    border-radius: 10px;
  }
}

.gallery-grid__side {
  flex: 0 0 auto;
  width: 20%;
  max-width: 154px;
  display: none;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.gallery-grid__side-item {
  position: relative;
  width: calc(100% - 20px);
  max-height: 22%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.gallery-grid__side-item img {
  cursor: pointer;
}

.gallery-grid__side-item button,
.gallery-grid__side-item input[type="button"],
.gallery-grid__side-item input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 7% 7% 16%;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1));
  background-position: bottom;
  background-size: 100% 180%;
  background-repeat: no-repeat;
  color: var(--apt-white);
  font-size: var(--14px);
  line-height: 16px;
  letter-spacing: 0.3px;

  @media (min-width: 992px) {
    font-size: var(--12px);
    line-height: 14px;
  }
  @media (min-width: 1100px) {
    padding: 7% 12% 11%;
    font-size: var(--16px);
    line-height: 18px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%) translateY(0) rotate(45deg);
    background: none;
    width: 10px;
    height: 10px;
    transform-origin: center;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    transition: transform 0.2s ease;

    @include min-media(992px) {
      bottom: 7px;
      width: 6px;
      height: 6px;
    }

    @media (min-width: 1100px) {
      bottom: 9px;
    }

    @include min-media(1300px) {
      bottom: 14px;
      width: 10px;
      height: 10px;
    }
  }

  &:hover::after {
    transform: translateX(-50%) translateY(3px) rotate(45deg);
  }
}

.gallery-grid__side-item:last-of-type {
  margin-bottom: 0;
}

.gallery-grid img,
.gallery-grid canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-slider {
  position: relative;
  padding: 0 0 0;
  border-radius: 10px;

  @media (max-width: 767px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.gallery-slider__slider-container {
  width: 100%;
}

.gallery-slider__pagination {
  position: relative;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  overflow-x: auto;
  padding-left: var(--15px);
  padding-right: var(--15px);
  padding-bottom: 15px;
  font-size: var(--14px);
  line-height: 20px;
  color: var(--apt-grey-4);

  @media (min-width: 768px) {
    display: none;
  }

  &.swiper-pagination-fraction {
    bottom: 0 !important;
    right: 0;
    left: unset;
    width: auto;
  }
}

.gallery-slider .swiper-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.gallery-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.gallery-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;
}

.gallery-slider figure button {
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.gallery-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  z-index: 8;
  width: 100%;
  color: var(--apt-white);
  padding: 50px 0 0 0;
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease;

  @media (min-width: 1100px) {
    padding: 80px 110px 0 0;
  }
}

.gallery-overlay--hidden {
  opacity: 0;
  pointer-events: none;
}

.gallery-overlay__close {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: var(--apt-black);
  text-align: center;
  z-index: 2;
  border-radius: 40px;

  @media (min-width: 1100px) {
    top: 50px;
    right: 40px;
    width: 45px;
    height: auto;
    padding: 0;
    background: none;
    border-radius: 0;
  }
}

.gallery-overlay__header {
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  padding: 0 20px;
}

.gallery-overlay__header--full {
  max-width: 1380px;

  @media (min-width: 512px) and (min-height: 600px) {
    padding-left: 70px;
  }

  @media (min-width: 1100px) {
    padding-left: 110px;
  }
}

.gallery-overlay__header span {
  font-size: var(--40px);
  line-height: 48px;
  letter-spacing: -0.5px;
  font-weight: var(--fw-medium);
}

.gallery-overlay__header span button {
  display: block;

  @media (min-width: 512px) {
    display: inline-block;
  }
}

.gallery-overlay__header span input[type="button"],
.gallery-overlay__header span input[type="submit"],
.gallery-overlay__header span button {
  font-size: 0.75em;
  line-height: 1;
  font-weight: var(--fw-light);
  margin-right: 0.51em;
}

.gallery-overlay__header .detail-sidebar__characteristics {
  font-size: var(--16px);
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-top: 1em;
  margin-bottom: 56px;

  @media (min-width: 512px) {
    margin-left: var(--48px);
  }
}

.gallery-overlay__content {
  width: 100%;
  height: auto;
  padding: 0 0 20px;
  margin: 0 auto;

  @media (min-width: 512px) and (min-height: 600px) {
    padding: 0 70px 60px 70px;
  }
  @media (min-width: 512px) and (max-height: 599px) {
    padding: 0 0 20px 0;
  }

  @media (min-width: 1100px) {
    padding: 0 0 100px 110px;
    margin: 0;
  }
}

.gallery-overlay__content--full {
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.gallery-overlay__content .swiper-slide {
  height: auto;
}

.gallery-overlay__gallery {
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.gallery-overlay__gallery figure {
  margin-bottom: 52px;

  @media (min-width: 1100px) {
    margin-bottom: 66px;
  }
}

.gallery-overlay__gallery img {
  max-width: 100%;
  width: auto;
  max-height: calc(90vh - 40px);
  margin: auto;
  display: block;

  @media (min-height: 600px) {
    width: 100%;
  }

  @media (min-width: 512px) {
    border-radius: var(--10px);
  }
}

.gallery-slider__buttons {
  @media (max-width: 767px) {
    display: none;
  }
}

.gallery-slider-button {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  line-height: 30px;
  font-size: var(--13px);
  border-radius: 30px;
  color: var(--apt-grey-4);
  z-index: 1;

  @media (min-width: 768px) {
    background-color: rgba(255,255,255,0.9);
  }
}

.gallery-slider-prev-button {
  left: 10px;

  @media (min-width: 768px) {
    left: 20px;
  }
}

.gallery-slider-next-button {
  right: 10px;

  @media (min-width: 768px) {
    right: 20px;
  }
}

.gallery-slider-button {
  opacity: 0 !important;
  background-color: rgba(255,255,255,0.9);
  transition: opacity 0.2s linear;
}

.offer-card-slider-container .gallery-slider-prev-button {
  left: 10px;
}
.offer-card-slider-container .gallery-slider-next-button {
  right: 10px;
}

.gallery-grid:hover .gallery-slider-button,
.offer-card-slider-container:hover .gallery-slider-button {
  opacity: 1 !important;
}

.gallery-slider-button:focus {
  border-radius: 30px;
  opacity: 1 !important;
}

.gallery-slider-button:hover {
  opacity: 1 !important;

  @media (min-width: 768px) {
    background-color: rgba(255,255,255,1);
  }
}

.gallery-slider-button[disabled] {
  opacity: 0.2;
}

.gallery-grid:hover:hover .gallery-slider-button[disabled],
.offer-card-slider-container:hover .gallery-slider-button[disabled] {
  opacity: 0.2 !important;
}

.gallery-grid--force-reload {
  border-radius: 0 !important;
}

.gallery-overlay-slider {
  width: 100%;

  @media (min-height: 599px) {
    max-width: 720px;
  }

  @media (min-width: 1100px) {
    max-width: 830px;
  }
}

.gallery-overlay-slider-button {
  position: absolute;
  top: calc(50% - 26px);
  z-index: 1;
  background-color: rgba(255,255,255,0.8);
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--apt-grey-4);
  transform: translateY(-50%);
  font-size: var(--13px);
  line-height: 30px;
  border-radius: 30px;

  @media (min-width: 1100px) {
    top: calc(50% - 33px);
  }

  &:focus {
    border-radius: 30px;
    background-color: rgba(255,255,255,1);
  }

  &:hover {
    background-color: rgba(255,255,255,1);
  }
}

.gallery-overlay-slider-next-button {
  right: 22px;

  @media (max-width: 767px) {
    right: 16px;
  }
}

.gallery-overlay-slider-prev-button {
  left: 22px;

  @media (max-width: 767px) {
    left: 16px;
  }
}

.gallery-overlay-slider__pagination {
  color: var(--apt-grey-4);

  @media (min-width: 1100px) {
    bottom: 0 !important;
  }
}

.card-slider {
  position: relative;
  padding: 0 0 0;
}
.card-slide figure {
  border-radius: 0;
}
.card-slider .swiper-slide {
  background: none;
  height: auto;
  align-items: stretch;
  padding: 0;
}

.card-slider figure {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  cursor: pointer;
}

.card-slider figure button {
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.card-slider figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-grid__toggle-container {
  position: absolute;
  bottom: 15px;
  left: 22px;
  z-index: 1;

  @media (max-width: 767px) {
    left: 17px;
  }
}

.gallery-grid__toggle-container button {
  display: block;
}

.gallery-grid__toggle-container svg,
.gallery-grid__toggle-container img {
  width: 30px;
  height: 30px;
  display: block;
}

.gallery-grid__toggle-container--photo {
  @media (max-width: 767px) {
    bottom: 82px;
    transform: translateY(50%);
  }
}

.offer-card-listing-header .gallery-grid__toggle-container {
  position: static;
  width: 100%;
  height: 20px;
  color: var(--apt-primary);
  text-decoration: underline;
  font-size: var(--12px);
  line-height: 1.667;
  font-weight:var(--fw-medium);
  margin-bottom: var(--13px);
}

.gallery-grid__primary--360 {
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.gallery-grid__fullscreen-container {
  position: absolute;
  bottom: 15px;
  right: 22px;
  z-index: 1;

  @media (max-width: 767px) {
    right: 17px;
  }
}

.gallery-grid__fullscreen-container button {
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--apt-white);
  border-radius: 30px;
  border: 1px solid var(--apt-grey-4);
}

.gallery-grid__fullscreen-container button svg {
  width: 13px;
  height: 13px;
}

.gallery-grid__fullscreen-container button svg path {
  fill: var(--apt-grey-4);
}

.gallery-grid__fullscreen-container--photo {
  @media (max-width: 767px) {
    bottom: 82px;
    transform: translateY(50%);
  }
}

.gallery-grid__user-interaction {
  @media (min-width: 992px) {
    display: none;
  }

  .user-interaction-dialog__button {
    width: 30px;
    height: 30px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--apt-white);
    border-radius: 30px;
    border: 1px solid var(--apt-grey-4);
    font-size: 75%;
    color: var(--apt-grey-4);
  }

  &.user-interaction-dialog--toggled .user-interaction-dialog__button {
      border-color: var(--apt-primary);
  }
}

.gallery-overlay-spinner {
  position: relative;
  width: 100%;
  max-width: 1270px;
  margin: 0 auto;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
.gallery-overlay-spinner .gallery-grid__sirv-container {
  @media (max-width: 767px) {
    order: 1;
  }
}
.gallery-overlay-spinner .gallery-button-list {
  @media (max-width: 767px) {
    position: static;
    order: 2;
    padding: 44px 15px;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  li { // .gallery-overlay-spinner .gallery-button-list li
    @media (max-width: 767px) {
      margin: 0 10px;
    }
  }
}

.gallery-container--spinner {
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  .gallery-button-list { // .gallery-container--spinner .gallery-button-list
    @media (max-width: 767px) {
      position: static;
      order: 2;
      padding: 18px 10px 6px;
      margin-bottom: 32px;
      width: 100%;
      max-width: 100%;
      justify-content: center;
      flex-wrap: wrap;
    }

    @media (max-width: 319px) {
      width: 220px;
      margin-left: auto;
      margin-right: auto;
    }

    li { // .gallery-container--spinner .gallery-button-list li
      @media (max-width: 767px) {
        margin: 0 5px 12px;
      }
    }
  }
}

.gallery-container--spinner .gallery-overlay-spinner .gallery-button-list {
  @media (max-width: 511px) {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  li {
    @media (max-width: 511px) {
      margin: 0 9px 18px;
    }

    @media (min-width: 768px) {
      margin: 0 var(--15px) var(--12px) 0;
    }
  }
}